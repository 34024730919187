//by yi 2020 kuaipf project


import React from 'react';
import './App.css';




class PreButton extends React.Component{




    constructor(){
        super()
        this.state ={
            initial:1,
            buttonContent:{
                transition:'0.15s ease-out all',
                width:'180px',
                height:'45px',
                backgroundColor:'#f6d808',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor:'#f6d808',
                color:'#000',


            },
            buttoninsidebg:{
                transition:'0.15s ease-out all',
                height:'40px',
                width:'110px',
                marginTop:'15px',
                backgroundColor:'',
                alignItems: "center",
                display:"flex",
                justifyContent: 'center',
                cursor: 'pointer',
                borderRadius: '20px',
            },
        }

        this.changevalue =this.changevalue.bind(this)

    }

    changevalue(){
        //this.setState({initial:2})
        this.setState(prevState=>{
            return{
                initial:prevState.initial +1
            }
        })
    }

    mouseEnter(event) {
        //console.log('in')

        this.setState({
                buttonContent:{
                    transition:'0.15s ease-out all',
                    width:'180px',
                    height:'45px',
                    backgroundColor:'#f6d808',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor:'#f6d808',
                    color:'#f6d808',


                },
                buttoninsidebg:{
                    transition:'0.15s ease-out all',
                    height:'40px',
                    width:'110px',
                    marginTop:'15px',
                    backgroundColor:'#fff',
                    alignItems: "center",
                    display:"flex",
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                },
            }
        )

    }

    mouseOut(event) {
        //console.log('out')
        this.setState({
                buttonContent:{
                    transition:'0.15s ease-out all',
                    width:'180px',
                    height:'45px',
                    backgroundColor:'#f6d808',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor:'#f6d808',
                    color:'#000',


                },
                buttoninsidebg:{
                    transition:'0.15s ease-out all',
                    height:'40px',
                    width:'110px',
                    marginTop:'15px',
                    backgroundColor:'',
                    alignItems: "center",
                    display:"flex",
                    justifyContent: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                },
            }
        )
    }
    mouseDown(event){

    }

    render(){

        const styles={
            buttonViewbox:{
                display:'flex',
                alignItems: 'center',
                width:'180px',
                height:'45px',

            },
            buttonInfo:{

                alignItems: 'center',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                verticalAlign:'center',
                textAlign:'center',
                width:'180px',
                height:'45px',
                position:'absolute',

            }

        }


        return(
            <div onClick={()=>this.props.callBack()} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }} style={this.state.buttoninsidebg}>
                <img src={require('../asset/arrowleft.png')}   style={{backgroundColor:'',minWidth:'30px',width:'1vw',paddingTop:'6px'}}  /><span className="contentText_center" style={{lineHeight:"40px",backgroundColor:''}} >上一步</span>
            </div>
        )


    }
}



export default PreButton