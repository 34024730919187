/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Button, Result,message,Typography,Space,Divider,Statistic, Row, Col} from "antd";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    LoadingOutlined,
    ThunderboltOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/Adminbridge";


const { Title,Text,Paragraph } = Typography;

/*
* choose registration account type
*/



class Termsview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            info:null,
            error:false,
            errorContent:'',
            loading:true,
            editorState: BraftEditor.createEditorState('<p>Hello <b>World!</b></p>'), // 设置编辑器初始内容
            outputHTML: '<p></p>',
            article:'',


        }


        this.saveArticle =this.saveArticle.bind(this)


    }

    componentDidMount() {
        this.isLivinig = true
        // 3秒后更改编辑器内容
      //  setTimeout(this.setEditorContentAsync, 3000)
    }

    componentWillUnmount () {
        this.isLivinig = false
    }

    componentWillMount() {
        this.Get_overview_info()
    }

    handleChange = (editorState) => {
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML()
        })
    }

    setEditorContentAsync = (content) => {
        this.isLivinig && this.setState({
            editorState: BraftEditor.createEditorState(content)
        })
    }



    /**
     * get info
     * @constructor
     */

    Get_overview_info(){
        const postdata={
            id:1
        }
        Http.get_articles(postdata).then((res)=>{

            this.setState({
                article:res.data,
                loading:false,
            },()=>{this.setEditorContentAsync(res.data.article)})

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }


    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    saveArticle(){
        const postdata={
            id:1,
            content:this.state.outputHTML,
        }
        Http.edit_articles(postdata).then((res)=>{

            message.success(`Terms update successfully.`);
        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }





    render() {

        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>



                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',


                    }}>
                        <Row gutter={16}>
                            <Col span={22}>
                                <Space>
                                    <Title level={5} style={{paddingTop: '6px'}}>服务条款</Title>

                                    <NotificationOutlined/>
                                </Space>
                                <Paragraph>
                                    快平方v1.0.0服务条款。
                                </Paragraph>
                            </Col>

                            <Col span={2}>
                                <Button type={"text"} onClick={this.saveArticle}>保存</Button>
                            </Col>
                        </Row>




                        <div className="editor-wrapper">
                            <BraftEditor
                                value={this.state.editorState}
                                onChange={this.handleChange}
                            />
                        </div>



                    </div>



                </div>
            )
        }
    }
}


export default Termsview;