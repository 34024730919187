/*const Beforeafter=[
    {id:0,type:'before',text:'我自己找了一些freelancer，发现他们很难被管理，也很难对时间和预算负责。'},
    {id:1,type:'before',text:'下完了brief 后过了五天，代理商还在为内部人手分配烦恼。'},
    {id:2,type:'before',text:'我现在每个月都要给固定月费到代理公司，感觉经常是为工作而来提案很难有好作品，太形式化了。'},
    {id:3,type:'before',text:'现今营销又是AI，又是数据，又是智慧零售，日新月异的MarTech技术，一般广告公司hold不住。'},
    {id:4,type:'before',text:'我找不到英语流利又了解国外社交平台运作的营销专才。'},
    {id:5,type:'before',text:'我自己找了一些兼职，发现他们很难被管理，也很难对时间和预算负责。'},
    {id:6,type:'before',text:'我自己在国内freelancer网站找人就是海底捞针，根本鸡同鸭讲，而且质量也不好把控 。'},
    {id:7,type:'before',text:'现在太难让人通通坐在公司工作，更不可能常常面对面开会了。'},
    {id:8,type:'before',text:'我不知道怎么样给我的项目划定范围和估价？如何跟上所有新的平台和技术？'},
    {id:9,type:'after',text:'不用再苦等广告公司比稿比价比方案，一两个月都比不完…'},
    {id:10,type:'after',text:'从我第一次给出大致的项目范围，到快平方给我这个相当默契的营销团队，开始合作只用了短短不到48小时…'},
    {id:11,type:'after',text:'在整个市场投入在优化的过程中, 我能更好的运用预算，把费用更好的分配 。'},
    {id:12,type:'after',text:'当我们和快平方合作，完成项目更快，速度更快，效果更好。'},
    {id:13,type:'after',text:'我们终于不用再被那么多家代理公司纠缠了，也不用一天到晚和不同代理商不停开会，工作效率大大提升。'},
    {id:14,type:'after',text:'我觉得享受到了VIP专属项目的“专车服务”。'},
    {id:15,type:'after',text:'我们公司在找到一组很合拍的营销专家后，发展相当快。'},
    {id:16,type:'after',text:'用不着跟自己的兼职人员去争论。我可以让快平方项目经理处理这一切。'},
    {id:17,type:'after',text:'整个市场环境翻天覆地在创新，营销手段变化飞快，快平方能快速匹配团队，合作的反应够快，让我们很省心！'},
]
*/

const Beforeafter=[
    {
        id:0,
        type:'before',
        text:'在这个快速迭代的市场环境下, 大部分品牌主已经不太会注重品牌建设, 反而更关心时效性的创意产出, 並在不同的促销节点前把创意内容做好, 准时上线。而且大部分创意内容的生命周期也不会超过一个月, 因此快已经成为品牌广告主在传播上的致勝关键。',
        name:'Cindy Lau',
        title:'佳沛奇异果中国区顾问董事',
        company:'Accolade Wines 誉嘉葡萄酒公司独立董事'
    },
    {
        id:1,
        type:'before',
        text:'运营设置架构于绩效指标，营销人员需对营销计划做出快速滚动性优化, 创建一个能够贯穿全年的整合行销计划是一个挑战。 要完成营销任务, 营销人员需要在短时间内快速做出反应达成绩效,实现持续的优化, 没有耐心等待长尾效应。',
        name:'Tina H. ',
        title:'Marketing Director, Asia.',
        company:''
    },
    {
        id:2,
        type:'before',
        text:'快速响应客户的需求肯定是对的, 品牌主最担心的地方就是创意机构对需求的不明确, 导致很多反复来回验证与讨论。品牌主更希望创意机构可以与品牌主一起产生共振, 以更灵活的工作方式与品牌方一起工作, 避免浪费时间, 以更具备时效性的快速产出优化整体效果。',
        name:'Joan Mui ',
        title:'Head of Marketing Activation - Glen Dimplex China',
        company:''
    },
    {
        id:3,
        type:'before',
        text:'文案和美术在数字当道的今天仍旧重要，因为营销创意还是酝酿好一个故事；但数据和算法可以将故事变得更具个性、更加有趣、更加快速触动人心！正如 Kovalevskaya说过的：不会写诗，就不可能成为数学家！快平方，快速响应是必须的, 但我关注把“平方”定义为有创意有技术两个加乘的能力。',
        name:'James Yang',
        title:'K11 数字营销部总经理',
        company:''
    },

]


export default Beforeafter