/*
 * yi 2020
 *
 *
 */


import React from 'react';
import TopButton from "../components/TopButton";
import {connect} from "react-redux";


import  {fullfooter,minfooter,minmenu} from  "../reduceractions";
import Nav_sim from "../components/nav_sim";

import Account_Type from "./ChooseType";
import Baseinfo from "./Baseinfo";
import Detailinfo from "./Detailinfo";
import Orderinfo from "./Orderinfo";
import IntroductFL from "./IntroductFL";
import Submited from "./Submited";
import Http from '../components/functions/ApiBridge';
import {message,Alert, Button, Modal, Typography} from "antd";

import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

const key = 'updatable';

let submitalert=null;

const {Title,Text,Paragraph } = Typography;


    const mapStateToProps = (state) =>({

        footerstate:state.footerstate,
        menustate:state.menustate,
        redux_islog:state.redux_islog,

    })




    //默认情况下 connent dispatch调用
    const mapDispatchToProps = (dispatch) => ({
        LoadPageState(){
            dispatch(minfooter())
            dispatch(minmenu())
         // console.log(minfooter())
        }
    })


class Registration extends React.Component {



    constructor(dispatch,props){
        super(dispatch,props)

        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            min_height:'10%',
            user_state:{
                type:'cl',
                cl_underline:'block',
                fl_underline:'none',
                email:true,
                password:true,
                repasswrod:true,
                mobile:true,
                passcode:true,
                terms:true,
                name:true,
                wechatid:true,
                province:true,
                city:true,
                area:true,
                title:true,
                working_years:true,
                working_hours:true,
                clients:true,
                awards:true,
                daily_cost:true,
                user_pro:true,
                user_exp:true,
                introduce:true,
                profile_url:true,
                contact_type:true,
                profile_file:true,
                company:true,
                order_name:true,
                department:true,
                deliver:true,
                brief_call:true,
                calling_time:true,
                remark:true,

            },
            user_data:{
                type:'cl',
                email:'',
                password:'',
                repasswrod:'',
                mobile:'',
                passcode:'',
                terms:'',
                name:'',
                wechatid:'',
                province:'',
                city:'',
                area:'',
                title:'',
                working_years:'1-5年',
                working_hours:'2-4小时',
                clients:'',
                awards:'',
                daily_cost:'',
                user_pro:'',
                user_exp:'',
                introduce:'',
                profile_url:'',
                contact_type:'不限',
                profile_file:'',
                order_name:'',
                company:'',
                department:'',
                deliver:'',
                brief_call:'',
                calling_time:'10:30',
                remark:'',
            },
            flowView:null,
            step:1,
            error:false,
            errorContent:'',
            loading:'true',



        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

        this.formstate=1;

        this.Next_button_Click =this.Next_button_Click.bind(this)
        this.Next_button_Click_Cl =this.Next_button_Click_Cl.bind(this)
        this.choose_Usertype =this.choose_Usertype.bind(this)
        this.User_data_input =this.User_data_input.bind(this)
        this.Baseinfo_submit =this.Baseinfo_submit.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this)
        this.send_passcode =this.send_passcode.bind(this)






    }

    componentDidMount() {
       // this.setState({
       //     flowView:new Account_Type({'callBack':this.Next_button_Click,'state':this.state.user_state,'action':this.choose_Usertype})
       // })

    }

    warning() {
        submitalert= Modal.success({
            title: '提示',
            content: '数据正在提交中...',
            okType:'text'
        });

    }

    /**
     * get url pam
     * @param variable
     * @returns {string|boolean}
     */

    get_url_val(variable){
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }


    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    onClose = () => {

        console.log('Clicked cancel button');
        this.setState({
            error: false,
            errorContent:'',
        });
    };

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentWillMount() {
        this.props.LoadPageState();

        const ta_type= this.get_url_val('ty')


        if(ta_type){

            this.choose_Usertype(ta_type)
        }else{
            this.setState({
                loading:'true'
            })
        }

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1,
                min_height:'30%',
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }

    /**
     * Cl 类型 user 创建用户提交步骤
     * @param data
     * @constructor
     */

    Next_button_Click_Cl(data){
        let nowStep=this.state.step
        const user_Data=this.state.user_data;

        if(data=='pre'){
            if(nowStep==2 || nowStep==3) {
                user_Data.terms = '';
                user_Data.password = '';
                user_Data.repasswrod = '';
                user_Data.mobile = '';
                user_Data.email = '';
                user_Data.passcode = '';
                this.setState({user_Data})
            }
            nowStep-=1
        }else {
            nowStep += 1;
        }

        if(data=='nex' && nowStep==3){
            this.Baseinfo_submit();
        }else if(data=='nex' && nowStep==4){
            this.User_submit_CL();
        }else if(data=='nex' && nowStep==5){

        }else{

            this.setState({  step:nowStep })
            window.scroll(0, -1000);
        }
    }

    /**
     * submit user CL type
     * @constructor
     */

    User_submit_CL(){
        const user_Data=this.state.user_data;
        const user_State=this.state.user_state;
        const targeta=['order_name','company','name','title','department','wechatid'];//判断数组

        let canpass=true;
        let that=this;
        Object.keys(user_Data).map(key =>
            {
                //查找是否在判断条件数组里
                if(targeta.indexOf(key)>-1){
                    if(user_Data[key]==''){
                        // console.log('no data')
                        user_State[key]=false;
                        that.setState({user_State})
                        canpass =false;
                        this.showErrorbar("请填写完整信息！")


                    }
                }
            }
        );

        if(canpass) {
          if(user_Data.deliver ==''){
              this.showErrorbar("请选择项目的上线时间！")
          }else{
              if(user_Data.brief_call ==''){
                  this.showErrorbar("请选择电话会议时间！")
              }else{
                  this.warning();
                  Http.User_cl_submit_creat(user_Data).then((res)=>{
                      this.setState({step: 4})
                      window.scroll(0, -1000);
                      console.log('submited user')
                      submitalert.destroy();

                  }).catch((res) => {

                      this.showErrorbar(res.message)
                  })

              }
          }
        }

    }


    /**
     * Fl 类型 user 创建用户提交步骤
     * @param data
     * @constructor
     */
    Next_button_Click(data){

        let nowStep=this.state.step


        if(data=='pre'){

            const user_Data=this.state.user_data;
            if(nowStep==2 || nowStep==3) {
                user_Data.terms = '';
                user_Data.password = '';
                user_Data.repasswrod = '';
                user_Data.mobile = '';
                user_Data.email = '';
                user_Data.passcode = '';
                this.setState({user_Data})
            }else if(nowStep==3){
                    user_Data.name='';
                    user_Data.wechatid='';
                    user_Data.province='';
                    user_Data.city='';
                    user_Data.area='';
                    user_Data.title='';
                    user_Data.working_years='1-5年';
                    user_Data.working_hours='2-4小时';
                    user_Data.clients='';
                    user_Data.awards='';
                    user_Data.daily_cost='';
                    user_Data.user_pro='';
                    user_Data.user_exp='';
                this.setState({user_Data})
            }else if(nowStep==4){
                user_Data.introduce='';
                user_Data.profile_url='';
                user_Data.contact_type='不限';

                this.setState({user_Data})
            }

            nowStep-=1
        }else {
            nowStep += 1;
        }


        if(data=='nex' && nowStep==3){
            this.Baseinfo_submit();
        }else if(data=='nex' && nowStep==4){
            this.Detailinfo_check();
        }else if(data=='nex' && nowStep==5){
            this.User_submit();
        }else{

            this.setState({  step:nowStep })
            window.scroll(0, -1000);
        }





    }

    /**
     * 基础信息判断
     * @constructor
     */

    Baseinfo_submit(){
        const user_Data=this.state.user_data;
        const user_State=this.state.user_state;
        const targeta=['email','password','mobile','repasswrod','passcode'];//判断数组

        let canpass=true;
        let that=this;
        Object.keys(user_Data).map(key =>
           {
               //查找是否在判断条件数组里
              if(targeta.indexOf(key)>-1){
                  if(user_Data[key]==''){
                     // console.log('no data')
                      user_State[key]=false;
                      that.setState({user_State})
                      canpass =false;
                      this.showErrorbar("请填写完整信息！")

                  }
              }
            }
        );

        if(canpass) {
            const emailcheck = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;

            if(user_Data.password != user_Data.repasswrod){
                this.showErrorbar("两次输入的密码不一致！")
            }else {
                if(!emailcheck.test(user_Data.email)){
                    this.showErrorbar("请输入正确的邮箱地址")
                }else {
                    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
                    if(mediumRegex.test(user_Data.password)) {
                        if (user_Data.terms != true) {
                            this.showErrorbar("请阅读并同意《网站服务条款》及《隐私政策》！")
                        } else {
                            //屏蔽验证码

                            Http.Verafilepasscode(user_Data.mobile, user_Data.passcode).then((res) => {
                                Http.Checkuser(user_Data.email, user_Data.mobile).then((res) => {
                                    this.setState({step: 3})
                                    window.scroll(0, -1000);
                                    console.log('check base')

                                }).catch((res) => {

                                    this.showErrorbar(res.message)
                                })
                            }).catch((res) => {
                                this.showErrorbar(res.message)
                            })

                        }
                    }else{
                        this.showErrorbar("您设置的密码太简单，请重新输入！")
                    }


                }
            }
        }



    }

    /**
     * 详细信息检查
     * @constructor
     */

    Detailinfo_check(){
        const user_Data=this.state.user_data;
        const user_State=this.state.user_state;
        const targeta=['name','wechatid','province','city','area','title','clients','awards','daily_cost',];//判断数组

        let canpass=true;
        let that=this;
        Object.keys(user_Data).map(key =>
            {
                //查找是否在判断条件数组里
                if(targeta.indexOf(key)>-1){
                    if(user_Data[key]==''){
                        user_State[key]=false;
                        that.setState({user_State})
                        canpass =false;
                        this.showErrorbar("请完善您的详细资料！")

                    }
                }
            }
        );

        if(canpass) {
            if(user_Data.user_pro==''){
                this.showErrorbar("请至少选择一项您的专业领域！")
            }else{
                if(user_Data.user_exp==''){
                    this.showErrorbar("请至少选择一项您的行业经验！")
                }else{
                     this.setState({  step:4 })
                     window.scroll(0, -1000);
                     console.log('check detail')
                }
            }

        }



    }

    /**
     * 创建用户
     * @constructor
     */
    User_submit(){
        const user_Data=this.state.user_data;
        const user_State=this.state.user_state;
        let canpass=true;
        if(user_Data.introduce=='' ){
            canpass=false;
            this.showErrorbar("请填写您的自我介绍！");

        }else{
            if(user_Data.profile_file!=''){
                //Toast.loading('数据提交中...')
                if(user_Data.profile_url=='')user_Data.profile_url='false';//用户url可不填
                this.warning();
                Http.User_submit_creat(user_Data).then((res)=>{

                    this.setState({step: 5})
                    window.scroll(0, -1000);
                    console.log('submit')
                    submitalert.destroy();
                }).catch((res) => {

                    this.showErrorbar(res.message)
                    console.log(res)
                })
            }else{
                this.showErrorbar("请上传您的个人作品！");
            }
        }


    }



    /**
     * upload profile
     */
    Uploadprofiles(data){
        const user_Data=this.state.user_data;
        let filePath='';
        Http.OSSsign(data).then((res)=>{
                filePath=res.data.dir

            message.loading({ content: 'upload...', key });
            console.log('uploading..')
            Http.OSSuploadfiles(data,res).then((res)=>{
                user_Data.profile_file=filePath+data[0];
                this.setState({
                    user_Data
                })
                message.success({ content: 'Done!', key, duration: 2 });
                 console.log(data)

            }).catch((res) => {

                this.showErrorbar(res.message)
                console.log(res)
            })

        }).catch((res) => {

            this.showErrorbar(res.message)
        })
    }

    /**
      *change user state
      */

    choose_Usertype(data){

        const user_data = this.state.user_data;

       if(data=="cl") {
           user_data.type = 'cl'
           this.setState({
               user_data,
               step:2,
               loading:'false',
               user_state:{
                   type:'cl',
                   cl_underline:'block',
                   fl_underline:'none',

               },

           })
       }else if(data=="fl"){
           user_data.type = 'fl'
           this.setState({
               user_data,
               step:2,
               loading:'false',
               user_state:{
                   type:'fl',
                   cl_underline:'none',
                   fl_underline:'block',

               },
           })
       }else{

        }


    }


    /**
     * send pass code
     * @param name
     * @param data
     * @constructor
     */

    send_passcode(){
        const user_data = this.state.user_data;
        if(user_data.mobile==''){
            this.showErrorbar('请输入手机号')
        }else {

            const post_data = {
                mobile: user_data.mobile,
            }
                Http.Send_pass(post_data).then((res) => {
                    console.log('pass sent')
                }).catch((res) => {

                    this.showErrorbar(res.message)
                })

        }
    }





    User_data_input(name,data){
        const user_data = this.state.user_data;
        switch (name){
            case 'email':
                user_data.email = data;
                break;
            case 'setpassword':
                user_data.password = data;
                break;
            case 'repasswrod':
                user_data.repasswrod = data;
                break;
            case 'passcode':
                user_data.passcode = data;
                break;
            case 'mobile':
                user_data.mobile = data;
                break;
            case 'terms':
                user_data.terms = data;
                break;
            case 'name':
                user_data.name = data;
                break;
            case 'wechatid':
                user_data.wechatid = data;
                break;
            case 'location':
                const locadata=data.split(',')
                user_data.province = locadata[0];
                user_data.city = locadata[1];
                user_data.area = locadata[2];
                break;
            case 'title':
                user_data.title = data;
                break;
            case 'working_years':
                user_data.working_years = data;
                break;
            case 'working_hours':
                user_data.working_hours = data;
                break;
            case 'clients':
                user_data.clients = data;
                break;
            case 'awards':
                user_data.awards = data;
                break;
            case 'daily_cost':
                user_data.daily_cost = data;
                break;
            case 'user_pro':
                user_data.user_pro = data;
                break;
            case 'user_exp':
                user_data.user_exp = data;
                break;
            case 'introduce':
                user_data.introduce = data;
                break;
            case 'profile_url':
                user_data.profile_url = data;
                break;
            case 'contact_type':
                user_data.contact_type = data;
                break;
            case 'uploadfileInput':
                if(data!='')this.Uploadprofiles(data);
                //user_data.profile_file=data;
                break;
            case 'order_name':
                user_data.order_name = data;
                break;
            case 'company':
                user_data.company = data;
                break;
            case 'department':
                user_data.department = data;
                break;
            case 'deliver':
                user_data.deliver = data;
                break;
            case 'brief_call':
                user_data.brief_call = data;
                break;
            case 'remark':
                user_data.remark = data;
                break;
            case 'calling_time':
                user_data.calling_time = data;
                break;
            case 'service':
                user_data.service = data;
                break;
            case 'industry':
                user_data.industry = data;
                break;
            default:
                //console.log(data);
        }
       // console.log(user_data)

        this.setState({
            user_data
        })

    }







    render() {
        console.log(this.props.menustate)


        let propsstate = {
            callBack: this.Next_button_Click,
            state: this.state.user_data,
            action: this.choose_Usertype,
        }


        let CustomView = Account_Type;

        if (this.state.step == 1) {
            propsstate = {
                callBack: this.Next_button_Click,
                state: this.state.user_state,
                action: this.choose_Usertype,
            }
            CustomView = Account_Type;
        } else if (this.state.step == 2) {
            propsstate = {
                callBack: this.Next_button_Click,
                state: this.state.user_state,
                data: this.state.user_data,
                action: this.User_data_input,
                sendpass: this.send_passcode,
            }
            CustomView = Baseinfo;
        } else if (this.state.step == 3) {
            if (this.state.user_data.type == 'fl') {
                propsstate = {
                    callBack: this.Next_button_Click,
                    state: this.state.user_state,
                    data: this.state.user_data,
                    action: this.User_data_input,
                    sendpass: this.send_passcode,
                }
                CustomView = Detailinfo;
            } else if (this.state.user_data.type == 'cl') {
                propsstate = {
                    callBack: this.Next_button_Click_Cl,
                    data: this.state.user_data,
                    action: this.User_data_input,
                    sendpass: this.send_passcode,
                }
                CustomView = Orderinfo;
            }
        } else if (this.state.step == 4) {
            if (this.state.user_data.type == 'fl') {
                propsstate = {
                    callBack: this.Next_button_Click,
                    state: this.state.user_state,
                    data: this.state.user_data,
                    action: this.User_data_input,
                    sendpass: this.send_passcode,
                }
                CustomView = IntroductFL;
            } else {
                propsstate = {
                    data: this.state.user_data,
                }
                CustomView = Submited;
            }
        } else if (this.state.step == 5) {
            propsstate = {
                data: this.state.user_data,
            }
            CustomView = Submited;
        } else {

        }

        if (this.state.loading == 'true') {
            return (
                <div style={{height:'100vh'}}>

                </div>
            )

        }else{

            return (


                <div className='contentView'>
                    <Nav_sim/>


                    <div className='contentFrameBox' style={{backgroundColor: '#EFEFEF', height: 'auto'}}>

                        <CustomView brideg={propsstate}/>

                        <Modal
                            title=""
                            style={{top: 20}}
                            visible={this.state.error}
                            onCancel={this.onClose}
                            footer={null}
                        >
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <ExclamationCircleOutlined
                                    style={{fontSize: '20px', color: '#ff7c62', paddingRight: '10px'}}/>
                                <Text type="secondary">{this.state.errorContent}</Text>

                                <Button type="text" onClick={() => this.onClose()}>返回</Button>

                            </div>
                        </Modal>
                    </div>


                </div>
            )

        }
    }

}




export default connect(mapStateToProps,mapDispatchToProps)(Registration) ;