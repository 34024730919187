//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import {Link} from "react-router-dom" 

 
 
class ButtonYellow extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initial:1,
      buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'#f6d808',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#f6d808',
            color:'#000',
             
           
      },
      buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            height:'43px',
            float:'left',
            backgroundColor:'#fff',
      },
  	}

  	this.changevalue =this.changevalue.bind(this)
    
  }

  changevalue(){
  	//this.setState({initial:2})
  	this.setState(prevState=>{
  		return{
  			initial:prevState.initial +1
  		}
  	})
  }

  mouseEnter(event) {
   //console.log('in')

   this.setState({
    buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'#f6d808',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#f6d808',
            color:'#f6d808',
            
            
      },
     buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'178px',
            height:'43px',
            float:'left',
            backgroundColor:'#fff',
      },  
   }
    )
   
  }

  mouseOut(event) {
   //console.log('out')
     this.setState({
    buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'#f6d808',
             borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#f6d808',
            color:'#000',
             
            
      },
     buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            height:'43px',
            float:'left',
            backgroundColor:'#fff',
      },  
   }
    )
  }
  mouseDown(event){

  }

  render(){

   const styles={
    buttonViewbox:{
      display:'flex',
      alignItems: 'center',
      width:'180px',
      height:'45px',
   
    },
    buttonInfo:{
 
      alignItems: 'center',

      display: 'flex',
      justifyContent: 'center', 
      verticalAlign:'center',
      textAlign:'center',
      width:'180px',
      height:'45px',
      position:'absolute',
  
    }

  }


   return(
       <div>

     <div style={this.state.buttonContent}  onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }}>
     <div style={styles.buttonInfo} className='textMin_content_bold'>
      {this.props.ButtonName}  
     </div>  
     <div  style={this.state.buttoninsidebg} >
    
     </div>
     
     </div>
           <img src={require('../asset/chart.png')}   style={{width:100,height:100,position:"relative",left:-80,marginTop:-80}}/>
       </div>
    )

  }
}



export default ButtonYellow