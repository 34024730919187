/**
 * yi 2020
 * Login
 */

import React from 'react';
import './login.css';
import {Link} from "react-router-dom"

import TopButton from  '../components/TopButton';
import Nav_sim from '../components/nav_sim';
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import Getcodebtn from  '../components/60slimitbutton';

import  '../components/global'

import Yihttprequests from  '../components/functions/Yihttprequests';
import {autoAchtion} from '../components/functions/authAchtions';


import {connect} from 'react-redux'
import counterReducer from "../reducers/counter";
import loggedReducer from "../reducers/isLogged";



import  {authlogin,authlogout,fullfooter,minfooter,minmenu} from  "../reduceractions";
import {Modal} from "antd";



const mapStateToProps = (state) =>({
    // auth 在reducers下定义的一大的reducers
    // auth :state.auth,
    counter:state.counter,
    redux_islog:state.redux_islog,



})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    toauthlogin(){
        dispatch(authlogin())
       // console.log(authlogin())
    }
})



class Login extends React.Component{


   // static  contextTypes={
    //    intals:PropTypes.string,

    //}

    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            userid:'',
            password:'',


        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.inputchange =this.inputchange.bind(this)


        this.scrolliconR='20px'

        this.centerViewbox_W='35%'

        this.conL_w='50%'
        this.conR_w='50%'



    }

    componentDidMount() {

        // let ti=sessionStorage.getItem('YiToken');
        //let ti=localStorage.getItem('YiToken');


      //  let contextd=this.context.intals;

      //  console.log("global context: "+contextd)

         //查看 redux connent 中的 state
       // console.log(this.props.redux_islog)



    }
    clickbtn(){
        window.scroll(0, -1000);

    }

    inputchange(name,data){
      switch (name){
          case 'userid':
              this.setState({userid:data})
              break
          case 'password':
              this.setState({password:data})
              break
          default:

      }

    }




    submitpsw(){

        if(this.state.userid=='' || this.state.password==''){

            this.warning("请填写登录信息！")
        }else {

            let Userlogininput = {
                password: this.state.password,
                email: this.state.userid,
                mobile: '',
            }

            const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            const isok= reg.test(this.state.userid);
            if(!isok) {
                Userlogininput = {
                    password: this.state.password,
                    email: '',
                    mobile: this.state.userid,
                }
            }



            //loginUser(Userlogininput,this.tasdfa);
            //this.props.toauthlogin()
            this.props.autoAchtion(Userlogininput, 'login') //redux auth call

        }

    }





    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentWillMount() {
        //set page state
        global.gData.Pagestate='login'




        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='100%'
            this.conL_w='0%'
            this.conR_w='100%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:0
            })

        }

    }


    componentDidUpdate(){



    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        //console.log('component UnMount')
    }


    //props update

    componentWillReceiveProps(){

    }

    warning(msg) {
        Modal.warning({
            title: '提示',
            content: msg,
            okType:'text'
        });
    }




    render(){





        return(



            <div  className='contentView'>



                <TopButton  callback={()=>this.clickbtn()} opacity={this.state.showscrolltop}/>

                <Nav_sim />


                <div className='contentFrameRow' style={{backgroundColor:'',height:'100vh'}}>

                    <div className='contentFrameColumn' style={{width:this.conL_w,height:'100%',backgroundColor:'#f5db10',float:'left'}}>

                        <div className='selfcenter_alignLeft' style={{width:'510px',height:'auto',backgroundColor:'',display:'flex',flexDirection: 'column'}}>
                            <span className='txt03' style={{color:'#000',fontSize:'35px'}}>“</span>
                            <span className='Stitle' style={{color:'#000',fontSize:'28px'}}>48小时定制你的快咖团队体验专属服务</span>
                            <span className='ATextcontent' style={{color:'#000',paddingTop:'20px'}}>只需填写简单的信息，我们会有专人在24小时内主动与您联系，並在了解须求后48小时内为您定制专业服务团队。</span>
                        </div>
                    </div>

                    <div style={{width:this.conR_w,height:'100%',backgroundColor:'#fff',float:'left',alignItems:'center',display:'flex',justifyContent: 'center'}}>

                        <div className='selfcenter_alignLeft' style={{margin:'30px',width:this.centerViewbox_W,height:'300px',backgroundColor:'',display:'flex',flexDirection: 'column'}}>
                            <div className='selfcenter_alignLeft' style={{backgroundColor:'',width:'100%',height:'50px',paddingTop:'0px'}}>
                                <span className='Subtitle'  ><img src={require('../asset/flashicon.png')}  style={{height:'26px'}}/> 用户登陆</span>
                            </div>

                            <Yinput width='100%' height='30px' name={'userid'} bgColor='' value='' preset='邮箱地址/手机号' limit={20} callBack={this.inputchange}/>
                            <Yinput width='100%' height='30px' name={'password'} bgColor='' value='' preset='密码'  type='password' callBack={this.inputchange}/>

                            <Yibutton width='100%' height='32px' value='登陆' contentfloat='' callBack={()=>{this.submitpsw() }}  />

                            <div className='selfcenter_alignRight' style={{backgroundColor:'',width:'100%',height:'50px',paddingTop:'0px',display:"flex",justifyContent:"space-between"}}>
                                <Link to={'/faq'} style={{color:'#000', textDecoration:'none'}}>
                                <span className="textMin_content"   >FAQ</span>
                                </Link>
                                <Link to={'/psw'} style={{color:'#000', textDecoration:'none'}}>
                                    <span className="textMin_content"   >忘记密码？</span>
                                </Link>
                                {/*
                                 <Link to={'/registration'} style={{color:'#000', textDecoration:'none'}}>
                                    <span className="textMin_content"   >用户注册</span>
                                  </Link>
                                  */}
                            </div>



                        </div>

                    </div>

                </div>


            </div>
        )

    }
}




//export default Login


export default connect(mapStateToProps,{autoAchtion})(Login) ;