//by yi 2020 kuaipf project


import React from 'react';
import ReactDOM from 'react-dom';
import './faq.css';
import {Link} from "react-router-dom" 
import  '../components/global'
import ButtonBlack from '../components/ButtonBlack'
import ButtonWhite from '../components/ButtonWhite'
import ButtonYellow from '../components/ButtonYellow'
import TopButton from  '../components/TopButton';
import QandAfull from  '../components/QandAfull'; 
import Nav from '../components/nav'
import {connect} from "react-redux";
import {fullfooter, fullmenu, navOthers} from "../reduceractions";
import Http from "../components/functions/ApiBridge";



const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navOthers())
        // console.log(minfooter())
    }
})

 class Faq extends React.Component{

constructor(){
  	super()
  	this.state ={
  		initall:0,
  		scrolliconAlpha:1,
  		showscrolltop:0,
        faq_Data:null,
        loading:true,
        visible: false,
        total_list:null,
        current_page:null,
  		 
       
  	}

    
   this.handleScroll =this.handleScroll.bind(this)
   this.clickbtn =this.clickbtn.bind(this)
    this.get_qa_list =this.get_qa_list.bind(this)

  
    this.scrolliconR='20px'
  
    this.centerViewbox_W='54%' 

    this.QAViewbox_W='60%' 
   
  
   
  }

 componentDidMount() {

 //console.log('component Mount')
  
  

   
  }
  clickbtn(){
  	 window.scroll(0, -1000);

  }

  

  handleScroll(){
   this.scrolltopcount=window.pageYOffset
   //console.log(this.scrolltopcount)
   let scrolliconAlpha=1
     if(this.scrolltopcount >= window.innerHeight){
         scrolliconAlpha=0 
    }else{
         scrolliconAlpha=1
    }
     this.setState({
         scrolliconAlpha:scrolliconAlpha,
         showscrolltop:1-scrolliconAlpha,
        })

  }

 componentWillMount() {


     this.props.LoadPageState();


  window.addEventListener('scroll', this.handleScroll);
  if(window.innerWidth<1124){
      this.centerViewbox_W='320px'
       this.QAViewbox_W='90%'   
       this.setState({
       scrolliconAlpha:0,
       showscrolltop:1
       })
       
    }

  this.get_qa_list(1);
   
  }


  componentDidUpdate(){

   
  
  }

  //close component
  componentWillUnmount(){
  	window.removeEventListener("scroll", this.handleScroll)
  	//console.log('component UnMount')
  }


  //props update

  componentWillReceiveProps(){

  }


     get_qa_list(page){
         const postdata={
             pageshow:30,
             page:page,
         }
         Http.get_faq_list(postdata).then((res)=>{

             if(res.data.total) {
                 //当数量达到分页数据存在分页数据时调用
                 this.setState({
                     total_list: res.data.total,
                     current_page: res.data.current_page,
                     faq_Data: res.data.data,

                 },()=>{
                     this.setState({loading: false,})
                 })
             }else {
                 this.setState({
                     faq_Data: res.data,

                     total_list: res.data.length,
                     current_page: 1,
                 },()=>{
                     this.setState({loading: false,})
                 })

             }



         }).catch((res) => {
             console.log(res.message)
         })

     }


 
 
render(){  
 
    

   if(this.state.loading){
       return (
           <div style={{width:'100%',height:'100vh'}}></div>
       )
   }else {


       return (


           <div className='contentView'>
               <Nav/>

               <TopButton callback={() => this.clickbtn()} opacity={this.state.showscrolltop}/>

               <div className='contentFrameBox'
                    style={{backgroundColor: '#f5f5f5', height: 'auto', paddingTop: '50px', paddingBottom: '0px'}}>
                   <div className='selfcenter_alignLeft' style={{
                       width: this.centerViewbox_W,
                       minWidth: '320px',
                       height: 'auto',
                       backgroundColor: '',
                       paddingTop: '100px'
                   }}>
                       <span className='pageNavText' style={{paddingTop: '100px'}}>首页/</span>
                       <span className='pageNavText' style={{paddingTop: '', color: '#999'}}>FAQ</span>
                   </div>
                   <div className='selfcenter_alignLeft' style={{
                       width: this.centerViewbox_W,
                       minWidth: '320px',
                       height: 'auto',
                       backgroundColor: '',
                       paddingTop: '20px'
                   }}>
                       <div className='pageLineS' style={{width: '50px'}}></div>
                   </div>

                   <div className='selfcenter_alignLeft' style={{
                       width: this.centerViewbox_W,
                       minWidth: '320px',
                       height: 'auto',
                       backgroundColor: '',
                       paddingTop: '50px'
                   }}>
                       <span className='Stitle'><img src={require('../asset/flashicon.png')} style={{height: '30px'}}/> 常见问题</span>
                   </div>
               </div>


               {/*FAQ*/}

               <div className='contentFrameBox'
                    style={{backgroundColor: '#f5f5f5', height: 'auto', paddingTop: '10px', paddingBottom: '0px'}}>


                   <div className='selfcenter_alignLeft'
                        style={{width: this.QAViewbox_W, height: '100%', paddingTop: '50px'}}>
                       <QandAfull data={this.state.faq_Data}/>
                   </div>
               </div>


               <div className='contentFrameBox'
                    style={{backgroundColor: '#f5f5f5', height: 'auto', paddingTop: '0px', paddingBottom: '50px'}}>
                   <div className='selfcenter_alignLeft'
                        style={{width: this.centerViewbox_W, height: 'auto', paddingTop: '0px'}}>
                       <Link to={'/contact'}>
                           <div style={{float: 'right', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                onClick={() => {
                                }}>

                               <span className='Subtitle'
                                     style={{color: '#000', fontSize: '14px', float: 'right'}}>联系我们</span>
                               <img src={require('../asset/arrowright.png')} width='34px' style={{float: 'right'}}/>
                           </div>
                       </Link>
                   </div>
               </div>


           </div>
       )
   }

  }
}




export default connect(mapStateToProps,mapDispatchToProps)(Faq) ;
