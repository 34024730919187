//by yi 2020 kuaipf project


import React from 'react';
import '../components/App.css';
import logoY from '../asset/logo-y.svg';
import logoB from '../asset/logo-b.svg';
import stata from '../asset/stata.svg';
import statb from '../asset/statb.svg';
import {Link} from "react-router-dom";


let canscroll=true


function Titems(prosps){

    let bgcolor='#fff'
    let borderclor='#E3E3E3'
    let fontcolor='#777'


    console.log()
    if(prosps.id%2==0){
         bgcolor='#F5D82D'
        borderclor='#F5D82D'
        fontcolor='#fff'

    }




    let usrtimg=prosps.talentimg

    return(
        <div style={{width:'260px',height:'280px',backgroundColor:'',margin:'1px'}}>
            <div style={{height:'62%',width:'99%',borderColor:'#999',border:'1px solid '+borderclor,backgroundColor:bgcolor}}>
                <div style={{padding:0,position:"absolute",width:'18px',overflow:"hidden",marginLeft:'15px',marginTop:'15px'}}>
                    {(prosps.id%2==0)?(<img src={logoY} alt="logo"  width={'100px'}  />):(<img src={logoB} alt="logo"  width={'100px'}  />)}
                </div>
                <div style={{width:'100px',height:'100px',borderRadius: '50%',overflow:'hidden',marginTop:'30px'}}>
                <img src={usrtimg}     height={'134px'} style={{marginLeft:'-17px',marginTop:'-17px'}}/>

                </div>
                <div style={{width:'100%',height:'40px',backgroundColor:'',textAlign:"center",paddingTop:'10px'}}>
                    <span style={{color:fontcolor,fontSize:'13px',fontWeight:"normal"}}>{prosps.stat}</span>

                    {(prosps.id%2==0)?(<img src={stata}   width={'14px'} style={{marginTop:'-3px',marginLeft:'4px'}}/>):(<img src={statb}   width={'14px'} style={{marginTop:'-3px',marginLeft:'4px'}}/>)}
                </div>

            </div>
            <div style={{paddingTop:'20px',height:'30%',width:'99%',borderColor:'#999',border:'1px solid #E3E3E3',borderTop:'0px',backgroundColor:'#fff'}}>
                <span  className={'talentsname'} >{prosps.name}</span>
                <div>
                <span  className={'talentstitle'}   >{prosps.title}</span>
                </div>
            </div>
        </div>
    )

}

 
class Talent extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initall:0,
        logoSvg:logoY,
      frameContent:{
            transition:'0.15s ease-out all',
            width:'17vw',
            height:'17vw',
            minWidth:'280px',
            minHeight:'280px',
            backgroundColor:'#fff',
            borderStyle: 'solid',
            borderWidth: '0px',
            borderColor:'#000',
            color:'#000',
            float:'left',
            marginRight:'10px',
            marginLeft:'10px',
            marginTop:'30px',
            boxShadow:'3px 3px 3px #f0f0f0',
            opacity:1,
            
           
      },
      buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            height:'50px',
            backgroundColor:'#f6d808',
      },
      talents:[
          {
              name: 'June L.',
              des:'founder',
              img:'./source/talent8.jpg',
              id:0,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Founder',
          },
          {
              name: 'Canon W.',
              des:'Chief Creative',
              img:'./source/talent9.jpg',
              id:1,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Chief Creative',
          },
          {

              name: 'Sky H.',
              des:'designer',
              img:'./source/talent5.jpg',
              id:2,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Digital Media',
          },
          {
              name: 'Ali W.',
              des:'designer',
              img:'./source/talent0.jpg',
              id:3,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Director',
          },
          {
              name: 'HaiDong G.',
              des:'designer',
              img:'./source/talent1.jpg',
              id:4,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Brand Strategy',
          },
          {
              name: 'Yuming C.',
              des:'designer',
              img:'./source/talent2.jpg',
              id:5,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Digital Creative',
          },
          {
              name: 'Cen C.',
              des:'designer',
              img:'./source/talent3.jpg',
              id:6,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Digital Strategy',
          },
          {
              name: 'Echo Z.',
              des:'designer',
              img:'./source/talent4.jpg',
              id:7,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Project Management',
          },
          {
              name: 'Joseph T.',
              des:'Digital eCosystem',
              img:'./source/talent10.jpg',
              id:8,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Digital eCosystem',
          },
          {
              name: 'Lei Z.',
              des:'designer',
              img:'./source/talent6.jpg',
              id:9,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Creative Content Writing',
          },
          {
              name: 'Yi Z.',
              des:'designer',
              img:'./source/talent7.jpg',
              id:10,
              left:0,
              speed:0.15,
              stat:'5.0',
              title:'Digital Technology',
          }
          ],

  	}


      this.mouseDownL =this.mouseDownL.bind(this)
      this.mouseDownR =this.mouseDownR.bind(this)

    
   
  }

  componentDidMount() {




      /*
         let FframeContent = Object.assign({}, this.state.frameContent, {minWidth:'350px',minHeight:'230px'})

         if(winW <= 1124){
          this.setState({
           frameContent:FframeContent
          })

         }
      */
   
   
  }

    componentWillMount() {
        const winW=window.innerWidth;



        let talentData=this.state.talents
        let item_l=0.06*winW
        let scrollW=winW-item_l*2
        let space_between=(scrollW-parseInt(scrollW/260)*260)/(parseInt(scrollW/260)-1)

        if(winW<500){
            item_l=(winW-260)/2
            space_between=winW-260
        }

        for(let i=0;i<talentData.length;i++){

            talentData[i].left=item_l+i*(space_between+260)


        }

        this.setState({
            talents:talentData
        })


    }


  componentDidUpdate(){

   

  }




 

  mouseEnter(event) {
   console.log('in')

   
  }

  mouseOut(event) {
   //console.log('out')
   
  }
  mouseDownL(event){
      if(canscroll) {
          canscroll=false
          console.log('L')
          const winW = window.innerWidth;

          let talentData = this.state.talents
          let item_l = 0.06 * winW
          let scrollW = winW - item_l * 2
          let space_between = (scrollW - parseInt(scrollW / 260) * 260) / (parseInt(scrollW / 260) - 1)


          if (winW < 500) {
              item_l = (winW - 260) / 2
              space_between = winW - 260
          }


          for (let i = 0; i < talentData.length; i++) {

              if (talentData[i].left >= (260 + space_between) * (talentData.length - 1)) {
                  talentData[i].left = item_l - (260 + space_between)
                  talentData[i].speed = 0
                  console.log('switch')
              }

          }

          this.setState({
              talents: talentData
          }, () => {


              setTimeout(() => {
                  for (let i = 0; i < talentData.length; i++) {

                      talentData[i].left += (260 + space_between)
                      talentData[i].speed = 0.15

                  }
                  this.setState({
                      talents: talentData
                  },()=>{
                      canscroll=true
                  })

              }, 50)


          })
      }


  }


  mouseDownR(event){

      if(canscroll) {
          canscroll=false
          const winW = window.innerWidth;

          let talentData = this.state.talents
          let item_l = 0.06 * winW
          let scrollW = winW - item_l * 2
          let space_between = (scrollW - parseInt(scrollW / 260) * 260) / (parseInt(scrollW / 260) - 1)

          if (winW < 500) {
              item_l = (winW - 260) / 2
              space_between = winW - 260
          }

          for (let i = 0; i < talentData.length; i++) {

              talentData[i].left -= (260 + space_between)
              talentData[i].speed = 0.15

          }

          this.setState({
              talents: talentData
          }, () => {

              setTimeout(()=> {
                  for (let i = 0; i < talentData.length; i++) {

                      if (talentData[i].left < 0) {
                          talentData[i].left += ((260 + space_between) * talentData.length)
                          talentData[i].speed = 0
                      }

                  }
                  this.setState({
                      talents: talentData
                  },()=>{
                      canscroll=true
                  })

              },50)


          })

      }





  }


  render(){
 


  
  const winW=window.innerWidth;

   if(winW <= 1124){
     

     }





   return(
     <div   style={{paddingTop:'30px',paddingBottom:'30px',alignSelf: 'center',backgroundColor:'',width:'100%'}}>
    


          <div style={{width:'100%',height:'260px',backgroundColor:'',display:"flex"}}>

              <div style={{width:'100%',height:'100%',backgroundColor:'',display:"flex",}}>



                  {
                      this.state.talents.map((key, index) => (
                          <div key={index} style={{position:"absolute",left:key.left,transition:key.speed+'s ease-out all'}}>
                              <Titems id={index} talentimg={key.img} stat={key.stat} name={key.name} title={key.title}/>
                          </div>
                      ))
                  }

              </div>


              <div onClick={this.mouseDownL} style={{width:'6%',minWidth:'60px',height:'260px',backgroundColor:'#fff',position:"absolute",left:0,cursor:"pointer",justifyContent:"center",alignItems:"center",display:"flex"}}>
                  <img src={require('../asset/arrowL.png')}  width={"40%"}  />

              </div>



              <div onClick={this.mouseDownR} style={{width:'6%',minWidth:'58px',height:'260px',backgroundColor:'#fff',position:"absolute",right:0,cursor:"pointer",justifyContent:"center",alignItems:"center",display:"flex"}}>

                  <img src={require('../asset/arrowR.png')}  width={"40%"}  />
              </div>


          </div>



     </div>
    )

  }
}



export default Talent