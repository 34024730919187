import {Menu} from "antd";
import React from "react";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    IdcardOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    ThunderboltOutlined,
    NotificationOutlined,
} from '@ant-design/icons';


/**
 * menu control
 * @returns {JSX.Element}
 */

class Sidemenu extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,
            total_orders:0,
            new_message:0,
            loading:false,
            menukey:'1',


        }


    }

    componentWillReceiveProps(){



    }



 render(){


    if(this.props.account_type=='admin'){
        return (
            <Menu style={{backgroundColor: '#fff'}} theme="light" mode="inline" defaultSelectedKeys={['1']}>


            </Menu>
        );
    }else {
        if (this.props.type == 'cl') {
            return (
                <Menu style={{backgroundColor: '#fff'}} theme="light" mode="inline"
                       selectedKeys={this.props.synckey.toString()}>
                    <Menu.Item key="1" onClick={() => this.props.action(1)} icon={<ProjectOutlined/>}>
                        概览
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => this.props.action(2)} icon={<CarryOutOutlined/>}>
                        项目管理
                    </Menu.Item>
                    <Menu.Item key="3" onClick={() => this.props.action(3)} icon={<ThunderboltOutlined/>}>
                        创建项目
                    </Menu.Item>
                    <Menu.Item key="4" onClick={() => this.props.action(4)} icon={<SettingOutlined/>}>
                        账号设置
                    </Menu.Item>
                    <Menu.Item key="5" onClick={() => this.props.action(5)} icon={<NotificationOutlined/>}>
                        系统消息
                    </Menu.Item>

                </Menu>
            );
        }
        if (this.props.type == 'fl') {
            return (
                <Menu style={{backgroundColor: '#fff'}} theme="light" mode="inline"
                       selectedKeys={this.props.synckey.toString()}>
                    <Menu.Item key="1" onClick={() => this.props.action(1)} icon={<ProjectOutlined/>}>
                        概览
                    </Menu.Item>
                    <Menu.Item key="6" onClick={() => this.props.action(6)} icon={<CarryOutOutlined/>}>
                        我的作品
                    </Menu.Item>
                    <Menu.Item key="7" onClick={() => this.props.action(7)} icon={<IdcardOutlined />}>
                        个人资料
                    </Menu.Item>

                    <Menu.Item key="2" onClick={() => this.props.action(2)} icon={<CarryOutOutlined/>}>
                        项目管理
                    </Menu.Item>
                    <Menu.Item key="4" onClick={() => this.props.action(4)} icon={<SettingOutlined/>}>
                        账号设置
                    </Menu.Item>
                    <Menu.Item key="5" onClick={() => this.props.action(5)} icon={<NotificationOutlined/>}>
                        系统消息
                    </Menu.Item>

                </Menu>
            );
        } else {
            return (<div></div>);
        }
    }
    }
}

export default Sidemenu;