/*
 * yi 2020
 *
 *
 */

import React from 'react';
import NextButton from "../components/NextButton";
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import VcodeBtn from "../components/60slimitbutton";
import axios from "axios";
import LocationCity from "../components/LocationCity";
import PreButton from "../components/PreButton";
import LoadingAnima from "../components/LoadingAnima";

import  '../components/global'


/*
* choose registration account type
*/


class Detailinfo extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            user_pro_data:null,
            user_exp_data:null,

        }


    }

    componentWillMount() {

       this.Get_tags_pro();

        this.Get_tags_exp();
    }


    /**
     * get tags data
     * @returns {array}
     */

    Get_tags_pro(){

        //请求API

        const pro_data_array=[];

        const url = global.gData.api_domain+"/api/auth/gettags?id=1";
        const resquest = axios.post(url);
        resquest.then(res => {

           // console.log(res.data.data);

            this.setState({
                user_pro_data:res.data.data,
            })

        })


    }

    Get_tags_exp(){

        //请求API

        const pro_data_array=[];

        const url = global.gData.api_domain+"/api/auth/gettags?id=2";
        const resquest = axios.post(url);
        resquest.then(res => {

            // console.log(res.data.data);

            this.setState({
                user_exp_data:res.data.data,
            })

        })


    }


    render(){

        const Typestyles={
            bubbo:{
                width:'80px',
                height:'80px',
                alignItems:'center',
                display:'flex',
                margin:'25px',
                justifyContent: 'center',
                backgroundColor:'#f5db10',
                borderRadius: '40px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
            }
        }

        let min_height="20vh";
        let centerboxW="400px";

        let max_height='auto';
        let next_bar_h='40vh';
        let centerboxMinW='430px';
        let topbarboxH="200px";


        if(window.innerWidth<1124){
            min_height="40%";
            centerboxW="90%";

            max_height='auto'
            next_bar_h='auto';
            centerboxMinW='';
            topbarboxH="400px";
        }

        const working_years=[
            '1-5年','6-8年','9-12年','12年以上'
        ];

        const  working_hours=['2-4小时','5-8小时','8-10小时','10小时以上'];

        if(this.state.user_pro_data !=null && this.state.user_exp_data != null) {


            return (
                <div style={{
                    width: '100%',
                    height: max_height,
                    minHeight: '100vh',
                    overflow: 'hidden',
                    backgroundColor: '',
                }}>
                    <div style={{
                        width: '100%',
                        height: topbarboxH,
                        backgroundColor: '#f6d809',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '0px',
                        flexDirection: 'column'
                    }}>

                        <div style={{
                            width: "80%",
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '0px',
                            flexDirection: 'column'
                        }}>
                            <span className="ATitle" style={{paddingTop: '50px', alignSelf: 'center'}}>加入快平方</span>

                            <span className="contentText_center"
                                  style={{paddingTop: '20px'}}> </span>
                        </div>

                    </div>
                    <div className='contentFrameBox' style={{backgroundColor: '#EFEFEF', paddingTop: '0px'}}>
                        <div className='triangleDown_Yellow'></div>
                    </div>


                    <div className="items_placeCenter_W" style={{height: 'auto'}}>
                        <div style={{backgroundColor: "", width: centerboxW, height: '100%', paddingTop: '20px'}}>
                            <div style={{float: 'left', display: 'flex', paddingBottom: '0px'}}>
                                <img src={require('../asset/flashicon.png')} style={{width: '25px',height:'25px'}}/>
                                <span className="Subtitle">详细信息</span>
                            </div>

                            <div className='pageLineS' style={{paddingTop: '35px', width: '100%'}}></div>
                            <div style={{height: '30px'}}></div>

                            <Yinput width='100%' height='30px' name='name' bgColor='#EFEFEF' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action} state={this.props.brideg.state.name}  value={this.props.brideg.data.name}
                                    preset='真实姓名' limit={40}/>
                            <Yinput width='100%' height='30px' name='wechatid'  bgColor='#EFEFEF'  preset='微信ID' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action} state={this.props.brideg.state.wechatid}  value={this.props.brideg.data.wechatid}
                                    limit={100}/>

                            <LocationCity width='100%' height='30px' bgColor='#EFEFEF' broderColor={'#b0b0b0'}
                                          callBack={this.props.brideg.action}
                                          province={this.props.brideg.data.province}
                                          city={this.props.brideg.data.city}
                                          area={this.props.brideg.data.area}
                            />

                            <Yinput width='100%' height='30px'  bgColor='#EFEFEF' value='' preset='最近职位' name={'title'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action} state={this.props.brideg.state.wechatid}  value={this.props.brideg.data.wechatid}
                                    limit={20}/>

                            <Yinput width='100%' height='30px' type='select' bgColor='#EFEFEF' value={working_years} name={'working_years'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}
                                    preset='工作年限' limit={20}/>

                            <Yinput width='100%' height='30px' type='select' bgColor='#EFEFEF' value={working_hours} name={'working_hours'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}
                                    preset='每天可投入工作时数' limit={20}/>


                            <Yinput width='100%' height='30px' type='' bgColor='#EFEFEF' value='' preset='曾经服务品牌' name={'clients'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}
                                    limit={80}/>

                            <Yinput width='100%' height='30px' type='' bgColor='#EFEFEF' value='' preset='曾经获得奖项' name={'awards'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}
                                    limit={80}/>

                            <Yinput width='100%' height='30px' type='' bgColor='#EFEFEF' value='' preset='期望日薪范围' name={'daily_cost'} broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}
                                    limit={80}/>


                            <div style={{float: 'left', display: 'flex', paddingBottom: '0px'}}>

                                <span className="Subtitle">您的专业领域</span>
                            </div>

                            <div className='pageLineS' style={{paddingTop: '35px', width: '100%'}}> </div>
                            <div style={{height: '30px'}}> </div>

                            <div style={{width: '100%',float:'left',height:'auto',backgroundColor:''}}>

                                {
                                    this.state.user_pro_data.map((i, index) => (

                                        <Yinput callBack={this.props.brideg.action} broderColor={'#b0b0b0'}  key={index} width='40%' height='30px'  type='checkbox' name='user_pro' bgColor='#EFEFEF' value={i['id']} preset={i['tag_name']} limit={20}/>
                                    ))
                                }

                            </div>


                            <div style={{float: 'left', display: 'flex', paddingTop: '20px'}}>

                                <span className="Subtitle">您的行业经验</span>
                            </div>


                            <div style={{height: '10px',width:'100%',display:'flex',paddingBottom: '30px'}}>

                            <div className='pageLineS' style={{paddingTop: '0px', width: '100%'}}> </div>

                            </div>

                            <div style={{width: '100%',float:'left',height:'auto',backgroundColor:''}}>

                                {
                                    this.state.user_exp_data.map((i, index) => (

                                        <Yinput callBack={this.props.brideg.action} broderColor={'#b0b0b0'}   key={index} width='40%' height='30px'  type='checkbox' name='user_exp' bgColor='#EFEFEF' value={i['id']} preset={i['tag_name']} limit={20}/>
                                    ))
                                }

                            </div>


                        </div>


                    </div>


                    <div className="items_placeCenter_W" style={{backgroundColor: ''}}>

                        <div style={{
                            width: '80%',
                            backgroundColor: "",
                            height: '80px',
                            paddingTop: '10px',
                            display: 'flex',
                            justifyContent: "space-between"
                        }}>

                            <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                                <PreButton callBack={()=>this.props.brideg.callBack('pre')} />


                            </div>

                            <div style={{width: '100px', height: '100px', backgroundColor: ''}}>

                                <img src={require('../asset/step2.png')} style={{minWidth: '60px', width: '3vw'}}/>


                            </div>

                            <div style={{width: '100px', height: '100px', backgroundColor: ''}}>

                                <NextButton callBack={() => this.props.brideg.callBack('nex')}/>


                            </div>
                        </div>

                    </div>

                </div>
            )
        }else{
            return (

                <div style={{height:'100vh'}}>

                   <LoadingAnima />

              </div>
            )
        }
    }
}


export default Detailinfo;