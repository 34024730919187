import React from 'react';
import { Typography, Space,Row, Col} from 'antd';
const { Text,Title } = Typography;



class DataListitem extends React.Component{


        constructor(props) {
            super(props)
            this.state = {
                initial: 1,
                loading:true,
                backgroundColor:'#fff',

            }

            this.mouseEnter = this.mouseEnter.bind(this)
            this.mouseOut = this.mouseOut.bind(this)

        }

    componentWillMount() {

    if(this.props.item){
      this.setState({loading:false})


     }


    }

     mouseEnter(){
            this.setState({backgroundColor:'#ebebeb'})


    }

     mouseOut(){
         this.setState({backgroundColor:'#fff'})
    }

    cutstring(str){
            let ksd=String(str)
            let kfa=''
           if(ksd.length>26)kfa='...'
            const kkn= ksd.substring(0, 26)+kfa ;
           return  kkn
    }

render() {

    if (this.state.loading) {
        return (<div></div>)
    } else {

        return (
            <div style={{
                width: '100%',
                height: '100%',
                minHeight:'36px',
                backgroundColor: this.state.backgroundColor,
                paddingTop: '0px',
                marginBottom: '0px',
                transition: '0.3s ease-out all',
                cursor: "pointer",
                fontSize:'13px',

            }}
                 onClick={()=>this.props.callBack(this.props.item)}
                 onMouseEnter={() => {
                this.mouseEnter()
            }}
                 onMouseLeave={() => {
                this.mouseOut()
            }}
            >
                <Row gutter={[16, 16]} style={{marginLeft: '4px',marginTop:'0px',height:'100%',padding:'10px',marginBottom:'1px'}}>
                    {
                       Object.values(this.props.item).map((key, index) => (

                            <Col span={this.props.space[index]} key={index}>
                                 <Text
                                type="secondary">{this.cutstring(key)}</Text>
                            </Col>


                        ))
                    }


                </Row>

            </div>
        )
    }
}

}

export default DataListitem;