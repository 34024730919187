/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Button, Result,Typography,Space,Divider,Statistic, Row, Col} from "antd";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    LoadingOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/ApiBridge";


const { Title,Text,Paragraph } = Typography;

/*
* choose registration account type
*/



class Overview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            total_orders:0,
            new_message:0,
            loading:false,


        }


    }

    componentWillMount() {



    }





    render() {
        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {

            return (
                <div style={{width: '100%', padding: '0px'}}>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '50px',
                        marginBottom: '10px',

                    }}>
                        <Title level={4}>您好 {this.props.bridge.family_name}，</Title>
                        <Space>
                            <UserOutlined/>
                            <Text type="secondary">{this.props.bridge.email}</Text>
                            <Text type="secondary" style={{color:'orange'}}>
                                {(this.props.bridge.member_state=='default')?'审核中':'正常'}

                            </Text>
                        </Space>


                        <Divider/>

                        <Row gutter={16}>
                            <Col span={10}>
                                <Statistic title="正在进行中的项目" value={this.props.bridge.total_orders}/>
                            </Col>
                            <Col span={10}>
                                <Statistic title="最新消息" value={this.props.bridge.new_message}/>

                            </Col>
                            {/*
                            <Col span={4}>

                                <Statistic title="等级" value={(this.props.bridge.member_level=='default')?'1':'this.props.bridge.member_level'}/>

                            </Col>*/}
                        </Row>

                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',


                    }}>
                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}>系统公告</Title>

                            <NotificationOutlined/>
                        </Space>
                        <Paragraph>
                            快平方v1.0.0更新，<a href="">点击</a>查看更多更新细节。
                        </Paragraph>


                    </div>

                </div>
            )
        }
    }
}


export default Overview;