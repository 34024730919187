/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Button, Result,Typography,Space,Divider,Statistic, Row, Col} from "antd";

import {
    MailOutlined,
    AuditOutlined,
    KeyOutlined,
    WechatOutlined,
    PhoneOutlined,
    PlusOutlined,
} from '@ant-design/icons';


const { Title,Text,Paragraph } = Typography;


/*
* choose registration account type
*/


class Accountview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            Drawervisible:false,

        }


    }




    render(){


        return(


            <div style={{width: '100%', padding: '0px'}}>



                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',

                }}>
                    <Space>
                        <Title level={5} style={{paddingTop:'6px'}}>安全设置</Title>


                    </Space>
                    <Paragraph>

                    </Paragraph>


                </div>

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',
                }}>

                    <Row  >
                        <Col span={20}>
                            <Space>
                                <MailOutlined />
                                <Text type="secondary">账号邮箱：{this.props.bridge.email}</Text>
                            </Space>
                        </Col>
                        <Col span={4}>

                            <Text type="secondary">账号绑定邮箱暂不支持修改。</Text>
                        </Col>
                    </Row>

                </div>

                {
                    (this.props.bridge.type=='cl')?(
                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                    }}>
                        <Row>
                            <Col span={20}>
                                <Space>
                                    <AuditOutlined/>
                                    <Text type="secondary">企业名称：{this.props.bridge.company}</Text>
                                </Space>
                            </Col>
                            <Col span={4}>

                                <Button type="text" onClick={() => this.props.callBack('account_company')}>修改</Button>
                            </Col>
                        </Row>

                    </div>
                    ):('')
                }

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',
                }}>
                    <Row  >
                        <Col span={20}>
                            <Space>
                                <KeyOutlined />
                                <Text type="secondary">登陆密码： *******</Text>
                            </Space>
                        </Col>
                        <Col span={4}>

                            <Button type="text" onClick={()=>this.props.callBack('account_password')}>修改</Button>
                        </Col>
                    </Row>

                </div>

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',
                }}>
                    <Row  >
                        <Col span={20}>
                            <Space>
                                <WechatOutlined />
                                <Text type="secondary">联系人微信号： {(this.props.bridge.wechat_id=='default')?('***'):(this.props.bridge.wechat_id)}</Text>
                            </Space>
                        </Col>
                        <Col span={4}>

                            <Button type="text" onClick={()=>this.props.callBack('account_wechatid')}>修改</Button>
                        </Col>
                    </Row>

                </div>

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',
                }}>
                    <Row  >
                        <Col span={20}>
                            <Space>
                                <PhoneOutlined />
                                <Text type="secondary">联系人手机号： {this.props.bridge.mobile}</Text>
                            </Space>
                        </Col>
                        <Col span={4}>
                            <Button type="text" onClick={()=>this.props.callBack('account_mobile')}>修改</Button>

                        </Col>
                    </Row>

                </div>



            </div>
        )
    }
}


export default Accountview;