//by yi 2020 kuaipf project


import React from 'react';

import './about.css';
import Triangledown from '../asset/tranigledown.svg';
import {Link} from "react-router-dom" 
import Beforeafter from "../components/beforeafter";
import ButtonBlack from '../components/ButtonBlack'
import ButtonWhite from '../components/ButtonWhite'
import ButtonYellow from '../components/ButtonYellow'
import TopButton from  '../components/TopButton';
import Nav from '../components/nav'
import  '../components/global'
import {connect} from "react-redux";
import {fullfooter, fullmenu, navAbout} from "../reduceractions";


let Bfitem_w='200px'
let Bfitem_H='220px'
let Bfitem_l='20px'
let Bfitem_r='20px'
let iconligh=2
if (window.innerWidth <= 1024) {
    Bfitem_w='80%'
    Bfitem_l='10%'
    Bfitem_r='0px'
    Bfitem_H='auto'
    iconligh=1
}


let VCitem_w='100%'
let VCinsideitem_w=720-20
let VCitem_H='180px'
let VCitem_l='20px'
let VCitem_r='20px'
let VCiconligh=2
if (window.innerWidth <= 1024) {
    VCitem_w='100%'
    VCitem_l='5%'
    VCitem_r='0px'
    VCitem_H='200px'
    VCinsideitem_w='90%'
    VCiconligh=1
}


function BFitem(props){
    return(
      <div style={{width:Bfitem_w,height:"auto",marginLeft:Bfitem_l,marginRight:Bfitem_r,float:"left"}}>
        <div style={{width:'100%',height:Bfitem_H,backgroundColor:'#fff',alignItems:"center",display:"flex",justifyContent:"center",flexDirection:"column",boxShadow:'0px 0px 2px #E4E4E4'}}>


            <div style={{width:'80%',height:'80%',paddingLeft:5,display:"flex",flexDirection:"column",alignItems:"flex-start",textAlign:"left",paddingBottom:20}}>
                <span  className='txt03' style={{color:'#000',fontSize:30,paddingTop:10,lineHeight:iconligh}}>“</span>
                <span className={'framContent_center'} style={{paddingTop:0,textAlign:"left",wordWrap: 'normal'}}> {props.text}</span>
            </div>

        </div>
          <div   style={{width:'100%',height:50,backgroundColor:'',textAlign:"right",display:"flex",justifyContent:"flex-end"}}>
              <div className='triangleDown_White' style={{marginRight:14}}>  </div>
          </div>
      </div>
    )

}


function VCitem(props){

    if(props.indexid % 2 == 0){
    return(
    <div style={{backgroundColor:'',width:VCitem_w,height:"auto",marginBottom:20,float:"right",display:"flex",justifyContent:"left"}}>
        <div style={{width:VCinsideitem_w,height:'auto',backgroundColor:'#fff',marginLeft:VCitem_l,alignItems:"center",display:"flex",justifyContent:"center",flexDirection:"column",boxShadow:'0px 2px 0px #E4E4E4'}}>
            <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'4%',backgroundColor:'',paddingTop:'5%',textAlign:"left",wordWrap: 'normal'}}>
                {props.text}
            </span>
            <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:10,textAlign:"right",wordWrap: 'normal',fontSize:'12px'}}>
                {props.name}
            </span>
            <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:0,textAlign:"right",wordWrap: 'normal',fontSize:'12px'}}>
                 {props.title}
            </span>
            <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:0,textAlign:"right",paddingBottom:'5%',wordWrap: 'normal',fontSize:'12px'}}>
         {props.company}
            </span>

        </div>
        <div   style={{width:'auto',height:VCitem_H,backgroundColor:'',verticalAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
           <div className='triangleDown_White_r' style={{marginRight:0,}}>  </div>
        </div>
    </div>
    )
        }else{

        return(
            <div style={{backgroundColor:'',width:VCitem_w,height:"auto",marginBottom:20,float:"right",display:"flex",justifyContent:"right"}}>

                <div   style={{width:'auto',height:VCitem_H,backgroundColor:'',verticalAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <div className='triangleDown_White_l' style={{marginRight:0}}>  </div>
                </div>
                <div style={{width:VCinsideitem_w,height:VCitem_H,marginRight:VCitem_r,backgroundColor:'#fff',alignItems:"center",display:"flex",justifyContent:"center",flexDirection:"column",boxShadow:'0px 2px 0px #E4E4E4'}}>

                     <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'4%',backgroundColor:'',paddingTop:'5%',textAlign:"left",wordWrap: 'normal'}}>
                {props.text}
            </span>
                    <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:10,textAlign:"right",wordWrap: 'normal',fontSize:'12px'}}>
                {props.name}
            </span>
                    <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:0,textAlign:"right",wordWrap: 'normal',fontSize:'12px'}}>
                 {props.title}
            </span>
                    <span className={'framContent_center'} style={{paddingLeft:'5%',paddingRight:'5%',backgroundColor:'',paddingTop:0,textAlign:"right",paddingBottom:'5%',wordWrap: 'normal',fontSize:'12px'}}>
         {props.company}
            </span>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navAbout())
        // console.log(minfooter())
    }
})


class About extends React.Component {


    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState();

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }


render() {



    let centerViewbox_W = 'auto'
    let centerViewbox_W_t='680px'
    let align_contL = '50px'
    let iconboxW = '25%'
    let iconboxH = '0px'

    let scrolliconR = '20px'
    let scrolliconAlpha = 1;
    let showscrolltop = 0
    let callforactionfontsize='23px'
    let sologenSize=25


        if (window.innerWidth <= 1124) {
            callforactionfontsize='15px'
            centerViewbox_W = '320px'
            centerViewbox_W_t='320px'
            align_contL = '0px'
            scrolliconR= '0px'
            sologenSize=20
            iconboxW = '80%'
            iconboxH = '100px'
            scrolliconAlpha = 0
        }


    let callactionwidth='380px'
    window.innerWidth<1024?callactionwidth='320px':callactionwidth='380px'



    return (
        <div className='aboutView'>
            <Nav/>
            <div className='about_Topviwbox'>
                {/*scroll icon*/}
                <TopButton callback={() => this.clickbtn()} opacity={this.state.showscrolltop}/>



                <img src={require('../asset/scrollicon.png')} className='scrollingIcon'
                     style={{right: scrolliconR, opacity: this.state.scrolliconAlpha,top:'200px', transition: '0.15s ease-out all'}}/>

                <div className='contentcenColumn' style={{
                    width: centerViewbox_W_t,
                    minWidth: '320px',
                    height: 'auto',
                    backgroundColor: '',
                    paddingTop: '',
                    paddingBottom:'60px',
                }}>
                       <span className='aboutTitle' style={{
                           textAlign: 'left',
                           paddingBottom: '10px',
                           paddingTop: '160px',
                           fontSize:sologenSize
                       }}>瞄准了市场就比谁先出手，快广告让子弹飞！</span>
                    <span className='about_Textcontent' style={{lineHeight:2,paddingBottom:14}}>不是只有你瞄准了这块蓝海市场! 当大家争相扣板机的时候，你的广告就得出手更快。折腾久了只有错失良机，损耗资源。在数字营销的环境下，宁可错发，也不要错失！
</span>


                    <span className='aboutTitle' style={{
                        width:'100%',
                        textAlign: 'left',
                        paddingBottom: '15px',
                        paddingTop: '50px',
                        fontSize:sologenSize
                    }}>只有精英高手，才能创作快广告</span>


                    <span className='about_Textcontent' style={{lineHeight:2,paddingBottom:14}}>别再和资浅、不专业瞎折腾！<br/>快平方的管理团队个个拥有超过20年4A广告公司高管经验，控制品管，网罗专业精英，精准配对，针对你的需求，限时产出好广告。</span>



                </div>


                <div className='contentFrameBox' style={{height: '50px', paddingTop: '50px',paddingBottom:'120px',backgroundColor:''}}>

                    <Link to={'/get-start'}>
                        <ButtonWhite ButtonName='立即下单'/>
                    </Link>
                </div>



            </div>





            {/* call for action */}

            <div className='contentFrameBox' style={{backgroundColor:'#f1f1f1',height:'auto'}}>


                <div className='contentcenColumn' style={{
                    width: centerViewbox_W,
                    minWidth: '320px',
                    height: 'auto',
                    backgroundColor: '',
                    paddingLeft: 10,
                    paddingBottom:40,
                }}>

                    <div style={{width:'100%',height:"auto", paddingBottom: '40px',paddingTop: '50px',textAlign:"center"}}>
                    <span className='aboutTitle' style={{
                        textAlign: 'left',
                        paddingBottom: '30px',
                        paddingTop: '50px',
                        fontSize:sologenSize
                    }}>营销高管圈的洞察，快平方广告的承诺
</span>

                    </div>





                </div>
                <div  className='contentFrameBox' style={{width:'100%',height:"auto",backgroundColor:'',paddingLeft: 0}}>
                    <div style={{width:'100%',height:"auto",backgroundColor:'',minWidth:370,maxWidth:720}}>
                    {
                        Beforeafter.map((item, key) => {
                            if(item.type=='before') {
                                return (
                                    <VCitem key={item.id} text={item.text} indexid={item.id} name={item.name} title={item.title} company={item.company}/>
                                )
                            }
                        })
                    }
                    </div>
                </div>

                <div className='contentFrameBox' style={{height: '50px', paddingTop: '100px',paddingBottom:'140px',backgroundColor:''}}>

                    <Link to={'/get-start'}>
                        <ButtonYellow ButtonName='立即下单'/>
                    </Link>
                </div>

                </div>
            {  /**
            <div className='about_Topviwbox' style={{paddingBottom:50}}>

                <img src={require('../asset/after.png')}  style={{height:'120px',padding:20,marginTop:20}}/>

                <div className='contentcenColumn' style={{
                    width: centerViewbox_W_t,
                    minWidth: '320px',
                    height: 'auto',
                    backgroundColor: '',
                    paddingLeft: 2,
                    paddingBottom:40,
                    paddingTop:20,
                    textAlign: 'left',
                }}>
                    <span className='about_Textsubline_d' style={{fontSize:sologenSize}}>合作后</span>
                    <span className='about_Textsubline_d'>与快平方合作可以解决您许多痛点，收获更高的工作效率。</span>
                </div>


                <div  className='contentFrameBox' style={{width:'100%',height:"auto",backgroundColor:'',paddingBottom:40,paddingLeft: 0,}}>
                    <div style={{width:'65%',height:"auto",minWidth:370,maxWidth:720}}>
                        {
                            Beforeafter.map((item, key) => {
                                if(item.type=='after') {
                                    return (
                                        <BFitem key={item.id} text={item.text}/>
                                    )
                                }
                            })
                        }
                    </div>
                </div>

                <Link to={'/get-start'}>
                    <ButtonWhite ButtonName='马上咨询'/>
                </Link>
            </div>
             **/}

        </div>
    )

}

}



export default connect(mapStateToProps,mapDispatchToProps)(About) ;