//by yi 2020 kuaipf project


import React ,{ useContext}from 'react';
import ReactDOM from 'react-dom';
import './home.css';
import  '../components/global'
import ButtonWhite from '../components/ButtonWhite'
import ButtonYellow from '../components/ButtonYellow'
import ButtonBlack from '../components/ButtonBlack'
import FrameBox from  '../components/Keyvaluebox';
import Valueitems from "../components/valueitems";
import ServiceInfo from  '../components/ServiceInfo';
import Clents from './Clents';
import Talent from "./Talent";
import TopButton from  '../components/TopButton';
import Nav from '../components/nav'

import {Link} from "react-router-dom"

import {connect, useDispatch, useSelector} from "react-redux";
import {incrementcounter, minfooter, minmenu,fullfooter,fullmenu,navHome} from "../reduceractions";







const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})


//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({
    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navHome())
        // console.log(minfooter())
    }
})



let inwinheight= window.innerHeight
let sologanfontSize = window.innerWidth / 52
let bannertext_w='780px'


let sologanLeft =(window.innerWidth -bannertext_w)/ 2

let sologansublinefontSize = 15

let columnValueW = '100%'
let topbtnshow=1

let columnValue_itemW='280px'
let columnValue_itemsContent='auto'
let columnValueW_line='480px'
let sologinW='100%'
let subcountW='100%'
let callforactionfontsize='23px'

let scrolliconR='20px'
let buttonleft='0px'
if (!sologanfontSize) {
    sologanfontSize = 40
}

let align_contL = '0px'
let AtitleSize=25
let bannerTopspace=50
let tran_sec=0



if (window.innerWidth <= 1124) {
    topbtnshow=0
    tran_sec=0.25
    sologanfontSize = 30
    AtitleSize=20
    scrolliconR='0px'
    sologansublinefontSize = 15
    columnValueW = '370px'
    callforactionfontsize='15px'
    align_contL='20px'
    columnValue_itemW=window.innerWidth*.9;
    columnValue_itemsContent=window.innerWidth*.9;
    columnValueW_line='350px'
    bannerTopspace=0

    buttonleft='0%'
    bannertext_w='80%'
    sologanLeft =(window.innerWidth-bannertext_w)/ 2
}


if(global.gData.isMobile==null && window.innerHeight <=680){
    sologanfontSize = 27
    sologansublinefontSize=13
    sologinW='100%'
    subcountW='100%'
    bannerTopspace=16
    bannertext_w='680px'
}




const sologenH= window.innerHeight/2


const bennerViewhieght = window.innerHeight



class Home extends React.Component {

    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            column02Top:bennerViewhieght,
            scrollfloatingSpace:'0',
            winscrollTop:null,
            bannerImgHeight : window.innerHeight * 1.2,
            bannerImgWidth : window.innerHeight * 1.2 * 1.2 * 1920 / 1135,
            bannerapha:true,
            floadingColumnH:'100vh',
            showvideo:'hidden',

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.playvideo =this.playvideo.bind(this)
        this.closevideo =this.closevideo.bind(this)



        this.centerViewbox_W='54%'



    }

    componentDidMount() {

        this.setState({
            bannerImgHeight : window.innerHeight * 1.2,
            bannerImgWidth : window.innerHeight * 1.2 * 1.2 * 1920 / 1135,
            bannerapha:false,
        })

        const baiduTongji = () => {
            window._hmt = window._hmt || [];
            let sc;
            (function () {
                if ((sc = document.getElementById('baidu'))) {
                    document.head.removeChild(sc);
                }
                let hm = document.createElement('script');
                hm.src = "https://hm.baidu.com/hm.js?b57f11b0f08d71fb015fe4441e72833a";
                hm.id = 'baidu';
                let s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(hm, s);
            })();
        };

        baiduTongji();

       // window._hmt.push(['_trackEvent', 'video', 'play', '视频播放']);



       // let _czc =[];
       //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
       // _czc.push(["_setAccount", "1279721278"]);
       // _czc.push(['_trackEvent', 'home', 'init', 'homepage']);


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })



        if(window.innerWidth>1024) {

            if (this.scrolltopcount < window.innerHeight) {
                let column02Top = window.innerHeight - this.scrolltopcount / 4
                let scrollfloatingSpace = this.scrolltopcount / 4

                this.setState({
                    column02Top: column02Top,
                    scrollfloatingSpace: scrollfloatingSpace,
                    winscrollTop: this.scrolltopcount,
                })

            } else {

            }
        }

        let columnH = document.getElementById("column02")
        let columnHd = ''
        let floadingColumnH = ''
        let  inwinheight_now= window.innerHeight
        if (columnH) {

            floadingColumnH = columnH.clientHeight - this.state.scrollfloatingSpace+(inwinheight_now-inwinheight)
            this.setState({
                floadingColumnH: floadingColumnH,

            })
        }




    }

    componentWillMount() {

        this.props.LoadPageState();
        /*
         *
         * change state
         */

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }



    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)

        this.setState({
            bannerImgHeight : window.innerHeight * 1.2,
            bannerImgWidth : window.innerHeight * 1.2 * 1.2 * 1920 / 1135,
            bannerapha:true,
        })
    }


    //props update

    componentWillReceiveProps(){

    }


     clickbtn(){
         window.scroll(0, -1000);

    }


    playvideo(){

       // console.log(121)
        this.setState({
            showvideo:'visible'
        })

        let myVideo = document.getElementsByTagName('video')[0];   //获取视频video


        myVideo.play();

       // _hmt.push(['_trackEvent', 'video', 'play', '视频播放']);


        // let _czc =[];
        //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
       // _czc.push(["_setAccount", "1279721278"]);
       // _czc.push(['_trackEvent', 'homevideo', 'play', '视频播放']);

        window._hmt.push(['_trackEvent', 'video', 'play', '首页视频播放']);




    }

    closevideo(){

        let myVideo = document.getElementsByTagName('video')[0];   //获取视频video


            myVideo.pause();



        console.log(121)
        this.setState({
            showvideo:'hidden'
        })

    }

render() {





    //dinamice change the main view height
    let columnH = document.getElementById("column02")
    let columnHd = ''
    let floadingColumnH = ''
    let  inwinheight_now= window.innerHeight
    if (columnH) {

            floadingColumnH = columnH.clientHeight - this.state.scrollfloatingSpace +(inwinheight_now-inwinheight)
            // columnHd = columnH.clientHeight + window.innerHeight - this.state.winscrollTop / 4

    }
    let banner_Text= document.getElementById("banner_Text")
    let banner_Text_top=''
    if(banner_Text){
        banner_Text_top=(window.innerHeight-banner_Text.clientHeight)/2-bannerTopspace
    }



    let callactionwidth='380px'
        window.innerWidth<1024?callactionwidth='320px':callactionwidth='380px'


    return (
        <div className='mainView' id='mainView' style={{height: 'auto',backgroundColor:'#999'}}>

              <div style={{position:"fixed",width:'100%',height:'100%',zIndex:120,display:"flex",alignItems:"center",justifyContent:"center",visibility:this.state.showvideo}}>
                  <div onClick={this.closevideo} style={{width:'100%',height:'100%',backgroundColor:'#000',opacity:'0.9',position:"absolute"}}>
                  </div>

                  <div style={{width:bannertext_w,height:'auto',backgroundColor:'',position:"absolute"}}>

                      <video id='playvideo_dom' src="https://kuaipfoss.oss-cn-beijing.aliyuncs.com/video/KPF%20Video_Web.mp4"   controls="controls"  style={{width:'100%',height:'100%',paddingLeft:0,left:0}} >

                      </video>


                  </div>

              </div>

            <Nav/>




            {/* banner */}
            <div className='bannerviwbox' style={{height: bennerViewhieght,backgroundSize:'100% '+this.state.bannerImgHeight}}>

                <div id='banner_Text' className='sologan' style={{width:bannertext_w,left: sologanLeft + 'px',height:'auto',alignItems:"center",backgroundColor:'',top:banner_Text_top,transition:'0.25s ease-out all'}}>


                    <div style={{width:"100%",backgroundColor:'',alignSelf:"center"}}>
                    <div className='txt01' style={{fontSize: sologanfontSize,width:sologinW,alignSelf:"center",backgroundColor:'',textAlign:"center"}}>快广告才是好广告<br/>广告快餐 5天出餐!</div>


                    <div className='txt02' style={{fontSize: sologansublinefontSize,width:subcountW,backgroundColor:'',display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div style={{width:'80%',alignSelf:"center",marginTop:'10px'}}>

                        </div>

                    </div>
                    <br/>
                        <div style={{width:"100%",backgroundColor:'',alignSelf:"center",textAlign:"center",paddingTop:60,display:"flex",justifyContent:'center'}}>
                    <div style={{width: '180px',textAlign:"center",height: 'auto',paddingTop:0,backgroundColor:'',marginLeft:buttonleft}}>
                        <Link to={'/get-start'}>
                            <ButtonWhite ButtonName='立即下单'/>
                        </Link>
                    </div>
                        </div>

                    </div>

                    {(window.innerWidth>1024)?(

                        <div style={{width: '100%', height: '150px', backgroundColor: '',paddingTop:'6vh'}}>

                            <div className='frameAligncenter' style={{width: columnValueW, paddingBottom: '50px',display:"flex",justifyContent:"space-between",flexDirection:"row"}}>

                                <Valueitems icon='icon-flash' nicon='nicon1.png' iconSize='45px' Title='广告主题快餐' subTitle='5天交件'
                                            Content='传播主题<br/>主视觉KV<br/>文案' Scrollstate={this.state.winscrollTop}/>
                                <Valueitems icon='icon-cor' nicon='nicon2.png' iconSize='30px' Title='视频创作快餐' subTitle='5天交件'
                                            Content='社交短视频 <br/>TVC创意' Scrollstate={this.state.winscrollTop}/>
                                <Valueitems icon='icon-dam' nicon='nicon3.png' iconSize='35px' Title='微信小程序快餐' subTitle='5天启动'
                                            Content='小程序架构<br/>界面设计，技术开发<br/>平台认证等服务'
                                            Scrollstate={this.state.winscrollTop}/>

                            </div>

                        </div>):('')

                    }


                </div>


                {/*scroll icon*/}

                <img src={require('../asset/scrollicon.png')} className='scrollingIcon' style={{right:scrolliconR}}/>


                {
                    (global.gData.isMobile==null && window.innerHeight/window.innerWidth <0.42)?(''):(
                        <img src={require('../asset/banner.jpg')} height={this.state.bannerImgHeight * 1.1}
                           className='bannerbg' style={{
                           height: this.state.bannerImgHeight * 1.1,
                           transition: '0.15s ease-out all',
                           }}/>
                    )

                }


            </div>


            {/* under the top layer div syns height*/}
            <div className='contentFrameBox' style={{backgroundColor: '#f5f5f5', height: this.state.floadingColumnH,transition:tran_sec+'s ease-out all'}}>
                <div style={{width:'100%',height:'50%',backgroundColor:'#f5f5f5'}}>
                </div>

                <div style={{width:'100%',height:'50%',backgroundColor:'#000'}}>
                </div>

            </div>


            {/* top layer */}
            <div className="column02" style={{top: this.state.column02Top, display: 'block',transition:tran_sec+'s ease-out all'}} id='column02'>

                <TopButton callback={() => this.clickbtn()} opacity={this.state.showscrolltop}/>

                <center style={{paddingTop: '0px', backgroundColor: '#f5f5f5'}}>

                    {(window.innerWidth<1024)?(

                        <div className='contentFrameBox'>

                            <div className='frameAligncenter' style={{width: columnValueW, paddingBottom: '50px'}}>

                                <FrameBox icon='icon-flash' nicon='yicon1.png' iconSize='45px' Title='广告主题快餐' subTitle='5天交件'
                                          Content='传播主题、主视觉KV、文案' Scrollstate={this.state.winscrollTop}/>
                                <FrameBox icon='icon-cor' nicon='yicon2.png' iconSize='30px' Title='视频创作快餐' subTitle='5天交件'
                                          Content='社交短视频、TVC创意' Scrollstate={this.state.winscrollTop}/>
                                <FrameBox icon='icon-dam' nicon='yicon3.png' iconSize='35px' Title='微信小程序快餐' subTitle='5天启动'
                                          Content='小程序架构、界面设计、技术开发、平台认证等服务'
                                            Scrollstate={this.state.winscrollTop}/>



                            </div>


                        </div>


                    ):('')
                    }




                    <div className='contentFrameColumnb2'
                         style={{backgroundColor: '#fff', height: 'auto', paddingTop: '50px'}}>

                        <div className='columnTitle'>
                            <span>主要服务</span>
                            <div className='columnTitleline' style={{width: '110px'}}></div>

                        </div>

                        <div  onClick={this.playvideo} style={{marginTop:'30px',width:'140px',cursor:"pointer",marginLeft:'0px',display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <span id='playvideo' className='txt07' style={{fontSize:'14px',color:'#666'}} >30秒了解快平方</span>
                            <div style={{width:'15px',height:'15px',marginLeft:'6px',marginTop:'-10px',}}>
                            <img src={require('../asset/play.png')}  width={'15px'} height={'15px'} style={{marginTop:'0px',alignSelf:"center"}} />
                            </div>
                        </div>


                        <div style={{width:'auto',height:'auto',backgroundColor:'',paddingTop:'50px',paddingBottom:'80px'}}>
                            <div style={{width:columnValue_itemsContent,height:'300px',backgroundColor:'',alignSelf:"center",float:"center"}}>
                            <div style={{width:columnValue_itemW,height:'auto',float:'left',margin:'0px',backgroundColor:'',}}>
                                <div style={{margin:'26px'}}>
                                <div style={{width:'100%',height:'120px'}}>
                                <img src={require('../asset/yicon1.png')}  style={{height:'70%'}}/>
                                </div>

                                <div className={"servicea"} style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='columnTitle'>广告主题</span>
                                </div>

                                <div   style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='framContent' >李筠 (June L.) 首席品控官<br/>20+年客服经验, 统筹无数国內国外品牌的营销项目，为你组建精英小组!</span>
                                </div>
                                </div>


                            </div>

                            <div style={{width:columnValue_itemW,height:'auto',float:'left',margin:'0px',backgroundColor:''}}>
                                <div style={{margin:'26px'}}>
                                <div style={{width:'100%',height:'120px'}}>
                                    <img src={require('../asset/yicon2.png')}  style={{height:'70%'}}/>
                                </div>

                                <div className={"serviceb"} style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='columnTitle'>视频创作</span>
                                </div>

                                <div   style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='framContent' >吴佳蓉 (Canon W.) 首席创意官<br/>掌舵4A创意部门,为众多领导品牌打造叫好叫座的广告创意, 作品屡获奖项並担任各大广告节评委，为你组建精英小组!</span>
                                </div>
                                </div>
                            </div>

                            <div style={{width:columnValue_itemW,height:'auto',float:'left',margin:'0px',backgroundColor:'',}}>
                                <div style={{margin:'26px'}}>
                                <div style={{width:'100%',height:'120px',paddingTop:'10px'}}>
                                    <img src={require('../asset/yicon3.png')}  style={{height:'70%'}}/>
                                </div>

                                <div className={"servicec"} style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='columnTitle'>微信小程序</span>
                                </div>

                                <div   style={{width:'100%',height:'120px',backgroundColor:''}}>
                                    <span className='framContent' >曾震东 (Joseph T.) 首席数字官<br/>数字平台原住民, 熟悉数字生态及各种技术开发。曾为不同500强企业打造数字营销战役，为你组建专案小组!</span>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>



                    </div>

                    {/* //client layer
                    <div className='contentFrameBox' style={{backgroundColor: '#fcfcfc', height: 'auto'}}>
                        <div className='columnTitle' style={{paddingTop: '30px'}}>
                            <span>合作客户</span>
                            <div className='columnTitleline' style={{width: '110px'}}></div>
                        </div>

                        <Clents/>


                    </div>

                     */}



                    <div className='contentFrameBox' style={{backgroundColor: '#fff', height: 'auto'}}>


                         <Talent/>


                    </div>


                    <div className='contentFrameBox' style={{backgroundColor: '#f5f5f5'}}>

                        <div className='triangleDown' style={{borderTopColor: '#fff'}} > </div>
                    </div>

                    {/* call for action */}
                    <div className='contentFrameBox' style={{backgroundColor: '#f5f5f5', height: 'auto',paddingBottom:'50px'}}>


                        <div className='contentcenColumn' style={{
                            width: 'auto',
                            minWidth: '320px',
                            height: 'auto',
                            backgroundColor: '',
                            paddingLeft: align_contL,
                            paddingRight:'20px',
                            textAlign:"left",
                        }}>




                    <span className='aboutTitle' style={{
                        textAlign: 'left',
                        paddingBottom: '60px',
                        paddingTop: '30px',
                        fontSize:AtitleSize
                    }}>选定快餐，您还想加点什么？快速下单就出街！</span>
                            <span className='about_Textsubline'>视觉设计及文案类</span>
                            <span className='about_Textcontent' style={{paddingTop: '0px'}}>社交海报Social Poster, 常规H5, Banner, 插画设计, GIF 制作,
KV监拍, KV延展,APP开屏广告设计等。</span>
                            <span className='about_Textsubline' style={{paddingTop: '20px'}}>视频创作类</span>
                            <span className='about_Textcontent'
                                  style={{paddingTop: '0px'}}>TVC拍摄制作，TVC监拍, TVC 剪切创意, 短视频拍摄制作，短视频监拍, 短视频Cutdown剪切等。</span>
                            <span className='about_Textsubline' style={{paddingTop: '20px'}}>小程序/H5 技术类</span>
                            <span className='about_Textcontent'
                                  style={{paddingTop: '0px'}}>平台入驻申请,  服务器租赁, 带宽设置, 使用数据跟踪等。</span>

                        </div>

                        <div className='contentFrameBox' style={{height: '50px', paddingTop: '120px',paddingBottom:'50px'}}>

                            <Link to={'/get-start'}>
                                <ButtonYellow ButtonName='立即下单'/>
                            </Link>
                        </div>


                    </div>


                </center>


            </div>




        </div>
    )

}
   
}



export default connect(mapStateToProps,mapDispatchToProps)(Home) ;

 