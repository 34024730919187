//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import {Link} from "react-router-dom"


/**
 * normal input
 * @param event
 */


//Yi input model 
class Yinput extends React.Component{
   constructor(props){
      super(props)
    this.state ={
        value:'',
        border:'1px solid #000',
        CHbgColor:this.props.bgColor,
        filepreset:'',
        fontscolor:'#999',
        marginBottom:'25px',
       }

    this.changeValue =this.changeValue.bind(this)

    
    }

    /**
     * test password strong
     * @param event
     */

    analyze(str) {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
        if(strongRegex.test(str)) {
            console.log(str);
            this.setState({ CHbgColor: "#0F9D58" });
        } else if(mediumRegex.test(str)) {
            this.setState({ CHbgColor: "#F4B400" });
        } else {
            this.setState({ CHbgColor: "#DB4437" });
        }


    }



    changeValue(e){
        let inputvalue=e.target.value

        if (this.props.type=='checkbox'){
            inputvalue=''
            let obj = document.getElementsByName(this.props.name);
            if(obj.length==1){
                if (e.target.checked) inputvalue = 'checked';
            }else {
                let check_val = [];
                for (let i = 0; i < obj.length; i++) {
                    if (obj[i].checked) {
                        check_val.push(obj[i].value);
                    }
                }
                //console.log(check_val)
                inputvalue = check_val;
            }
        }else if (this.props.type=='select'){
            inputvalue=''
             if(e.target.value)inputvalue=e.target.value;
        }else if (this.props.type=='file') {
            inputvalue = ''
            let fileNameStr = e.target.value;
            let index_n = fileNameStr.length;
            let index_t;
            if(fileNameStr.indexOf("/") != -1) {
                 index_t = fileNameStr.lastIndexOf("/");
            } else {
                index_t = fileNameStr.lastIndexOf("\\");
            }
            if (index_t <= -1) {
                index_t = 0;
            } else {
                index_t += 1;
            }
            fileNameStr = fileNameStr.substring(index_t, index_n);
            const filesObj=[fileNameStr,e.target.files[0],e.target.value];
            if(e.target.files[0].size/1024 < this.props.filesize){
                console.log(e.target.files[0].size/1024)
                console.log(e.target.files[0].type)
                if(e.target.files[0].type == this.props.filetype || e.target.files[0].type == 'application/pdf') {

                    this.setState({
                        fontscolor:'#999',
                        filepreset: e.target.value,
                    })
                    //console.log(e.target.value)
                    inputvalue = filesObj;

                }else{
                    this.setState({fontscolor:'#DC143C'})
                }
            }else{
                this.setState({fontscolor:'#DC143C'})
            }

            //application/pdf
            //image/jpeg

        }else {
            let limt_lengh = this.props.limit

            //limit the input value & focur input in Numbers
            if (this.props.type == 'phone') {
                inputvalue = inputvalue.replace(/[^\d]/g, '');

            }

            if (limt_lengh) {
                if (inputvalue.length > limt_lengh) inputvalue = inputvalue.slice(0, limt_lengh)
            }


            this.setState({
                value: inputvalue,

            })

            if(this.props.name == 'setpassword'){

                if(this.props.analyze == false){

                }else {
                    const strongtest = this.analyze(inputvalue);
                    if (strongtest) {

                    } else {

                    }
                }

            }


        }

        if(this.props.callBack) this.props.callBack(this.props.name,inputvalue);
  }

  componentDidMount() {
     this.setState({
      value:this.props.value,
      filepreset:this.props.preset,
    })

      if(this.props.broderColor){
         const newborder='1px solid '+this.props.broderColor;
              this.setState({

                  border:newborder,
              })
      }

      if(this.props.marginBottom){
          this.state.marginBottom=this.props.marginBottom
      }

  }

    componentWillReceiveProps(){


    }



    render(){
    if(this.props.type=='phone'){
         return( 
          <input type='tel' style={{outline: 'none',color:'#000',width:this.props.width,height:this.props.height,backgroundColor:this.state.CHbgColor,border: this.state.border,marginBottom:'25px'}}
                   className='inputText'  value={this.state.value} onChange={(e)=>{this.changeValue(e)}}  placeholder={this.props.preset} pattern="[0-9]*"
                   />
                   )
    }else if(this.props.type=='password'){
     return(
             <input type='password' style={{outline: 'none',color:'#000',width:this.props.width,height:this.props.height,backgroundColor:this.state.CHbgColor,border: this.state.border,marginBottom:this.state.marginBottom}}
                   className='inputText'  value={this.state.value} onChange={(e)=>{this.changeValue(e)}}  placeholder={this.props.preset}/>

             
      )
   }else if(this.props.type=='checkbox'){
    return(


        <div style={{fontSize:'12px',height:'20px',backgroundColor:'',marginBottom:'20px',marginRight:'30px',float:'left',width:this.props.width,alignItems:'center',display:'flex',justifyContent: 'flex-start'}}>
            <input name={this.props.name}
                   type="checkbox"
                   onChange={(e)=>{this.changeValue(e)}}
                   value={this.props.value}   style={{outline: 'none',color:this.props.borderColor,backgroundColor:this.props.bgColor,border: this.state.border,}} />
            <span style={{paddingLeft:'5px',color:'#666'}}> {this.props.preset}</span>
        </div>


      )
   }else if(this.props.type=='select'){
        return(
            <div style={{width:this.props.width,height:this.props.height,marginBottom:'30px'}}>
            <select className='selectText' onChange={(e)=>{this.changeValue(e)}}
                    name={this.props.name}
                    style={{height:this.props.height,width:'52%',
                color:this.props.borderColor,backgroundColor:this.props.bgColor,border: this.state.border,marginBottom:'25px',
                appearance:'none',outline: 'none',marginRight:'20px,',
            }}>

                {
                    this.props.value.map((i,index) => (
                        <option value={i} key={index}>{i}</option>
                    ))
                }
            </select>

                <span className='inputText'> {this.props.preset}</span>
           </div>

        )
    }else if(this.props.type=='file'){
        return(


            <div >
                <span className='inputText' style={{color:this.state.fontscolor}}>{this.state.filepreset}</span>
                <input type='file' name={this.props.name} id={this.props.name} style={{display: 'none'}} onChange={(e)=>{this.changeValue(e)}}/>
            </div>


        )
    }else if(this.props.type=='realtimeinput'){
        return(
            <input  type='text' readOnly={this.props.readonly} style={{outline: 'none',color:'#000',width:this.props.width,height:this.props.height,backgroundColor:this.state.CHbgColor,border: this.state.border,marginBottom:'25px'}}
                    className='inputText'  name={this.props.name}  value={this.props.value} onChange={(e)=>{this.changeValue(e)}}  placeholder={this.props.preset}/>

        )
    }else{
     return(
             <input  type='text' readOnly={this.props.readonly} style={{outline: 'none',color:'#000',width:this.props.width,height:this.props.height,backgroundColor:this.state.CHbgColor,border: this.state.border,marginBottom:'25px'}}
                   className='inputText'  name={this.props.name}  value={this.state.value} onChange={(e)=>{this.changeValue(e)}}  placeholder={this.props.preset}/>

             
      )
   }
  }
}


/**
 * Yi textarea model
 * @param event
 */


class Ytextarea extends React.Component{
   constructor(){
      super()
    this.state ={
        value:'22',
        border:'1px solid #000',
        limitlengh:'',
       }

    this.changeValue =this.changeValue.bind(this)

    
    }
  changeValue(e){
    let inputvalue=e.target.value


          let limt_lengh = this.props.limit

          if (limt_lengh) {
              if (inputvalue.length > limt_lengh) inputvalue = inputvalue.slice(0, limt_lengh)
          }

          this.setState({
              value: inputvalue,
              limitlengh: limt_lengh - inputvalue.length,
          })

      if(this.props.callBack) this.props.callBack(this.props.name,inputvalue);

      }


  componentDidMount() {
     this.setState({
      value:this.props.value,
      limitlengh:this.props.limit,
      
    })

      if(this.props.borderColor){
          this.setState({
              border:'1px solid '+this.props.borderColor,
          })
      }
  } 


  render(){
       if(this.props.full==true){
           return (
               <div style={{width: 'auto', height: 'auto', display: 'flex',paddingBottom:'30px', alignItems: 'flex-end',flexDirection:'column'}}>
                   <textarea style={{
                       outline: 'none',
                       color: '#000',
                       width: this.props.width,
                       height: this.props.height,
                       backgroundColor: this.props.bgColor,
                       border: this.state.border,
                       marginBottom: '25px',
                       float: 'left'
                       }}
                       className='inputText'
                       value={this.state.value}
                       onChange={(e) => {this.changeValue(e)}} placeholder={this.props.preset}/>
                       <span className='framTitle'
                         style={{float: 'left', margin: '0px',paddingTop:'0px', fontStyle: 'italic'}}>{this.state.limitlengh}</span>
               </div>
           )
       }else if(this.props.full==false){
           return (
               <div style={{outline: 'none',width: 'auto', height: 'auto', display: 'flex', alignItems: 'flex-end'}}>
             <textarea style={{
                 color: '#000',
                 width: this.props.width,
                 height: this.props.height,
                 backgroundColor: this.props.bgColor,
                 border: this.state.border,
                 marginBottom: '25px',
                 float: 'left',
                 outline: 'none',
             }}
                       className='inputText' value={this.state.value} onChange={(e) => {
                 this.changeValue(e)
             }} placeholder={this.props.preset}/>
                   <span className='framTitle'
                         style={{float: 'left', margin: '20px', fontStyle: 'italic'}}>{this.state.limitlengh}</span>
               </div>
           )
       }else{
           return (
               <div style={{outline: 'none',width: 'auto', height: 'auto', display: 'flex', alignItems: 'flex-end'}}>
             <textarea style={{

                 width: this.props.width,
                 height: this.props.height,
                 backgroundColor: this.props.bgColor,
                 border: this.state.border,
                 marginBottom: '10px',
                 float: 'left',
                 outline: 'none',
                 color:'#999',
                 resize:'none',

                 fontSize:'12px'
             }}
                        value={this.state.value} onChange={(e) => {
                 this.changeValue(e)
             }} placeholder={this.props.preset}/>

               </div>
           )
       }
  }
}

/**
 * yi button model
 * @param event
 */


 class Yibutton extends React.Component{
   constructor(props){
      super(props)
    this.state ={
        value:'22',
        border:'1px solid '+this.props.borderColor,
        limitlengh:'',
        buttonStyle:{
            border:'1px solid #999',
        }
       }

    this.changeValue =this.changeValue.bind(this)

    
    }
  changeValue(e){
      e.preventDefault();

      if(this.props.uploadbindID!='' && this.props.uploadbindID!=null){

          document.getElementById(this.props.uploadbindID).click();
      }else{
         this.props.callBack()
      }

  }


    mouseEnter(event) {
        //console.log('in')

        this.setState({
            border:'1px solid #E2E2E2',

            }
        )

    }

    mouseOut(event) {
        if(this.props.borderColor!=undefined){
            this.setState({
                border:'1px solid '+this.props.borderColor,
            })
        }else{
            this.setState({
                border:'1px solid #999' ,
            })
        }
    }

  componentDidMount() {
     if(this.props.borderColor){
         this.setState({
             border:'1px solid '+this.props.borderColor,
         })
     }else{
         this.setState({
             border:'1px solid #999' ,
         })
     }
  } 


  render(){
     return(
          <div  style={{border: this.state.border,width:this.props.width,height:this.props.height,display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center',float:this.props.contentfloat,cursor: 'pointer'}}
                onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }}
                onClick={(e)=>{ this.changeValue(e)}}
          >
            <span className='textMin_content' style={{height:'auto',fontSize:'12px',color:this.props.color}}>{this.props.value}</span>
            </div> 
      )
  }
}
 


class Formelements extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initial:1,
      
  	}

  	this.changevalue =this.changevalue.bind(this)
    
  }

  changevalue(){
  	//this.setState({initial:2})
  	this.setState(prevState=>{
  		return{
  			initial:prevState.initial +1
  		}
  	})
  }

  mouseEnter(event) {
   //console.log('in')
 
   
  }

  mouseOut(event) {
    
  }
  mouseDown(event){

  }

  render(){

   const styles={
    buttonViewbox:{
      display:'flex',
      alignItems: 'center',
      width:'180px',
      height:'45px',
   
    } 

  }

   return(
     <div >
      
     </div>
    )

  }
}

 

export   {Formelements,Yinput,Ytextarea,Yibutton}