/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Upload, message, Image, Button, Result, Typography, Space, Divider, Statistic, Row, Col, Alert} from "antd";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    ThunderboltOutlined,
    FilePdfOutlined,
    NotificationOutlined,
    InboxOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/ApiBridge";

const { Dragger } = Upload;
const { Title,Text,Paragraph } = Typography;

/*
* choose registration account type
*/



class Profolio extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,
            profile_url:'',
            error:false,
            errorContent:'',
            profile_file_url:'',


        }
        this.showErrorbar =this.showErrorbar.bind(this);
        this.setfiledata =this.setfiledata.bind(this);
        this.Uploadprofiles =this.Uploadprofiles.bind(this);
        this.updateUserdetail =this.updateUserdetail.bind(this);
        this.get_down_url =this.get_down_url.bind(this);
    }

    componentDidMount() {
        if(this.props.bridge.profile_file) {
            this.get_down_url(this.props.bridge.profile_file)
        }

    }

    componentWillMount() {



    }

    componentDidUpdate(){

    }

    //props update

    componentWillReceiveProps(){


    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    /**
     * oss down url gen
     */
    get_down_url(filekey){

            const postdata={
                osskey:filekey,
            }
            Http.Get_oss_file(postdata).then((res)=>{

                // console.log(res.data)
                this.setState({
                    profile_file_url:res.data,
                })

            }).catch((res) => {

                this.showErrorbar(res.message)

            })


    }

    /**
     * upload profile
     */
    Uploadprofiles(data){

        let filePath='';
        Http.OSSsign(data).then((res)=>{
            filePath=res.data.dir

            const fileurl=filePath+data[0];
            console.log(fileurl+'上传中...')
            Http.OSSuploadfiles(data,res).then((res)=>{

               this.updateUserdetail(fileurl);

            }).catch((res) => {

                this.showErrorbar(res.message)
                console.log(res)
            })

        }).catch((res) => {

            this.showErrorbar(res.message)
        })
    }

    updateUserdetail(finepath){
        const postdata={
            profile_file:finepath,
        }
        Http.Update_profile_file(postdata).then((res)=>{
            message.success(`file uploaded successfully.`);
            this.get_down_url(finepath)
            this.props.callBack('profile_view')

            console.log('success')

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }

     setfiledata(info){
        // console.log(info)
         const filedata=[info.file.name,info.file];
          if(info.file.size>5000000  ){
              this.showErrorbar('上传文件超出大小限制')
          }else{
              if(info.file.type == 'application/pdf' || info.file.type =='image/jpeg'){
                  this.Uploadprofiles(filedata)
              }else{
                  this.showErrorbar('上传文件格式错误')
              }
          }

     }

    handleReturn =(file,fileList)=>{

    };

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };



    render(){

        const props = {
            name: 'file',
            showUploadList:false,
            beforeUpload:this.handleReturn,
            multiple: false,
           // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            customRequest:this.setfiledata,
            onChange(info) {
               // console.log(info)
                /*
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }

                 */
            },
        };


        return(
            <div style={{width: '100%', padding: '0px'}}>
                {
                    this.state.error ? (
                        <Alert
                            message="Error!"
                            description={this.state.errorContent}
                            type="error"
                            closable
                            onClose={this.onClose}
                        />

                    ) : ('')
                }

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',

                }}>
                    <Text type="secondary"> 我的作品URL：</Text>
                    <Text type="secondary"> {(this.props.bridge.profile_url=='false')?'':this.props.bridge.profile_url}</Text>


                </div>

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '50px',
                    marginBottom:'10px',

                }}>



                    <Row  >
                        <Col span={2}>
                            <FilePdfOutlined style={{fontSize:"50px"}}/>
                        </Col>
                        <Col span={20}>
                            <Space direction={"vertical"}>
                            <Text type="secondary"> {this.props.bridge.profile_file}</Text>
                            <Text type="secondary"> 作品下载链接已经过加密处理并将于24小时后失效</Text>
                            </Space>
                        </Col>
                        <Col span={2}>
                            <Button type="text" href={this.state.profile_file_url} target={"_blank"} >下载</Button>
                        </Col>

                    </Row>


                </div>

                <div style={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: '30px',
                    marginBottom:'10px',

                }}>
                    <Dragger
                        {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖动文件到此处更新个人作品集</p>
                        <p className="ant-upload-hint">
                            支持文件格式pdf，单个文件小于5M。
                        </p>
                    </Dragger>

                </div>



            </div>
        )
    }
}


export default Profolio;