/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Button, Result,Typography,Space,Divider,Statistic, Row, Col} from "antd";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    LoadingOutlined,
    ThunderboltOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/Adminbridge";


const { Title,Text,Paragraph } = Typography;

/*
* choose registration account type
*/



class Systeminfo extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            info:null,
            loading:true,


        }


    }

    componentWillMount() {

            this.Get_overview_info()


    }



    /**
     * get info
     * @constructor
     */

    Get_overview_info(){

        Http.overview_info().then((res)=>{

            this.setState({
                info:res.data,
                loading:false,
            })

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }


    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }





    render() {

        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '50px',
                        marginBottom: '10px',

                    }}>
                        <Title level={4}>您好 {this.props.bridge.family_name}，</Title>
                        <Space>
                            <UserOutlined/>
                            <Text type="secondary">{this.props.bridge.email}</Text>
                        </Space>


                        <Divider/>

                        <Row gutter={16}>
                            <Col span={6}>
                                <Statistic title="正在进行中的项目" value={this.state.info.total_orders}/>
                            </Col>
                            <Col span={6}>
                                <Statistic title="总用户数" value={this.state.info.total_users}/>

                            </Col>
                            <Col span={6}>
                                <Statistic title="总客户数" value={this.state.info.total_clients}/>

                            </Col>

                           <Col span={6}>
                            <Statistic title="新的咨询预约" value={this.state.info.new_message}/>

                           </Col>
                        </Row>

                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',


                    }}>
                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}>系统公告</Title>

                            <NotificationOutlined/>
                        </Space>
                        <Paragraph>
                            快平方v1.0.0更新，<a href="">点击</a>查看更多更新细节。
                        </Paragraph>


                    </div>

                </div>
            )
        }
    }
}


export default Systeminfo;