//by yi 2020 kuaipf project


import React from 'react';
import ReactDOM from 'react-dom';
import './what.css';
import  '../components/global'
import {Link} from "react-router-dom"
import ButtonBlack from '../components/ButtonBlack'
import ButtonWhite from '../components/ButtonWhite'
import ButtonYellow from '../components/ButtonYellow'
import TopButton from  '../components/TopButton';
import logoY from '../asset/logo-y.svg';
import logoB from '../asset/logo-b.svg';

import Nav from '../components/nav'
import {connect} from "react-redux";
import {fullfooter, fullmenu, navWhat} from "../reduceractions";
import { Timeline } from 'antd';




const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})





//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navWhat())
        // console.log(minfooter())
    }
})

class What extends React.Component{

    constructor(){
        super()
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,


        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)




        this.centerViewbox_W='auto'

        this.QAViewbox_W='810px'

        this.textviewbox='500px'



    }


    clickbtn(){
        window.scroll(0, -1000);

    }



    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }

        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })
        if(window.innerWidth<1124){
            this.setState({
                showscrolltop:0,
            })
        }

    }

    componentDidMount() {

        //console.log('component Mount')

        const baiduTongji = () => {
            window._hmt = window._hmt || [];
            let sc;
            (function () {
                if ((sc = document.getElementById('baidu'))) {
                    document.head.removeChild(sc);
                }
                let hm = document.createElement('script');
                hm.src = "https://hm.baidu.com/hm.js?b57f11b0f08d71fb015fe4441e72833a";
                hm.id = 'baidu';
                let s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(hm, s);
            })();
        };

        baiduTongji();


        let myVideo = document.getElementById("playvideo_dom");

        myVideo.addEventListener('play',function(){
            console.log("开始播放");
            window._hmt.push(['_trackEvent', 'video', 'play', '了解我们视频播放']);
        });




    }



    componentWillMount() {

        this.props.LoadPageState();
        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.textviewbox='310px'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){



    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        //console.log('component UnMount')
    }


    //props update

    componentWillReceiveProps(){

    }




    render(){



        let callforactionfontsize='23px'
        let scrolliconR='20px'
        let sologenSize=25
        if (window.innerWidth <= 1124) {

            callforactionfontsize='15px'
            sologenSize=19
            scrolliconR='0px'
        }

        let callactionwidth='380px'
        window.innerWidth<1024?callactionwidth='320px':callactionwidth='380px'

        return(


            <div  className='contentView'>
                <Nav />

                <div className='solution_Topviwbox' style={{height:'auto',backgroundColor:'#F5D82D'}}>
                    <TopButton  callback={()=>this.clickbtn()} opacity={this.state.showscrolltop}/>

                    <img src={require('../asset/scrollicon.png')}     className='scrollingIcon'  style={{backgroundColor:'',right:scrolliconR,opacity:this.state.scrolliconAlpha,transition:'0.15s ease-out all',top:'200px'}} />

                    <div className='contentcenColumn' style={{width:this.centerViewbox_W,minWidth:'320px',display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center",height:'auto',backgroundColor:'',paddingTop:'100px'}}>


                        <div style={{padding:0,width:'30px',overflow:"hidden",marginLeft:'15px',marginTop:'15px',paddingBottom:'40px',paddingTop:'40px'}}>
                           <img src={logoY} alt="logo"  width={'160px'}  />
                        </div>


                        <div style={{width:'100%',paddingBottom:'140px',textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center"}}>

                            <span className='whattop' style={{color:'#000',fontSize:20,minWidth:'310px',width:'40%',alignItems:'center',display:"flex",paddingLeft:0}}>

                           快平方平台由一群来自4A广告集团的全球执行长及大中国区首席执行官，首席创意官，首席数字官，联手打造而成，一站网罗业界精英。创始团队于所属WPP及IPG集团平均掌舵运营超过20年，协助诸多国际及国内知名品牌在中国屡创辉煌战役。
                            </span>

                        </div>


                    </div>
                </div>

                <div className='contentFrameBox' style={{backgroundColor:'#fff'}}>

                    <div className='triangleDown_Yellow'>  </div>
                </div>





                {/*FAQ*/}

                <div className='contentFrameBox' style={{backgroundColor:'#fff',height:'auto',paddingTop:'0px',paddingBottom:'0px'}}>

                    <div className='selfcenter_alignLeft' style={{width:this.QAViewbox_W,height:'100%',paddingTop:'50px'}}>

                        <div style={{width:'180px',backgroundColor:'',float:"right",height:"400px"}}>

                            {  /**    <img src={require('../asset/tgw.jpg')}  width={'100%'} /> */}


                        </div>


                        <div style={{width:this.textviewbox,backgroundColor:'',float:"right",marginRight:"10px"}}>
                            <span className='whatcontent' style={{color:'#F5D82D',fontSize:40,width:'100%',display:"flex",position:"absolute",paddingTop:'60px',marginLeft:'-40px'}}>
                            “
                            </span>

                             <span className='whatcontent' style={{color:'#000',fontSize:16,minWidth:'330px',width:'100%',display:"flex",paddingBottom:'20px',paddingTop:'60px'}}>
                            这是一个互动的时代！
                            </span>
                            <span className='whatcontent' style={{color:'#000',fontSize:16,minWidth:'330px',width:'100%',display:"flex",paddingBottom:'20px'}}>
                            品牌与消费者在数位平台上互动，共鸣，使用，分享。
                            </span>
                            <span className='whatcontent' style={{color:'#000',fontSize:16,minWidth:'330px',width:'100%',display:"flex",paddingBottom:'20px'}}>
                            营销管理人和广告精英也应该有一个数位平台彼此互动，共鸣，产出，分享。
                            </span>
                            <span className='whatcontent' style={{color:'#000',fontSize:16,minWidth:'330px',width:'100%',display:"flex",paddingBottom:'20px'}}>
                            我们因此打造了快平方平台，希望营销管理人和广告精英可以更快速，轻松，精准的互动，完成精彩的营销传播项目。
                            </span>
                            <span className='whatcontent' style={{color:'#000',fontSize:16,minWidth:'330px',width:'100%',display:"flex",paddingBottom:'20px'}}>
                            —创办人 Joseph Tsang 曾震东
                            </span>


                            {  /**     <img src={require('../asset/thsignature.jpg')}  width={'130px'} /> */}


                        </div>



                    </div>
                </div>

                <div className='contentFrameBox' style={{backgroundColor:'#F5D82D'}}>

                    <div className='triangleDown_White'>  </div>
                </div>


                <div className='contentFrameBox' style={{backgroundColor:'#F5D82D',height:'auto',paddingTop:'0px',paddingBottom:'50px'}}>

                    <div style={{width:this.QAViewbox_W,textAlign:"center",backgroundColor:'',display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                       <span className='whatcontent' style={{color:'#000',backgroundColor:'',fontSize:20,width:'auto',paddingBottom:'0px',paddingTop:'30px'}}>
                            30秒了解快平方
                        </span>

                        <img src={require('../asset/selecticon.png')}  width={'35px'} />


                        <div style={{width:'100%',height:'auto',backgroundColor:'',paddingBottom:'60px',paddingTop:'20px'}}>

                            <video id='playvideo_dom' src="https://kuaipfoss.oss-cn-beijing.aliyuncs.com/video/KPF%20Video_Web.mp4"   controls="controls"   style={{backgroundColor:'#000',width:'100%',height:'100%',paddingLeft:0,left:0}} >

                            </video>


                        </div>

                        <div style={{height:'auto',paddingBottom:'60px',paddingTop:'60px'}}>
                            <Link to={'/get-start'} style={{paddingRight: ''}}>
                                <ButtonWhite ButtonName='立即下单'/>

                            </Link>
                        </div>


                    </div>

                </div>
            </div>
        )

    }
}



export default connect(mapStateToProps,mapDispatchToProps)(What) ;
