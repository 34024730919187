/**
 * yi 2020
 *
 *
 */

import React from 'react';
import Http from "../../components/functions/Adminbridge";

import {
    Descriptions,
    Steps,
    Timeline,
    Typography,
    Drawer,
    Pagination,
    Divider,
    Space,
    Button,
    Alert,
    Input,
    Col,
    Row,
    Modal, Calendar,
} from 'antd';

import {
    MenuUnfoldOutlined,
    LoadingOutlined,
    MenuFoldOutlined,
    UserOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {Yinput, Ytextarea} from "../../components/Formelements";
import moment from "moment";


const { Step } = Steps;
const { Text,Title } = Typography;
const { Search } = Input;


class Projectmanage extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            order_list:null,
            total_list:null,
            current_page:null,
            order_bgColor:'#fff',
            type:'',
            Drawervisible: false,
            childrenDrawer:false,
            error:false,
            errorContent:'',
            target_order:{
                created_at:'00000000000',
                deliver:'00000000000',
                brief_call:'000000000000',
            },
            target_index:0,
            Stepscurrent:0,
            Modalvisible: false,
            progress_state:['项目创建','前期准备','进行中','交付'],
            search_order:'',
            event_name:'',
            event_date:'',
            order_events:'',
            event_index:null,

        }


        this.onPageChange =this.onPageChange.bind(this)
        this.set_order_data =this.set_order_data.bind(this)
        this.StepsonChange =this.StepsonChange.bind(this)
        this.handleModalCancel =this.handleModalCancel.bind(this)
        this.cancelProject =this.cancelProject.bind(this)
        this.searchOrder =this.searchOrder.bind(this)
        this.onChildrenDrawerClose =this.onChildrenDrawerClose.bind(this)
        this.showChildrenDrawer =this.showChildrenDrawer.bind(this)
        this.onPanelChange =this.onPanelChange.bind(this)
        this.input_content_contact =this.input_content_contact.bind(this)
        this.submitevents =this.submitevents.bind(this)
        this.Get_order_events =this.Get_order_events.bind(this)
        this.update_target_order =this.update_target_order.bind(this)
        this.timeline_item_mouseIn =this.timeline_item_mouseIn.bind(this)
        this.timeline_item_mouseOut =this.timeline_item_mouseOut.bind(this)
        this.delete_timelineitme =this.delete_timelineitme.bind(this)







    }

    componentDidMount() {




    }
    componentWillMount() {


        this.Get_peoject_list(1);
        this.setState({
            type:this.props.bridge.type
        })

    }

    /**
     *
     * error Drower
     * @param error
     */

    showErrorbar(error){
        this.setState({
            error:true,
            errorContent:error,
        })
    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    showDrawer = (item,index) => {
        let progress=Number(item.progress)
        if(item.progress==null)progress=0

        this.setState({
            target_order:item,
            target_index:index,
            Stepscurrent:progress,
        },()=>{

            this.Get_order_events(item.id)

            this.setState({ Drawervisible: true,})


        });
        
    };

    onCloseDrawer = () => {

        this.setState({
            Drawervisible: false,
            order_events:''
        });
    };

    update_target_order(index){


        this.setState({
            target_order: this.state.order_list[index]
        })
    }


    Get_order_events(order_id){
        console.log(order_id)
        const post_data = {
            order_id:order_id,
        }
        Http.get_order_event(post_data).then((res) => {


            if(res.data.length>0){
                this.setState({
                    order_events:res.data
                })
            }

        }).catch((res) => {

            this.showErrorbar(res.message)
        })
    }

    /**
     *
     *
     */

    Get_peoject_list(page){

        const postdata={
            'page':page,
            'keyword':this.state.search_order,
        }
        Http.get_Order_all(postdata).then((res)=>{

            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                })
                this.set_order_data(res.data.data)
            }else{
                this.setState({
                    total_list: res.data.length,
                    current_page: 1,
                })
                this.set_order_data(res.data)
            }

        }).catch((res) => {
            console.log(res.message)
            if(res.code=='600003'){
                this.setState({order_list:'noproject'})
            }
        })
    }

    set_order_data(Orderdata){
        const order_data=Orderdata;
        let new_order_list=[];
        order_data.map((key, index) => {
            let keyitem=key;

            if(key.state=='true'){
                keyitem.bgColor='#fff';
            }else{
                keyitem.bgColor='#f5f5f5';
            }
            new_order_list.push(keyitem);
        })
        this.setState({
            order_list:new_order_list,

        },()=>{ this.update_target_order(this.state.target_index)})


    }

    showTotal(total) {
        return `Total ${total} items`;
    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            order_list:null,

        })
        this.Get_peoject_list(page);
    }

    StepsonChange(Stepscurrent){
       // this.setState({ Stepscurrent });

       let that=this
       if(Stepscurrent>=this.state.Stepscurrent && this.state.target_order.state=='true') {
        // console.log(Stepscurrent)

           Modal.confirm({
               title: '提示',
               content: '你正在对项目状态进行更新。',
               okText: '确认',
               cancelText: '取消',
               onOk: function () {
                   const postdata={
                       id:that.state.target_order.id,
                       progress:Stepscurrent,
                       order_id:that.state.target_order.order_id,
                       user_id:that.state.target_order.user_id,
                   }
                   Http.update_order_progerss(postdata).then((res)=>{
                       that.setState({
                           Stepscurrent:Stepscurrent,
                       })

                       that.Get_peoject_list(that.state.current_page)
                   }).catch((res) => {
                       console.log(res.message)
                   })
               },
               onCancel: function () {

               },
           });
       }
    }


    cancelProject(){
        const tar=this.state.target_order;
        let that=this;
        if(tar.state=='false' || tar.progress == '3'){
            Modal.warning({
                title: '提示',
                content: '项目已经终止或结束。',
                okText: '返回',

            });
        }else {
            Modal.confirm({
                title: '警告',
                content: '项目终止后将不可恢复，你决定要终止该项目吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: function () {

                    const postdata={
                        id:that.state.target_order.id,
                        state:'false',
                        order_id:that.state.target_order.order_id,
                        user_id:that.state.target_order.user_id,
                    }
                    Http.update_projcet_state(postdata).then((res)=>{
                        const target_order=that.state.target_order;
                        target_order.state='false';
                        that.setState({
                            target_order
                        })
                        that.Get_peoject_list(that.state.current_page)
                    }).catch((res) => {
                        console.log(res.message)
                    })

                },
                onCancel: function () {

                },
            });
        }

    }


    searchOrder(value){
        this.setState({
            search_order:value,
        },()=>{
            this.Get_peoject_list(1)
        })
    }




    handleModalCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            Modalvisible: false,
        });
    };


    showChildrenDrawer = () => {
        let GMTdate = new Date(moment().subtract(0, 'day')._d)

        let Str = GMTdate.getFullYear() + '-' +
            (GMTdate.getMonth() + 1) + '-' +
            GMTdate.getDate()



        this.setState({
            event_date:Str,
            childrenDrawer: true,
        });
    };

    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,
            event_name:'',

        });
    };


    onPanelChange(date) {

          let GMTdate = new Date(date._d)

            let Str = GMTdate.getFullYear() + '-' +
                (GMTdate.getMonth() + 1) + '-' +
                GMTdate.getDate()
            /*
                GMTdate.getHours() + ':' +
                GMTdate.getMinutes() + ':' +
                GMTdate.getSeconds()*/

            this.setState({
                event_date: Str
            })


    }

    input_content_contact(target,value){

        switch (target){
            case 'eventname':
                this.setState({event_name:value})
                break
            case 'pass_code':

                break
            default:
        }

    }


    submitevents(){

        if(this.state.event_name==''){
            this.showErrorbar('请填写事件名称')
        }else{
            let progress=this.state.target_order.progress
            if(this.state.target_order.progress==null)progress=1

            const post_data = {
                order_id:this.state.target_order.id,
                user_id:this.state.target_order.user_id,
                progress:progress,
                event_name:this.state.event_name,
                event_date:this.state.event_date,
            }
            Http.create_order_event(post_data).then((res) => {
                console.log('event submit'+res)
               this.onChildrenDrawerClose()
                this.Get_order_events(this.state.target_order.id)

            }).catch((res) => {

                this.showErrorbar(res.message)
            })

        }

    }


    timeline_item_mouseIn(index){
        //console.log(index)
        this.setState({
            event_index:index
        })
    }

    timeline_item_mouseOut(){
        //console.log('out')
        this.setState({
            event_index:null
        })
    }

    delete_timelineitme(id){

        let that=this
        Modal.confirm({
            title: '警告',
            content: '日志删除后不可回复，确定删除本条日志吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: function () {

                const postdata={
                    id:id,
                    state:'false',
                    user_id:that.state.target_order.user_id
                }
                Http.delete_order_event(postdata).then((res)=>{
                    console.log('delete success')
                    that.Get_order_events(that.state.target_order.id)
                }).catch((res) => {
                    console.log(res.message)
                })

            },
            onCancel: function () {

            },
        });


    }




    render() {


        const timelineItemcolor=['orange','orange','orange','green','green']

        if (this.state.order_list == null) {
            return (<div  style={{width: '100%', height: '500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <LoadingOutlined style={{fontSize:'20px'}} />
            </div>);

        }else if(this.state.order_list == 'noproject'){
            return (
                <div style={{width: '100%', padding: '0px'}}>
                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"center",

                    }}>
                        <Text type="secondary">没有进行中的项目 </Text>
                    </div>
                </div>
            )
        } else {

                return (
                    <div style={{width: '100%', padding: '0px'}}>


                        <div style={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            padding: '30px',
                            marginBottom:"10px"

                        }}>


                            <Search
                                placeholder="项目编号/项目名称"
                                onSearch={value => this.searchOrder(value)}
                                style={{ width: 400 }}
                            />

                        </div>


                        {
                            this.state.order_list.map((key, index) => (

                                <div key={index}  onClick={()=>this.showDrawer(key,index)}
                                     style={{
                                    width: '100%',
                                    backgroundColor: key.bgColor,
                                    padding: '30px',
                                    marginBottom: '10px',
                                    cursor:"pointer",
                                    }} >
                                    <Title level={5}>项目名称 : {key.order_name}</Title>
                                    <div style={{
                                        width: '100%',
                                        height: '30px',
                                        backgroundColor: '',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div style={{width: '50%', fontSize: '13px'}}>
                                            <Text type="secondary">项目编号 {key.order_id}</Text>
                                        </div>
                                        <div style={{width: '30%', fontSize: '13px'}}>

                                        </div>
                                        <div style={{width: '20%', fontSize: '13px'}}>
                                            <Text type="secondary">上线时间 {key.deliver.substr(0, 10)}</Text>
                                        </div>
                                    </div>

                                    <div style={{
                                        width: '100%',
                                        height: '30px',
                                        backgroundColor: '',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingBottom: '20px'
                                    }}>
                                        <div style={{width: '50%', fontSize: '13px'}}>
                                            <Text type="secondary">项目状态 {(key.state=='true')?'正常':'终止'}</Text>
                                        </div>
                                        <div style={{width: '30%', fontSize: '13px'}}>

                                        </div>
                                        <div style={{width: '20%', fontSize: '13px'}}>
                                            <Text type="secondary">创建日期 {key.created_at.substr(0, 10)}</Text>
                                        </div>
                                    </div>



                                    <Steps current={(key.progress==null)?0:Number(key.progress)} progressDot status={(key.state=='true')?'process':'error'}>
                                        <Step title="项目创建" description={key.created_at.substr(0, 10)} />
                                        <Step title="前期准备" description={/*key.brief_call.substr(0, 10) +'-'+ key.calling_time*/''} />
                                        <Step title="进行中" description="" />
                                        <Step title="交付" description={key.deliver.substr(0, 10)} />
                                    </Steps>

                                </div>
                            ))
                        }

                        <div style={{
                            width: '100%',
                            height: '86px',
                            backgroundColor: '#fff',
                            padding: '30px',
                            marginBottom: '10px'
                        }}>
                            <Pagination
                                size="small"
                                defaultPageSize={10}
                                defaultCurrent={this.state.current_page}
                                total={this.state.total_list}
                                onChange={this.onPageChange}
                            />

                        </div>


                        {
                            this.state.target_order ? (

                        <Drawer
                            width={'60%'}
                            title={'项目编号：'+this.state.target_order.order_id}
                            placement="right"
                            closable={false}
                            onClose={this.onCloseDrawer}
                            visible={this.state.Drawervisible}
                            footer={
                                <div
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {(this.state.target_order.state=='true')?(<Button key={'child'} type="text" onClick={()=>this.showChildrenDrawer()}  >添加事件日志</Button>):('')}


                                    <Button key={'cancel'} type="text" onClick={()=>this.cancelProject()}  >终止</Button>

                                </div>
                            }
                        >




                            <div style={{width:'100%',height:'15px'}}> </div>


                            <Descriptions
                                title=""


                            >
                                <Descriptions.Item label="项目状态">{(this.state.target_order.state=='true')?'正常':'终止'}</Descriptions.Item>
                                <Descriptions.Item label="项目进度">{(this.state.target_order.progress==null)?('等待审核'):(this.state.progress_state[this.state.target_order.progress])}</Descriptions.Item>
                                <Descriptions.Item label="企业名称">{this.state.target_order.company}</Descriptions.Item>
                                <Descriptions.Item label="项目需求">{this.state.target_order.service}</Descriptions.Item>
                                <Descriptions.Item label="所属行业">{this.state.target_order.industry}</Descriptions.Item>
                                <Descriptions.Item label="联系人姓名">{this.state.target_order.name}</Descriptions.Item>
                                <Descriptions.Item label="联系人电话">{this.state.target_order.mobile}</Descriptions.Item>
                                <Descriptions.Item label="联系人邮箱">{this.state.target_order.email}</Descriptions.Item>
                                <Descriptions.Item label="联系人微信id">{this.state.target_order.wechat_id}</Descriptions.Item>
                                <Descriptions.Item label="联系人职位">{this.state.target_order.title}</Descriptions.Item>
                                <Descriptions.Item label="联系人部门">{this.state.target_order.department}</Descriptions.Item>
                                <Descriptions.Item label=""></Descriptions.Item>
                                <Descriptions.Item label="备注">{this.state.target_order.remark}</Descriptions.Item>
                            </Descriptions>



                            <Divider />
                            <div style={{alignItems:"center",width:'100%',height:'50px'}}>
                            <Text  type="secondary" style={{color:'#999'}}>点击下方STEPS即可更新目前项目进度，项目进度会实时同步到客户账号。</Text>
                            </div>
                            {/*
                            <Timeline style={{paddingTop:'5px'}}>
                                <Timeline.Item>项目创建 {this.state.target_order.created_at}</Timeline.Item>
                                <Timeline.Item color="gray">电话会议 {this.state.target_order.brief_call+' '+this.state.target_order.calling_time}</Timeline.Item>
                                <Timeline.Item color="gray">制作
                                </Timeline.Item>
                                <Timeline.Item color="gray">项目交付 {this.state.target_order.deliver}</Timeline.Item>
                            </Timeline>*/}




                            <Steps current={this.state.Stepscurrent} onChange={this.StepsonChange} status={(this.state.target_order.state=='true')?'process':'error'}>
                                <Step title="项目创建" description={this.state.target_order.created_at.substr(0, 10)} />
                                <Step title="前期准备" description={/*this.state.target_order.brief_call.substr(0, 10)+' '+this.state.target_order.calling_time*/''} />
                                <Step title="进行中" description="" />
                                <Step title="交付" description={this.state.target_order.deliver.substr(0, 10)} />
                            </Steps>

                            <Divider />
                            <Text type="secondary">事件日志: </Text>
                            <br/><br/>
                            <Timeline style={{paddingTop:10,backgroundColor:'',height:'auto'}}>

                                {
                                    (this.state.order_events.length>0)?(
                                          this.state.order_events.map((key, index) => (
                                              <Timeline.Item key={index} color={(key.progress==null)?('yellow'):(timelineItemcolor[key.progress])}>
                                                  <div style={{width:'100%',backgroundColor:'',height:20}} onMouseEnter={()=>this.timeline_item_mouseIn(index)} onMouseLeave={this.timeline_item_mouseOut}>
                                                  {key.event} <span style={{width:80,paddingLeft:20}}>{key.request_date.substr(0, 10)} </span>

                                                         <DeleteOutlined  onClick={()=>this.delete_timelineitme(key.id)}  style={{color:'orange',height:10,cursor:'pointer',width:10,visibility:(this.state.event_index==index)?('visible'):('hidden')}}/>

                                                  </div>
                                              </Timeline.Item>
                                            ))


                                    ):('')
                                }

                            </Timeline>


                            <Drawer
                                title="项目日志"
                                width={350}
                                closable={false}
                                onClose={this.onChildrenDrawerClose}
                                visible={this.state.childrenDrawer}

                                footer={
                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >

                                        <Button key={'cancel'} type="text" onClick={()=>this.submitevents()}  >提交</Button>

                                    </div>
                                }

                            >
                               <br/>
                                <Text type="secondary">日志名称 </Text><span style={{color:'#FFC300',paddingBottom:10}}>*</span>
                                <Yinput width='100%' height='30px' type={'realtimeinput'} name='eventname' broderColor={'#b0b0b0'}   callBack={this.input_content_contact} marginBottom={'0px'} analyze={true}   bgColor='#fff' state={""}  value={this.state.event_name} preset='' limit={60}/>
                                <Text type="secondary">日期 </Text><span style={{color:'#FFC300'}}>*</span>

                                <div style={{width:300,border: '1px solid #fff',borderRadius: '2px',paddingTop:0}} >

                                    <Calendar
                                        fullscreen={false}
                                        onSelect={this.onPanelChange}

                                       // disabledDate= {current=>{return (current < moment().subtract(1, 'day') )}}

                                    />
                                </div>


                            </Drawer>


                        </Drawer>

                            ) : ('')
                        }

                        <Modal
                            title="确认操作"
                            style={{ top: 20 }}
                            visible={this.state.Modalvisible}
                            onCancel={this.handleModalCancel}
                            footer={null}
                            icon={<UserOutlined/>}

                        >
                            <Space>
                                <Text type="secondary">你正在对项目状态进行更新。</Text>

                                <Button type="text"  >确认</Button>
                            </Space>
                        </Modal>

                        <Modal
                            title="提示"
                            visible={this.state.error}
                            footer={false}
                            onCancel={this.onClose}
                        >
                            <ExclamationCircleOutlined style={{color:'red',paddingRight:12,fontSize:20}} /><Text type={"secondary"} style={{color:'#000'}}>{this.state.errorContent}</Text>


                        </Modal>


                    </div>
                )

        }
    }
}


export default Projectmanage;