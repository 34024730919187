//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
 
 
class Topbutton extends React.Component{




  constructor(){
      super()
  	this.state ={
  		  initial:1,
        fontS:24,


       }
  	}


  buttonClick(event){
   //window.scrollBy(800,40)
   this.props.callback()
   
  } 

   mouseEnter(event) {
   this.setState({
      fontS:35,
 
    })

   
  }

  mouseOut(event) {
    this.setState({
      fontS:24,
 
    })
   
  } 

   componentDidMount() {

 
    console.log('top botton mount')
    this.props.callback()
     //window.scroll(0, -1000);
    

   
  }
 


 render(){

 	let styles={
 		footNav:{
          fontSize:'12px',
          fontFamily:'"Microsoft YaHei", "黑体","宋体",',
          color:'#999',
          width:'28%',
          minWidth:'130px',
          height:'50px',
          margin:'5px',
          alignItems:'left',
          display:'flex',
          justifyContent: 'center',
          backgroundColor:'',
          float:'left',
 		} 
 	}





   return (
      <div className="Topbutton" style={{top:'70%',cursor:"pointer",opacity:this.props.opacity,transition:'0.15s ease-out all',}} onClick={()=>{this.buttonClick()}} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }}>

       <span className='icon-topbtn' style={{fontSize:this.state.fontS,color:'#fff',lineHeight:'10px',transition:'0.15s ease-out all',}}> </span>

       
      </div>
   	)


}



}


export default Topbutton