/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import TopButton from "../components/TopButton";
import {connect} from "react-redux";
import  '../components/global';
import { Space,Skeleton } from 'antd';



import {fullfooter, fullmenu, navOthers} from "../reduceractions";
import Nav from "../components/nav";
import Http from "../components/functions/Adminbridge";

/*
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navOthers())
        // console.log(minfooter())
    }
})


class Privacy extends React.Component {



    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            content:'',
            loading:true,

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {
        this.get_articles()
    }

    /**
     * get info
     * @constructor
     */

    get_articles(){
        const postdata={
            id:2
        }
        Http.get_articles(postdata).then((res)=>{

            this.setState({
                content:res.data,
                loading:false,
            })


        }).catch((res) => {
            console.log(res.message)


        })
    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState();

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }



    render(){

        let centerViewbox_W='50%';

        if (window.innerWidth <= 1124) {
            centerViewbox_W = '90%';

        }

        if(this.state.loading){
            return (<div style={{height:'100vh'}}></div>)
        }else {

            return (


                <div className='contentView'>
                    <Nav/>
                    <TopButton callback={() => this.clickbtn()} opacity={this.state.showscrolltop}/>
                    <div className='contentFrameBox' style={{backgroundColor: '#efefef', height: 'auto'}}>

                        <div className='selfcenter_alignLeft' style={{
                            width: centerViewbox_W,
                            minWidth: '320px',
                            height: 'auto',
                            backgroundColor: '',
                            paddingTop: '100px'
                        }}>
                            <span className='pageNavText' style={{paddingTop: '100px'}}>首页/</span>
                            <span className='pageNavText' style={{paddingTop: '', color: '#999'}}>隐私政策</span>
                        </div>

                        <div className='selfcenter_alignLeft' style={{
                            width: centerViewbox_W,
                            minWidth: '320px',
                            height: 'auto',
                            backgroundColor: '',
                            paddingTop: '20px'
                        }}>
                            <div className='pageLineS' style={{width: '50px'}}></div>
                        </div>

                        <div className='selfcenter_alignLeft' style={{
                            width: centerViewbox_W,
                            minWidth: '320px',
                            height: 'auto',
                            backgroundColor: '#fff',
                            marginTop: '50px',
                            padding: '30px',
                        }}>
                            <Space>

                                <span className='pageNavText' style={{paddingTop: '100px',}}>

                           </span>
                            </Space>
                            <p>
                            </p>

                            <div dangerouslySetInnerHTML={{__html: this.state.content.article}}></div>


                        </div>


                    </div>


                </div>
            )

        }
    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Privacy) ;