export const incrementcounter = () =>{
    return{
        type:'INCREMENT'
    }
}



export const authlogin = () =>{
    return{
        type:'SIGN_IN'
    }
}

export const authlogout = () =>{
    return{
        type:'SIGN_OUT'
    }
}


export const minmenu = () =>{
    return{
        type:'MENU_MIN'
    }
}

export const panelmenu = () =>{
    return{
        type:'MENU_PANEL'
    }
}



export const fullmenu = () =>{
    return{
        type:'MENU_FULL'
    }
}

export const fullfooter = () =>{
    return{
        type:'FOOTER_FULL'
    }
}

export const minfooter = () =>{
    return{
        type:'FOOTER_MIN'
    }
}

export const navHome = () =>{
    return{
        type:'HOME'
    }
}

export const navAbout = () =>{
    return{
        type:'ABOUT'
    }
}

export const navSolution = () =>{
    return{
        type:'SOLUTION'
    }
}

export const navWhat = () =>{
    return{
        type:'WHAT'
    }
}

export const navContact = () =>{
    return{
        type:'CONTACT'
    }
}

export const navOthers = () =>{
    return{
        type:'OTHERS'
    }
}
