//by yi 2020 kuaipf project


import React from 'react';
import '../components/App.css';
import {Link} from "react-router-dom" 

 
 
class Keyvaluebox extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initall:0,
      frameContent:{
            transition:'0.15s ease-out all',
            width:'17vw',
            height:'17vw',
            minWidth:'280px',
            minHeight:'280px',
            backgroundColor:'#fff',
            borderStyle: 'solid',
            borderWidth: '0px',
            borderColor:'#000',
            color:'#000',
            float:'left',
            marginRight:'10px',
            marginLeft:'10px',
            marginTop:'30px',
            boxShadow:'3px 3px 3px #f0f0f0',
            opacity:1,
            
           
      },
      buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            height:'50px',
            backgroundColor:'#f6d808',
      },
  	}

    
    this.changeframeBoxalpha =this.changeframeBoxalpha.bind(this)

 
    
   
  }

  componentDidMount() {

   const winW=window.innerWidth;
/*
   let FframeContent = Object.assign({}, this.state.frameContent, {minWidth:'350px',minHeight:'230px'})

   if(winW <= 1124){
    this.setState({
     frameContent:FframeContent
    })

   }
*/
   
   
  }


  componentDidUpdate(){

   

  }


  changeframeBoxalpha(){
    
     let FframeContent = Object.assign({}, this.state.frameContent, {opacity:1})

    let UframeContent = Object.assign({}, this.state.frameContent, {opacity:0})

    if(this.props.Scrollstate >= window.innerHeight/2 && this.state.initall==0){
      
    this.setState({
      initall:1,
     frameContent:FframeContent
    })
  }

 
  }

 

  mouseEnter(event) {
   console.log('in')

   
  }

  mouseOut(event) {
   //console.log('out')
   
  }
  mouseDown(event){

  }

  render(){
 
   let styles={
    buttonViewbox:{
      display:'flex',
      alignItems: 'center',
      width:'200px',
      height:'50px',
   
    },
    buttonInfo:{
      verticalAlign:'center',
      alignItems: 'center',
      paddingTop:'16px',
      textAlign:'center',
      width:'200px',
      height:'50px',
      position:'absolute',
  
    },
    frameContent:{
            transition:'0.15s ease-out all',
            width:'17vw',
            height:'17vw',
            minWidth:'280px',
            minHeight:'280px',
            backgroundColor:'#fff',
            borderStyle: 'solid',
            borderWidth: '0px',
            borderColor:'#000',
            color:'#000',
            float:'left',
            marginRight:'10px',
            marginLeft:'10px',
            marginTop:'30px',
            boxShadow:'3px 3px 3px #f0f0f0',
            opacity:1,
            
    },
    clentImg:{
      width:'20%',

      minWidth:'100px',
      minHeight:'100px',
      paddingLeft:'2vw',
      paddingRight:'2vw',
      paddingTop:'2vw',
      float:'center',
    } 



  }

  
  const winW=window.innerWidth;

   if(winW <= 1124){
     
     styles.frameContent.minWidth='350px' 

     styles.frameContent.minHeight='240px'
     }

    if(this.props.Scrollstate >= window.innerHeight/2.7 && this.state.initall==0){
      styles.frameContent.opacity=1
    
    }  



   return(
     <div className='contentFrame_center' style={{paddingTop:'30px',paddingBottom:'30px'}}>
    
          <img src={require('../asset/clent0.jpg')} style={styles.clentImg}  />
          <img src={require('../asset/clent1.jpg')} style={styles.clentImg}  />
          <img src={require('../asset/clent2.jpg')} style={styles.clentImg}  />
          <img src={require('../asset/clent3.jpg')} style={styles.clentImg}  />



     </div>
    )

  }
}



export default Keyvaluebox