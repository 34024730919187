/**
 * yi 2020
 *
 *
 */

import React from 'react';
import DataListitem from "../../components/DataListitem";
import {Avatar,Select,Switch,Tabs,Tag,Breadcrumb,Descriptions,Badge,List,Input, Drawer, Button,Modal,Result, Typography, Space, Divider, Statistic, Row, Col, Pagination, Alert} from "antd";


import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MessageOutlined,
    ProjectOutlined,
    LockOutlined,
    InboxOutlined,
    LoadingOutlined,
    FilePdfOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/Adminbridge";
import {Ytextarea} from "../../components/Formelements";
import axios from "axios";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;
const { Title,Text,Paragraph } = Typography;
const { TabPane } = Tabs;

/*
* choose registration account type
*/



class Usermanage extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            GET_Data:null,
            loading:true,
            visible: false,
            Drawereditvisible:false,
            childrenDrawer:false,
            total_list:null,
            current_page:null,
            error:false,
            errorContent:'',
            Modalvisible:false,
            itemstate:'true',
            edit_item:{
                id:'',
                comment_a:'',
                comment_q:'',

            },
            submit_data:{
                comment_a:'',
                comment_q:'',
            },
            tab_key:1,
            tab_value:['','fl'],
            profile_file_url:'',
            tag_data:{
                tag1:null,
                tag2:null,
                tag1_option:null,
                tag2_option:null,
            },
            tag_pro:[],
            tag_exp:[],
            filter_tag1:[],
            filter_tag2:[],
            working_years:'1-5年',
            working_hours:'2-4小时'


        }

        this.onPageChange =this.onPageChange.bind(this)
        this.get_data_list =this.get_data_list.bind(this)
        this.createFAQ =this.createFAQ.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this);
        this.qainput =this.qainput.bind(this);
        this.edit_qainput =this.edit_qainput.bind(this);
        this.handleCancel =this.handleCancel.bind(this);
        this.Tabcallback =this.Tabcallback.bind(this);
        this.activeUser =this.activeUser.bind(this);
        this.select_tag1_handler =this.select_tag1_handler.bind(this);
        this.select_tag2_handler =this.select_tag2_handler.bind(this);
        this.working_years_onChange =this.working_years_onChange.bind(this);
        this.working_hours_onChange =this.working_hours_onChange.bind(this);
        this.onChildrenDrawerClose =this.onChildrenDrawerClose.bind(this)
        this.showChildrenDrawer =this.showChildrenDrawer.bind(this)
    }

    componentWillMount() {


        this.get_data_list(1)
        const tag1=this.state.tag_data
        this.Get_tags_pro(1,tag1)
        this.Get_tags_pro(2,tag1)

    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            GET_Data:null,
            loading:true,
        })
        this.get_data_list(page);
    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    handleCancel(){

        this.setState({
            Modalvisible: false,
        });
    }



    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    showDrawer_edit = (item) => {
        // console.log(item)
        this.setState({
            edit_item:item,
            tag_pro:[],
            tag_exp:[],
        },()=>{
            this.get_down_url(item.profile_file)
            this.Get_user_tags(1,item)
            this.Get_user_tags(2,item)
            this.setState({
                Drawereditvisible: true,
            });
        });


    };

    onCloseDrawer_edit = () => {
        this.setState({
            Drawereditvisible: false,

        });
    };

    showChildrenDrawer = () => {
        let GMTdate = new Date(moment().subtract(0, 'day')._d)

        let Str = GMTdate.getFullYear() + '-' +
            (GMTdate.getMonth() + 1) + '-' +
            GMTdate.getDate()



        this.setState({
            childrenDrawer: true,
        });
    };

    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,

        });
    };





    get_data_list(page){
        let filter_tag=null
         filter_tag=this.state.filter_tag1.concat(this.state.filter_tag2)

        console.log(filter_tag)
        const postdata={
            type:this.state.tab_value[this.state.tab_key],
            tag_id:filter_tag,
            working_years:this.state.working_years,
            working_hours:this.state.working_hours,
            page:page,

        }

        Http.filter_user_list(postdata).then((res)=>{


            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                    GET_Data: res.data.data,
                    loading: false,
                })
            }else {
                this.setState({
                    GET_Data: res.data,
                    loading: false,
                    total_list: res.data.length,
                    current_page: 1,
                })
            }

        }).catch((res) => {
            console.log(res.message)
        })

    }

    createFAQ(){
        this.showDrawer()
    }

    edit_qainput(ta){
        const name=ta.target.name
        const val=ta.target.value

        const submitdata=this.state.edit_item
        if(name=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(name=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }


    }

    qainput(ta,val){
        const submitdata=this.state.submit_data
        if(ta=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(ta=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }
    }
    submitFAQ(){
        if(this.state.submit_data.comment_a=='' || this.state.submit_data.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.submit_data.comment_a,
                comment_q:this.state.submit_data.comment_q,
            }

            Http.create_faq(postdata).then((res)=>{

                this.onCloseDrawer();
                this.onPageChange(1);

            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }

    }

    deleteFAQ(){

        this.setState({
            Modalvisible: true,
        });

    }

    submiteditFAQ(state){
        if(this.state.edit_item.comment_a=='' || this.state.edit_item.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.edit_item.comment_a,
                comment_q:this.state.edit_item.comment_q,
                id:this.state.edit_item.id,
                state:state
            }
            this.setState({
                Modalvisible: false,
            });

            Http.edit_faq(postdata).then((res)=>{

                this.onCloseDrawer_edit();

                setTimeout(this.onPageChange(1), 1000 )


            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }
    }

    /**
     * get all tags
     * @constructor
     */

    Get_tags_pro(id,tagdata){
        const postdata={
            type_id:id,
        }
        Http.get_all_tag(postdata).then((res)=>{
            const pro_data_array=[];
            const select_data_array=[];
            res.data.map((_key, index) => {
                pro_data_array[_key.id]=_key.tag_name
                select_data_array.push(<Option key={_key.id}>{_key.tag_name}</Option>)
            })
            if(id==1) {
                tagdata.tag1 = pro_data_array
                tagdata.tag1_option=select_data_array
            }else if(id==2){
                tagdata.tag2 = pro_data_array
                tagdata.tag2_option=select_data_array
            }
            this.setState({
                tagdata,
            },()=>{console.log('load tags')})

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }


    /**
     * get user tags
     * @constructor
     */

    Get_user_tags(type_id,user_item){

              if(type_id==1){
              this.setState({
                  tag_pro:user_item.link_tags
              })

              }else if(type_id==2){
                  this.setState({
                      tag_exp:user_item.link_tags
                  })
              }

    }


    /**
     * oss down url gen
     */
    get_down_url(filekey){

        const postdata={
            osskey:filekey,
        }
        Http.Get_oss_file(postdata).then((res)=>{
            this.setState({
                profile_file_url:res.data,
            })

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }


    Tabcallback(key) {
        // console.log(key);
        this.setState({
            tab_key:key,
        },()=>{
            this.get_data_list(1)
        })


    }

    working_years_onChange(value){
        this.setState({
            working_years:value
        },()=>{
            this.get_data_list(1)
        })
    }

    working_hours_onChange(value){
        this.setState({
            working_hours:value
        },()=>{
            this.get_data_list(1)
        })
    }


    select_tag1_handler(value){

        this.setState({
            filter_tag1:value
        },()=>{
            this.get_data_list(1)
        })
    }

    select_tag2_handler(value){
        this.setState({
            filter_tag2:value
        },()=>{
            this.get_data_list(1)
        })
    }


    /**
     * extra func
     * @returns {JSX.Element}
     */

    activeUser(action,id){
        if(action=='activate'){
            action='default'
        }else{
            action='activate'
        }
        const postdata={
            id:id,
            action:action
        }
        Http.activate_user(postdata).then((res)=>{
            const ta_item=this.state.edit_item
            ta_item.member_state=action
            this.setState({
                ta_item
            })
            this.get_data_list(this.state.current_page)
        }).catch((res) => {
            console.log(res.message)
        })
    }



    render() {



        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>


                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px 30px 0px 30px',


                    }}>


                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}> </Title>

                        </Space>

                        <Tabs defaultActiveKey={this.state.tab_key} onChange={this.Tabcallback}>
                            <TabPane tab="FREELANCER" key="1">

                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="专业领域"
                                   // defaultValue={['a10', 'c12']}
                                    onChange={this.select_tag1_handler}
                                >
                                    {this.state.tag_data.tag1_option}
                                </Select>

                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' ,paddingTop:'10px'}}
                                    placeholder="行业经验"
                                    // defaultValue={['a10', 'c12']}
                                    onChange={this.select_tag2_handler}
                                >
                                    {this.state.tag_data.tag2_option}
                                </Select>

                                <div style={{paddingTop:'10px',paddingBottom:'20px'}}>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="工作年限"
                                    defaultValue={'1-5年'}
                                    onChange={this.working_years_onChange}
                                >
                                    <Option value="1-5年">1-5年</Option>
                                    <Option value="6-8年">6-8年</Option>
                                    <Option value="9-12年">9-12年</Option>
                                    <Option value="12年以上">12年以上</Option>
                                </Select>

                                <Select

                                    style={{ width: 200 }}
                                    placeholder="每天工作时长"
                                    defaultValue={'2-4小时'}
                                    onChange={this.working_hours_onChange}
                                >
                                    <Option value="2-4小时">2-4小时</Option>
                                    <Option value="5-8小时">5-8小时</Option>
                                    <Option value="8-10小时">8-10小时</Option>
                                    <Option value="10小时以上">10小时以上</Option>
                                </Select>
                                </div>

                            </TabPane>


                        </Tabs>


                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        paddingTop: '0px',


                    }}>

                        <List

                            itemLayout="horizontal"
                            style={{backgroundColor:'#fff'}}
                            dataSource={this.state.GET_Data}
                            renderItem={item => (
                                <List.Item style={{paddingLeft:'30px'}}

                                >
                                    {(item.user_state=='false')?(
                                        <div style={{paddingRight:'30px'}}>
                                            <LockOutlined style={{color:'#999'}}/>
                                        </div>

                                    ):(
                                        (item.member_state=='default')?(

                                        <div style={{paddingRight:'30px'}}>
                                            <Badge count={1} dot>
                                                <Avatar icon={<UserOutlined />} />
                                            </Badge>
                                        </div>
                                        ):( <div style={{paddingRight:'30px'}}>

                                                <Avatar icon={<UserOutlined />} />

                                            </div>
                                        )
                                    )}
                                    <List.Item.Meta
                                        title={item.family_name}
                                        description={''}
                                    />
                                    <List.Item.Meta

                                        title={item.email}
                                    />
                                    <List.Item.Meta
                                        title={item.mobile}
                                    />
                                    <List.Item.Meta
                                        title={(item.type=='cl')?'CLIENT':'FREELANCER'}
                                    />



                                    <Button key={'back'} type="text" onClick={()=>this.showDrawer_edit(item)} style={{color:"#999",marginRight:'30px',fontSize:'13px'}}  >查看</Button>


                                </List.Item>
                            )}
                        />



                    </div>

                    <div style={{
                        width: '100%',
                        height: '86px',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"space-between",
                    }}>
                        <Pagination
                            size="small"
                            defaultPageSize={10}
                            defaultCurrent={this.state.current_page}
                            total={this.state.total_list}
                            onChange={this.onPageChange}
                        />



                    </div>

                    <Drawer
                        width={640}
                        title="创建新的FAQ"
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer}
                        visible={this.state.visible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button key={'back'} type="text"   onClick={()=>this.submitFAQ()}>提交</Button>
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Text  type="secondary" >输入问题</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <Ytextarea width={'100%'} height='50px' name={'question'} full={'none'}  callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={60}/>

                        <Text  type="secondary"  >输入答案</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>
                        <Ytextarea width={'100%'} height='120px' name={'answer'} full={'none'}   callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={250}/>



                    </Drawer>



                    <Drawer
                        width={'60%'}
                        title={'用户：'+this.state.edit_item.family_name}
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer_edit}
                        visible={this.state.Drawereditvisible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {/*
                                <Button key={'delect'} type="text"   onClick={()=>this.deleteFAQ()}>删除</Button>

                                <Button key={'back'} type="text"   onClick={()=>this.submiteditFAQ('true')}>更新</Button>
                                */}
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }


                        <Text  style={{color:'#666'}} >账号状态：</Text><Switch checkedChildren="启用中" unCheckedChildren="审核中" onChange={()=>this.activeUser(this.state.edit_item.member_state,this.state.edit_item.id)} checked={(this.state.edit_item.member_state=='default')?false:true} />
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Descriptions
                            title=""


                        >

                            <Descriptions.Item label="ID">{this.state.edit_item.user_id}</Descriptions.Item>
                            <Descriptions.Item label="邮箱">{this.state.edit_item.email}</Descriptions.Item>
                            <Descriptions.Item label="电话">{this.state.edit_item.mobile}</Descriptions.Item>
                            <Descriptions.Item label="微信ID">{this.state.edit_item.wechat_id}</Descriptions.Item>
                            <Descriptions.Item label="省份">{this.state.edit_item.province}</Descriptions.Item>
                            <Descriptions.Item label="地址">{this.state.edit_item.city+this.state.edit_item.area}</Descriptions.Item>
                            <Descriptions.Item label="工作年限">{this.state.edit_item.working_years}</Descriptions.Item>
                            <Descriptions.Item label="每天工作时间">{this.state.edit_item.working_hours}</Descriptions.Item>
                            <Descriptions.Item label="曾服务客户">{this.state.edit_item.clients}</Descriptions.Item>
                            <Descriptions.Item label="期望日薪">{this.state.edit_item.daily_cost}</Descriptions.Item>
                            <Descriptions.Item label="首选联系方式">{this.state.edit_item.contact_type}</Descriptions.Item>
                            <Descriptions.Item label="曾任职位">{this.state.edit_item.title}</Descriptions.Item>
                            <Descriptions.Item label="曾获得奖项">{this.state.edit_item.awards}</Descriptions.Item>
                            <p/>
                            <p/>
                            <Descriptions.Item label="个人网站">{this.state.edit_item.profile_url}</Descriptions.Item>
                            <p/>
                            <p/>

                            <Descriptions.Item label="注册日期">{this.state.edit_item.created_at}</Descriptions.Item>

                        </Descriptions>



                        <Text   >个人介绍</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <textarea style={{
                            border:'0px solid #efefef',
                            outline: 'none',
                            width:'100%',
                            paddingLeft:'2px',
                            paddingTop:'2px',
                            marginBottom:'20px',
                            height:'auto',
                            overflow:'hidden',
                            color:'#999',
                            resize:'none',
                            backgroundColor:'',
                            fontSize:'12px'
                        }}
                                  readOnly={true}
                                  name={'question'}
                                  onChange={this.edit_qainput}
                                  value={this.state.edit_item.introduce}

                        />

                        <Divider orientation="center" style={{fontSize:'13px'}}>专业领域</Divider>

                        <div>

                            {

                                this.state.tag_pro.map((key, index) => (
                                    (key.type_id==1)?(
                                    <Tag key={index} color="volcano">{this.state.tag_data.tag1[key.tag_id]}</Tag>
                                    ):('')
                                ))
                            }

                        </div>

                        <Divider orientation="center" style={{fontSize:'13px'}}>熟悉行业</Divider>

                        <div>

                            {

                                this.state.tag_exp.map((key, index) => (
                                    (key.type_id==2)?(
                                    <Tag key={index} color="gold">{this.state.tag_data.tag2[key.tag_id]}</Tag>):('')
                                ))
                            }
                        </div>

                         <Divider/>


                        <Row  >
                            <Col span={2}>
                                <FilePdfOutlined style={{fontSize:"50px"}}/>
                            </Col>
                            <Col span={20}>
                                <Space direction={"vertical"}>
                                    <Text type="secondary"> {this.state.edit_item.profile_file}</Text>
                                    <Text type="secondary" style={{fontSize:'11px'}}> 作品下载链接已经过加密处理并将于24小时后失效</Text>
                                </Space>
                            </Col>
                            <Col span={2}>
                                <Button type="text" href={this.state.profile_file_url} target={"_blank"} >下载</Button>
                            </Col>

                        </Row>






                    </Drawer>

                    <Modal
                        title="删除"
                        style={{ top: 20 }}
                        visible={this.state.Modalvisible}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                            <Text type="secondary">确认要删除这条数据吗？</Text>

                            <Button type="text" onClick={()=>this.submiteditFAQ('false')}>确认</Button>

                        </div>
                    </Modal>

                </div>
            )
        }
    }
}


export default Usermanage;