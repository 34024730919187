/**
 *  yi 2020
 * API bridge
 */

import axios from 'axios';
import '../global'
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";


const Http = {};


/**
 *
 * check token
 */

function Checktoken (){



    const access_token=  cookie.load('YiToken')



    if(access_token == 'undefined' || access_token == undefined){

        return "null token"
    }else {

        const decoded = jwt_decode(access_token)

        //jwt 用的是秒作为exp单位  有时候要转化为毫秒

        const expdate = new Date(decoded.exp * 1000)
        const exptimestamp = decoded.exp * 1000
        const nowtimestamp = (new Date()).valueOf()

        //要和服务器过期时间同步
        const exptimedis = 20

        if ((nowtimestamp - exptimestamp) / 1000 > exptimedis) {

            console.log('Token need refresh Expired')
            Http.Refresh_token();
            return access_token;

        } else if((nowtimestamp - exptimestamp) / 1000 < exptimedis) {
            console.log('Token in used')
            return access_token;
        }else{
            return 'error'
        }
    }


}



/**
 * refresh token
 * @Token
 */

Http.Refresh_token=()=>{
    const access_token= {
        token:cookie.load('YiToken')

    }
    axios.post(global.gData.api_domain+'/api/auth/refresh', access_token)
        .then(function (response) {

            cookie.save('YiToken',response.data.access_token,{path:"/"})
            console.log('refresh token success')


        })
        .catch(function (error) {
            let statuscode=error.response.status
            cookie.remove('YiToken')
            console.log(global.codeMessage[statuscode]);

        });
}


/**
 * update order state
 * @Token
 */

Http.update_projcet_state=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            order_id:postdata.order_id,
            user_id:postdata.user_id,
            state:postdata.state,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/update_projcet_state',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * create order
 *
 */


/**
 * 创建订单 cl type
 * @Token
 */

Http.Creat_order = (postdata) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');

        const access_token=  Checktoken();
        const data={
            token:access_token,
            name:postdata.name,
            user_id:postdata.user_id,
            email:postdata.email,
            mobile:postdata.mobile,
            wechat_id:postdata.wechat_id,
            company:postdata.company,
            order_name:postdata.order_name,
            department:postdata.department,
            deliver:postdata.deliver,
            brief_call:postdata.brief_call,
            calling_time:postdata.calling_time,
            remark:postdata.remark,
            service:postdata.service,
            title:postdata.title,
            industry:postdata.industry,
            ip_address:'false',
        }
        console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/admin_create_order',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * insert order event
 * @Token
 */

Http.create_order_event=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            order_id:postdata.order_id,
            user_id:postdata.user_id,
            progress:postdata.progress,
            event:postdata.event_name,
            request_date:postdata.event_date,
            type:1,
        }
        //console.log(data)

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/create_order_event',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * delete order event
 * @Token
 */

Http.delete_order_event=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            user_id:postdata.user_id,
            state:postdata.state,


        }
        //console.log(data)

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/delete_order_event',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * create user
 */


Http.Creat_user=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            type:postdata.type,
            name:postdata.name,
            email:postdata.email,
            mobile:postdata.mobile,
            password:postdata.password,
            company:postdata.company,
            title:postdata.title,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/createuser',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * get order event
 * @Token
 */

Http.get_order_event=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            order_id:postdata.order_id,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_order_event',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * update order progress
 * @Token
 */

Http.update_order_progerss=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            order_id:postdata.order_id,
            user_id:postdata.user_id,
            progress:postdata.progress,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/update_projcet_process',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * get  user tag
 * @Token
 */

Http.list_user_tags=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            type_id:postdata.type_id,
            user_id:postdata.user_id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_user_tags',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * get  all tag
 * @Token
 */

Http.get_all_tag=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.type_id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/gettags',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * activate leave form
 * @Token
 */

Http.activate_leaveform=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            action:postdata.action,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/activate_leaveform',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * activate user
 * @Token
 */

Http.activate_user=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            action:postdata.action,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/activate_user',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * get article
 *
 */


Http.edit_articles=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
            articlecontent:postdata.content,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/edit_articles',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * get article
 *
 */


Http.get_articles=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/get_articles',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * get overview info
 *
 */


Http.overview_info=()=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/overview_info',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * filter  client list
 * @Token
 */

Http.filter_clients_list=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            page:postdata.page,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/filter_clients_list',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * filter  user list
 * @Token
 */

Http.filter_user_list=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        let tag_id_=null;
        if(postdata.tag_id!=null){
            tag_id_=postdata.tag_id.join(',');
        }
        const data={
            token:access_token,
            tag_id:tag_id_,
            working_hours:postdata.working_hours,
            working_years:postdata.working_years,
            type:postdata.type,
            page:postdata.page,
        }

        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/filter_user_list',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * get  user list
 * @Token
 */

Http.get_user_list=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            type:postdata.type,
            page:postdata.page
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_user_list',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * leave form readed
 * @Token
 */

Http.leaveform_readed=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/leaveform_readed',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * message readed
 * @Token
 */

Http.message_readed=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/message_readed_admin',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * get leave form
 * @Token
 */

Http.get_leaveform=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            type:postdata.type,
            page:postdata.page
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_leaveform_all',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * get leave message
 * @Token
 */

Http.get_leavemessage=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            type:postdata.type,
            page:postdata.page
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_leavemessage_all',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 获取用户信息
 * @Token
 */

Http.get_Profile=()=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/profile',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * 获取oss文件临时连接
 * @Token
 */

Http.Get_oss_file = (postdata) =>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            osskey:postdata.osskey,

        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_oss_file',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}





/**
 * 获取所有订单
 * @Token
 */

Http.get_Order_all=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            keyword:postdata.keyword,
            page:postdata.page,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_order_all',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)

        }).catch((error) => {

            rejiect(error)
        })
    })
}



/**
 *
 * create faq
 */



Http.create_faq=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            comment_a:postdata.comment_a,
            comment_q:postdata.comment_q,
            type:'qa',
            level:3,
            belong:1,
        }
        //console.log(data)get_Order_list
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/create_qa',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 *
 * edit faq
 */

Http.edit_faq=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            comment_a:postdata.comment_a,
            comment_q:postdata.comment_q,
            state:postdata.state,
            id:postdata.id,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/edit_faq_item',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


function resSuccess(resolve, rejiect, res) {
    let datas = res.data

    // if(res.data.message=='Token has expired') {
    //     Http.Refresh_token();//刷新token
    //     window.location.reload();
    // }else {

    if (datas.status == true || datas.code == 0 || datas == '') {
        resolve(datas);
    } else {
        rejiect(datas);
    }
    // }
}
export default Http;