/**
 *by yi 2020 kuaipf project
 */


import React from 'react';
import './App.css';
import {Link} from "react-router-dom"
import logoY from '../asset/logo-y.svg';
import logoB from '../asset/logo-b.svg';
import menuIcon from '../asset/menu.svg';

import {connect} from 'react-redux'

import  '../components/global'
import {authlogin} from "../reduceractions";
import {autoAchtion} from "./functions/authAchtions";
import {Motion, spring} from 'react-motion';
import { Drawer, Button,  Typography, Space,} from "antd";



const mapStateToProps = (state) =>({
    // auth  reducers
    // auth :state.auth,
    navstate:state.navstate,
    counter:state.counter,
    redux_islog:state.redux_islog,



})

const { Text,Title } = Typography;

//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    toauthlogin(){
        dispatch(authlogin())
        // console.log(authlogin())
    }
})



class nav extends React.Component{




    constructor(){
        super()
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            showNav:'block',
            nav_pc:'visible',
            nav_mb:'hidden',
            logo_width:'110px',
            barbgdisable:true,
            barbgopacity:0,
            barbgheight:60,
            barPaddingtop:0,
            logoSvg:logoY,
            barBgtop:-5,
            loginbuttonW:'60px',
            loginbuttontranW:'66px',
            loginbuttonname:'登陆',
            homebtn:{
                fontSize:'13px',
                fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                color:'#000',
                width:'60px',
                height:'30px',
                alignItems:'center',
                display:'flex',
                justifyContent: 'center',
                backgroundColor:'#ffe600',
                borderRadius: '15px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
                cursor: 'pointer',
            },
            homeline: null,
            aboutline:null,
            solutionline:null,
            contactline:null,
            whatline:null,
            drawervisible: false,



        }

        this.handleScroll =this.handleScroll.bind(this)

    }

    mouseEnter(event) {
        //console.log('in')

        this.setState({
                homebtn:{
                    fontSize:'13px',
                    fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                    color:'#000',
                    width:this.state.loginbuttontranW,
                    height:'30px',
                    alignItems:'center',
                    display:'flex',
                    justifyContent: 'center',
                    backgroundColor:'#ffe600',
                    borderRadius: '15px',
                    textDecoration: 'none',
                    transition:'0.15s ease-out all',
                    cursor: 'pointer',
                }

            }
        )

    }

    mouseOut(event) {
        //console.log('out')
        this.setState({
                homebtn:{
                    fontSize:'13px',
                    fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                    color:'#000',
                    width:this.state.loginbuttonW,
                    height:'30px',
                    alignItems:'center',
                    display:'flex',
                    justifyContent: 'center',
                    backgroundColor:'#ffe600',
                    borderRadius: '15px',
                    textDecoration: 'none',
                    transition:'0.15s ease-out all',
                    cursor: 'pointer',
                }
            }
        )
    }

    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

       // const barbg=this.state.navBar_bg
        if(this.scrolltopcount/window.innerHeight >= 1/26){

            this.setState({
                barbgdisable:false,
                barbgopacity:1,
                barbgheight:80,
                barPaddingtop:6,
                logoSvg:logoB,
                barBgtop:0,

            })
        }else{

            this.setState({
                barbgdisable:true,
                barbgopacity:0,
                barbgheight:60,
                barPaddingtop:0,
                logoSvg:logoY,
                barBgtop:-5,


            })
        }



    }


    componentDidMount() {
        console.log(this.props.navstate)


         switch (this.props.navstate){
             case "HOME":
                 this.setState({
                     homeline:{
                         marginTop:'6px',
                         width: 28,
                         borderBottom: '2px solid black',
                         transition:'0.55s ease-out all',
                     }
                 })
                 break
             case "ABOUT":
                 this.setState({
                     aboutline:{
                         marginTop:'6px',
                         width: 70,
                         borderBottom: '2px solid black',
                         transition:'0.55s ease-out all',
                     }
                 })
                 break
             case  "SOLUTION":
                 this.setState({
                     solutionline:{
                         marginTop:'6px',
                         width: 57,
                         borderBottom: '2px solid black',
                         transition:'0.55s ease-out all',
                     }
                 })
                 break
             case  "CONTACT":
                 this.setState({
                     contactline:{
                         marginTop:'6px',
                         width: 57,
                         borderBottom: '2px solid black',
                         transition:'0.55s ease-out all',
                     }
                 })
                 break
             case  "WHAT":

                 this.setState({
                     whatline:{
                         marginTop:'6px',
                         width: 57,
                         borderBottom: '2px solid black',
                         transition:'0.55s ease-out all',
                     }
                 })
                 break
             default:
         }


    }

    componentDidUpdate(){



    }



    componentWillMount() {

        const resetnvline={
            marginTop:'6px',
            width: 0,
            borderBottom: '2px solid black',
            transition:'0.55s ease-out all',
        }
        this.setState({
            homeline:resetnvline,
            aboutline:resetnvline,
            solutionline:resetnvline,
            contactline:resetnvline,
            whatline:resetnvline,
        })

        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                nav_pc:'hidden',
                nav_mb:'visible',
                logo_width:'110px',
            })
            if(window.innerWidth<600){
                this.setState({

                    logo_width:'110px',
                })
            }

        }

        if(this.props.redux_islog){
            this.setState({
                loginbuttonname:'个人中心',
                loginbuttonW:'76px',
                loginbuttontranW:'82px',
                homebtn:{
                    fontSize:'13px',
                    fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                    color:'#000',
                    width:'76px',
                    height:'30px',
                    alignItems:'center',
                    display:'flex',
                    justifyContent: 'center',
                    backgroundColor:'#ffe600',
                    borderRadius: '15px',
                    textDecoration: 'none',
                    transition:'0.15s ease-out all',
                    cursor: 'pointer',
                }
            })
        }

    }


    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        console.log('component UnMount')
    }


    //props update

    componentWillReceiveProps(){




    }


    logoutHandler(e){
        this.props.autoAchtion(null,'logout')
    }

    showDrawer = () => {
        this.setState({
            drawervisible: true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            drawervisible: false,
        });
    };




    render(){

        const styles={
            navBar_bg:{
                width: '100%',
                height: '80px',
                padding: 0,
                margin: 0,
                top:'-5px',
                display: 'flex',
                alignItems: 'flex-start',
                position: 'absolute',
                backgroundColor: '#fff',
               // boxShadow:'0px 0px 2px #999'
            },
            navBar_box:{
                width: '100%',
                height: '80px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                position: 'absolute',
                top:'0px',
            },
        }


        const navStyle={

            color:'#000',
            textDecoration: 'none',
            cursor: 'pointer'


        }






        return(

            <nav style={{display:this.state.showNav,zIndex:50}}>
                <Motion style={{ opacity: spring(this.state.barbgopacity),height:spring(this.state.barbgheight),top:spring(this.state.barBgtop) }}  >
                    {
                        ({ opacity,height,top}) => <div    style={Object.assign({}, styles.navBar_bg, { opacity,height,top} )}  >   </div>
                    }
                </Motion>

                <Motion style={{top:spring(0)}}>
                    {
                        ({top})=>
                            <div   style={Object.assign({},styles.navBar_box,{top})}>
                                <div style={{width:this.state.logo_width,backgroundColor:'',marginLeft:'5%'}}>
                                  <Link   to="/home">
                                     <img src={this.state.logoSvg} alt="logo"  width={this.state.logo_width}  style={{padding:0}}/>
                                  </Link>
                                </div>

                                <div className='nav_content_mobile' style={{visibility:this.state.nav_mb}}>
                                    <div className='nav_items_mobile'>
                                    </div>
                                    <img src={menuIcon}   className="menuIcon"   alt="menu" onClick={()=>this.showDrawer()} />
                                </div>

                                <div  style={{visibility:this.state.nav_pc,width:'90%',backgroundColor:''}} >
                                    <div style={{height:'20px',width:'100%',backgroundColor:'',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <ul className="nav-links" style={{minWidth:320}}>
                                            <Link style={navStyle} to="/home">
                                                <li>
                                                    首页
                                                    <div style={this.state.homeline}  > </div>
                                                </li>
                                            </Link>
                                            <Link style={navStyle} to="/why-kuaipf">
                                                <li>
                                                    选择快平方
                                                    <div style={this.state.aboutline}> </div>
                                                </li>
                                            </Link>
                                            <Link style={navStyle} to="/how">
                                                <li>
                                                    合作方式
                                                    <div style={this.state.solutionline}> </div>
                                                </li>
                                            </Link>
                                            <Link style={navStyle} to="/about">
                                                <li>
                                                    了解我们
                                                    <div style={this.state.whatline}> </div>
                                                </li>
                                            </Link>
                                            {/*
                                            <Link style={navStyle} to="/contact">
                                                <li>
                                                    联系我们
                                                    <div style={this.state.contactline}> </div>
                                                </li>
                                            </Link>
                                            */}
                                        </ul>

                                        <div style={{width:'100%',height:20,backgroundColor:''}}>

                                        </div>



                                            {
                                                (this.props.redux_islog)?(
                                                    <ul className="control-bar">
                                                        {/*
                                                            <li> </li>


                                                            <li> </li>

                                                        <li>
                                                            <Link target='_blank' style={navStyle} to="/login">
                                                                <div  style={this.state.homebtn} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }} >

                                                                    {this.state.loginbuttonname}

                                                                </div>
                                                            </Link>
                                                        </li>
                                                        */}

                                                    </ul>
                                                ):(
                                                    <ul className="control-bar">
                                                        {/*
                                                <Link style={navStyle} to="/registration?ty=cl">
                                                    <li>我有活</li>
                                                </Link>
                                                <Link style={navStyle} to="/registration?ty=fl">
                                                <li>我接活</li>
                                                </Link>

                                                        <li>
                                                            <Link target='_blank' style={navStyle} to="/login">
                                                                <div  style={this.state.homebtn} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }} >

                                                                    {this.state.loginbuttonname}

                                                                </div>
                                                            </Link>
                                                        </li>
                                                        */}

                                                    </ul>

                                                )
                                            }


                                    </div>
                                </div>
                            </div>
                    }
                </Motion>

                <Drawer
                    width={'50%'}
                    title="导航"
                    placement="right"
                    closable={false}
                    onClose={this.onCloseDrawer}
                    visible={this.state.drawervisible}

                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {/*
                            <Link style={navStyle} to="/registration">
                            <Button key={'back'} type="text"   onClick={()=>this.onCloseDrawer()}>注册</Button>
                            </Link>
                            */}

                        </div>
                    }
                >
                    <Space size={"large"} direction={"vertical"}>
                        <Link style={navStyle} to="/home">
                        <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>首页</Title>
                        </Link>
                        <Link style={navStyle} to="/why-kuaipf">
                        <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>选择快平方</Title>
                        </Link>
                        <Link style={navStyle} to="/how">
                        <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>合作方式</Title>
                        </Link>
                        <Link style={navStyle} to="/about">
                            <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>了解我们</Title>
                        </Link>


                    </Space>

                </Drawer>


            </nav>
        )
    }

}



export default connect(mapStateToProps,{autoAchtion})(nav) ;