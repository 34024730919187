/*
 * yi 2020
 *
 *
 */

import React from 'react';
import {Link} from "react-router-dom";







/*
* choose registration account type
*/


class Submited extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,

        }


    }


    render(){

        const Typestyles={
            bubbo:{
                width:'80px',
                height:'80px',
                alignItems:'center',
                display:'flex',
                margin:'25px',
                justifyContent: 'center',
                backgroundColor:'#f5db10',
                borderRadius: '40px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
            }
        }

        let min_height="20vh";
        let centerboxW="500px";

        let max_height='auto';
        let next_bar_h='40vh';
        let centerboxMinW='430px';
        let topbarboxH="200px";
        let viewtop='20vh';


        if(window.innerWidth<1124){
            min_height="40%";
            centerboxW="90%";

            max_height='auto'
            next_bar_h='auto';
            centerboxMinW='';
            topbarboxH="400px";
            viewtop='10vh';
        }

        const user_type=this.props.brideg.data.type;
       if(user_type=='fl') {
           return (
               <div style={{
                   width: '100%',
                   height: max_height,
                   minHeight: '100vh',
                   overflow: 'hidden',
                   backgroundColor: '',
               }}>
                   <div style={{
                       width: '100%',
                       height: topbarboxH,
                       backgroundColor: '#f6d809',
                       alignItems: 'center',
                       display: 'flex',
                       justifyContent: 'center',
                       paddingTop: '0px',
                       flexDirection: 'column'
                   }}>

                       <div style={{
                           width: "80%",
                           alignItems: 'center',
                           display: 'flex',
                           justifyContent: 'center',
                           paddingTop: '0px',
                           flexDirection: 'column'
                       }}>
                           <span className="ATitle" style={{paddingTop: '50px', alignSelf: 'center'}}>48小时定制您的快平方专业团队 体验专属服务</span>

                           <span className="contentText_center"
                                 style={{paddingTop: '20px'}}>只需填写简单的表格信息快平方咨询顾问会在您指定时间与您联系</span>
                       </div>

                   </div>
                   <div className='contentFrameBox' style={{backgroundColor: '#EFEFEF', paddingTop: '0px'}}>
                       <div className='triangleDown_Yellow'></div>
                   </div>


                   <div className="items_placeCenter_W" style={{height: 'auto'}}>

                       <div style={{
                           width: "80%",
                           alignItems: 'center',
                           display: 'flex',
                           justifyContent: 'center',
                           paddingTop: viewtop,
                           flexDirection: 'column'
                       }}>

                           <span className="Subtitle" style={{paddingTop: '0px'}}>提交成功</span>

                           <span className="contentText_center"
                                 style={{paddingTop: '20px'}}>我们将尽快对您的信息进行审核，通过审核后即可开通账号，届时您将可以进一步完善账号信息。</span>
                           <br/>
                           <Link to={'/home/'}>
                           <span className="contentText_center" style={{paddingTop: '80px'}}>返回首页</span>
                           </Link>
                       </div>


                   </div>


               </div>
           )
       }else{
           return(
               <div style={{
                   width: '100%',
                   height: max_height,
                   minHeight: '100vh',
                   overflow: 'hidden',
                   backgroundColor: '',
               }}>
                   <div style={{
                       width: '100%',
                       height: topbarboxH,
                       backgroundColor: '#f6d809',
                       alignItems: 'center',
                       display: 'flex',
                       justifyContent: 'center',
                       paddingTop: '0px',
                       flexDirection: 'column'
                   }}>

                       <div style={{
                           width: "80%",
                           alignItems: 'center',
                           display: 'flex',
                           justifyContent: 'center',
                           paddingTop: '0px',
                           flexDirection: 'column'
                       }}>
                           <span className="ATitle" style={{paddingTop: '50px', alignSelf: 'center'}}>48小时定制您的快平方专业团队 体验专属服务</span>

                           <span className="contentText_center"
                                 style={{paddingTop: '20px'}}>只需填写简单的表格信息快平方咨询顾问会在您指定时间与您联系</span>
                       </div>

                   </div>
                   <div className='contentFrameBox' style={{backgroundColor: '#EFEFEF', paddingTop: '0px'}}>
                       <div className='triangleDown_Yellow'></div>
                   </div>


                   <div className="items_placeCenter_W" style={{height: 'auto'}}>

                       <div style={{
                           width: "80%",
                           alignItems: 'center',
                           display: 'flex',
                           justifyContent: 'center',
                           paddingTop: viewtop,
                           flexDirection: 'column'
                       }}>

                           <span className="Subtitle" style={{paddingTop: '0px'}}>感谢您使用我们的服务</span>

                           <span className="contentText_center"  style={{paddingTop: '30px'}}>我们的咨询顾问会在指定的时间段与您联系。</span>
                           <span className="contentText_center"  style={{paddingTop: '2px'}}>您可以在控制台中查看项目进度。</span>
                           <br/>

                           <Link to={'/home/'}>
                           <span className="contentText_center" style={{paddingTop: '80px'}}>返回首页</span>
                           </Link>
                       </div>


                   </div>


               </div>
           )
       }
    }
}


export default Submited;