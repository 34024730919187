/*
 * yi 2020
 *
 * REDUCER  change state  判断action type
 */

const footerstateReducer=(state='FOOTER_FULL',action)=>{
    // console.log('reducer func call : '+action.type)
    switch(action.type){
        case "FOOTER_FULL":
            // console.log('reducer match :'+action.type)
            return 'FOOTER_FULL';
        case "FOOTER_MIN":
            //  console.log('reducer match :'+action.type)
            return 'FOOTER_MIN';
        default:
            //   console.log('reducer match nothing:')
            return state;
    }
}

export  default footerstateReducer;