import  counterReducer from "./counter";
import  loggedReducer from "./isLogged";
import  footerstateReducer from "./footerstate";
import  menustateReducer from "./menustate";
import navstateReducer from "./navstate";
import  {combineReducers} from "redux";



// 集合所有reducer一并输出 方便调用
const allReducer = combineReducers({
    counter:counterReducer,
    redux_islog:loggedReducer,
    footerstate:footerstateReducer,
    menustate:menustateReducer,
    navstate:navstateReducer,

})

export  default  allReducer;