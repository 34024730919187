//by yi 2020 kuaipf project


import React from 'react';
import ReactDOM from 'react-dom';
import './solution.css';
import  '../components/global'
import {Link} from "react-router-dom" 
import ButtonBlack from '../components/ButtonBlack'
import ButtonWhite from '../components/ButtonWhite'
import ButtonYellow from '../components/ButtonYellow'
import TopButton from  '../components/TopButton';
import QandAsimpfly from "../components/QandAsimpfly";
import Nav from '../components/nav'
import {connect} from "react-redux";
import {fullfooter, fullmenu, navSolution} from "../reduceractions";
import { Timeline } from 'antd';

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navSolution())
        // console.log(minfooter())
    }
})
   
 class Solution extends React.Component{

constructor(){
  	super()
  	this.state ={
  		initall:0,
  		scrolliconAlpha:1,
  		showscrolltop:0,
  		 
       
  	}

    
   this.handleScroll =this.handleScroll.bind(this)
   this.clickbtn =this.clickbtn.bind(this)

  

  
    this.centerViewbox_W='auto'

    this.QAViewbox_W='800px'
   
  
   
  }

 
  clickbtn(){
  	 window.scroll(0, -1000);

  }

  

  handleScroll(){
   this.scrolltopcount=window.pageYOffset
   //console.log(this.scrolltopcount)
   let scrolliconAlpha=1
     if(this.scrolltopcount >= window.innerHeight){
         scrolliconAlpha=0 
    }else{
         scrolliconAlpha=1
    }

     this.setState({
         scrolliconAlpha:scrolliconAlpha,
         showscrolltop:1-scrolliconAlpha,
        })
      if(window.innerWidth<1124){
          this.setState({
              showscrolltop:0,
          })
      }

  }

  componentDidMount() {

   //console.log('component Mount')
   
   
  }



 componentWillMount() {

    this.props.LoadPageState();
  //
  window.addEventListener('scroll', this.handleScroll);
  if(window.innerWidth<1124){
      this.centerViewbox_W='320px'
       this.QAViewbox_W='90%'   
       this.setState({
       scrolliconAlpha:0,
       showscrolltop:1
       })
       
    } 
   
  }


  componentDidUpdate(){

   
  
  }

  //close component
  componentWillUnmount(){
  	window.removeEventListener("scroll", this.handleScroll)
  	//console.log('component UnMount')
  }


  //props update

  componentWillReceiveProps(){

  }


 
 
render(){



    let callforactionfontsize='23px'
    let scrolliconR='20px'
    let sologenSize=25
    if (window.innerWidth <= 1124) {

        callforactionfontsize='15px'
        sologenSize=19
        scrolliconR='0px'
    }

    let callactionwidth='380px'
    window.innerWidth<1024?callactionwidth='320px':callactionwidth='380px'

   return(


     <div  className='contentView'>
     <Nav />

          <div className='solution_Topviwbox' style={{height:'auto'}}>
            <TopButton  callback={()=>this.clickbtn()} opacity={this.state.showscrolltop}/>

           <img src={require('../asset/scrollicon.png')}     className='scrollingIcon'  style={{backgroundColor:'',right:scrolliconR,opacity:this.state.scrolliconAlpha,transition:'0.15s ease-out all',top:'200px'}} />

           <div className='contentcenColumn' style={{width:this.centerViewbox_W,minWidth:'320px',display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center",height:'auto',backgroundColor:'',paddingTop:'100px'}}>
                       <span className='ATitle' style={{textAlign: 'left',paddingBottom:'30px',paddingTop:'60px',fontSize:sologenSize}}>
                           <img src={require('../asset/flashicon.png')}  style={{height:'30px'}}/> 快定义您的需求 快组建您的专属团队 快交付您的项目
                       </span>


               <div style={{width:'auto',paddingBottom:'40px',}}>

               <Timeline style={{paddingTop:30,backgroundColor:'',height:'auto'}}>
                   <Timeline.Item color={'white'}><span className='txt03' style={{color:'#000',fontSize:15,width:'100%',alignItems:'left',display:"flex",textAlign:"left"}}>1. 选定快餐及项目报价 </span></Timeline.Item>
                   <Timeline.Item color={'white'}><span className='txt03' style={{color:'#000',fontSize:15,width:'100%',alignItems:'left',display:"flex",textAlign:"left"}}>2. 签订合同并支付定金 </span></Timeline.Item>
                   <Timeline.Item color={'white'}><span className='txt03' style={{color:'#000',fontSize:15,width:'100%',alignItems:'left',display:"flex",textAlign:"left"}}>3.  <span style={{fontFamily:"Arial"}}> &nbsp;Brief&nbsp;</span>专才  </span></Timeline.Item>
                   <Timeline.Item color={'white'}><span className='txt03' style={{color:'#000',fontSize:15,width:'100%',alignItems:'left',display:"flex",textAlign:"left"}}>4. 项目验收及支付尾款 </span></Timeline.Item>


               </Timeline>

               <span className='txt03' style={{color:'#000',fontSize:18,width:'100%',alignItems:'left',display:"flex",paddingLeft:26}}>快！就这么省心 </span>

               </div>


               <div style={{height:'auto',paddingBottom:'60px',paddingTop:'40px'}}>
               <Link to={'/get-start'} style={{paddingRight: ''}}>
                   <ButtonWhite ButtonName='立即下单'/>

               </Link>
               </div>
           </div>
          </div>

          <div className='contentFrameBox' style={{backgroundColor:'#f5f5f5'}}>

               <div className='triangleDown_Yellow'>  </div>
          </div>  




        
         {/*FAQ*/}

          <div className='contentFrameBox' style={{backgroundColor:'#f5f5f5',height:'auto',paddingTop:'50px',paddingBottom:'0px'}}>

             <div className='selfcenter_alignLeft' style={{width:this.QAViewbox_W,height:'100%',paddingTop:'50px'}}>
                  <QandAsimpfly />
             </div>
          </div>


          <div className='contentFrameBox' style={{backgroundColor:'#f5f5f5',height:'auto',paddingTop:'0px',paddingBottom:'50px'}}>

          </div>
     </div>
    )

  }
}



export default connect(mapStateToProps,mapDispatchToProps)(Solution) ;
 