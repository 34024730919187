//by yi 2020 kuaipf project


import React from 'react';

import './contact.css';
import Tranigleright from '../asset/tranigleright.svg';
import {Link} from "react-router-dom"
import VcodeBtn from "../components/60slimitbutton";
import TopButton from  '../components/TopButton';
import  '../components/global'
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import Nav from '../components/nav'
import {fullfooter, fullmenu, navContact} from "../reduceractions";
import {connect} from "react-redux";

import {message, Typography, Space, Alert, Modal, Input, Button} from "antd";
import Http from "../components/functions/ApiBridge";

const {Title,Text,Paragraph } = Typography;

let inputViewLeft= -window.innerWidth*0.85

if(window.innerWidth<1124){
  inputViewLeft= -window.innerWidth
}

let nowscroll=window.pageYOffset

let iconAlpha=1;

let contact_type='presale';
let type_name='售前咨询';


function showInputView(type){
    contact_type=type
    if(type=='presale')type_name='售前咨询';
    if(type=='aftersale')type_name='后顾无忧';
    if(type=='suggest')type_name='宝贵意见';
   nowscroll=window.pageYOffset
   nowscroll=nowscroll+1
   inputViewLeft='0px'
   //console.log(nowscroll)
   window.scroll(0, nowscroll);


}
function closeInputView(event){
  nowscroll=window.pageYOffset
  if(nowscroll==0){
    nowscroll+=1
  }else{
   nowscroll-=1
 }
   if(window.innerWidth <=1124){
   inputViewLeft= -window.innerWidth
   }else{
   inputViewLeft= -window.innerWidth*0.85
   }

   window.scroll(0, nowscroll);


}

const ContactItems=(props)=>{

    return(
        <div style={{width:'100%',height:'120px',backgroundColor:'#fff',boxShadow:'0px 0px 2px #e9e9e9',marginBottom:'30px',cursor: 'pointer'}} onClick={()=>{showInputView(props.type)}}>
            <div style={{width:'10%',height:'120px',backgroundColor:'',float:'left',display:'flex',alignItems:'center',justifyContent: 'flex-end'}}><span className={props.icon} style={{fontSize:props.iconsize,margin:'20px',opacity:iconAlpha}}></span></div>
            <div style={{width:'75%',height:'120px',backgroundColor:'',float:'left'}}>
                <div className='Subtitle' style={{width:'100%',height:'50%',backgroundColor:'',alignItems: 'flex-end',display:'flex',paddingBottom:'10px'}}> {props.Msgtitle}</div>
                <div className='pageNavText' style={{width:'100%',height:'50%',backgroundColor:'',}}>{props.MsgDiscript}</div>

            </div>
            <div style={{width:'15%',height:'120px',backgroundColor:'',float:'left',display:'flex',alignItems:'center',justifyContent: 'center',}}> <img src={require('../asset/arrowright.png')}   style={{width:'40%'}}  /></div>
        </div>
    )

}




const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(fullfooter())
        dispatch(fullmenu())
        dispatch(navContact())
        // console.log(minfooter())
    }
})



class Contact extends React.Component{

    constructor(){
        super()
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            contact_type:'',
            error:false,
            errorContent:'',
            contact_data:{
                type:'',
                name:'',
                email:'',
                message:'',
                mobile:'',
                pass_code:'',

            },
            pass_code_btn:'0px',
            Modalvisible:false,

        }

        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.input_content_contact =this.input_content_contact.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this)
        this.submitMessage =this.submitMessage.bind(this)
        this.send_passcode =this.send_passcode.bind(this)
        this.handleCancel =this.handleCancel.bind(this)
        this.backbtn =this.backbtn.bind(this)


        this.scrolliconR='20px'

        this.centerViewbox_W='50%'

        this.QAViewbox_W='60%'


    }




    clickbtn(){
        window.scroll(0, -1000);

    }



    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentDidMount() {

        //console.log('component Mount')


    }



    componentWillMount() {

        this.props.LoadPageState();
        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){



    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        //console.log('component UnMount')
        this.backbtn()
    }


    //props update

    componentWillReceiveProps(){

    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }


    input_content_contact(target,value){

        const contactdata=this.state.contact_data;

        switch (target){
            case 'mobile':
                contactdata.mobile=value;
                this.setState({contactdata})
                break
            case 'name':
                contactdata.name=value;
                this.setState({contactdata})
                break
            case 'email':
                contactdata.email=value;
                this.setState({contactdata})
                break
            case 'pass_code':
                contactdata.pass_code=value;
                this.setState({contactdata})
                break
            case 'message':
                contactdata.message=value;
                this.setState({contactdata})
                break
            default:
        }

        if(this.state.contact_data.mobile==''){
            this.setState({pass_code_btn:'0px'})

        }else{
            this.setState({pass_code_btn:'60px'})
        }

        // console.log(this.state.contact_data)



    }

    send_passcode(){
        const post_data={
            mobile:this.state.contact_data.mobile,
        }

       if(this.state.contact_data.mobile!=''){
           Http.Send_pass(post_data).then((res)=>{
              console.log('pass sent')
           }).catch((res) => {

               this.showErrorbar(res.message)
           })
       }
    }

    submitMessage(){

        if(this.state.contact_data.name=='' || this.state.contact_data.mobile=='' || this.state.contact_data.message=='' || this.state.contact_data.email=='' || this.state.contact_data.pass_code==''){
            this.showErrorbar('请填写完整信息');

        }else{
            const post_data={
                name:this.state.contact_data.name,
                mobile:this.state.contact_data.mobile,
                email:this.state.contact_data.email,
                type:contact_type,
                message:this.state.contact_data.message,
                pass_code:this.state.contact_data.pass_code,
            }

            if(this.state.contact_data.mobile!=''){
                Http.leave_message(post_data).then((res)=>{
                    console.log('success')

                    this.setState({
                        Modalvisible:true,
                        contact_data:{
                            type:'',
                            name:'',
                            email:'',
                            message:'',
                            mobile:'',
                            pass_code:'',

                        },
                    })

                }).catch((res) => {

                    this.showErrorbar(res.message)
                })
            }
        }

    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    handleCancel(){
        console.log('Clicked cancel button');
        this.setState({
            Modalvisible: false,
        });
    }

    backbtn(){
      this.handleCancel();

        closeInputView();
    }





    render() {


        let centerViewbox_W = '50%'
        let align_contL = '70px'
        let iconboxW = '20%'
        let iconboxH = '0px'

        let scrolliconR = '20px'
        let scrolliconAlpha = 1;
        let showscrolltop = 0

        let inputcontentBarW = '60%'
        let inputcontentBarcodeW = '60%'
        let inputcontentBarcodebtnW = '30%'


        let input_viewW = '80%'
        let input_viewArrowW = '20%'


        if (window.innerWidth <= 1124) {
            centerViewbox_W = '90%'
            align_contL = '0px'
            iconboxW = '80%'
            iconboxH = '100px'
            scrolliconAlpha = 0
            iconAlpha = 0;

            input_viewW = '100%'
            input_viewArrowW = '0%'
            inputcontentBarW = '90%'

            inputcontentBarcodeW = '60%'
            inputcontentBarcodebtnW = '30%'

        } else {
            iconAlpha = 1
        }


        let contact_H = ''

        contact_H = document.getElementById("contact_menu")

        if (contact_H) {
            contact_H = contact_H.clientHeight
        }


        return (
            <div className='contactView'>
                <Nav/>

                {/*top button*/}
                <TopButton callback={() => this.clickbtn()} opacity={showscrolltop}/>

                <Modal
                    title="提交成功"
                    style={{ top: 20 }}
                    visible={this.state.Modalvisible}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                        <Text type="secondary">我们的客户经理会尽快回复您。</Text>

                        <Button type="text" onClick={()=>this.backbtn()}>返回</Button>

                    </div>
                </Modal>



                {/*input*/}


                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: contact_H,
                    backgroundColor: '',
                    left: inputViewLeft,
                    transition: '0.3s ease-out all'
                }}>

                    <div className='selfcenter_alignRight'
                         style={{width: input_viewW, height: '100%', backgroundColor: '#fff', float: 'left'}}>

                        <div className='selfcenter_alignLeft'
                             style={{backgroundColor: '', width: inputcontentBarW, height: '100%'}}>

                            <div className='selfcenter_alignLeft'
                                 style={{backgroundColor: '', width: '100%', height: '50px', paddingTop: '220px'}}>

                                <span className='Subtitle'><img src={require('../asset/flashicon.png')}  style={{height:'30px'}}/> {type_name}</span>
                            </div>

                            <div className='selfcenter_alignLeft'
                                 style={{width: '100%', height: 'auto', backgroundColor: '', paddingTop: '40px'}}>
                                <div className='pageLineS' style={{width: inputcontentBarW}}> </div>
                            </div>


                            <div className='selfcenter_alignLeft'
                                 style={{width: '100%', height: 'auto', backgroundColor: '', paddingTop: '50px'}}>

                                <Yinput width={inputcontentBarW} height='30px' name={'name'} bgColor='' value={this.state.contact_data.name} preset='姓名'
                                         limit={20} callBack={this.input_content_contact} />
                                <Yinput width={inputcontentBarW} height='30px' name={'email'} bgColor='' value={this.state.contact_data.email} preset='邮箱地址'
                                        limit={20}  callBack={this.input_content_contact}/>
                                <Ytextarea width={inputcontentBarW} height='100px' name={'message'} full={false} bgColor='' value={this.state.contact_data.message} preset='内容'
                                           limit={60} callBack={this.input_content_contact}/>
                                <Yinput width={inputcontentBarW} height='30px' name={'mobile'} bgColor='' value={this.state.contact_data.mobile} preset='+86'
                                        type='phone' limit={11} callBack={this.input_content_contact}/>

                                <div className='selfcenter_alignLeft'
                                     style={{width: inputcontentBarW, height: this.state.pass_code_btn, backgroundColor: '',overflow:"hidden",transition:'0.15s ease-out all'}}>
                                    <Yinput width={inputcontentBarcodeW} height='30px' name={'pass_code'} bgColor='' value={this.state.contact_data.pass_code} preset=''
                                            type='' limit={11} callBack={this.input_content_contact}/>
                                   <div style={{width:inputcontentBarcodebtnW,height:'30px',float:'right'}}>
                                    <VcodeBtn canClick={true} width={'100%'} borderColor={"#000"}  callBack={this.send_passcode}/>
                                   </div>
                                </div>

                                <Yibutton width={inputcontentBarW} callBack={this.submitMessage} borderColor={'#000'} height='32px' value='发送' contentfloat=''/>


                                <div className='selfcenter_alignLeft' style={{
                                    width: inputcontentBarW,
                                    height: 'auto',
                                    backgroundColor: '',
                                    paddingTop: '20px'
                                }}>
                                    <div style={{
                                        float: 'right',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        closeInputView()
                                    }}>

                                        <img src={require('../asset/arrowleft.png')} width='34px'
                                             style={{float: 'right'}}/>
                                        <span className='Subtitle'
                                              style={{color: '#000', fontSize: '14px', float: 'right'}}>取消</span>


                                    </div>
                                    {
                                        this.state.error ? (
                                            <Alert
                                                message="Error!"
                                                description={this.state.errorContent}
                                                type="error"
                                                closable
                                                onClose={this.onClose}
                                            />

                                        ) : ('')
                                    }
                                </div>

                            </div>


                        </div>

                    </div>

                    <div className='selfcenter_alignRight' style={{
                        width: input_viewArrowW,
                        height: '100%',
                        float: 'left',
                        display: 'flex',
                        backgroundColor:'',
                        justifyContent: 'flex-start',
                        opacity: 0
                    }}>
                        <img src={Tranigleright} height={'100%'} style={{height:'100%'}}/>
                    </div>


                </div>

                <div className='contact_vissionBox' id='contact_menu'>


                    <div className='selfcenter_alignLeft' style={{
                        width: centerViewbox_W,
                        minWidth: '320px',
                        height: 'auto',
                        backgroundColor: '',
                        paddingTop: '100px'
                    }}>
                        <span className='pageNavText' style={{paddingTop: '100px'}}>首页/</span>
                        <span className='pageNavText' style={{paddingTop: '', color: '#999'}}>联系我们</span>
                    </div>
                    <div className='selfcenter_alignLeft' style={{
                        width: centerViewbox_W,
                        minWidth: '320px',
                        height: 'auto',
                        backgroundColor: '',
                        paddingTop: '20px'
                    }}>
                        <div className='pageLineS' style={{width: '50px'}}></div>
                    </div>

                    <div className='selfcenter_alignLeft' style={{
                        width: centerViewbox_W,
                        minWidth: '320px',
                        height: 'auto',
                        backgroundColor: '',
                        paddingTop: '50px'
                    }}>
                        <span className='Stitle'><img src={require('../asset/flashicon.png')}  style={{height:'30px'}}/> 联系我们</span>
                    </div>


                    <div className='selfcenter_alignLeft' style={{
                        width: centerViewbox_W,
                        minWidth: '320px',
                        height: 'auto',
                        backgroundColor: '',
                        paddingTop: '50px',
                        transition: '0.15s ease-out all'
                    }}>

                        <ContactItems  type={'presale'} Msgtitle='售前咨询' icon='icon-question' iconsize='14px'
                                      MsgDiscript='写下你的需求，我们的售前顾问会第一时间联系你。'/>

                        <ContactItems  type={'aftersale'} Msgtitle='后顾无忧' icon='icon-pen' iconsize='20px'
                                      MsgDiscript='我们将为专家团队及其工作提供保障，竭力获得您的满意。'/>

                        <ContactItems  type={'suggest'}  Msgtitle='宝贵意见' icon='icon-msg' iconsize='20px'
                                      MsgDiscript='欢迎留下你的体验与建议，我们将不断提升更优质的服务。'/>
                    </div>


                    <div className='selfcenter_alignRight' style={{
                        width: centerViewbox_W,
                        minWidth: '320px',
                        height: 'auto',
                        backgroundColor: '',
                        paddingTop: '',
                        transition: '0.15s ease-out all'
                    }}>
                        <Link to={'/faq'}>
                            <span className='Subtitle' style={{color: '#f6d809', cursor: 'pointer'}}>FAQ</span>
                        </Link>
                    </div>


                </div>


            </div>
        )
    }


}



export default connect(mapStateToProps,mapDispatchToProps)(Contact) ;
