//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import logoY from '../asset/logo-y.svg';
import {Link} from "react-router-dom"
 
 
class Nav_sim extends React.Component{




  constructor(){
      super()
  	this.state ={
  		  initial:1,
        fontS:24,
        homebtn:{
            fontSize:'13px',
            fontFamily:'"Microsoft YaHei", "黑体","宋体",',
            color:'#000',
            width:'60px',
            height:'30px',
            alignItems:'center',
            display:'flex',
            justifyContent: 'center',
            backgroundColor:'#ffe600',
            borderRadius: '15px',
            textDecoration: 'none',
            transition:'0.15s ease-out all',
            cursor: 'pointer',
        }


    }
  	}


  buttonClick(event){
   //window.scrollBy(800,40)
 
  }

    mouseEnter(event) {
        //console.log('in')

        this.setState({
            homebtn:{
                fontSize:'13px',
                fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                color:'#000',
                width:'66px',
                height:'30px',
                alignItems:'center',
                display:'flex',
                justifyContent: 'center',
                backgroundColor:'#ffe600',
                borderRadius: '15px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
                cursor: 'pointer',
            }

            }
        )

    }

    mouseOut(event) {
        //console.log('out')
        this.setState({
            homebtn:{
                fontSize:'13px',
                fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                color:'#000',
                width:'60px',
                height:'30px',
                alignItems:'center',
                display:'flex',
                justifyContent: 'center',
                backgroundColor:'#ffe600',
                borderRadius: '15px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
                cursor: 'pointer',
            }
            }
        )
    }

   componentDidMount() {

 

   
  }
 


 render(){



   const navStyle={
     
      color:'#000',
       width:'60px',
       height:'30px',
       marginRight:'5%',
       marginTop:'25px',
      textDecoration: 'none',
       backgroundColor:'',

    
    }

let logoleft="4%"
let logo_width='110px'
     if (window.innerWidth <= 1124) {
         logoleft="20px"
         logo_width='110px'
     }



   return (
        <div style={{width:'100%',height:'100px',backgroundColor:'',position:'absolute',display:'flex',justifyContent:'space-between'}}>

            <div style={{width:logo_width,backgroundColor:'',marginLeft:'5%',marginTop:'22px'}}>
                <Link   to="/home">
           <img src={logoY}     alt=""  width={logo_width}  style={{padding:0}} />
                </Link>
            </div>

            <Link to='/home' style={navStyle}>
             <div style={this.state.homebtn} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }} >

              首页

            </div>
            </Link>


       </div>
   	)


}



}


export default Nav_sim