//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import Http from "./functions/ApiBridge";
import { Modal, Button, Space ,Typography} from 'antd';
import {Link} from "react-router-dom";
import {
    MenuUnfoldOutlined,
    LoadingOutlined,
    ThunderboltOutlined,

} from '@ant-design/icons';

const {Title,Text,Paragraph } = Typography;




class QAitems extends React.Component{
    constructor(){
        super()
        this.state ={
            ItemBgcolor:'#fff',
            AnswerH:0,
            unitH:0,
            Answerstate:false,
            QAicon:require('../asset/QA.png'),
            Itemfloat:'left',
            textarea_h:'auto'

        }

    }

    mouseClick(event){

        if(this.state.Answerstate){
            this.props.callback(null)
        }
        else{
            this.props.callback(this.props.id)
        }

        if(event=='n'){
            this.props.closeTab()
        }

    }


    mouseEnter(event) {
        this.setState({
            ItemBgcolor:'#ebebeb',
        })
    }

    mouseOut(event) {

        this.setState({
            ItemBgcolor:'#fff',
        })
    }

    mouseiconEnter(e){
        e.target.style.color='#f5db10'
    }

    mouseiconOut(e){
        e.target.style.color='#000'
    }

    componentDidMount(){


    }

    componentWillMount(){
        let danmH=this.props.Answer.length

        let maxchar=55
        if(window.innerWidth <1024)maxchar=20
        if(danmH>=maxchar){
            danmH=danmH/maxchar*30
        }else{
            danmH=50
        }
        this.setState({
            unitH:danmH,
            Answerstate:true,
            AnswerH:danmH,
            QAicon:require('../asset/QAhide.png'),
        })



        if(this.props.id%2==0){
            this.setState({
                Itemfloat:'left',
            })
        }else{
            this.setState({
                Itemfloat:'right',
            })
        }





    }

    textareachange(){
        console.log(12)
    }


    componentDidUpdate(){


    }

    //props update
    componentWillReceiveProps(){
        // console.log(2)





    }


    changtextheight(){
        console.log(12)
    }

    render(){

        let Ibgcolor="#fff"

        return(
            <div style={{width:this.props.Itemwidth,height:'auto',backgroundColor:'',marginLeft:'5%',
                marginRight:'5%',marginBottom:'20px',
                alignSelf:"center",
                transition:'0.15s ease-out all'}}  >
                <div style={{width:'5%',height:'24px',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center',float:'left'}}>

                    <span  className='txt03' style={{color:'#000',fontSize:24}}>{this.props.id+1}</span>
                </div>
                <div style={{width:'95%',height:'auto',backgroundColor:'',paddingBottom:10,display:'flex',justifyContent:'flex-start',alignItems:'center',float:'left',cursor: ''}}>

                    <span className='txt03' style={{color:'#000',fontSize:16,paddingLeft:'20px',textAlign:"left"}}>{this.props.Itemname}</span>


                </div>



                <div style={{width:'100%',height:'auto',backgroundColor:'',paddingLeft:'20px',paddingBottom:4}}>

                    {/*
                     <textarea style={{
                         border: 0,
                         outline: 'none',
                         width:'100%',
                         paddingLeft:'5%',
                         paddingTop:'0px',
                         height:this.state.AnswerH,
                         overflow:'hidden',
                         color:'#999',
                         resize:'none',
                         backgroundColor:"transparent",
                         fontSize:'13px'
                     }}

                               value={this.props.Answer}
                               readOnly={true}
                     >

                  </textarea>
                  */}

                  <Typography>
                      <Paragraph style={{fontSize:13,color:'#444',textAlign:"left",paddingLeft:'5%',}}>
                          {this.props.Answer}
                      </Paragraph>
                  </Typography>




                </div>

            </div>
        )

    }

}

class QandAsimpfly extends React.Component{




    constructor(){
        super()
        this.state ={
            initial:1,
            fontS:24,
            forcUpdate:false,
            itemsSata:null,
            faq_Data:null,
            loading:true,
            visible: false,
            total_list:null,
            modalvisible:false,
            unmount:false,


        }


        this.buiddata =this.buiddata.bind(this)
    }


    callback(e){

        //window.scrollBy(800,40)
        let callback_id=e
        let newitemsSata=this.state.itemsSata

        newitemsSata.map((item,key) =>{


            // change this to that inside functon
            let that=this
            //使用异步事件确保先后执行次序
            let awaitfunc = new Promise(function(resolve, reject) {
                if(key==callback_id){
                    newitemsSata[key].active=true
                }else{
                    newitemsSata[key].active=false
                }
                resolve("Success!"); // 成功
                //or
                //reject ("Error!");  // 拒绝
            });

            awaitfunc.then(function(value) {

                that.setState({
                    itemsSata:newitemsSata
                })
                console.log(value); // Success!
            }, function(reason) {
                console.log(reason); // Error!
            });






        })

    }



    componentDidMount() {

        this.get_qa_list(1)

        //window.scroll(0, -1000);




    }

    componentWillMount() {



    }

    //close component
    componentWillUnmount(){
        // 卸载异步操作设置状态
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return
        }
    }


    get_qa_list(page){
        const postdata={
            pageshow:10,
            page:page,
        }
        Http.get_faq_list(postdata).then((res)=>{

            if (res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                    faq_Data: res.data.data,
                })
            } else {
                this.setState({
                    faq_Data: res.data,
                    total_list: res.data.length,
                    current_page: 1,
                })

            }

            this.buiddata()




        }).catch((res) => {
            console.log(res.message)
        })

    }


    showModal = () => {
        this.setState({
            modalvisible: true,
        });
    };


    hideModal = () => {
        this.setState({
            modalvisible: false,
        });
    };


    buiddata(){
        let getItemsArray=[]

        getItemsArray=this.state.faq_Data

        if(this.state.faq_Data) {
            getItemsArray.map((key, index) => {
                key['active']=false
                key['index']=index
                // console.log(key)
            })


            this.setState({
                itemsSata: getItemsArray,
                loading:false,
            })

        }
    }



    render() {

        let QAitemW = '90%'
        if (window.innerWidth < 1000) {
            QAitemW = '90%'
        }

        let styles = {
            footNav: {
                fontSize: '12px',
                fontFamily: '"Microsoft YaHei", "黑体","宋体",',
                color: '#999',
                width: '28%',
                minWidth: '130px',
                height: '50px',
                margin: '5px',
                alignItems: 'left',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '',
                float: 'left',
            }
        }


        if (this.state.loading) {
            return (
                <div  style={{width: '100%', height: '500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <LoadingOutlined style={{fontSize:'20px'}} />
                </div>
            )
        } else {

            return (
                <div className="contentFrameColumn" style={{backgroundColor: '',}}>

                    <div className='selfcenter_alignLeft' style={{backgroundColor: '', width: '100%', height: '100%',textAlign:"center"}}>

                        {
                            this.state.itemsSata.map((item, key) => {
                                return <QAitems key={key} id={item.index} Itemwidth={QAitemW} Answer={item.comment_a}
                                                Itemname={item.comment_q} active={item.active} callback={(e) => {
                                    this.callback(e)
                                }}  closeTab={this.showModal}/>
                            })
                        }

                    </div>

                    <Modal
                        title="提示"
                        visible={this.state.modalvisible}
                        footer={false}
                        onCancel={this.hideModal}
                    >
                        <div style={{width:'100%',textAlign:"center"}}>
                            <p> <ThunderboltOutlined style={{color:'orange',fontSize: 16}}/> <Text type={"secondary"}>没有解答您的问题？马上联系我们获取更多帮助。</Text></p>

                            <p>
                                <Link to={'/contact'} >
                                    <Button type={"text"}>确定</Button>
                                </Link>

                            </p>

                        </div>
                    </Modal>


                </div>
            )


        }

    }

}


export default QandAsimpfly