//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import { Modal, Button, Space ,Typography} from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
const {Title,Text,Paragraph } = Typography;


class QAitems extends React.Component{
  constructor(props){
    super(props)
    this.state ={
        ItemBgcolor:'#fff',
        AnswerH:0,
        unitH:0,
        Answerstate:false,
        QAicon:require('../asset/QA.png'),
        Itemfloat:'left',
        modalvisible: true,

       }

        
  
    }

    mouseClick(event){


      if(this.state.Answerstate){
           this.props.callback(null)
         }
      else{
          this.props.callback(this.props.id)
      }

      if(event=='n'){
          this.props.closeTab()
      }

     
     
     }


   mouseEnter(event) {
     this.setState({
      ItemBgcolor:'#ebebeb',
     })
  }

  mouseOut(event) {
    
    this.setState({
      ItemBgcolor:'#fff',
     })
  } 

  mouseiconEnter(e){
     e.target.style.color='#f5db10'
  }

   mouseiconOut(e){
     e.target.style.color='#000'
  }

  componentDidMount(){
      

  }

  componentWillMount(){

      let danmH=this.props.Answer.length

       if(danmH>=20){
            danmH=danmH/20*20+80
          }else{
            danmH=90
          }
       this.setState({
         unitH:danmH,
       })
      

      if(this.props.id%2==0){
        this.setState({
         Itemfloat:'left',
        })
      }else{
         this.setState({
         Itemfloat:'right',
        })
      }

      
  }


   componentDidUpdate(){

  
  }

 //props update
  componentWillReceiveProps(){
      
      

      if(this.props.active){
      this.setState({
          Answerstate:true,
          AnswerH:this.state.unitH,
          QAicon:require('../asset/QAhide.png'),
       })
    }else{
      this.setState({
          Answerstate:false,
          AnswerH:0,
          QAicon:require('../asset/QA.png'),
       })
    }
    
  }

 render(){
 
  let Ibgcolor="#fff"

  return(
      <div style={{width:this.props.Itemwidth,height:'auto',backgroundColor:this.state.ItemBgcolor,marginLeft:'5%',
           marginRight:'5%',marginBottom:'20px',float:this.state.Itemfloat,boxShadow:'0px 0px 2px #e3e3e3',
           transition:'0.15s ease-out all'}}  >

         <div style={{width:'80%',height:'80px',backgroundColor:'',display:'flex',justifyContent:'flex-start',alignItems:'center',float:'left',cursor: 'pointer'}}
            onClick={()=>{this.mouseClick()}}
            onMouseEnter={()=>{this.mouseEnter()}}  onMouseLeave={()=>{this.mouseOut()}}  >

            <span className='contentText' style={{paddingLeft:'20px'}}>{this.props.Itemname}</span>
         </div>  

         <div style={{width:'20%',height:'80px',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center',float:'left'}}
           onClick={()=>{this.mouseClick()}}>
            <img src={this.state.QAicon}       style={{backgroundColor:'',width:'40%',minWidth:'30px'}} />
         </div> 

         <div style={{width:'100%',height:this.state.AnswerH,backgroundColor:'#f5f5f5',display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'0px',transition:'0.15s ease-out all'}}>
             <div style={{width:'100%',height:'98%',marginTop:'5px',backgroundColor:'#fff',overflow:'hidden',display:'flex',flexDirection:'column'}}>

                  <textarea style={{
                      border: 0,
                      outline: 'none',
                      width:'94%',
                      paddingLeft:'20px',
                      paddingTop:'20px',
                      height:'100%',
                      overflow:'hidden',
                      color:'#999',
                      resize:'none',
                      backgroundColor:'',
                      fontSize:'12px'

                  }}

                            value={this.props.Answer}

                            readOnly={true}
                          >

                  </textarea>

                  <div   style={{alignItems: 'flex-end',width:'100%',height:'50px',marginTop:'0px',display:"flex",flexDirection:"row-reverse",verticalAlign:"bottom"}}>
                    <span className='icon-wrong' style={{padding:'20px',fontWeight: 'bold',cursor: 'pointer'}} onClick={()=>{this.mouseClick('n')}}  onMouseEnter={(e)=>{this.mouseiconEnter(e)}} onMouseLeave={(e)=>{this.mouseiconOut(e)}}> </span>
                    <span className='icon-right' style={{padding:'20px',fontSize:'20px',fontWeight: 'bold',cursor: 'pointer'}} onClick={()=>{this.mouseClick('y')}} onMouseEnter={(e)=>{this.mouseiconEnter(e)}} onMouseLeave={(e)=>{this.mouseiconOut(e)}}> </span>
                  </div>
             </div>
            

         </div>


 
      </div>
    )

}

}
 
class QandA extends React.Component{




  constructor(props){
      super(props)
  	this.state ={
  		  initial:1,
        fontS:24,
        forcUpdate:false,
        itemsSata:null,
        loading:true,
        modalvisible:false,
        
       }
  	}


  callback(e){
   //window.scrollBy(800,40)
    let callback_id=e
    let newitemsSata=this.state.itemsSata
     
        newitemsSata.map((item,key) =>{


        // change this to that inside functon   
         let that=this
        //使用异步事件确保先后执行次序
         let awaitfunc = new Promise(function(resolve, reject) {
             if(key==callback_id){
                newitemsSata[key].active=true
             }else{
                newitemsSata[key].active=false
             }
           resolve("Success!"); // 成功
           //or
           //reject ("Error!");  // 拒绝
           });

           awaitfunc.then(function(value) {
        
            that.setState({
             itemsSata:newitemsSata
            })
            console.log(value); // Success!
            }, function(reason) {
            console.log(reason); // Error!
            });

                
           

           

         })
   
  } 

  

   componentDidMount() {

 
     
     //window.scroll(0, -1000);



   
  }

  componentWillMount() {

 let getItemsArray=[
{id:0,active:false,discription:'如何开始我的项目',answer:'问题回答答案问题回答答案问题回答答案问题回答答案问题回答答案'},
{id:1,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:2,active:false,discription:'如何开始我的项目',answer:'问题回答答案问题回答答案问题回答答案问题回答答案问题回答答案问题回答答案回答答案问题回答答案问题回答答案'},
{id:3,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:4,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:5,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:6,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:7,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:8,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:9,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:10,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
{id:11,active:false,discription:'如何开始我的项目',answer:'问题回答答案'},
]



      getItemsArray=this.props.data

if( this.props.data) {
    getItemsArray.map((key, index) => {
        key['active']=false
        key['index']=index
       // console.log(key)
    })


    this.setState({
        itemsSata: getItemsArray,
        loading:false,
    })

}


    
  //setState is 异步 function   
  //console.log(this.state.itemsSata)  

}

    showModal = () => {
        this.setState({
            modalvisible: true,
        });
    };


    hideModal = () => {
        this.setState({
            modalvisible: false,
        });
    };
 


 render(){

   let QAitemW='40%'
  if(window.innerWidth <1000){
     QAitemW='90%'
  }

 	let styles={
 		footNav:{
          fontSize:'12px',
          fontFamily:'"Microsoft YaHei", "黑体","宋体",',
          color:'#999',
          width:'28%',
          minWidth:'130px',
          height:'50px',
          margin:'5px',
          alignItems:'left',
          display:'flex',
          justifyContent: 'center',
          backgroundColor:'',
          float:'left',
 		} 
 	}



if(this.state.loading){
    return (
        <div style={{width:'100%',height:'600px'}}></div>
    )
}else {

    return (
        <div className="contentFrameColumn" style={{backgroundColor: '',}}>

            <div className='selfcenter_alignLeft' style={{backgroundColor: '', width: '100%', height: '100%'}}>

                {
                    this.state.itemsSata.map((item, key) => {
                        return <QAitems key={key} id={item.index} Itemwidth={QAitemW} Answer={item.comment_a}
                                        Itemname={item.comment_q} active={item.active} callback={(e) => {
                            this.callback(e)
                        }}  closeTab={this.showModal}/>
                    })
                }

            </div>

            <Modal
                title="提示"
                visible={this.state.modalvisible}
                footer={false}
                onCancel={this.hideModal}
            >
                <div style={{width:'100%',textAlign:"center"}}>
                    <p> <ThunderboltOutlined style={{color:'orange',fontSize: 16}}/> <Text type={"secondary"}>没有解答您的问题？马上联系我们获取更多帮助。</Text></p>

                    <p>
                        <Link to={'/contact'} >
                        <Button type={"text"}>确定</Button>
                        </Link>

                    </p>

                </div>
            </Modal>

        </div>
    )
}

}



}


export default QandA