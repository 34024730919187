
/**
 *yi 2020
 *
 *REDUCER  change state  判断action type
 */

const navstateReducer=(state='OTHERS',action)=>{
    // console.log('reducer func call : '+action.type)
    switch(action.type){
        case "HOME":
            // console.log('reducer match :'+action.type)
            return 'HOME';
        case "ABOUT":
            //  console.log('reducer match :'+action.type)
            return 'ABOUT';
        case "SOLUTION":
            //  console.log('reducer match :'+action.type)
            return 'SOLUTION';
        case "CONTACT":
            //  console.log('reducer match :'+action.type)
            return 'CONTACT';
        case "OTHERS":
            //  console.log('reducer match :'+action.type)
            return 'OTHERS';
        case "WHAT":
            //  console.log('reducer match :'+action.type)
            return 'WHAT';
        default:
            //   console.log('reducer match nothing:')
            return state;
    }
}

export  default navstateReducer