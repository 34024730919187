//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import {Link} from "react-router-dom" 

 
 
class ButtonWhite extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initial:1,
      buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#000',
            color:'#000',
             
           
      },
      buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            height:'45px',
            float:'left',
            backgroundColor:'#f6d808',
      },
  	}

  	this.changevalue =this.changevalue.bind(this)
    
  }

  changevalue(){
  	//this.setState({initial:2})
  	this.setState(prevState=>{
  		return{
  			initial:prevState.initial +1
  		}
  	})
  }

  mouseEnter(event) {
   //console.log('in')

   this.setState({
    buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#000',
            color:'#fff',
             
            
      },
     buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'180px',
            float:'left',
            height:'45px',
            backgroundColor:'#000',
      },  
   }
    )
   
  }

  mouseOut(event) {
   //console.log('out')
     this.setState({
    buttonContent:{
            transition:'0.15s ease-out all',
            width:'180px',
            height:'45px',
            backgroundColor:'',
             borderStyle: 'solid',
            borderWidth: '1px',
            borderColor:'#000',
            color:'#000',
            
            
      },
     buttoninsidebg:{
            transition:'0.15s ease-out all',
            width:'0px',
            float:'left',
            height:'45px',
            backgroundColor:'#000',
      },  
   }
    )
  }
  mouseDown(event){

  }

  render(){

   const styles={
    buttonViewbox:{
      display:'flex',
      alignItems: 'center',
      width:'200px',
      height:'50px',
   
    },
    buttonInfo:{
 
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center', 
      verticalAlign:'center', 
      textAlign:'center',
      width:'180px',
      height:'45px',
      position:'absolute',
  
    }

  }

   return(
     <div style={this.state.buttonContent}  onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }}>
     <div style={styles.buttonInfo} className='textMin_content'>
      {this.props.ButtonName}  
     </div>  
     <div  style={this.state.buttoninsidebg} >
    
     </div>
     
     </div>
    )

  }
}



export default ButtonWhite