//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import {Link} from "react-router-dom" 
import ButtonYellow from '../components/ButtonYellow'

 
 
class ServiceInfo extends React.Component{




  constructor(){
  	super()
  	this.state ={
  		initall:0,
       
  	}

    
    this.changeframeBoxalpha =this.changeframeBoxalpha.bind(this)

 
    
   
  }

  componentDidMount() {
 
   
   
  }


  componentDidUpdate(){

   

  }


  changeframeBoxalpha(){
    
     

 
  }

 

  mouseEnter(event) {
   console.log('in')

   
  }

  mouseOut(event) {
   //console.log('out')
   
  }
  mouseDown(event){

  }

  render(){
 
   let styles={
 
    frameContent:{
            width:'100%',
            height:'100%',
            backgroundColor:'',
            borderStyle: 'solid',
            borderWidth: '0px',
            borderColor:'#000',
            color:'#000',
            textAlign:'left',
            opacity:1,
            
          },
     numFont:{
         fontSize:'60px',
         fontWeight:'blod',
         color:'#f6d808',
         fontFamily:'"Arial","Tahoma","黑体","宋体',
         paddingLeft:'80px',
         position:'absolute',
     }       



  }

  
  const winW=window.innerWidth;

   if(winW <= 1124){
     styles.frameContent.width='320px'
      
     }

    



   return(
     <div style={styles.frameContent}  >

         
           <div className='contentFrame_alignR' style={{backgroundColor:'',position:'',paddingTop:'0px',width:'100%',height:'100%'}} >
   


            <div className='contentFrame_alignL' style={{width:'100%',height:'100%',backgroundColor:''}}>
              <div style={styles.numFont}>{this.props.Num}</div>
              <span className='Stitle' style={{position:'absolute',paddingTop:'25px'}}>{this.props.Title}</span>
              <span className='Subtitle' style={{paddingTop:'60px'}}>{this.props.subTitle}</span>

              <div className='ScontentText' style={{paddingTop:'30px'}}>{this.props.disContent}</div>
              
              <div className='alignBottmR' style={{height:'100%'}}>
              <ButtonYellow ButtonName='马上开始'/> 
              </div>
            </div>
            
           </div>  
          
 
          
              

     </div>
    )

  }
}



export default ServiceInfo