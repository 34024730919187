/*
 * yi 2020
 *
 *
 */

import React from 'react';
import NextButton from "../components/NextButton";
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import VcodeBtn from "../components/60slimitbutton";
import PreButton from "../components/PreButton";







/*
* choose registration account type
*/


class IntroductFL extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,

        }


    }


    render(){

        const Typestyles={
            bubbo:{
                width:'80px',
                height:'80px',
                alignItems:'center',
                display:'flex',
                margin:'25px',
                justifyContent: 'center',
                backgroundColor:'#f5db10',
                borderRadius: '40px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
            }
        }

        let min_height="20vh";
        let centerboxW="500px";

        let max_height='auto';
        let next_bar_h='40vh';
        let centerboxMinW='430px';
        let topbarboxH="200px";
        let textareaW='100%';


        if(window.innerWidth<1124){
            min_height="40%";
            centerboxW="90%";

            max_height='auto'
            next_bar_h='auto';
            centerboxMinW='';
            topbarboxH="400px";
            textareaW='100%';
        }


        const contact_type=['不限','微信','电话','邮件']

        return(
            <div style={{width:'100%',height:max_height,minHeight:'100vh',overflow: 'hidden',backgroundColor:'',}}>
                <div style={{width:'100%',height:topbarboxH,backgroundColor:'#f6d809',alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>

                    <div    style={{width:"80%",alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>
                        <span className="ATitle" style={{paddingTop:'50px',alignSelf:'center'}}>加入快平方</span>

                        <span className="contentText_center" style={{paddingTop:'20px'}}></span>
                    </div>

                </div>
                <div className='contentFrameBox' style={{backgroundColor:'#EFEFEF',paddingTop:'0px'}}>
                    <div className='triangleDown_Yellow'>  </div>
                </div>





                <div  className="items_placeCenter_W" style={{height:'auto'}}>
                    <div  style={{backgroundColor:"",width:centerboxW,height:'100%',paddingTop:'20px'}}>

                       <div style={{float: 'left', display: 'flex', paddingBottom: '0px'}}>
                            <img src={require('../asset/flashicon.png')} style={{width: '25px',height:'25px'}}/>
                            <span className="Subtitle">自我介绍</span>
                       </div>


                        <div className='pageLineS' style={{paddingTop:'35px',width:textareaW}}> </div>
                        <div style={{height:'30px'}}> </div>


                        <Ytextarea width={'100%'} height='200px' name={'introduce'} full={true}  callBack={this.props.brideg.action} bgColor='#EFEFEF' value='' preset=' ' limit={250}/>



                        <Yinput width={textareaW} height='30px' name={'profile_url'}  callBack={this.props.brideg.action} bgColor='#EFEFEF' value='' preset='作品URL(选填)' limit={40}/>

                        <Yinput width='100%' height='30px' type='select' name={'contact_type'}  callBack={this.props.brideg.action} bgColor='#EFEFEF' value={contact_type}  preset='您比较建议的联络方式' limit={20}/>

                        <div className='inputText'>

                            <Yinput type='file' filesize={'5500'} filetype={'image/jpeg'}  name='uploadfileInput' id='uploadfileInput' preset={'上传您的个人作品.pdf格式文件不大于5M'}  callBack={this.props.brideg.action} />
                            <Yibutton width={textareaW} height='32px' value='上传' uploadbindID={'uploadfileInput'} contentfloat='' name={'uploadfile'} callBack={this.props.brideg.action}  />
                        </div>

                    </div>
                </div>




                <div  className="items_placeCenter_W"  style={{backgroundColor:''}}>

                    <div style={{width:'80%',backgroundColor:"",height:'150px',paddingTop:'70px',display:'flex',justifyContent:"space-between"}}>
                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <PreButton callBack={()=>this.props.brideg.callBack('pre')} />


                        </div>

                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <img src={require('../asset/step3.png')}     style={{minWidth:'60px',width:'3vw'}}  />


                        </div>

                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <NextButton callBack={()=>this.props.brideg.callBack('nex')} />


                        </div>
                    </div>

                </div>

            </div>
        )
    }
}


export default IntroductFL;