/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import TopButton from "../components/TopButton";
import {connect} from "react-redux";
import  '../components/global'
import {Link} from "react-router-dom"
import {fullfooter, minfooter, minmenu, navHome} from "../reduceractions";
import Nav_sim from "../components/nav_sim";

import {Calendar,Typography, Steps, Button, message, Modal, Divider, Space} from 'antd';
import {ExclamationCircleOutlined, SolutionOutlined, SettingOutlined, FieldTimeOutlined ,CheckCircleOutlined,AlertOutlined} from '@ant-design/icons';


const { Step } = Steps;

const { Text,Title } = Typography;


/*
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})


let topbarboxH="200px";
let AtitleSize=25;


//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(minfooter())
        dispatch(minmenu())
        dispatch(navHome())
        // console.log(minfooter())
    }
})


class Errorpage extends React.Component {



    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            current: 0,
            pass_code_btn:'0px',
            mobile:'',
            email:'',
            name:'',
            title:'',
            company:'',
            brief_date:'',
            brief_time:'10:00',
            pass_code:'',
            error:false,
            errorContent:'',
            code:'',
            setpsw:'',
            repsw:'',

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.onClose =this.onClose.bind(this)



        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    handleResize(e){
        if(window.innerWidth<1124){

            topbarboxH="200px";
            AtitleSize=20;
        }


    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState();

        //

        this.handleResize()
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize.bind(this));
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        window.removeEventListener('resize', this.handleResize.bind(this));
    }


    //props update

    componentWillReceiveProps(){



    }



    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            error:true,
            errorContent:error,
        })
    }







    render(){


        return(


            <div  className='contentView'>
                <Nav_sim />
                <div style={{width:'100%',height:'90vh',backgroundColor:'#efefef',alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>
                  <Space>

                    <AlertOutlined style={{fontSize:30}} />
                      <Title  style={{paddingTop:20}} level={1}>404</Title>

                  </Space>
                    <br/> <br/>
                    <Text type={"secondary"}>您所访问的页面不存在</Text>


                </div>









            </div>
        )

    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Errorpage) ;