/**
 * yi 2020
 *
 *
 */

import React from 'react';
import Http from "../../components/functions/Adminbridge";
import {Result,Button,Alert,Select,Space,Input,Steps ,Typography ,Divider,Pagination,DatePicker} from 'antd';

import {
    MenuUnfoldOutlined,
    LoadingOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {Yinput} from "../../components/Formelements";


const { Step } = Steps;
const { Text,Title } = Typography;
const { Option } = Select;



class Createacount extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,
            order_list:null,
            total_list:null,
            current_page:null,
            loading:true,
            error:false,
            errorContent:'',
            calling_time_ar:['10:00','10:30','11:00','11:30','01:00','01:30','02:00','03:30'],
            user_pro_data:false,
            user_exp_data:false,
            user_data:{
                name:'',
                type:'cl',
                email:'',
                mobile:'',
                wechat_id:'',
                company:'',
                title:'',
                department:'',
                service:'',
                industry:'',
                deliver:'',
                brief_call:'',
                calling_time:'10:00',
                remark:'',
                password:'',
                repasswrod:'',

            },
            showresult:false,



        }


        this.inputChannge =this.inputChannge.bind(this);

        this.serviceSelectChange =this.serviceSelectChange.bind(this);
        this.industrySelectChange =this.industrySelectChange.bind(this);
        this.deliverChannge =this.deliverChannge.bind(this);
        this.calldateChannge =this.calldateChannge.bind(this);
        this.calltimeChannge =this.calltimeChannge.bind(this);
        this.onClose =this.onClose.bind(this);
        this.submitOrder =this.submitOrder.bind(this);
        this.checkProject =this.checkProject.bind(this);
        this.inputChanngep =this.inputChanngep.bind(this);


    }

    componentDidMount() {


        this.setState({loading:false})




    }

    componentWillMount() {







    }



    inputChannge(e){

        const val=e.target.value;
        const user_Data=this.state.user_data
       // console.log(e.target.id+'/'+val)
        switch (e.target.id){
            case 'name':
                user_Data.name=val
                break
            case 'email':
                user_Data.email=val
                break
            case 'mobile':
                user_Data.mobile=val
                break
            case 'company':
                user_Data.company=val
                break
            case 'title':
                user_Data.title=val
                break


            default:

        }


        this.setState({user_Data})
       // console.log(user_Data)
    }

    inputChanngep(na,value){
        const user_Data=this.state.user_data
        switch (na){
            case 'setpassword':

                user_Data.password=value
                break
            case 'repasswrod':

                user_Data.repasswrod=value
                break
            case 'counttype':
                if(value=='CLIENT') {
                    user_Data.type = 'cl'
                }else if(value=='ADMIN'){
                    user_Data.type = 'admin'
                }
                break
            default:

        }
        this.setState({user_Data})

    }




    deliverChannge(date, dateString){
        //console.log(dateString)
        const user_Data=this.state.user_data
        user_Data.deliver=dateString
        this.setState({user_Data})
    }

    calldateChannge(date, dateString){
        const user_Data=this.state.user_data
        user_Data.brief_call=dateString
        this.setState({user_Data})
    }
    calltimeChannge(value){
        const user_Data=this.state.user_data
        user_Data.calling_time=value
        this.setState({user_Data})
    }

    serviceSelectChange(value){
        const user_Data=this.state.user_data
        user_Data.service=value
        this.setState({user_Data})
    }

    industrySelectChange(value){
        const user_Data=this.state.user_data
        user_Data.industry=value
        this.setState({user_Data})
    }



    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };


    submitOrder(){

        const user_Data=this.state.user_data;

        const targeta=['company','email','mobile','name','title'];//判断数组
        let canpass=true;
        let that=this

       // console.log(user_Data.password)

        Object.keys(user_Data).map(key =>
            {
                //查找是否在判断条件数组里
                if(targeta.indexOf(key)>-1){
                    if(user_Data[key]==''){

                        that.setState({
                            error:true,
                            errorContent:'请填写完整信息！',
                        })
                        canpass =false;
                        console.log(user_Data[key]);

                    }
                }

            }
        );

        if(canpass) {



                if(user_Data.password ==''){

                    this.setState({ error:true,  errorContent:'请设定密码！',})
                }else if(user_Data.repasswrod ==''){

                    this.setState({ error:true,  errorContent:'请重复密码',})
                }else{

                    if(user_Data.password != user_Data.repasswrod) {

                        this.setState({ error:true,  errorContent:'两次密码不一致',})

                    }else{

                        this.setState({error: false})



                        Http.Creat_user(user_Data).then((res) => {


                            this.setState({showresult: true})

                        }).catch((res) => {
                            this.setState({error: true, errorContent: res.message,})

                        })


                    }

                }

        }

    }

    checkProject(){
        this.props.callBack(7)
    }



    render() {


        const  account_type=['CLIENT','ADMIN'];


        if (this.state.loading ) {
            return (
                <div  style={{width: '100%', height: '500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <LoadingOutlined style={{fontSize:'20px'}} />
                </div>
            );

        } else {

            if (this.state.showresult) {
                return(

                    <div style={{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#fff',
                        padding: '0px',
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        <Result
                            status="success"
                            title="用户创建成功!"
                            subTitle="请牢记用户名及密码"
                            extra={[


                                <Button key={'back'} type="text" onClick={this.checkProject}>返回</Button>
                            ]}
                        />,


                    </div>

                )
            } else {
                return (
                    <div style={{width: '100%', padding: '0px'}}>

                        {
                            this.state.error ? (
                                <div style={{paddingBottom:10}}>
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />
                                </div>

                            ) : ('')
                        }

                        <div style={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            padding: '0px',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            <div style={{width: 'auto', padding: '50px', fontSize: '13px'}}>


                                <Space direction="vertical" size={"middle"} style={{width: '400px'}}>
                                    <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>创建用户</Title>
                                    <Divider/>
                                    <Text type="secondary">填写用户基本资料</Text>

                                    <div style={{height:30,paddingBottom:0}}>
                                    <Yinput width='100%' height='30px' type='select' bgColor='#fff' value={account_type} name={'counttype'}
                                            callBack={this.inputChanngep} broderColor={'#b0b0b0'}
                                            preset='账号类型' limit={20}/>
                                    </div>

                                    <Input id='name' style={{fontSize: '13px'}} placeholder="姓名"
                                           onChange={this.inputChannge}/>
                                    <Input id='email' style={{fontSize: '13px'}} placeholder="邮箱"
                                           onChange={this.inputChannge}/>
                                    <Input id='mobile' style={{fontSize: '13px'}} placeholder="电话"
                                           onChange={this.inputChannge}/>
                                    <Input id='company' style={{fontSize: '13px'}} placeholder="企业名称"
                                           onChange={this.inputChannge}/>
                                    <Input id='title' style={{fontSize: '13px'}} placeholder="职位"
                                           onChange={this.inputChannge}/>

                                    <Text type="secondary">密码需包含数字及字母</Text>


                                    <div style={{height:30,paddingBottom:0}}>
                                    <Yinput width='100%' height='30px' name='setpassword' broderColor={'#999'}  callBack={this.inputChanngep}
                                            type='password' bgColor='#fff' state={this.state.user_data.password}  value={this.state.user_data.password} preset='密码' limit={20}/>
                                    </div>
                                    <div style={{height:30,paddingBottom:0}}>
                                    <Yinput width='100%' height='30px' name='repasswrod'  broderColor={'#999'} callBack={this.inputChanngep} type='password'
                                            bgColor='#fff' state={this.state.user_data.repasswrod}  value={this.state.user_data.repasswrod} preset='确认密码' limit={20}/>
                                    </div>



                                    <Divider/>
                                    <Button type="text" onClick={this.submitOrder}>提交</Button>

                                </Space>
                            </div>

                        </div>


                    </div>
                )
            }
        }
    }
}


export default Createacount;