/**
 *  yi 2020
 * API bridge
 */

import axios from 'axios';
import '../global'
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";


const Http = {};


/**
 *
 * check token
 */

function Checktoken (){



    const access_token=  cookie.load('YiToken')



    if(access_token == 'undefined' || access_token == undefined){

        return "null token"
    }else {

        const decoded = jwt_decode(access_token)

        //jwt 用的是秒作为exp单位  有时候要转化为毫秒

        const expdate = new Date(decoded.exp * 1000)
        const exptimestamp = decoded.exp * 1000
        const nowtimestamp = (new Date()).valueOf()

        //要和服务器过期时间同步
        const exptimedis = 20

        if ((nowtimestamp - exptimestamp) / 1000 > exptimedis) {

            console.log('Token need refresh Expired')
            Http.Refresh_token();
            return access_token;

        } else if((nowtimestamp - exptimestamp) / 1000 < exptimedis) {
              console.log('Token in used')
            return access_token;
        }else{
            return 'error'
        }
    }


}

/**
 * refresh token
 * @Token
 */

Http.Refresh_token=()=>{
    const access_token= {
        token:cookie.load('YiToken')

    }
    axios.post(global.gData.api_domain+'/api/auth/refresh', access_token)
        .then(function (response) {

            cookie.save('YiToken',response.data.access_token,{path:"/"})
            console.log('refresh token success')


        })
        .catch(function (error) {
            let statuscode=error.response.status
            cookie.remove('YiToken')
            console.log(global.codeMessage[statuscode]);

        });
}





/**
 * 获取oss文件临时连接
 * @Token
 */

Http.Get_oss_file = (postdata) =>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            osskey:postdata.osskey,

        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_oss_file',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * 更新作品文件
 * @Token
 */

Http.Update_profile_file = (postdata) =>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            profile_file:postdata.profile_file,

        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/update_profile_file',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 更新个人资料
 * @Token
 */

Http.Update_user_detail = (postdata) =>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            family_name:postdata.family_name,
            clients:postdata.clients,
            awards:postdata.awards,
            daily_cost:postdata.daily_cost,
            province:postdata.province,
            city:postdata.city,
            area:postdata.area,
            working_years:postdata.working_years,
            working_hours:postdata.working_hours,
            contact_type:postdata.contact_type,
            title:postdata.title,
            introduce:postdata.introduce,
            profile_url:postdata.profile_url,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/update_user_detail',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 更新作品文件url
 * @Token
 */

Http.Update_profile_url = (postdata) =>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            profile_url:postdata.profile_url,

        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/update_profile_url',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * get article
 *
 */


Http.get_articles=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_articles',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * get overview info
 *
 */


Http.user_overview_info=()=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/user_overview_info',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * 创建订单 cl type
 * @Token
 */

Http.Creat_order = (userdata) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');

        const access_token=  Checktoken();
        const data={
            token:access_token,
            name:userdata.name,
            email:userdata.email,
            mobile:userdata.mobile,
            wechat_id:userdata.wechat_id,
            company:userdata.company,
            order_name:userdata.order_name,
            department:userdata.department,
            deliver:userdata.deliver,
            brief_call:userdata.brief_call,
            calling_time:userdata.calling_time,
            remark:userdata.remark,
            service:userdata.service,
            title:userdata.title,
            industry:userdata.industry,
            ip_address:'false',
        }
        console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/create_order',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 更改企业名称
 * @Token
 */

Http.Change_company=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            company:postdata.company,
            pass_code:postdata.pass_code,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/change_company',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}
/**
 * 更改密码
 * @Token
 */

Http.Change_psw=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            password:postdata.oldpassword,
            newpassword:postdata.newpassword,
            pass_code:postdata.pass_code,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/change_psw',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 获取私有验证码
 * @Token
 */

Http.Private_pass=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/sms_pass',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 修改微信id
 * @Token
 */

Http.Change_wechatid=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            wechat_id:postdata.wechat_id,
            pass_code:postdata.pass_code,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/change_wechatid',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}





/**
 * message readed
 * @Token
 */

Http.message_readed=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            id:postdata.id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/user_message_readed',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 *
 * get user message
 *@Token
 */

Http.get_message_user=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            page:postdata.page,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_message_user',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 获取订单
 * @Token
 */

Http.get_Order_list=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            page:postdata.page,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/get_order_list',
            data: data
        }).then((res) => {
                resSuccess(resolve, rejiect, res)

        }).catch((error) => {

            rejiect(error)
        })
    })
}

/**
 * get order event
 * @Token
 */

Http.user_get_order_event=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            order_id:postdata.order_id,
        }


        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/user_get_order_event',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 *
 * create faq
 */

Http.create_faq=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            comment_a:postdata.comment_a,
            comment_q:postdata.comment_q,
            type:'qa',
            level:3,
            belong:1,
        }
        //console.log(data)get_Order_list
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/create_qa',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 *
 * edit faq
 */

Http.edit_faq=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();

        const data={
            token:access_token,
            comment_a:postdata.comment_a,
            comment_q:postdata.comment_q,
            state:postdata.state,
            id:postdata.id,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/edit_faq_item',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}




/**
 * 获取用户信息
 * @Token
 */

Http.get_Profile=()=>{
    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/profile',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 修改手机号码
 * @Token
 */

Http.Change_mobile = (postdata) =>{

    return new Promise((resolve, rejiect) => {
        const access_token=  Checktoken();
        const data={
            token:access_token,
            mobile:postdata.mobile,
            pass_code:postdata.pass_code,

        }
       // console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/v1/change_mobile',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 *
 *
 *  public function
 *
 *
 *
 *
 */



/**
 *
 *  leave form check
 */


Http.pass_check_leave_form=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            mobile:postdata.mobile,
            pass_code:postdata.pass_code,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/pass_check_leave_form',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * check leave from booking
 */


Http.check_leave_form_booking=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            date:postdata.date,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/check_leave_form_booking',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 *
 *  leave form submit
 */


Http.submit_leave_form=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            name:postdata.name,
            email:postdata.email,
            mobile:postdata.mobile,
            title:postdata.title,
            company:postdata.company,
            request_date:postdata.brief_date,
            remark:postdata.brief_time,
            service:postdata.service,
            industry:postdata.industry,
            deliver:postdata.deliver,
            code:postdata.code,
        }
        console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/submit_leave_form',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 *
 * call reset psw
 */


Http.call_reset_psw=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            mobile:postdata.mobile,
            pass_code:postdata.pass_code,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/call_reset_psw',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 *
 *  reset psw
 */


Http.reset_psw_mobile=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            mobile:postdata.mobile,
            new_password:postdata.psw,
            code:postdata.code,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/reset_psw_mobile',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}



/**
 * 获取faq
 *
 */

Http.get_faq_list=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            pageshow:postdata.pageshow,
            page:postdata.page,
        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/get_faq_list',
            data: data
        }).then((res) => {

            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}




/**
 * 用户留言
 *
 */

Http.leave_message=(postdata)=>{
    return new Promise((resolve, rejiect) => {
        const data={
            mobile:postdata.mobile,
            type:postdata.type,
            email:postdata.email,
            name:postdata.name,
            msg_content:postdata.message,
            pass_code:postdata.pass_code,

        }
        console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/leave_msg',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 发送验证码
 *
 */

Http.Send_pass = (postdata) =>{

    return new Promise((resolve, rejiect) => {

        const data={
            mobile:postdata.mobile,
            type:'pass',

        }
        //console.log(data)
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/sms_pass',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 创建用户 cl type
 */

Http.Get_tag_list = (id) => {
    return new Promise((resolve, rejiect) => {
        const data={
            id:id,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/gettags',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 创建用户 cl type
 */

Http.User_cl_submit_creat = (userdata) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');


        const data={
            name:userdata.name,
            type:userdata.type,
            email:userdata.email,
            password:userdata.password,
            mobile:userdata.mobile,
            passcode:userdata.passcode,
            terms:userdata.terms,
            family_name:userdata.name,
            wechat_id:userdata.wechatid,
            open_id:'false',
            address:'false',
            company:userdata.company,
            order_name:userdata.order_name,
            department:userdata.department,
            deliver:userdata.deliver,
            brief_call:userdata.brief_call,
            calling_time:userdata.calling_time,
            remark:userdata.remark,
            country:'China',
            province:userdata.province,
            city:userdata.city,
            area:userdata.area,
            title:userdata.title,
            service:userdata.service,
            industry:userdata.industry,
            working_years:userdata.working_years,
            working_hours:userdata.working_hours,
            clients:userdata.clients,
            awards:userdata.awards,
            daily_cost:userdata.daily_cost,
            user_pro:userdata.user_pro,
            user_expr:userdata.user_exp,
            introduce:userdata.introduce,
            profile_url:userdata.profile_url,
            contact_type:userdata.contact_type,
            profile_file:userdata.profile_file,
            ip_address:'false',
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/registration',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}


/**
 * 创建用户 fl type
 */

Http.User_submit_creat = (userdata) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');

        const user_pro=userdata.user_pro.join(',');
        const user_exp=userdata.user_exp.join(',');

        const data={
            name:userdata.name,
            type:userdata.type,
            email:userdata.email,
            password:userdata.password,
            mobile:userdata.mobile,
            passcode:userdata.passcode,
            terms:userdata.terms,
            family_name:userdata.name,
            wechat_id:userdata.wechatid,
            open_id:'false',
            address:'false',
            company:'personal',
            country:'China',
            province:userdata.province,
            city:userdata.city,
            area:userdata.area,
            title:userdata.title,
            working_years:userdata.working_years,
            working_hours:userdata.working_hours,
            clients:userdata.clients,
            awards:userdata.awards,
            daily_cost:userdata.daily_cost,
            user_pro:user_pro,
            user_expr:user_exp,
            introduce:userdata.introduce,
            profile_url:userdata.profile_url,
            contact_type:userdata.contact_type,
            profile_file:userdata.profile_file,
            ip_address:'false',
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/registration',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * Aliyun OSS upload
 */

Http.OSSsign=()=>{
    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');
        const data={}
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/osssign',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })

}

Http.OSSuploadfiles=(filedata,sign)=>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');
        const data=new FormData()

        data.append('key', sign.data.dir + filedata[0]);//存储在oss的文件路径
        data.append('OSSAccessKeyId', sign.data.accessid)//accessKeyId
        data.append('policy', sign.data.policy) //policy
        data.append('Signature', sign.data.signature); //签名
        data.append('file',filedata[1])
        data.append('success_action_status', 200);


        let fileUrl = sign.data.cdn_host +'/'+ sign.data.dir + filedata[0];
        let posturl= sign.data.host;
        //let posturl= 'http://oss.kuaipf.com';
        // let posturl= '/alioss';

        axios({
            method: 'post',
            url: posturl,
            data: data,
            headers:{// 这里可设置所有的请求头
               // 'Content-Type':'multipart/form-data',
               // "Access-Control-Allow-Origin": "*",
               // 'Access-Control-Allow-Credentials':'true',
                //'content-type' : 'application/x-www-form-urlencoded' //该项建议设置 如果未 POST请求时 数据未做处理时会出现错误，最理想的解决方法就是 直接设置该项

            },
        }).then((res) => {
            resSuccess(resolve, rejiect, res)

          //  resolve(res);
        }).catch((error) => {
            rejiect(error)
        })
    })

}


/**
 * 获取验证码
 * @constructor
 */
Http.Getpasscode = () =>{

    return true;
}

/**
 * 匹配验证码
 * @constructor
 */

Http.Verafilepasscode = (mobile,passcode) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');
        const data={
            mobile:mobile,
            code:passcode,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/check_passcode',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}

/**
 * 检查用户是否存在
 * @constructor
 */

Http.Checkuser = (email,mobile,passcode) =>{

    return new Promise((resolve, rejiect) => {
        // axios.defaults.headers.common['token'] = localStorage.getItem('token');
        const data={
            email:email,
            mobile:mobile,
        }
        axios({
            method: 'post',
            url: global.gData.api_domain+'/api/auth/check_user',
            data: data
        }).then((res) => {
            resSuccess(resolve, rejiect, res)
        }).catch((error) => {
            rejiect(error)
        })
    })
}





function resSuccess(resolve, rejiect, res) {
    let datas = res.data

   // if(res.data.message=='Token has expired') {
   //     Http.Refresh_token();//刷新token
   //     window.location.reload();
   // }else {

        if (datas.status == true || datas.code == 0 || datas == '') {
            resolve(datas);
        } else {
            rejiect(datas);
        }
   // }
}


export default Http;