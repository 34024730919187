//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import {Link} from "react-router-dom"
import  './global'

let contentfont=15

if(global.gData.isMobile==null && window.innerHeight <=680){
    contentfont = 14

}


class Valueitems extends React.Component{




    constructor(){
        super()
        this.state ={
            initall:0,
            frameContent:{
                transition:'0.15s ease-out all',
                width:'17vw',
                height:'17vw',
                minWidth:'280px',
                minHeight:'280px',
                backgroundColor:'#fff',
                borderStyle: 'solid',
                borderWidth: '0px',
                borderColor:'#000',
                color:'#000',
                float:'left',
                marginRight:'10px',
                marginLeft:'10px',
                marginTop:'30px',
                boxShadow:'0px 0px 3px #f0f0f0',
                opacity:1,


            },
            buttoninsidebg:{
                transition:'0.15s ease-out all',
                width:'0px',
                height:'50px',
                backgroundColor:'#f6d808',
            },
        }


        this.changeframeBoxalpha =this.changeframeBoxalpha.bind(this)




    }

    componentDidMount() {

        const winW=window.innerWidth;
        /*
           let FframeContent = Object.assign({}, this.state.frameContent, {minWidth:'350px',minHeight:'230px'})

           if(winW <= 1124){
            this.setState({
             frameContent:FframeContent
            })

           }
        */


    }


    componentDidUpdate(){



    }


    changeframeBoxalpha(){

        let FframeContent = Object.assign({}, this.state.frameContent, {opacity:1})

        let UframeContent = Object.assign({}, this.state.frameContent, {opacity:0})

        if(this.props.Scrollstate >= window.innerHeight/2 && this.state.initall==0){

            this.setState({
                initall:1,
                frameContent:FframeContent
            })
        }


    }



    mouseEnter(event) {
        console.log('in')


    }

    mouseOut(event) {
        //console.log('out')

    }
    mouseDown(event){

    }

    render(){

        let styles={
            buttonViewbox:{
                display:'flex',
                alignItems: 'center',
                width:'200px',
                height:'50px',

            },
            buttonInfo:{
                verticalAlign:'center',
                alignItems: 'center',
                paddingTop:'16px',
                textAlign:'center',
                width:'200px',
                height:'50px',
                position:'absolute',

            },
            frameContent:{
                transition:'0.15s ease-out all',
                width:'200px',
                height:'10vw',
                minWidth:'180px',
                minHeight:'180px',
                backgroundColor:'',
                borderStyle: 'solid',
                borderWidth: '0px',
                borderColor:'',
                color:'#000',
                float:'left',
                marginRight:'0px',
                marginLeft:'0px',
                marginTop:'0px',
                opacity:1,

            }



        }


        const winW=window.innerWidth;

        if(winW <= 1124){

            styles.frameContent.minWidth='350px'

            styles.frameContent.minHeight='240px'
        }

        if(this.props.Scrollstate >= window.innerHeight/2.7 && this.state.initall==0){
            styles.frameContent.opacity=1

        }

        console.log(this.props.nicon)

        let niconcom=require('../asset/nicon1.png')
        if(this.props.nicon =='nicon2.png'){
            niconcom=require('../asset/nicon2.png')
        }else if(this.props.nicon =='nicon3.png'){
            niconcom=require('../asset/nicon3.png')
        }

        return(
            <div style={styles.frameContent}  onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }}>
                <div className="flexframeBox_Colum_ex">
                    {/*  <span className={this.props.icon} style={{fontSize:this.props.iconSize,color:'#fff',lineHeight:'70px',paddingTop:'2vw'}} ></span> */}
                    <span   style={{color:'#fff',lineHeight:'70px',paddingTop:'2vw',paddingBottom:'10px'}} >
                    <img src={niconcom}  style={{height:'70px'}}/>
                    </span>
                    <span className='framTitle'>{this.props.Title}</span>
                    <span className='framTitle'>- {this.props.subTitle} -</span>

                    <span className='contentFrameColumn_ex' style={{fontSize:contentfont,whiteSpace: 'pre-wrap',display:"table"}} dangerouslySetInnerHTML={{__html:this.props.Content}} />

                </div>

            </div>
        )

    }
}



export default Valueitems