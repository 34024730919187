/**
 * yi 2020
 *
 *
 */

import React from 'react';
import Http from "../../components/functions/ApiBridge";
import {Result,Button,Alert,Select,Space,Input,Steps ,Typography ,Divider,Pagination,DatePicker} from 'antd';

import {
    MenuUnfoldOutlined,
    LoadingOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';


const { Step } = Steps;
const { Text,Title } = Typography;
const { Option } = Select;



class Createorder extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,
            order_list:null,
            total_list:null,
            current_page:null,
            loading:true,
            error:false,
            errorContent:'',
            calling_time_ar:['10:00','10:30','11:00','11:30','01:00','01:30','02:00','03:30'],
            user_pro_data:false,
            user_exp_data:false,
            user_data:{
                order_name:'',
                name:'',
                email:'',
                mobile:'',
                wechat_id:'',
                company:'',
                title:'',
                department:'',
                service:'',
                industry:'',
                deliver:'',
                brief_call:'',
                calling_time:'10:00',
                remark:'',

            },
            showresult:false,



        }


        this.inputChannge =this.inputChannge.bind(this);
        this.Get_tags_pro =this.Get_tags_pro.bind(this);
        this.Get_tags_exp =this.Get_tags_exp.bind(this);
        this.serviceSelectChange =this.serviceSelectChange.bind(this);
        this.industrySelectChange =this.industrySelectChange.bind(this);
        this.deliverChannge =this.deliverChannge.bind(this);
        this.calldateChannge =this.calldateChannge.bind(this);
        this.calltimeChannge =this.calltimeChannge.bind(this);
        this.onClose =this.onClose.bind(this);
        this.submitOrder =this.submitOrder.bind(this);
        this.checkProject =this.checkProject.bind(this);


    }

    componentDidMount() {


        this.setState({loading:false})
        this.Get_tags_pro();
        this.Get_tags_exp();




    }

    componentWillMount() {


     console.log(this.props.bridge)
        this.setState({
            user_data:{
                order_name:'',
                name:this.props.bridge.family_name,
                email:this.props.bridge.email,
                mobile:this.props.bridge.mobile,
                wechat_id:this.props.bridge.wechat_id,
                company:this.props.bridge.company,
                title:this.props.bridge.title,
                department:'',
                service:'',
                industry:'',
                deliver:'',
                brief_call:'',
                calling_time:'10:00',
                remark:'',

            }
        })




    }



    inputChannge(e){

        const val=e.target.value;
        const user_Data=this.state.user_data
        console.log(e.target.id+'/'+val)
        switch (e.target.id){
            case 'name':
                user_Data.name=val
                break
            case 'order_name':
                user_Data.order_name=val
                break
            case 'email':
                user_Data.email=val
                break
            case 'mobile':
                user_Data.mobile=val
                break
            case 'wechat_id':
                user_Data.wechat_id=val
                break
            case 'company':
                user_Data.company=val
                break
            case 'title':
                user_Data.title=val
                break
            case 'department':
                user_Data.department=val
                break
            case 'remark':
                user_Data.remark=val
                break
            default:

        }
        this.setState({user_Data})
        console.log(user_Data)
    }

    deliverChannge(date, dateString){
         //console.log(dateString)
        const user_Data=this.state.user_data
        user_Data.deliver=dateString
        this.setState({user_Data})
    }

    calldateChannge(date, dateString){
        const user_Data=this.state.user_data
        user_Data.brief_call=dateString
        this.setState({user_Data})
    }
    calltimeChannge(value){
        const user_Data=this.state.user_data
        user_Data.calling_time=value
        this.setState({user_Data})
    }

    serviceSelectChange(value){
        const user_Data=this.state.user_data
        user_Data.service=value
        this.setState({user_Data})
    }

    industrySelectChange(value){
        const user_Data=this.state.user_data
        user_Data.industry=value
        this.setState({user_Data})
    }

    /**
     * get tags data
     * @returns {array}
     */

    Get_tags_pro(){

        //请求API
        const user_Data=this.state.user_data

        const pro_data_array=[];
        Http.Get_tag_list(1).then((res)=>{

            Object.keys(res.data).map(key =>
            {
                pro_data_array.push(res.data[key].tag_name)
            })
            user_Data.service=pro_data_array[0]
            this.setState({
                user_pro_data:pro_data_array,
                user_Data,
            })


        }).catch((res) => {

            this.setState({errorContent:res.message,error:true})

        })


    }


    Get_tags_exp(){
        const user_Data=this.state.user_data
        const exp_data_array=[];
        Http.Get_tag_list(2).then((res)=>{
            Object.keys(res.data).map(key =>
            {
                exp_data_array.push(res.data[key].tag_name)
            })
            user_Data.industry=exp_data_array[0]
            this.setState({
                user_Data,
                user_exp_data:exp_data_array,
            })

        }).catch((res) => {

            this.setState({errorContent:res.message,error:true})
        })


    }

     onClose = () => {
        //console.log(e, 'I was closed.');
         this.setState({
             error:false,
             errorContent:'',
         })
    };


    submitOrder(){

        const user_Data=this.state.user_data;
        //console.log(user_Data)
        const targeta=['order_name','company','email','mobile','name','title','department','wechat_id'];//判断数组
        let canpass=true;
        let that=this

        Object.keys(user_Data).map(key =>
            {
                //查找是否在判断条件数组里
                if(targeta.indexOf(key)>-1){
                    if(user_Data[key]==''){

                        that.setState({
                            error:true,
                            errorContent:'请填写完整信息！',
                        })
                        canpass =false;


                    }
                }
            }
        );

        if(canpass) {
            if(user_Data.deliver ==''){

                this.setState({ error:true,  errorContent:'请选择项目的上线时间！',})
            }else{
                if(user_Data.brief_call ==''){

                    this.setState({ error:true,  errorContent:'请选择电话会议时间！',})
                }else{

                    this.setState({error: false})

                    Http.Creat_order(user_Data).then((res)=>{


                        this.setState({showresult:true})

                    }).catch((res) => {
                        this.setState({ error:true,  errorContent:res.message,})

                    })

                }
            }
        }

    }

    checkProject(){
        this.props.callBack(2)
    }



    render() {

        if (this.state.user_exp_data ==false || this.state.user_pro_data ==false ) {
            return (
                <div  style={{width: '100%', height: '500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <LoadingOutlined style={{fontSize:'20px'}} />
            </div>
            );

        } else {

            if (this.state.showresult) {
                return(

                        <div style={{
                            width: '100%',
                            height: '500px',
                            backgroundColor: '#fff',
                            padding: '0px',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            <Result
                                status="success"
                                title="提交成功!"
                                subTitle="我们的项目经理会尽快与您联系，您可以在项目管理中查看项目进展。"
                                extra={[


                                    <Button key={'back'} type="text" onClick={this.checkProject}>返回</Button>
                                ]}
                            />,


                        </div>

                )
            } else {
                return (
                    <div style={{width: '100%', padding: '0px'}}>



                        <div style={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            padding: '0px',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            <div style={{width: 'auto', padding: '50px', fontSize: '13px'}}>


                                <Space direction="vertical" size={"middle"} style={{width: '400px'}}>
                                    <Title level={5} style={{paddingTop:'6px',paddingBottom:'0px'}}>项目需求</Title>
                                    <Divider/>
                                    <Text type="secondary">请填写以下表格信息快平方咨询顾问会在您指定时间与您联系</Text>


                                    <Input id='order_name' style={{fontSize: '13px'}} placeholder="项目名称"
                                           onChange={this.inputChannge}/>
                                    <Input id='company' style={{fontSize: '13px'}} placeholder="企业名称"
                                           onChange={this.inputChannge} value={this.state.user_data.company} />
                                    <Input id='name' style={{fontSize: '13px'}} placeholder="联络人姓名"
                                           onChange={this.inputChannge} value={this.state.user_data.name}/>
                                    <Input id='email' style={{fontSize: '13px'}} placeholder="联络人邮箱"
                                           onChange={this.inputChannge} value={this.state.user_data.email}/>
                                    <Input id='mobile' style={{fontSize: '13px'}} placeholder="联络人电话"
                                           onChange={this.inputChannge} value={this.state.user_data.mobile}/>
                                    <Input id='wechat_id' style={{fontSize: '13px'}} placeholder="联络人微信ID"
                                           onChange={this.inputChannge} value={this.state.user_data.wechat_id}/>

                                    <Input id='title' style={{fontSize: '13px'}} placeholder="职位"
                                           onChange={this.inputChannge} value={this.state.user_data.title}/>
                                    <Input id='department' style={{fontSize: '13px'}} placeholder="部门"
                                           onChange={this.inputChannge}/>

                                    <Text type="secondary"> 请选择所需服务 </Text>

                                    <Select defaultValue={this.state.user_pro_data[0]}
                                            style={{width: 250, color: '#999', fontSize: '13px'}}
                                            onChange={this.serviceSelectChange}>
                                        {
                                            this.state.user_pro_data.map((i, index) => (
                                                <Option value={i} key={index}>{i}</Option>
                                            ))
                                        }


                                    </Select>

                                    <Text type="secondary"> 请选择属在行业 </Text>

                                    <Select defaultValue={this.state.user_exp_data[0]}
                                            style={{width: 250, color: '#999', fontSize: '13px'}}
                                            onChange={this.industrySelectChange}>
                                        {
                                            this.state.user_exp_data.map((i, index) => (
                                                <Option value={i} key={index}>{i}</Option>
                                            ))
                                        }


                                    </Select>


                                    <Text type="secondary"> 项目上线时间 </Text>

                                    <DatePicker style={{borderColor: '#dadada'}} type="primary"
                                                onChange={this.deliverChannge}/>

                                    < Text type="secondary"> 请选择一个时间以便我们的项目经理安排电话会议 </Text>
                                    <Space>
                                        <DatePicker style={{borderColor: '#dadada'}} onChange={this.calldateChannge}/>
                                        <Select defaultValue={this.state.calling_time_ar[0]}
                                                style={{width: 180, color: '#999'}} onChange={this.calltimeChannge}>
                                            {
                                                this.state.calling_time_ar.map((i, index) => (
                                                    <Option value={i} key={index}>{i}</Option>
                                                ))
                                            }

                                            <Option value="Yiminghe">yiminghe</Option>
                                        </Select>
                                    </Space>

                                    <Input id='remark' style={{fontSize: '13px'}} placeholder="备注"
                                           onChange={this.inputChannge}/>

                                    <Divider/>
                                    <Button type="text" onClick={this.submitOrder}>提交</Button>

                                </Space>
                            </div>

                        </div>

                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                    </div>
                )
            }
        }
    }
}


export default Createorder;