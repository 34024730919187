/**
 * loading component
 */


import React from "react";

function LoadingAnima(){


    return(

        <div style={{height:'100%',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>

            <img src={require('../asset/loading.gif')} style={{ width: '5px'}}/>

            <span className='inputText' style={{fontSize:'9px'}}>加载中...</span>

        </div>
    )
}

export default LoadingAnima;