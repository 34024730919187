/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import {connect} from "react-redux";
import  '../../components/global';
import  PanelNav from '../../components/panelNav';
import  {fullfooter,minfooter,minmenu,authlogout} from  "../../reduceractions";

import Overview from "../controller/overview";
import Termsview from "./termsview";
import Messageview from "./messageview";
import Usermanage from "./usermanage";
import Clientmanage from "../admin/clientmanage";
import Faqview from "./faqview";
import Projectmanage from "./projectmanage";
import Systeminfo from "./systeminfo";
import Privacyview from "./privacyview";
import Leaveformview from "./leaveformview";
import Createacount from "./createacount";
import Http from "../../components/functions/Adminbridge";


/**
 * antd layout
 */

import {Layout, Menu, Modal} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LoadingOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';

import Sidemenu from "./sidemenu";
import cookie from "react-cookies";
const { Header, Sider, Content } = Layout;

/**
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(load){
            if(load=='land'){
                dispatch(minfooter())
                dispatch(minmenu())
            }else{
                cookie.remove('YiToken')
                window.location.href='/panel'
            }


        // console.log(minfooter())
    }


})


class Adminpanel extends React.Component {


    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            collapsed: false,
            user_data:{
                type:null,
            },
            controll_key:1,
            action:"",
            loading:true,
            info:"",


        }


        this.handleScroll =this.handleScroll.bind(this)
        this.menuselected =this.menuselected.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.componentsCallBack =this.componentsCallBack.bind(this)
        this.oncancel =this.oncancel.bind(this)
        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1

        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })



    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState('land');

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

        this.Get_userinfo();

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    menuselected(key){
        this.setState({
            controll_key:key,
        })
    }

    /**
     * get user data
     * @constructor
     */
    Get_userinfo(){

        Http.get_Profile().then((res)=>{


            if(res.data.user_type!='admin'){
                this.warning()
            }else{
                this.setState({
                    user_data:res.data,
                })

                this.Get_overview_info()
            }

        }).catch((res) => {
            console.log(res.message)
        })

    }

    /**
     * get info
     * @constructor
     */

    Get_overview_info(){

        Http.overview_info().then((res)=>{

            this.setState({
                info:res.data,

            },()=>{

                this.setState({
                    loading:false,
                })
            })


        }).catch((res) => {

            Modal.warning({
                title: 'ERROR',
                content: res.message,
                okType:'text',

            });

        })
    }

    /**
     * callback
     * @returns {JSX.Element}
     */

    componentsCallBack(key){
        this.Get_userinfo()

        switch (key){
            case 2:
                this.setState({
                    controll_key:2
                })
                break;
            case 7:
                this.setState({
                    controll_key:7
                })
                break;
            case 'account_mobile':
                this.setState({controll_key:2,action:'set_mobile'})
                break;
            default:
        }

    }

    oncancel(){
        this.props.LoadPageState('out');
    }

    warning() {
        Modal.warning({
            title: 'ERROR',
            content: '账号错误',
            okType:'text',
            onOk:this.oncancel,
        });
    }




    render(){
       // console.log(this.props.menustate)
        const controllkey=this.state.controll_key;

        let ControllerComponent=Systeminfo;
        if(controllkey==1){
            ControllerComponent=Systeminfo;
        }else if(controllkey==2){
            ControllerComponent=Projectmanage;
        } else if(controllkey==3){
            ControllerComponent=Overview;
        }else if(controllkey==4){
            ControllerComponent=Faqview;
        }else if(controllkey==5){
            ControllerComponent=Messageview;
        }else if(controllkey==6){
            ControllerComponent=Usermanage;
        }else if(controllkey==7){
            ControllerComponent=Clientmanage;
        }else if(controllkey==8){
            ControllerComponent=Termsview;
        }else if(controllkey==9){
            ControllerComponent=Privacyview;
        }else if(controllkey==13){
            ControllerComponent=Leaveformview;
        }else if(controllkey==14){
            ControllerComponent=Createacount;
        }





        if(this.state.user_data.user_type=='admin'  & this.state.loading==false){


               return (


                   <div className='contentView'>
                       <PanelNav data={this.state.info.new_message}/>
                       <div style={{height: '86px', width: '100%', backgroundColor: '#efefef'}}>
                       </div>
                       <Layout>
                           <Sider className={'panel_sider'} theme={'light'} trigger={null} collapsible
                                  collapsed={this.state.collapsed}>

                               <Sidemenu account_type={this.state.user_data.user_type} action={this.menuselected} synckey={this.state.controll_key}/>

                           </Sider>
                           <Layout className="site-layout">
                               <Header className="site-layout-background" style={{padding: 0}}>
                                   {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                       className: 'trigger',
                                       onClick: this.toggle,
                                   })}
                               </Header>

                               <Content
                                   className="site-layout-background"
                                   style={{
                                       margin: '24px 16px',
                                       padding: 24,
                                       minHeight: '76vh',
                                       backgroundColor: '#f0f2f5',
                                   }}
                               >
                                   {
                                       (!this.state.loading) ? (
                                           <ControllerComponent bridge={this.state.user_data} action={this.state.action}
                                                                callBack={this.componentsCallBack}/>
                                       ) : (
                                           <div style={{
                                               width: '100%',
                                               height: '500px',
                                               display: 'flex',
                                               justifyContent: 'center',
                                               alignItems: 'center'
                                           }}>
                                               <LoadingOutlined style={{fontSize: '20px'}}/>
                                           </div>
                                       )
                                   }


                               </Content>


                           </Layout>
                       </Layout>

                   </div>
               )


        }else{
            return (
                <div className='contentView'>


                    <div style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <LoadingOutlined style={{fontSize: '20px'}}/>
                    </div>

                </div>
            )
        }

    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Adminpanel) ;

