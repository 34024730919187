//by yi 2020 kuaipf project


import React from 'react';
import logo from './components/logo.svg';
import './components/App.css';
import  './components/global'
import Appinfo from './components/info'
import Footer from './components/Footer'
import About from './about/about'
import Home from './home/home'
import What from "./what/what";
import Solution from './solution/solution'
import Contact from './contact/contact'
import Faq from './faq/faq'
import Terms from "./policy/terms";
import Privacy from "./policy/privacy";
import Login from './login/login'
import Regustration from './registration/registration'
import  Consolepanel from './panel/console/console'
import  Adminpanel from './panel/admin/admin'
import Panel from "./admin/panel";
import Password from "./registration/password";
import Leaveform from "./registration/leaveform";
import Errorpage from "./errorpage/errorpage";
import {BrowserRouter as Router,Switch,Route,Redirect} from 'react-router-dom'

import {useSelector,useDispatch} from "react-redux";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import axios from "axios";

import {
    MenuUnfoldOutlined,
    LoadingOutlined,

} from '@ant-design/icons';



function CheckAuth (){



    const access_token=  cookie.load('YiToken')



    if(access_token == 'undefined' || access_token == undefined){

        return "null token"
    }else {

        const decoded = jwt_decode(access_token)

       // const decoded ='';


        //jwt 用的是秒作为exp单位  有时候要转化为毫秒

        const expdate = new Date(decoded.exp * 1000)
        const exptimestamp = decoded.exp * 1000
        const nowtimestamp = (new Date()).valueOf()

        //要和服务器过期时间同步
        const exptimedis = 40
        // console.log((nowtimestamp-exptimestamp)/1000)
        //  console.log('check auth : '+ JSON.stringify(decoded))

        if ((nowtimestamp - exptimestamp) / 1000 > exptimedis) {

              console.log('Token need refresh')
            return 'Expired'

        } else if((nowtimestamp - exptimestamp) / 1000 < exptimedis) {
            //  console.log('Token in used')
            return 'Available'
        }else{
            return 'error'
        }
    }


}




function App() {
    let InitRouter=false;//在异步刷新token的时候防止页面闪现 

    const Istokenexp= CheckAuth()


    const dispatch =useDispatch();


    if(Istokenexp=='Expired'){
        const access_token= {
            token:cookie.load('YiToken')

        }
        axios.post(global.gData.api_domain+'/api/auth/refresh', access_token)
            .then(function (response) {

                cookie.save('YiToken',response.data.access_token,{path:"/"})
                console.log('refresh token ')
                dispatch({ type:'SIGN_IN' })
                InitRouter=true
            })
            .catch(function (error) {
                let statuscode=error.response.status
                cookie.remove('YiToken')
                console.log(global.codeMessage[statuscode]);
            });


    }else if(Istokenexp=='Available'){

        dispatch({type:'SIGN_IN'})
        console.log('token available')
        InitRouter=true

    }else if(Istokenexp=='error'){
        cookie.remove('YiToken')
        InitRouter=true

    }else if(Istokenexp=='null token'){

        InitRouter=true

    }





    const  islogged=useSelector(state => state.redux_islog);

    const  footerstate=useSelector(state => state.footerstate);

    console.log("react redux login state:"+islogged)




     if(InitRouter) {
         return (

             <Router>


                 <div className='mainContentBox'>
                     <Switch>

                         <Route path="/" exact component={Home}/>
                         <Route path="/why-kuaipf" component={About}/>
                         <Route path="/home" component={Home}/>
                         <Route path="/how" component={Solution}/>
                         <Route path="/about" component={What}/>
                         <Route path="/contact" component={Contact}/>
                         <Route path="/faq" component={Faq}/>
                         <Route path="/registration" component={Regustration}/>
                         <Route path="/terms" component={Terms}/>
                         <Route path="/privacy" component={Privacy}/>
                         <Route path="/psw" component={Password}/>
                         <Route path="/get-start" component={Leaveform}/>






                         {/*<Route path="/login" component={Login}/>*/}
                         {/*Redirect 顺序应线Router再Redirect*/}
                         <Route exact path="/login" render={() => (
                             !islogged ?
                                 (<Route path="/login" component={Login}/>)
                                 : (<Redirect to="/console" component={Consolepanel}/>)
                         )}/>



                         <Route exact path="/console" render={() => (
                             islogged ?
                                 (<Route path="/console" component={Consolepanel}/>)
                                 : (<Redirect to="/login" component={Login}/>)
                         )}/>



                         <Route exact path="/control-panel" render={() => (
                             !islogged ?
                                 (<Route path="/control-panel" component={Panel}/>)
                                 : (<Redirect to="/admin-kuaipf" component={Adminpanel}/>)
                         )}/>


                         <Route exact path="/admin-kuaipf" render={() => (
                             islogged ?
                                 (<Route path="/admin-kuaipf" component={Adminpanel}/>)
                                 : (<Redirect to="/control-panel" component={Panel}/>)
                         )}/>


                         <Route path="*" component={Errorpage}/>


                     </Switch>

                     <Footer type={footerstate}/>

                 </div>

             </Router>



     );
     }else{
         return (
             <div  style={{width: '100%', height: '100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                 <LoadingOutlined style={{fontSize:'20px'}} />
             </div>

         )
     }

}

 

export default App;
