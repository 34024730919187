/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Select, Button, Result, Typography, Space, Divider, Statistic, Row, Col, Modal} from "antd";
import LocationCity from "../../components/LocationCity";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    EditOutlined,
    setLengthLimitedStr,
    LoadingOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/ApiBridge";


let submitalert=null;

const { Title,Text,Paragraph } = Typography;
const { Option } = Select;
/*
* choose registration account type
*/



class Userdetial extends React.Component{


    constructor(){
        super()
        this.state ={
            initial:1,
            total_orders:0,
            new_message:0,
            loading:true,
            user_data:null,
            default_data:null,
            family_name:'',
            clients:'',
            awards:'',
            daily_cost:'',
            province:'',
            city:'',
            area:'',
            working_years:'',
            working_hours:'',
            contact_type:'',
            title:'',
            profile_url:'',
            introduce:'',





        }

        this.onChangeinput =this.onChangeinput.bind(this)
        this.loadtionChange =this.loadtionChange.bind(this)
        this.wyselectChange =this.wyselectChange.bind(this)
        this.whselectChange =this.whselectChange.bind(this)
        this.ctselectChange =this.ctselectChange.bind(this)

    }

    componentDidMount() {
     const user_Data=this.props.bridge
        this.setState({
            user_data:user_Data,
            family_name:user_Data.family_name,
            clients:user_Data.clients,
            awards:user_Data.awards,
            daily_cost:user_Data.daily_cost,
            province:user_Data.province,
            city:user_Data.city,
            area:user_Data.area,
            working_years:user_Data.working_years,
            working_hours:user_Data.working_hours,
            contact_type:user_Data.contact_type,
            title:user_Data.title,
            introduce:user_Data.introduce,
            profile_url:user_Data.profile_url,
        },()=>{
            this.setState({
                loading:false
            })

        })


    }

    //close component
    componentWillUnmount(){

    }

    componentWillMount() {



    }

    loadtionChange(name,data){
        const user_data=this.state.user_data
        if(name=='location'){
            const locadata=data.split(',')

            this.setState({
               province :locadata[0],
                 city: locadata[1],
                 area : locadata[2],
            })
        }

    }

    update_submit(){
        const user_Data=this.state.user_data
        const postdata={
            family_name:this.state.family_name,
            clients:this.state.clients,
            awards:this.state.awards,
            daily_cost:this.state.daily_cost,
            province:this.state.province,
            city:this.state.city,
            area:this.state.area,
            working_years:this.state.working_years,
            working_hours:this.state.working_hours,
            contact_type:this.state.contact_type,
            title:this.state.title,
            introduce:this.state.introduce,
            profile_url:this.state.profile_url,
        }
        this.warning();
        Http.Update_user_detail(postdata).then((res)=>{
            console.log(res)
                user_Data.family_name=this.state.family_name;
                user_Data.clients=this.state.clients;
                user_Data.awards=this.state.awards;
                user_Data.daily_cost=this.state.daily_cost;
                user_Data.province=this.state.province;
                user_Data.city=this.state.city;
                user_Data.area=this.state.area;
                user_Data.working_years=this.state.working_years;
                user_Data.working_hours=this.state.working_hours;
                user_Data.contact_type=this.state.contact_type;
                user_Data.title=this.state.title;
                user_Data.introduce=this.state.introduce;
                user_Data.profile_url=this.state.profile_url;
            this.setState({

            })
            submitalert.destroy();

        }).catch((res) => {
            console.log(res.message)
        })
    }



    warning() {
        submitalert= Modal.success({
            title: '提示',
            content: '数据正在提交中...',
            okType:'text'
        });

    }



    onChangeinput(value,type){
        const uset_Data=this.state.user_data
      switch (type){
          case 'family_name':
              this.setState({ family_name:value })
              break;
          case 'clients':
              this.setState({ clients:value })
              break;
          case 'awards':
              this.setState({ awards:value })
              break;
          case 'daily_cost':
              this.setState({ daily_cost:value })
              break;
          case 'title':
              this.setState({ title:value })
              break;
          case 'introduce':
              this.setState({ introduce:value })
              break;
          case 'profile_url':
              this.setState({ profile_url:value })
              break;
          default:

      }



    }

    wyselectChange(e){
        this.setState({
            working_years:e
        })
    }

    whselectChange(e){
        this.setState({
            working_hours:e
        })
    }
    ctselectChange(e){
        this.setState({
            contact_type:e
        })
    }



    render() {

        let that=this

        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {

            return (
                <div style={{width: '100%', padding: '0px'}}>


                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',


                    }}>



                        <Space>
                        <Text type={"secondary"}>姓名</Text>
                        <Paragraph
                            style={{outline:"none",outlineColor:'orange',width:'300px',paddingTop:'15px',paddingLeft:'10px'}}
                            editable={{
                                icon: <EditOutlined style={{color:'orange'} } />,
                                tooltip: 'click to edit',
                                onChange: function (value){that.onChangeinput(value,'family_name')},
                                maxLength:50,
                                autoSize:{ minRows: 1, maxRows: 1 },
                            }}
                        >
                        {this.state.family_name}
                        </Paragraph>
                        </Space>

                        <Space>
                            <Text type={"secondary"}>曾服务客户</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'300px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'clients')},
                                    maxLength:50,
                                    autoSize:{ minRows: 1, maxRows: 1 },
                                }}
                            >
                                {this.state.clients}
                            </Paragraph>
                        </Space>

                        <Space>
                            <Text type={"secondary"}>奖项</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'300px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'awards')},
                                    maxLength:50,
                                    autoSize:{ minRows: 1, maxRows: 1 },
                                }}
                            >
                                {this.state.awards}
                            </Paragraph>
                        </Space>


                        <Space  >
                            <Text type={"secondary"}>期望日薪</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'300px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'daily_cost')},
                                    maxLength:50,
                                    autoSize:{ minRows: 1, maxRows: 1 },
                                }}
                            >
                                {this.state.daily_cost}
                            </Paragraph>
                        </Space>




                        <Space style={{paddingBottom:10}}>
                            <Text type={"secondary"}>曾任职位</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'300px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'title')},
                                    maxLength:50,
                                    autoSize:{ minRows: 1, maxRows: 1 },
                                }}
                            >
                                {this.state.title}
                            </Paragraph>
                        </Space>


                        <LocationCity width='80%' height='30px' bgColor='#fff' broderColor={'#efefef'}
                                      callBack={this.loadtionChange}
                                      province={this.state.province}
                                      city={this.state.city}
                                      area={this.state.area}
                        />

                        <Space style={{paddingRight:30}}>
                            <Text type={"secondary"}>工作年限</Text>
                        <Select defaultValue={this.state.working_years} style={{ width: 180 }} onChange={this.wyselectChange}>
                            <Option value="1-5年">1-5年</Option>
                            <Option value="6-8年">6-8年</Option>
                            <Option value="9-12年">9-12年</Option>
                            <Option value="12年以上">12年以上</Option>
                        </Select>

                            <Text type={"secondary"}>每天可工作时间</Text>
                            <Select defaultValue={this.state.working_hours} style={{ width: 180 }} onChange={this.whselectChange}>
                                <Option value="2-4小时">2-4小时</Option>
                                <Option value="5-8小时">5-8小时</Option>
                                <Option value="8-10小时">8-10小时</Option>
                                <Option value="10小时以上">10小时以上</Option>
                            </Select>
                        </Space>
                       <br/>
                        <Space style={{paddingTop:25}}>
                            <Text type={"secondary"}>首选联系方式</Text>
                            <Select defaultValue={this.state.contact_type} style={{ width: 180 }} onChange={this.ctselectChange}>
                                <Option value="不限">不限</Option>
                                <Option value="微信">微信</Option>
                                <Option value="电话">电话</Option>
                                <Option value="邮件">邮件</Option>
                            </Select>
                        </Space>
                        <br/>
                        <Space style={{paddingTop:10}}>
                            <Text type={"secondary"}>个人网站</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'500px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'profile_url')},
                                    maxLength:150,
                                    autoSize:{ minRows: 1, maxRows: 1 },
                                }}
                            >
                                {this.state.profile_url}
                            </Paragraph>
                        </Space>

                        <Space style={{paddingTop:0}}>
                            <Text type={"secondary"}>自我介绍</Text>
                            <Paragraph
                                style={{outline:"none",outlineColor:'orange',width:'500px',paddingTop:'15px',paddingLeft:'10px'}}
                                editable={{
                                    icon: <EditOutlined style={{color:'orange'} } />,
                                    tooltip: 'click to edit',
                                    onChange: function (value){that.onChangeinput(value,'introduce')},
                                    maxLength:250,
                                    autoSize:true,
                                }}
                            >
                                {this.state.introduce}
                            </Paragraph>
                        </Space>

                        <Divider />

                        <Button type={'text'} onClick={()=>this.update_submit()}>更新</Button>


                    </div>

                </div>
            )
        }
    }
}


export default Userdetial;