
//by yi 2020 kuaipf project


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
 
import  {createStore,applyMiddleware,compose} from 'redux';
import  allReducer from "./reducers";
import  { Provider } from "react-redux"
import  thunk from "redux-thunk"



/*

使用 redux 的时候添加 applymiddleware 和thunk 中间件，否则执行connent 的 dispatch方法会异步报错
 */
const  Yistore=createStore(allReducer,compose(applyMiddleware(thunk)));

// this just a temp class 
// for intro fucntion 
class Mainview extends React.Component{

  constructor(){
  	super()
  	this.state ={
  		islogin:"yes",
  		initial:1
  	}

  	this.changevalue =this.changevalue.bind(this)
  }

  changevalue(){
  	//this.setState({initial:2})
  	this.setState(prevState=>{
  		return{
  			initial:prevState.initial +1
  		}
  	})
  }


  render(){

   
   return(
     <div>
       
       <h4> class base component {this.props.cool} </h4>
     </div>
    )

  }
}





/*
*
*  redux
*
//STORE global state stroage



//ACTION 返回 action type
const increment=()=>{
	return {
		type:'INCREMENT'
	}

}
const decrement=()=>{
	return {
		type:'DECREMENT'
	}

}


//REDUCER  change state  判断action type

const counter=(state=0,action)=>{

	switch(action.type){
		case "INCREMENT":
			return state +1;
		case "DECREMENT":
		    return state -1;
	}
}

let store =createStore(counter);

//Display

store.subscribe(()=>console.log(store.getState()));

//DISPATCH 执行 action
store.dispatch(increment())
*/


ReactDOM.render(<Provider store={Yistore}><App/></Provider>,
  document.getElementById('root')
);

 