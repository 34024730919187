//REDUCER  change state  判断action type

const loggedReducer=(state=false,action)=>{
  // console.log('reducer func call : '+action.type)
    switch(action.type){
        case "SIGN_IN":
           // console.log('reducer match :'+action.type)
            return true ;
        case "SIGN_OUT":
          //  console.log('reducer match :'+action.type)
            return false ;
        default:
         //   console.log('reducer match nothing:')
            return state;
    }
}

export  default loggedReducer;