/*
 * yi 2020
 *
 *
 */

import React from 'react';
import NextButton from "../components/NextButton";








/*
* choose registration account type
*/


class Account_Type extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,

        }


    }


    componentWillMount() {


       // console.log(urdl)


    }



        render(){

    const Typestyles={
        bubbo:{
            width:'80px',
            height:'80px',
            alignItems:'center',
            display:'flex',
            margin:'25px',
            justifyContent: 'center',
            backgroundColor:'#f5db10',
            borderRadius: '40px',
            textDecoration: 'none',
            transition:'0.15s ease-out all',
        }
    }

    let min_height="20vh";
    let centerboxW="40%";
    let choosetypeboxW="50%";
    let max_height='100vh';
    let next_bar_h='40vh';
    let centerboxMinW='630px';

    if(window.innerWidth<1124){
        min_height="40%";
        centerboxW="90%";
        choosetypeboxW="100%";
        max_height='auto';
        next_bar_h='auto';
        centerboxMinW='';
    }

    return(
        <div style={{width:'100%',height:max_height,overflow: 'hidden'}}>

            <div style={{paddingBottom:'100px',width:'100%',height:'100px',backgroundColor:'',alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:min_height}}>
                <span className="ATitle">请选择您的注册类别</span>
            </div>

            <div  className="items_placeCenter_W" style={{height:next_bar_h,backgroundColor:""}}>
                <div  style={{backgroundColor:"",width:centerboxW,minWidth:centerboxMinW,height:'auto'}}>

                    <div style={{width:choosetypeboxW,height:"300px",backgroundColor:"",float:"left"}}>
                        <div  className="items_placeCenter_W"  >
                            <span className="framContent" style={{backgroundColor:"",textAlign:"center",height:"50px",color:"#000"}}>广告客户您如需专才服务请按"我有活"</span>
                        </div>

                        <div  className="items_placeCenter_W" style={{display:'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <div onClick={()=>this.props.brideg.action('cl')}  style={{marginTop:'10px',width:"180px",height:"180px",cursor: 'pointer',backgroundColor:"#fff",boxShadow:"0px 0px 2px #DADADA",alignItems: "center",display:"flex",flexDirection: "column"}}>
                                <div  className="items_placeCenter_W"   >
                                    <div style={Typestyles.bubbo}>
                                        <span className="icon-regtypecl" style={{fontSize:"40px",color:'#fff',lineHeight:'0px',paddingTop:'10px'}} > </span>
                                    </div>
                                </div>
                                <span className="contentText_center">我有活</span>

                            </div>

                            <div className='pageLineS' style={{paddingTop:'20px',display:this.props.brideg.state.cl_underline}}> </div>
                        </div>



                    </div>

                    <div style={{width:choosetypeboxW,height:"300px",backgroundColor:"",float:"left"}}>
                        <div  className="items_placeCenter_W"  >
                            <span className="framContent" style={{backgroundColor:"",textAlign:"center",height:"50px",color:"#000"}}>广告专才您希望发挥所长请按"我有活"</span>
                        </div>

                        <div  className="items_placeCenter_W" style={{display:'flex',flexDirection: 'column',alignItems: 'center'}}>
                            <div onClick={()=>this.props.brideg.action('fl')} style={{marginTop:'10px',width:"180px",height:"180px",backgroundColor:"#fff",cursor: 'pointer',boxShadow:"0px 0px 2px #DADADA",alignItems: "center",display:"flex",flexDirection: "column"}}>
                                <div  className="items_placeCenter_W" >
                                    <div style={Typestyles.bubbo}>
                                        <span className="icon-regtypefl" style={{fontSize:"30px",color:'#fff',lineHeight:'0px',paddingTop:'0px'}} > </span>
                                    </div>
                                </div>
                                <span className="contentText_center">我接活</span>
                            </div>

                            <div className='pageLineS' style={{paddingTop:'20px',display:this.props.brideg.state.fl_underline}}> </div>
                        </div>



                    </div>

                </div>


            </div>

            <div  className="items_placeCenter_W" >

                <div style={{width:'80%',backgroundColor:"",height:'80px',paddingTop:'10px',display:'flex',justifyContent:"space-between"}}>
                    <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                        <img src={require('../asset/step1.png')}     style={{minWidth:'60px',width:'3vw'}}  />


                    </div>

                    <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                        <NextButton callBack={()=>this.props.brideg.callBack('nex')} />

                    </div>
                </div>

            </div>

        </div>
    )
}
}


export default Account_Type;