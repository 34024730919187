/*
 * yi 2020
 *
 *
 */

import React from 'react';
import NextButton from "../components/NextButton";
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import VcodeBtn from "../components/60slimitbutton";
import axios from "axios";
import PreButton from "../components/PreButton";
import LoadingAnima from "../components/LoadingAnima";
import { DatePicker } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './registration.css'; // or 'antd/dist/antd.less'
import Http from '../components/functions/ApiBridge';
import Toast from "../components/toast";



/*
* choose registration account type
*/


class Orderinfo extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            user_pro_data:null,
            user_exp_data:null,

        }


        this.dateonChange_deliver =this.dateonChange_deliver.bind(this)

        this.dateonChange_call =this.dateonChange_call.bind(this)
    }

    componentWillMount() {

        this.Get_tags_pro();

        this.Get_tags_exp();
    }

    dateonChange_deliver(date, dateString){

        if(this.props.brideg.action) this.props.brideg.action('deliver',dateString);
    }

    dateonChange_call(date, dateString){

        if(this.props.brideg.action) this.props.brideg.action('brief_call',dateString);
    }


    /**
     * get tags data
     * @returns {array}
     */

    Get_tags_pro(){

        //请求API

        const pro_data_array=[];

        Http.Get_tag_list(1).then((res)=>{


            Object.keys(res.data).map(key =>
            {
               // console.log(res.data[key].tag_name)
                pro_data_array.push(res.data[key].tag_name)
            })
            this.setState({
                user_pro_data:pro_data_array,
            })

            if(this.props.brideg.action) this.props.brideg.action('service',pro_data_array[0]);//初始化数据

        }).catch((res) => {

            Toast.error(res.message)
        })


    }

    Get_tags_exp(){

        //请求API

        const exp_data_array=[];

        Http.Get_tag_list(2).then((res)=>{
            Object.keys(res.data).map(key =>
            {

                exp_data_array.push(res.data[key].tag_name)
            })
            this.setState({
                user_exp_data:exp_data_array,
            })
           // console.log(exp_data_array)
            if(this.props.brideg.action) this.props.brideg.action('industry',exp_data_array[0]);//初始化数据


        }).catch((res) => {

            Toast.error(res.message)
        })


    }


    render(){



        let min_height="20vh";
        let centerboxW="400px";

        let max_height='auto';
        let next_bar_h='40vh';
        let centerboxMinW='430px';
        let topbarboxH="200px";


        if(window.innerWidth<1124){
            min_height="40%";
            centerboxW="90%";

            max_height='auto'
            next_bar_h='auto';
            centerboxMinW='';
            topbarboxH="400px";
        }

        const Typestyles={

            datepick:{
                width:centerboxW,
                display:'flex',
                outline:'none !important',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#fff',

            }
        }


        const  calling_time=['10:00','10:30','11:00','11:30','01:00','10:30','02:00','03:30'];

        if(this.state.user_pro_data !=null && this.state.user_exp_data != null) {


            return (
                <div style={{
                    width: '100%',
                    height: max_height,
                    minHeight: '100vh',
                    overflow: 'hidden',
                    backgroundColor: '',
                }}>
                    <div style={{
                        width: '100%',
                        height: topbarboxH,
                        backgroundColor: '#f6d809',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '0px',
                        flexDirection: 'column'
                    }}>

                        <div style={{
                            width: "80%",
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '0px',
                            flexDirection: 'column'
                        }}>
                            <span className="ATitle" style={{paddingTop: '50px', alignSelf: 'center'}}>48小时定制您的快平方专业团队 体验专属服务</span>

                            <span className="contentText_center"
                                  style={{paddingTop: '20px'}}>只需填写简单的表格信息快平方咨询顾问会在您指定时间与您联系</span>
                        </div>

                    </div>
                    <div className='contentFrameBox' style={{backgroundColor: '#EFEFEF', paddingTop: '0px'}}>
                        <div className='triangleDown_Yellow'></div>
                    </div>


                    <div className="items_placeCenter_W" style={{height: 'auto'}}>
                        <div style={{backgroundColor: "", width: centerboxW, height: '100%', paddingTop: '20px'}}>
                            <div style={{float: 'left', display: 'flex', paddingBottom: '0px'}}>
                                <img src={require('../asset/flashicon.png')} style={{width: '25px',height:'25px'}}/>
                                <span className="Subtitle">详细信息</span>
                            </div>

                            <div className='pageLineS' style={{paddingTop: '35px', width: '100%'}}></div>
                            <div style={{height: '30px'}}></div>



                            <Yinput width='100%' height='30px' name='company' bgColor='#EFEFEF' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.company}
                                    preset='企业名称' limit={40}/>

                            <Yinput width='100%' height='30px' name='order_name' bgColor='#EFEFEF' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.order_name}
                                    preset='项目名称' limit={60}/>

                            <Yinput width='100%' height='30px' name='name' bgColor='#EFEFEF' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.name}
                                    preset='联系人名字' limit={40}/>

                            <Yinput width='100%' height='30px' name='wechatid'  bgColor='#EFEFEF'  preset='微信ID' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.wechatid}
                                    limit={100}/>
                            <Yinput width='100%' height='30px' name='email' readonly={'readonly'}  bgColor='#e3e3e3'  preset={'邮箱 '+this.props.brideg.data.email}
                                    callBack={this.props.brideg.action}    value={''} broderColor={'#b0b0b0'}
                                    limit={100}/>

                            <Yinput width='100%' height='30px' name='mobile'  readonly={'readonly'}   bgColor='#e3e3e3'  preset={'电话 '+this.props.brideg.data.mobile}
                                    callBack={this.props.brideg.action}    value={''} broderColor={'#b0b0b0'}
                                    limit={100}/>

                            <Yinput width='100%' height='30px' name='title'  bgColor='#EFEFEF'  preset='职位' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.title}
                                    limit={100}/>

                            <Yinput width='100%' height='30px' name='department'  bgColor='#EFEFEF'  preset='部门' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.department}
                                    limit={100}/>


                            <Yinput width='100%' height='30px' type='select' bgColor='#EFEFEF' value={this.state.user_pro_data} name={'service'}
                                    callBack={this.props.brideg.action} broderColor={'#b0b0b0'}
                                    preset='请选择您需要的服务' limit={20}/>


                            <Yinput width='100%' height='30px' type='select' bgColor='#EFEFEF' value={this.state.user_exp_data} name={'industry'}
                                    callBack={this.props.brideg.action} broderColor={'#b0b0b0'}
                                    preset='请选择您的行业类别' limit={20}/>



                            <span className={'inputText'}>请选择项目上线时间</span>
                            <div style={{width:'100%',height:'30px',marginBottom:'20px',marginTop:'10px'}}>

                                <DatePicker  bordered={true} className={'antd_style'} inputReadOnly={true} onChange={this.dateonChange_deliver}/>

                            </div>


                            <span className={'inputText'} >请选择一个时间以便我们的项目经理安排一个电话会议</span>


                            <div style={{width:'100%',height:'30px',marginBottom:'30px',marginTop:'10px'}}>
                                <DatePicker  bordered={true} className={'antd_style'} inputReadOnly={true} onChange={this.dateonChange_call}/>

                            </div>



                            <Yinput width='100%' height='30px' type='select' bgColor='#EFEFEF' value={calling_time} name={'calling_time'}
                                    callBack={this.props.brideg.action} broderColor={'#b0b0b0'}
                                    preset='请选择具体时间' limit={20}/>




                            <Yinput width='100%' height='30px' name='remark'  bgColor='#EFEFEF'  preset='备注' broderColor={'#b0b0b0'}
                                    callBack={this.props.brideg.action}    value={this.props.brideg.data.remark}
                                    limit={100}/>


                        </div>


                    </div>


                    <div className="items_placeCenter_W" style={{backgroundColor: ''}}>

                        <div style={{
                            width: '80%',
                            backgroundColor: "",
                            height: '80px',
                            paddingTop: '10px',
                            display: 'flex',
                            justifyContent: "space-between"
                        }}>

                            <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                                <PreButton callBack={()=>this.props.brideg.callBack('pre')} />


                            </div>

                            <div style={{width: '100px', height: '100px', backgroundColor: ''}}>

                                <img src={require('../asset/step2.png')} style={{minWidth: '60px', width: '3vw'}}/>


                            </div>

                            <div style={{width: '100px', height: '100px', backgroundColor: ''}}>

                                <NextButton callBack={() => this.props.brideg.callBack('nex')}/>


                            </div>
                        </div>

                    </div>

                </div>
            )
        }else{
            return (

                <div style={{height:'100vh'}}>

                    <LoadingAnima />

                </div>
            )
        }
    }
}


export default Orderinfo;