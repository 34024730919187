/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import TopButton from "../components/TopButton";
import {connect} from "react-redux";
import  '../components/global'
import {Link} from "react-router-dom"
import {fullfooter, minfooter, minmenu, navHome} from "../reduceractions";
import Nav_sim from "../components/nav_sim";

import {Typography, Steps, Button, message, Modal, Divider, Space} from 'antd';
import {ExclamationCircleOutlined, SolutionOutlined, SettingOutlined, SmileOutlined ,CheckCircleOutlined} from '@ant-design/icons';
import {Yinput} from "../components/Formelements";
import VcodeBtn from "../components/60slimitbutton";
import Http from "../components/functions/ApiBridge";

const { Step } = Steps;

const { Text,Title } = Typography;


/*
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(minfooter())
        dispatch(minmenu())
        dispatch(navHome())
        // console.log(minfooter())
    }
})


class Password extends React.Component {



    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            current: 0,
            pass_code_btn:'0px',
            mobile:'',
            pass_code:'',
            error:false,
            errorContent:'',
            code:'',
            setpsw:'',
            repsw:'',

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.input_content_contact =this.input_content_contact.bind(this)
        this.send_passcode =this.send_passcode.bind(this)
        this.onClose =this.onClose.bind(this)
        this.nextStep =this.nextStep.bind(this)



        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState();

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }

    input_content_contact(target,value){

        switch (target){
            case 'mobile':
                this.setState({mobile:value})
                if(value==''){
                    this.setState({pass_code_btn:'0px'})
                }else{
                    this.setState({pass_code_btn:'60px'})
                }
            case 'pass_code':
                this.setState({pass_code:value})
                break
            case 'setpassword':
                this.setState({setpsw:value})
                break
            case 'repassword':
                this.setState({repsw:value})
                break
            default:
        }

    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            error:true,
            errorContent:error,
        })
    }

    nextStep(){
        if(this.state.current==0) {
            if (this.state.mobile == '') {
                this.showErrorbar('请输入注册的手机号！')

            } else {
                if (this.state.pass_code == '') {
                    this.showErrorbar('请输入验证码！')
                } else {
                    const postdata = {
                        mobile: this.state.mobile,
                        pass_code: this.state.pass_code,
                    }
                    Http.call_reset_psw(postdata).then((res) => {

                        this.setState({
                            code: res.data.code,
                            current: 1,
                        })

                    }).catch((res) => {
                        this.showErrorbar(res.message)
                    })
                }
            }
        }else if(this.state.current==1){
            if (this.state.setpsw == '' || this.state.repsw == '') {
                this.showErrorbar('请输入新密码并确认密码！')
            }else{

                const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
                if(mediumRegex.test(this.state.setpsw)) {
                    if(this.state.setpsw != this.state.repsw){
                        this.showErrorbar('两次密码不一致，请重新输入！')
                    }else{
                        const postdata = {
                            mobile: this.state.mobile,
                            code: this.state.code,
                            psw: this.state.setpsw,
                        }
                        Http.reset_psw_mobile(postdata).then((res) => {

                            this.setState({
                                current: 2,
                            })

                        }).catch((res) => {
                            this.showErrorbar(res.message)
                        })

                    }
                }  else {
                    this.showErrorbar('您的密码太简单请重新输入！')

                }



            }

        }
    }




    send_passcode(){
        const post_data={
            mobile:this.state.mobile,
        }

        if(this.state.mobile!=''){
            Http.Send_pass(post_data).then((res)=>{
                console.log('pass sent')
            }).catch((res) => {
                this.showErrorbar(res.message)
            })
        }else{
            this.showErrorbar('请输入注册的手机号！')
        }
    }


    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }



    render(){
        console.log(this.props.menustate)

        return(


            <div  className='contentView'>

                <TopButton  callback={()=>this.clickbtn()} opacity={this.state.showscrolltop}/>
                <Nav_sim />

                <div className='contentFrameBox' style={{backgroundColor:'#efefef',height:'90vh'}}>

                    <div style={{width:'70%',minWidth:'360px'}}>
                    <Steps current={this.state.current} status="process"  size={"small" } labelPlacement={"vertical"}  >

                        <Step    title="验证账号" icon={<SolutionOutlined />} />
                        <Step    title="重设密码" icon={<SettingOutlined />} />
                        <Step    title="完成" icon={<SmileOutlined />} />
                    </Steps>

                    </div>

                    <div style={{width:'70%',minWidth:'360px',backgroundColor:'#fff',height:'400px',marginTop:20,display:"flex",justifyContent:"center",alignItems:"center"}}>

                        {
                            (this.state.current==0)?(
                            <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>
                                <Text type="secondary" style={{color: '#000'}}>请输入您注册的手机号 </Text>
                                <br/>
                                <br/>
                                <Yinput width={300} height='30px' name={'mobile'} bgColor='' value={this.state.mobile}
                                        preset='+86'
                                        type='phone' limit={11} callBack={this.input_content_contact}/>

                                <div className='selfcenter_alignLeft'
                                     style={{
                                         width: 300,
                                         height: this.state.pass_code_btn,
                                         backgroundColor: '',
                                         overflow: "hidden",
                                         transition: '0.15s ease-out all'
                                     }}>
                                    <Yinput width={200} height='30px' name={'pass_code'} bgColor=''
                                            value={this.state.pass_code} preset=''
                                            type='' limit={11} callBack={this.input_content_contact}/>
                                    <div style={{width: 90, height: '30px', float: 'right'}}>
                                        <VcodeBtn canClick={true} width={'100%'} borderColor={"#000"}
                                                  callBack={this.send_passcode}/>
                                    </div>
                                </div>
                                <div style={{width: 300, textAlign: "right"}}>
                                    <Button type={"text"} onClick={() => this.nextStep()}>下一步</Button>
                                </div>

                            </div>):('')

                        }

                        {
                            (this.state.current==1)?(
                                <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>
                                    <Text type="secondary" style={{color: '#000'}}>密码长度至少8位且需包含大写字母以及数字。 </Text>
                                    <br/>
                                    <br/>
                                    <div style={{width:300}}>
                                    <Text type="secondary">输入新的密码 </Text>
                                    <Yinput width='100%' height='30px' name='setpassword' broderColor={'#b0b0b0'}   callBack={this.input_content_contact} marginBottom={'0px'} analyze={true} type='password' bgColor='#fff' state={""}  value={""} preset='' limit={20}/>
                                        <br/>
                                        <br/>
                                    <Text type="secondary">确认新的密码 </Text>
                                    <Yinput width='100%' height='30px' name='repassword' broderColor={'#b0b0b0'}   callBack={this.input_content_contact}  marginBottom={'0px'}  type='password' bgColor='#fff' state={""}  value={""} preset='' limit={20}/>

                                    </div>
                                    <br/>

                                    <div style={{width: 300, textAlign: "right"}}>
                                       <Button type={"text"} onClick={() => this.nextStep()}>下一步</Button>
                                    </div>

                                </div>

                            ):('')
                        }

                        {
                            (this.state.current==2)?(
                                <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>
                                    <div style={{width: 300, textAlign: "center"}}>
                                        <CheckCircleOutlined style={{fontSize:20,paddingRight:12,color:'green'}}/>
                                    <Text type="secondary" style={{color: '#000'}}>您的密码已重设成功</Text>
                                    </div>
                                    <br/>
                                    <br/>

                                    <div style={{width: 300, textAlign: "center"}}>
                                        <Link to={'/login'}>
                                        <Button type={"text"} onClick={() => this.nextStep()}>登陆</Button>
                                        </Link>
                                    </div>

                                </div>

                            ):('')
                        }



                    </div>


                </div>

                <Modal
                    title="提示"
                    visible={this.state.error}
                    footer={false}
                    onCancel={this.onClose}
                >
                    <ExclamationCircleOutlined style={{color:'red',paddingRight:12,fontSize:20}} /><Text type={"secondary"} style={{color:'#000'}}>{this.state.errorContent}</Text>


                </Modal>


            </div>
        )

    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Password) ;