/**
 * yi 2020
 *
 *
 */

import React from 'react';
import NextButton from "../components/NextButton";
import PreButton from "../components/PreButton";
import {Yinput,Ytextarea,Yibutton} from  '../components/Formelements';
import VcodeBtn from "../components/60slimitbutton";
import {Link} from "react-router-dom"

import { Checkbox, Button } from 'antd';





/*
* choose registration account type
*/


class Baseinfo extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,

        }

        this.send_action =this.send_action.bind(this)
        this.termsCheck =this.termsCheck.bind(this)
    }
    componentDidMount() {




    }
    componentWillMount() {



    }

    termsCheck(){
        if(this.props.brideg.data.terms){
            this.props.brideg.action('terms',false);
        }else{
            this.props.brideg.action('terms',true);
        }

    }

    send_action(){

      //this.props.brideg.sendpass()
    }


    render(){

        const Typestyles={
            bubbo:{
                width:'80px',
                height:'80px',
                alignItems:'center',
                display:'flex',
                margin:'25px',
                justifyContent: 'center',
                backgroundColor:'#f5db10',
                borderRadius: '40px',
                textDecoration: 'none',
                transition:'0.15s ease-out all',
            }
        }

        let min_height="20vh";
        let centerboxW="400px";

        let max_height='auto';
        let next_bar_h='40vh';
        let centerboxMinW='430px';
        let topbarboxH="200px";


        if(window.innerWidth<1124){
            min_height="40%";
            centerboxW="90%";

            max_height='auto'
            next_bar_h='auto';
            centerboxMinW='';
            topbarboxH="400px";
        }

        return(
            <div style={{width:'100%',height:max_height,minHeight:'800px',overflow: 'hidden',backgroundColor:'',}}>
                <div style={{width:'100%',height:topbarboxH,backgroundColor:'#f6d809',alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>


                        {
                            (this.props.brideg.data.type=='cl')?(

                            <div    style={{width:"80%",alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>
                            <span className="ATitle" style={{paddingTop:'50px',alignSelf:'center'}}>48小时定制您的快平方专业团队 体验专属服务</span>
                            <span className="contentText_center" style={{paddingTop:'20px'}}>只需填写简单的信息，快平方咨询顾问会在您指定时间与您联系。</span>
                            </div>

                            ):(
                            <div    style={{width:"80%",alignItems:'center',display:'flex',justifyContent: 'center',paddingTop:'0px',flexDirection:'column'}}>
                            <span className="ATitle" style={{paddingTop:'50px',alignSelf:'center'}}>加入快平方</span>
                            <span className="contentText_center" style={{paddingTop:'20px'}}></span>
                            </div>
                            )
                        }


                </div>
                <div className='contentFrameBox' style={{backgroundColor:'#EFEFEF',paddingTop:'0px'}}>
                    <div className='triangleDown_Yellow'>  </div>
                </div>






                <div  className="items_placeCenter_W" style={{height:'auto',minHeight:'400px'}}>
                    <div  style={{backgroundColor:"",width:centerboxW,height:'60vh',paddingTop:'20px'}}>
                          <div style={{float:'left',display:'flex',paddingBottom:'0px'}}>
                              <img src={require('../asset/flashicon.png')}     style={{width:'25px',height:'25px'}}  />
                              <span className="Subtitle">欢迎加入快平方</span>
                          </div>

                         <div className='pageLineS' style={{paddingTop:'35px',width:'100%'}}> </div>
                         <div style={{height:'30px'}}> </div>

                         <Yinput width='100%' height='30px' name='email' bgColor='#EFEFEF' broderColor={'#b0b0b0'} callBack={this.props.brideg.action} state={this.props.brideg.state.email}  value={this.props.brideg.data.email} preset='Email' limit={80}/>
                         <Yinput width='100%' height='30px' name='setpassword' broderColor={'#b0b0b0'}  callBack={this.props.brideg.action} type='password' bgColor='#EFEFEF' state={this.props.brideg.state.password}  value={this.props.brideg.data.password} preset='设定密码需包含数字及字母' limit={20}/>
                         <Yinput width='100%' height='30px' name='repasswrod'  broderColor={'#b0b0b0'} callBack={this.props.brideg.action} type='password' bgColor='#EFEFEF' state={this.props.brideg.state.repasswrod}  value={this.props.brideg.data.repasswrod} preset='确认密码' limit={20}/>
                         <Yinput width='100%' height='30px' name='mobile' broderColor={'#b0b0b0'} callBack={this.props.brideg.action} type='phone' bgColor='#EFEFEF' state={this.props.brideg.state.mobile}  value={this.props.brideg.data.mobile} preset='+86' limit={20}/>

                         <div style={{width:'101%',display:'flex',justifyContent:"space-between"}}>
                         <Yinput width='50%' height='30px' name='passcode' broderColor={'#b0b0b0'}  type='phone' callBack={this.props.brideg.action} bgColor='#EFEFEF' state={this.props.brideg.state.passcode} value={this.props.brideg.data.passcode} preset='' limit={20}/>
                         <VcodeBtn  width={'41%'} callBack={this.props.brideg.sendpass} />
                         </div>



                        <Checkbox
                            checked={this.props.brideg.data.terms}

                            onChange={this.termsCheck}
                        >
                            <span className={'contentText_center'} style={{fontSize:'12px'}}>我已阅读 <Link to='/terms' target={'_bank'} style={{color:'orange'}}>《用户协议》</Link>及<Link to='/privacy' target={'_bank'} style={{color:'orange'}}>《隐私政策》</Link></span>

                        </Checkbox>


                    </div>


                </div>





                <div  className="items_placeCenter_W"  style={{backgroundColor:'',height:'auto'}}>

                    <div style={{width:'80%',backgroundColor:"",height:'80px',paddingTop:'0px',display:'flex',justifyContent:"space-between"}}>
                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <PreButton callBack={()=>this.props.brideg.callBack('pre')} />


                        </div>

                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <img src={require('../asset/step1.png')}     style={{minWidth:'60px',width:'3vw'}}  />


                        </div>

                        <div style={{width:'100px',height:'100px',backgroundColor:''}}>

                            <NextButton callBack={()=>this.props.brideg.callBack('nex')} />


                        </div>
                    </div>

                </div>

            </div>
        )
    }
}


export default Baseinfo;