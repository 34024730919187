import {Menu} from "antd";
import React from "react";

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    ContainerOutlined,
    TeamOutlined,
    ProjectOutlined,
    MailOutlined,
    CarryOutOutlined,
    FileDoneOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
const { SubMenu } = Menu;

/**
 * menu control
 * @returns {JSX.Element}
 */

function Sidemenu(props){
    if(props.account_type=='admin'){
        return (
            <Menu style={{backgroundColor: '#fff'}} theme="light" mode="inline" defaultSelectedKeys={'1'}  >
                <Menu.Item key="1" onClick={() => props.action(1)} icon={<ProjectOutlined/>}>
                    概览
                </Menu.Item>
                <Menu.Item key="13" onClick={() => props.action(13)} icon={<WhatsAppOutlined />}>
                    咨询预约
                </Menu.Item>

                <Menu.Item key="2" onClick={() => props.action(2)} icon={<CarryOutOutlined/>}>
                    项目管理
                </Menu.Item>
                <SubMenu
                    key="submenu"
                    title={
                        <span>
              <TeamOutlined />
              <span>账户管理</span>
            </span>
                    }
                >
                    <Menu.Item key="14" onClick={() => props.action(14)} >创建用户</Menu.Item>
                    <Menu.Item key="6" onClick={() => props.action(6)} >FREELANCER</Menu.Item>
                    <Menu.Item key="7" onClick={() => props.action(7)} >CLIENT</Menu.Item>


                </SubMenu>
                <Menu.Item key="4" onClick={() => props.action(4)} icon={<ContainerOutlined />}>
                    FAQ管理
                </Menu.Item>
                <Menu.Item key="5" onClick={() => props.action(5)} icon={<MailOutlined />} disabled={true}>
                    留言信息
                </Menu.Item>

                <Menu.Item key="8" onClick={() => props.action(8)} icon={<FileDoneOutlined /> }>
                    TERMS
                </Menu.Item>

                <Menu.Item key="9" onClick={() => props.action(9)} icon={<FileDoneOutlined /> }>
                    PRIVACY
                </Menu.Item>



            </Menu>
        );
    }else {


            return (
                <Menu style={{backgroundColor: '#fff'}} theme="light" mode="inline" defaultSelectedKeys={['1']}>
                    <Menu.Item key="5" onClick={() => props.action(1)} icon={<ProjectOutlined/>}>
                        概览
                    </Menu.Item>


                </Menu>
            )
    }
}

export default Sidemenu;