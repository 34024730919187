
/*
yi 2020

REDUCER  change state  判断action type
 */

const menustateReducer=(state='MENU_FULL',action)=>{
    // console.log('reducer func call : '+action.type)
    switch(action.type){
        case "MENU_FULL":
            // console.log('reducer match :'+action.type)
            return 'MENU_FULL';
        case "MENU_MIN":
            //  console.log('reducer match :'+action.type)
            return 'MENU_MIN';
        case "MENU_PANEL":
            //  console.log('reducer match :'+action.type)
            return 'MENU_PANEL';
        default:
            //   console.log('reducer match nothing:')
            return state;
    }
}

export  default menustateReducer;