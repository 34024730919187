/**
 * yi 2020
 *
 *
 */

import React from 'react';
import DataListitem from "../../components/DataListitem";
import {Tabs,Breadcrumb,Descriptions,Badge,List,Input, Drawer, Button,Modal,Result, Typography, Space, Divider, Statistic, Row, Col, Pagination, Alert} from "antd";


import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MessageOutlined,
    ProjectOutlined,
    LikeOutlined,
    InboxOutlined,
    LoadingOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/Adminbridge";
import {Ytextarea} from "../../components/Formelements";
const { TextArea } = Input;

const { Title,Text,Paragraph } = Typography;
const { TabPane } = Tabs;

/*
* choose registration account type
*/



class Messageview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            GET_Data:null,
            loading:true,
            visible: false,
            Drawereditvisible:false,
            total_list:null,
            current_page:null,
            error:false,
            errorContent:'',
            Modalvisible:false,
            itemstate:'true',
            edit_item:{
                id:'',
                comment_a:'',
                comment_q:'',
            },
            submit_data:{
                comment_a:'',
                comment_q:'',
            },
            tab_key:1,
            tab_value:['','all','presale','aftersale','suggest'],


        }

        this.onPageChange =this.onPageChange.bind(this)
        this.get_data_list =this.get_data_list.bind(this)
        this.createFAQ =this.createFAQ.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this);
        this.qainput =this.qainput.bind(this);
        this.edit_qainput =this.edit_qainput.bind(this);
        this.handleCancel =this.handleCancel.bind(this);
        this.Tabcallback =this.Tabcallback.bind(this);
    }

    componentWillMount() {


        this.get_data_list(1)

    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            GET_Data:null,
            loading:true,
        })
        this.get_data_list(page);
    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    handleCancel(){

        this.setState({
            Modalvisible: false,
        });
    }



    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    showDrawer_edit = (item) => {
        // console.log(item)
        this.setState({
            edit_item:item,
        },()=>{
            if(item.remark=='false')this.message_readed(item.id)
            this.setState({
                Drawereditvisible: true,
            });
        });


    };

    onCloseDrawer_edit = () => {
        this.setState({
            Drawereditvisible: false,
        });
    };




    get_data_list(page){
        const postdata={
            type:this.state.tab_value[this.state.tab_key],
            page:page,

        }
        Http.get_leavemessage(postdata).then((res)=>{

            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                    GET_Data: res.data.data,
                    loading: false,
                })
            }else {
                this.setState({
                    GET_Data: res.data,
                    loading: false,
                    total_list: res.data.length,
                    current_page: 1,
                })
            }




        }).catch((res) => {
            console.log(res.message)
        })

    }

    createFAQ(){
        this.showDrawer()
    }

    edit_qainput(ta){
        const name=ta.target.name
        const val=ta.target.value

        const submitdata=this.state.edit_item
        if(name=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(name=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }


    }

    qainput(ta,val){
        const submitdata=this.state.submit_data
        if(ta=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(ta=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }
    }
    submitFAQ(){
        if(this.state.submit_data.comment_a=='' || this.state.submit_data.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.submit_data.comment_a,
                comment_q:this.state.submit_data.comment_q,
            }

            Http.create_faq(postdata).then((res)=>{

                this.onCloseDrawer();
                this.onPageChange(1);

            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }

    }

    deleteFAQ(){

        this.setState({
            Modalvisible: true,
        });

    }

    submiteditFAQ(state){
        if(this.state.edit_item.comment_a=='' || this.state.edit_item.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.edit_item.comment_a,
                comment_q:this.state.edit_item.comment_q,
                id:this.state.edit_item.id,
                state:state
            }
            this.setState({
                Modalvisible: false,
            });

            Http.edit_faq(postdata).then((res)=>{

                this.onCloseDrawer_edit();

                setTimeout(this.onPageChange(1), 1000 )


            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }
    }


    Tabcallback(key) {
       // console.log(key);
        this.setState({
            tab_key:key,
        },()=>{
            this.get_data_list(1)
        })


    }


    /**
     * extra func
     * @returns {JSX.Element}
     */

    message_readed(id){
        const postdata={
            id:id,
        }
        Http.message_readed(postdata).then((res)=>{
            console.log('readed')
            this.get_data_list(this.state.current_page)
        }).catch((res) => {
            console.log(res.message)
        })
    }



    render() {



        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>


                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px 30px 0px 30px',


                    }}>


                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}> </Title>

                        </Space>

                        <Tabs defaultActiveKey={this.state.tab_key} onChange={this.Tabcallback}>
                            <TabPane tab="全部" key="1">

                            </TabPane>
                            <TabPane tab="售前咨询" key="2">

                            </TabPane>
                            <TabPane tab="售后无忧" key="3">

                            </TabPane>
                            <TabPane tab="意见建议" key="4">

                            </TabPane>
                        </Tabs>


                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        paddingTop: '0px',


                    }}>

                        <List

                            itemLayout="horizontal"
                            style={{backgroundColor:'#fff'}}
                            dataSource={this.state.GET_Data}
                            renderItem={item => (
                                <List.Item style={{paddingLeft:'30px'}}

                                >
                                    {(item.remark=='false')?(
                                        <div style={{paddingRight:'30px'}}>
                                            <Badge count={1} dot>
                                                <InboxOutlined />
                                            </Badge>
                                        </div>

                                    ):(
                                        <div style={{paddingRight:'30px'}}>
                                            <InboxOutlined style={{color:'#bebebe'}} />
                                        </div>
                                    )}
                                    <List.Item.Meta
                                        title={item.name}
                                        description={item.content}
                                    />
                                    <Button key={'back'} type="text" onClick={()=>this.showDrawer_edit(item)} style={{color:"#999",marginRight:'30px',fontSize:'13px'}}  >查看</Button>


                                </List.Item>
                            )}
                        />



                    </div>

                    <div style={{
                        width: '100%',
                        height: '86px',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"space-between",
                    }}>
                        <Pagination
                            size="small"
                            defaultPageSize={10}
                            defaultCurrent={this.state.current_page}
                            total={this.state.total_list}
                            onChange={this.onPageChange}
                        />



                    </div>

                    <Drawer
                        width={640}
                        title="创建新的FAQ"
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer}
                        visible={this.state.visible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button key={'back'} type="text"   onClick={()=>this.submitFAQ()}>提交</Button>
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Text  type="secondary" >输入问题</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <Ytextarea width={'100%'} height='50px' name={'question'} full={'none'}  callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={60}/>

                        <Text  type="secondary"  >输入答案</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>
                        <Ytextarea width={'100%'} height='120px' name={'answer'} full={'none'}   callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={250}/>



                    </Drawer>



                    <Drawer
                        width={640}
                        title={'消息ID：'+this.state.edit_item.id}
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer_edit}
                        visible={this.state.Drawereditvisible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {/*
                                <Button key={'delect'} type="text"   onClick={()=>this.deleteFAQ()}>删除</Button>

                                <Button key={'back'} type="text"   onClick={()=>this.submiteditFAQ('true')}>更新</Button>
                                */}
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Descriptions
                            title=""


                        >

                            <Descriptions.Item label="姓名">{this.state.edit_item.name}</Descriptions.Item>
                            <Descriptions.Item label="邮箱">{this.state.edit_item.email}</Descriptions.Item>
                            <Descriptions.Item label="电话">{this.state.edit_item.mobile}</Descriptions.Item>
                            <Descriptions.Item label="创建日期">{this.state.edit_item.created_at}</Descriptions.Item>

                        </Descriptions>



                        <Text  type="secondary" >留言</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <textarea style={{
                            border:'0px solid #efefef',
                            outline: 'none',
                            width:'100%',
                            paddingLeft:'2px',
                            paddingTop:'2px',
                            marginBottom:'20px',
                            height:'auto',
                            overflow:'hidden',
                            color:'#999',
                            resize:'none',
                            backgroundColor:'',
                            fontSize:'12px'
                        }}
                                  readOnly={true}
                                  name={'question'}
                                  onChange={this.edit_qainput}
                                  value={this.state.edit_item.content}

                        />






                    </Drawer>

                    <Modal
                        title="删除"
                        style={{ top: 20 }}
                        visible={this.state.Modalvisible}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                            <Text type="secondary">确认要删除这条数据吗？</Text>

                            <Button type="text" onClick={()=>this.submiteditFAQ('false')}>确认</Button>

                        </div>
                    </Modal>

                </div>
            )
        }
    }
}


export default Messageview;