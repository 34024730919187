//by yi 2020 kuaipf project


import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import {Link} from "react-router-dom"
import logoY from '../asset/logo-y.svg';
import logoB from '../asset/logo-b.svg';
import menuIcon from '../asset/menu.svg';
import {Motion, spring} from 'react-motion';

import {connect} from 'react-redux'

import  '../components/global'
import {authlogin} from "../reduceractions";
import {autoAchtion} from "./functions/authAchtions";
import {Badge,Alert} from "antd";



import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BellOutlined,

} from '@ant-design/icons';

const mapStateToProps = (state) =>({
    // auth  reducers
    // auth :state.auth,
    counter:state.counter,
    redux_islog:state.redux_islog,



})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    toauthlogin(){
        dispatch(authlogin())
        // console.log(authlogin())
    }
})



 class PanelNav extends React.Component{




 constructor(props){
    super(props)
    this.state ={
      initall:0,
      scrolliconAlpha:1,
      showscrolltop:0,
      showNav:'block',
      nav_pc:'visible',
      nav_mb:'hidden',
      logo_width:'200px',
      barbgdisable:true,
      barbgopacity:0,
      barbgheight:60,
      barPaddingtop:0,
      logoSvg:logoY,
      barBgtop:-5,

        homebtn:{
            fontSize:'13px',
            fontFamily:'"Microsoft YaHei", "黑体","宋体",',
            color:'#000',
            width:'60px',
            height:'30px',
            alignItems:'center',
            display:'flex',
            justifyContent: 'center',
            backgroundColor:'#ffe600',
            borderRadius: '15px',
            textDecoration: 'none',
            transition:'0.15s ease-out all',
            cursor: 'pointer',
        },
        newmsg:this.props.data,
       
       
    }
   
    this.handleScroll =this.handleScroll.bind(this)
     this.notic =this.notic.bind(this)



  }

     mouseEnter(event) {
         //console.log('in')

         this.setState({
                 homebtn:{
                     fontSize:'13px',
                     fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                     color:'#000',
                     width:'66px',
                     height:'30px',
                     alignItems:'center',
                     display:'flex',
                     justifyContent: 'center',
                     backgroundColor:'#ffe600',
                     borderRadius: '15px',
                     textDecoration: 'none',
                     transition:'0.15s ease-out all',
                     cursor: 'pointer',
                 }

             }
         )

     }

     mouseOut(event) {
         //console.log('out')
         this.setState({
                 homebtn:{
                     fontSize:'13px',
                     fontFamily:'"Microsoft YaHei", "黑体","宋体",',
                     color:'#000',
                     width:'60px',
                     height:'30px',
                     alignItems:'center',
                     display:'flex',
                     justifyContent: 'center',
                     backgroundColor:'#ffe600',
                     borderRadius: '15px',
                     textDecoration: 'none',
                     transition:'0.15s ease-out all',
                     cursor: 'pointer',
                 }
             }
         )
     }

  handleScroll(){
   this.scrolltopcount=window.pageYOffset
   //console.log(this.scrolltopcount)
   let scrolliconAlpha=1
     if(this.scrolltopcount >= window.innerHeight){
         scrolliconAlpha=0 
    }else{
         scrolliconAlpha=1
    }
     this.setState({
         scrolliconAlpha:scrolliconAlpha,
         showscrolltop:1-scrolliconAlpha,
        })



  }


  componentDidMount() {

      this.setState({
          barbgdisable:false,
          barbgopacity:1,
          barbgheight:80,
          barPaddingtop:6,
          logoSvg:logoY,
          barBgtop:0,
          new_message:0,
      })
      console.log(this.state.newmsg)

  
  }

  componentDidUpdate(){

     
  
  }



  componentWillMount() {



  //check page state
   // console.log('Page state:'+global.gData.Pagestate)



  //
  //window.addEventListener('scroll', this.handleScroll);
  if(window.innerWidth<1124){
      this.centerViewbox_W='320px'
       this.QAViewbox_W='90%'   
       this.setState({
           newmsg:this.props.data
       })
       
    } 
   
  }


   //close component
  componentWillUnmount(){
   // window.removeEventListener("scroll", this.handleScroll)

  }


  //props update

  componentWillReceiveProps(){






  }


  logoutHandler(e){
      this.props.autoAchtion(null,'logout')
  }

     notic(){
     if(this.props.callBack){
         this.props.callBack()
     }
     }



  render(){ 

  const styles={
    navBar_bg:{
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    top:'-5px',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'absolute',
    backgroundColor: '#f6d808',
    boxShadow:'0px 0px 2px #999'
    },
    navBar_box:{
    width: '100%',
    height: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
    position: 'absolute',  
    top:'0px',
    },
  }


   const navStyle={
     
      color:'#000',
      textDecoration: 'none',
       cursor: 'pointer'

    
    }





      let logoleft="4%"

      if (window.innerWidth <= 1124) {
          logoleft="2%"
      }

   
   return(

      <nav style={{display:this.state.showNav,zIndex:50}}>
      <Motion style={{ opacity: spring(this.state.barbgopacity),height:spring(this.state.barbgheight),top:spring(this.state.barBgtop) }}  >
      {
      ({ opacity,height,top}) => <div    style={Object.assign({}, styles.navBar_bg, { opacity,height,top} )}  >   </div>
      }
      </Motion>

      <Motion style={{top:spring(this.state.barPaddingtop)}}>
       {
        ({top})=>
       <div   style={Object.assign({},styles.navBar_box,{top})}> 

        <img src={this.state.logoSvg} className="Top-logo" alt="logo"    style={{height:'30px',backgroundColor:'',paddingLeft:logoleft}}/>

        <div className='nav_content_mobile' style={{visibility:this.state.nav_mb}}>
           <div className='nav_items_mobile'>
           </div>
           <img src={menuIcon}   className="menuIcon"   alt="menu" /> 
        </div>

        <div   style={{visibility:this.state.nav_pc,width:'90%',display:'flex',justifyContent: 'space-between',backgroundColor:''}} >
        <ul className="nav-links">

        </ul> 

       
        <ul className="control-bar">
          <Link style={navStyle} to="/home">
            <li>网站首页</li>
          </Link>


            <li onClick={this.notic}>
                <Badge count={this.props.data} dot>
                <BellOutlined style={{color:'#000',fontSize:'14px',cursor:'pointer'}}  />
                </Badge>
            </li>

            <li>
            <div onClick={()=>this.logoutHandler()} style={this.state.homebtn} onMouseEnter={()=>{this.mouseEnter() }}  onMouseLeave={()=>{this.mouseOut() }} >

                登出

            </div>
            </li>
        </ul>
      </div>   
      </div>
      }
      </Motion>
 
      </nav>
    )
}
  
}



export default connect(mapStateToProps,{autoAchtion})(PanelNav) ;