/**
 * yi 2020
 *
 *
 */

import React from 'react';
import {Modal,Button, Result, Typography, Space, Divider, Statistic, Row, Col, Input, Alert} from "antd";
import VcodeBtn from "../../components/60slimitbutton";

import {
    MailOutlined,
    AuditOutlined,
    KeyOutlined,
    WechatOutlined,
    PhoneOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/ApiBridge";
import {Yinput} from "../../components/Formelements";


const {Title,Text,Paragraph } = Typography;


/*
* choose registration account type
*/


class Setaccount extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            initial:1,
            Drawervisible:false,
            mobile:'',
            pass_code:'',
            error:false,
            errorContent:'',
            viewstate:'',
            resulttitle:'',
            wechat_id:'',
            newpassword:'',
            oldpassword:'',
            repassword:'',
            company:'',
            resultcontent:'您的电话号码已修改成功',
            Modalvisible: false,
            confirmLoading: false,


        }
        this.inputChannge =this.inputChannge.bind(this);
        this.send_pass_code =this.send_pass_code.bind(this);
        this.submitMobileChange =this.submitMobileChange.bind(this);
        this.submitedDone =this.submitedDone.bind(this);
        this.onClose =this.onClose.bind(this);
        this.submitWechatchange =this.submitWechatchange.bind(this);
        this.submitWithPasscode =this.submitWithPasscode.bind(this);
        this.submit_check_pass =this.submit_check_pass.bind(this);
        this.showErrorbar =this.showErrorbar.bind(this);
        this.setPswWithPasscode =this.setPswWithPasscode.bind(this);
        this.YiinputChannge =this.YiinputChannge.bind(this);
        this.setCompanyWithPasscode =this.setCompanyWithPasscode.bind(this);

    }

    componentDidMount() {
     this.setState({viewstate:this.props.action})


    }

    /**
     *
     * send pass code
     */
    submit_check_pass(){

        Http.Private_pass().then((res) => {

            this.setState({
                Modalvisible: true,
            });

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    /**
     * show modal
     */

    showModal = () => {
        if(this.state.wechat_id==''){
            this.showErrorbar('请填写新的微信号')
        }else {
            this.submit_check_pass()
        }
    };

    showModal_password = () => {
        if(this.state.newpassword=='' || this.state.oldpassword=='' || this.state.repassword=='null' ){
            this.showErrorbar('请填写完整信息')
        }else{
            if(this.state.newpassword != this.state.repassword){
                this.showErrorbar('两次输入的密码不匹配')
            }else{
                this.submit_check_pass()
            }
        }

    };

    showModal_company= () => {
            if(this.state.company==''){
                this.showErrorbar('请填写新的企业名称')
            }else {
                this.submit_check_pass()
            }
    }


    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            Modalvisible: false,
        });
    };

    /**
     * input change
     * @param e
     */


    inputChannge(e){

        const val=e.target.value;
        switch (e.target.id) {
            case 'mobile':
                const numval=val.replace(/[^\d]/g, '');
                  this.setState({mobile:numval})
                break
            case 'pass_code':
                this.setState({pass_code:val})
                break
            case 'wechat_id':
                this.setState({wechat_id:val})
                break
            case 'company':
                this.setState({company:val})
                break
            default:
        }

    }
    YiinputChannge(key,val){


        switch (key) {
            case 'setpassword':
                this.setState({newpassword:val})
                break
            case 'repassword':
                this.setState({repassword:val})
                break
            case 'oldpassword':
                this.setState({oldpassword:val})
                break
            default:
        }

    }

    /**
     * send pass code
     */

    send_pass_code(){
         if(this.state.mobile==''){
             this.setState({
                 error:true,
                 errorContent:'请填写手机号',
             })
         }else{
             const postdata={
                 mobile:this.state.mobile,

             }
             Http.Send_pass(postdata).then((res)=>{
                   console.log('send pass code')
             }).catch((res) => {
                 this.showErrorbar(res.message)

             })

         }
    }

    /**
     * submit mobile changes data
     *
     */

    submitMobileChange(){

         if(this.state.mobile=='' || this.state.pass_code==''){
             this.setState({
                 error:true,
                 errorContent:'请填写手机号及验证码',
             })
         }else{
             const postdata={
                 mobile:this.state.mobile,
                 pass_code:this.state.pass_code,
             }
             Http.Change_mobile(postdata).then((res)=>{

                 this.setState({
                     viewstate:'set_submit_success',
                     resultcontent:'您的电话号码已修改成功',
                 })

             }).catch((res) => {
                 this.showErrorbar(res.message)

             })
         }

    }




    /**
     * submit normail change (wechat company password)
     * @param submfunction
     */

    submitWithPasscode(submfunction){
        if(this.state.pass_code==''){
            this.showErrorbar('请填写正确验证码')

        }else{
                submfunction()
        }
    }

    submitWechatchange(){

        const postdata={
            wechat_id:this.state.wechat_id,
            pass_code:this.state.pass_code,
        }
        Http.Change_wechatid(postdata).then((res)=>{
            if(res.status) {
                this.setState({
                    Modalvisible: false,
                    viewstate: 'set_submit_success',
                    resultcontent: '您的微信号码已修改成功',
                })
            }else{
                this.showErrorbar(res.message)
            }
        }).catch((res) => {
            this.showErrorbar(res.message)

        })

    }



    setPswWithPasscode(){
        const postdata={
            oldpassword:this.state.oldpassword,
            newpassword:this.state.newpassword,
            pass_code:this.state.pass_code,
        }
        Http.Change_psw(postdata).then((res)=>{
            if(res.status) {
                this.setState({
                    Modalvisible: false,
                    viewstate: 'set_submit_success',
                    resultcontent: '您的密码已修改成功',
                })
            }else{
                this.showErrorbar(res.message)
            }
        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }

    setCompanyWithPasscode(){
        const postdata={
            company:this.state.company,
            pass_code:this.state.pass_code,
        }
        Http.Change_company(postdata).then((res)=>{
            if(res.status) {
                this.setState({
                    Modalvisible: false,
                    viewstate: 'set_submit_success',
                    resultcontent: '企业名称已修改成功',
                })
            }else{
                this.showErrorbar(res.message)
            }
        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }



    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };


    submitedDone(){

     this.props.callBack('account_view')
    }


    render(){

        const viewstate=this.state.viewstate;

      if(viewstate=='set_mobile') {
          return (


              <div style={{width: '100%', padding: '0px'}}>


                  <div style={{
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#fff',
                      padding: '30px',
                      marginBottom: '10px',
                      display:"flex",
                      justifyContent:"center",

                  }}>

                      <div style={{width:'auto'}}>
                      <Space>
                          <Title level={5} style={{paddingTop: '6px'}}>更改手机号码</Title>


                      </Space>

                          <Divider/>
                      <Space direction={"vertical"}>
                      <Text type="secondary">新手机号码 </Text>
                      <Input id='mobile' style={{fontSize: '13px',width:'200px'}} value={this.state.mobile} placeholder="+86"
                             onChange={this.inputChannge}/>

                             <Space>
                          <Input id='pass_code' style={{fontSize: '13px',width:'200px'}} placeholder=""
                                 onChange={this.inputChannge}/>
                          <VcodeBtn width={'100px'} callBack={this.send_pass_code}/>
                             </Space>
                          <Divider/>
                          <div style={{width:'100%',height:'50px',display:"flex",justifyContent:'center',alignItems:"center"}}>

                              <Button key={'back'} type="text" onClick={this.submitedDone}>返回</Button>
                          <Button type="text" onClick={this.submitMobileChange}>提交</Button>
                          </div>
                      </Space>
                       </div>
                  </div>

                  {
                      this.state.error ? (
                          <Alert
                              message="Error!"
                              description={this.state.errorContent}
                              type="error"
                              closable
                              onClose={this.onClose}
                          />

                      ) : ('')
                  }


              </div>
          )
      }else if(viewstate=='set_submit_success') {
         return(
             <div style={{
                 width: '100%',
                 height: '500px',
                 backgroundColor: '#fff',
                 padding: '0px',
                 marginBottom: '10px',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center'
             }}>

                 <Result
                     status="success"
                     title="提交成功!"
                     subTitle={this.state.resultcontent}
                     extra={[


                         <Button key={'back'} type="text" onClick={this.submitedDone}>返回</Button>
                     ]}
                 />


             </div>
         )

      }else if(viewstate=='set_wechat') {

          return (
              <div style={{width: '100%', padding: '0px'}}>

                  <Modal
                      title="安全验证"
                      style={{ top: 20 }}
                      visible={this.state.Modalvisible}
                      onCancel={this.handleCancel}
                      footer={null}
                  >
                      <Space>
                          <Text type="secondary">验证码已发送至{this.props.bridge.mobile}</Text>
                          <Input id='pass_code' style={{fontSize: '13px',width:'200px'}} placeholder=""
                                 onChange={this.inputChannge}/>
                          <Button type="text" onClick={()=>this.submitWithPasscode(this.submitWechatchange)}>确认</Button>
                      </Space>
                  </Modal>

                  <div style={{
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#fff',
                      padding: '30px',
                      marginBottom: '10px',
                      display:"flex",
                      justifyContent:"center",

                  }}>
                      <div style={{width:'auto'}}>
                          <Space>
                              <Title level={5} style={{paddingTop: '6px'}}>更改微信号</Title>

                          </Space>

                          <Divider/>
                          <Space direction={"vertical"}>
                              <Text type="secondary">输入新的微信号 </Text>
                              <Input id='wechat_id' style={{fontSize: '13px',width:'200px'}}   placeholder=""
                                     onChange={this.inputChannge}/>

                              <Divider/>
                              <div style={{width:'100%',height:'50px',display:"flex",justifyContent:'center',alignItems:"center"}}>
                                  <Button key={'back'} type="text" onClick={this.submitedDone}>返回</Button>
                                  <Button type="text" onClick={this.showModal}>提交</Button>
                              </div>
                          </Space>
                      </div>
                  </div>

                  {
                      this.state.error ? (
                          <Alert
                              message="Error!"
                              description={this.state.errorContent}
                              type="error"
                              closable
                              onClose={this.onClose}
                          />

                      ) : ('')
                  }



              </div>
                  )
      } else if(viewstate=='set_password') {

          return (
              <div style={{width: '100%', padding: '0px'}}>

                  <Modal
                      title="安全验证"
                      style={{ top: 20 }}
                      visible={this.state.Modalvisible}
                      onCancel={this.handleCancel}
                      footer={null}
                  >
                      <Space>
                          <Text type="secondary">验证码已发送至{this.props.bridge.mobile}</Text>
                          <Input id='pass_code' style={{fontSize: '13px',width:'200px'}} placeholder=""
                                 onChange={this.inputChannge}/>
                          <Button type="text" onClick={()=>this.submitWithPasscode(this.setPswWithPasscode)}>确认</Button>
                      </Space>
                  </Modal>

                  <div style={{
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#fff',
                      padding: '30px',
                      marginBottom: '10px',
                      display:"flex",
                      justifyContent:"center",

                  }}>
                      <div style={{width:'auto'}}>
                          <Space>
                              <Title level={5} style={{paddingTop: '6px'}}>更改密码</Title>

                          </Space>
                          <Paragraph style={{width:'220px',fontSize:'11px'}}>
                              <Text type="secondary">为保障您的账号安全，密码长度至少为8位并建议以大写字母开头，且包含数字及字符。 </Text>
                          </Paragraph>
                          <Divider/>

                          <Space direction={"vertical"}>
                              <Text type="secondary">输入旧的密码 </Text>
                              <Yinput width='100%' height='30px' name='oldpassword' broderColor={'#b0b0b0'}   callBack={this.YiinputChannge} marginBottom={'0px'}   type='password' bgColor='#fff' state={""}  value={""} preset='' limit={20}/>
                              <Text type="secondary">输入新的密码 </Text>
                              <Yinput width='100%' height='30px' name='setpassword' broderColor={'#b0b0b0'}   callBack={this.YiinputChannge} marginBottom={'0px'} analyze={true} type='password' bgColor='#fff' state={""}  value={""} preset='' limit={20}/>
                              <Text type="secondary">确认新的密码 </Text>
                              <Yinput width='100%' height='30px' name='repassword' broderColor={'#b0b0b0'}   callBack={this.YiinputChannge}  marginBottom={'0px'}  type='password' bgColor='#fff' state={""}  value={""} preset='' limit={20}/>
                              <Divider/>
                              <div style={{width:'100%',height:'50px',display:"flex",justifyContent:'center',alignItems:"center"}}>
                                  <Button key={'back'} type="text" onClick={this.submitedDone}>返回</Button>
                                  <Button type="text" onClick={this.showModal_password}>提交</Button>
                              </div>
                          </Space>
                      </div>
                  </div>

                  {
                      this.state.error ? (
                          <Alert
                              message="Error!"
                              description={this.state.errorContent}
                              type="error"
                              closable
                              onClose={this.onClose}
                          />

                      ) : ('')
                  }

              </div>
          )
      } else if(viewstate=='set_company') {

          return (
              <div style={{width: '100%', padding: '0px'}}>

                  <Modal
                      title="安全验证"
                      style={{ top: 20 }}
                      visible={this.state.Modalvisible}
                      onCancel={this.handleCancel}
                      footer={null}
                  >
                      <Space>
                          <Text type="secondary">验证码已发送至{this.props.bridge.mobile}</Text>
                          <Input id='pass_code' style={{fontSize: '13px',width:'200px'}} placeholder=""
                                 onChange={this.inputChannge}/>
                          <Button type="text" onClick={()=>this.submitWithPasscode(this.setCompanyWithPasscode)}>确认</Button>
                      </Space>
                  </Modal>

                  <div style={{
                      width: '100%',
                      height: 'auto',
                      backgroundColor: '#fff',
                      padding: '30px',
                      marginBottom: '10px',
                      display:"flex",
                      justifyContent:"center",

                  }}>
                      <div style={{width:'auto'}}>
                          <Space>
                              <Title level={5} style={{paddingTop: '6px'}}>更改企业名称</Title>

                          </Space>
                          <Divider/>

                          <Space direction={"vertical"}>
                              <Text type="secondary">输入新的企业名称 </Text>
                              <Input id='company' style={{fontSize: '13px',width:'200px'}}   placeholder=""
                                     onChange={this.inputChannge}/>
                              <Divider/>
                              <div style={{width:'100%',height:'50px',display:"flex",justifyContent:'center',alignItems:"center"}}>
                                  <Button key={'back'} type="text" onClick={this.submitedDone}>返回</Button>
                                  <Button type="text" onClick={this.showModal_company}>提交</Button>
                              </div>
                          </Space>
                      </div>
                  </div>

                  {
                      this.state.error ? (
                          <Alert
                              message="Error!"
                              description={this.state.errorContent}
                              type="error"
                              closable
                              onClose={this.onClose}
                          />

                      ) : ('')
                  }

              </div>
          )
      }else{
          return(<div></div>)
      }
    }
}


export default Setaccount;