/**
 * yi 2020
 *
 *
 */

import React from 'react';
import Http from "../../components/functions/ApiBridge";

import {Steps, Typography, Divider, Pagination, Space, Drawer, Button, Timeline} from 'antd';

import {
    MenuUnfoldOutlined,
    LoadingOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';


const { Step } = Steps;
const { Text,Title } = Typography;


class Projectview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            order_list:null,
            total_list:null,
            current_page:null,
            order_bgColor:'#fff',
            type:'cl',
            Stepscurrent:0,
            Modalvisible: false,
            progress_state:['项目创建','前期准备','进行中','交付'],
            Drawervisible:false,
            target_order:'',
            target_index:'',
            order_events:'',

        }


        this.onPageChange =this.onPageChange.bind(this)
        this.set_order_data =this.set_order_data.bind(this)
        this.Get_order_events =this.Get_order_events.bind(this)




    }

    componentDidMount() {




    }
    componentWillMount() {


        this.Get_peoject_list(1);
        this.setState({
            type:this.props.bridge.type
        })

    }

    Get_peoject_list(page){
        const postdata={
            'page':page,
        }
        Http.get_Order_list(postdata).then((res)=>{

            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                })
                this.set_order_data(res.data.data)
            }else{
                this.setState({
                    total_list: res.data.length,
                    current_page: 1,
                })
                this.set_order_data(res.data)
            }



        }).catch((res) => {
            console.log(res.message)
            if(res.code=='600003'){
               this.setState({order_list:'noproject'})
            }
        })
    }

    set_order_data(Orderdata){
        const order_data=Orderdata;
        let new_order_list=[];
        order_data.map((key, index) => {
            let keyitem=key;

            if(key.state=='true'){
                keyitem.bgColor='#fff';
            }else{
                keyitem.bgColor='#f5f5f5';
            }
            new_order_list.push(keyitem);
        })
        this.setState({
             order_list:new_order_list,

        })


    }

    showTotal(total) {
        return `Total ${total} items`;
    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            order_list:null,

        })
        this.Get_peoject_list(page);
    }


    onCloseDrawer = () => {

        this.setState({
            Drawervisible: false,
            order_events:''

        });
    };


    showDrawer = (item,index) => {


        this.setState({
            target_order:item,
            target_index:index,

        },()=>{

            this.setState({ Drawervisible: true,})

            this.Get_order_events(item.id)
        });

    };

    Get_order_events(order_id){
        console.log(order_id)
        const post_data = {
            order_id:order_id,
        }


        Http.user_get_order_event(post_data).then((res) => {


            if(res.data.length>0){
                this.setState({
                    order_events:res.data
                })
            }



        }).catch((res) => {

            this.showErrorbar(res.message)
        })
    }

    /**
     *
     * error Drower
     * @param error
     */

    showErrorbar(error){
        this.setState({
            error:true,
            errorContent:error,
        })
    }



    render() {

        const timelineItemcolor=['orange','orange','orange','green','green']

        if (this.state.order_list == null) {
            return (<div  style={{width: '100%', height: '500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <LoadingOutlined style={{fontSize:'20px'}} />
               </div>);

        }else if(this.state.order_list == 'noproject'){
            return (
                <div style={{width: '100%', padding: '0px'}}>
                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"center",

                    }}>
                        <Text type="secondary">没有进行中的项目 </Text>
                    </div>
                </div>
            )
        } else {
            if (this.state.type == 'cl') {
                return (
                    <div style={{width: '100%', padding: '0px'}}>

                        {
                            this.state.order_list.map((key, index) => (

                                <div key={index} onClick={()=>this.showDrawer(key,index)}  style={{
                                    width: '100%',
                                    backgroundColor: key.bgColor,
                                    padding: '30px',
                                    marginBottom: '10px',
                                    cursor:"pointer",
                                }}>
                                    <Title level={5}>项目名称 : {key.order_name}</Title>
                                    <div style={{
                                        width: '100%',
                                        height: '30px',
                                        backgroundColor: '',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <div style={{width: '50%', fontSize: '13px'}}>
                                            <Text type="secondary">项目编号 {key.order_id}</Text>
                                        </div>
                                        <div style={{width: '30%', fontSize: '13px'}}>
                                            <Text type="secondary">项目负责人 {key.name}</Text>
                                        </div>
                                        <div style={{width: '20%', fontSize: '13px'}}>
                                            <Text type="secondary">上线时间 {key.deliver.substr(0, 10)}</Text>
                                        </div>
                                    </div>

                                    <div style={{
                                        width: '100%',
                                        height: '30px',
                                        backgroundColor: '',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingBottom: '20px'
                                    }}>
                                        <div style={{width: '50%', fontSize: '13px'}}>
                                            <Text type="secondary">项目状态 {(key.state=='true')?'正常':'终止'}</Text>
                                        </div>
                                        <div style={{width: '30%', fontSize: '13px'}}>
                                            <Text type="secondary">项目进度 {(key.progress==null)?('等待审核'):(this.state.progress_state[key.progress])}</Text>
                                        </div>
                                        <div style={{width: '20%', fontSize: '13px'}}>
                                            <Text type="secondary">创建日期 {key.created_at.substr(0, 10)}</Text>
                                        </div>
                                    </div>


                                    <Steps current={(key.progress==null)?0:Number(key.progress)} progressDot status={(key.state=='true')?'process':'error'}>
                                        <Step title="项目创建" description={key.created_at.substr(0, 10)} />
                                        <Step title="前期准备" description={/*key.brief_call.substr(0, 10) +'-'+ key.calling_time*/''} />
                                        <Step title="进行中" description="" />
                                        <Step title="交付" description={key.deliver.substr(0, 10)} />
                                    </Steps>

                                </div>
                            ))
                        }

                        <div style={{
                            width: '100%',
                            height: '86px',
                            backgroundColor: '#fff',
                            padding: '30px',
                            marginBottom: '10px'
                        }}>
                            <Pagination
                                size="small"

                                defaultCurrent={this.state.current_page}
                                total={this.state.total_list}
                                onChange={this.onPageChange}
                            />

                        </div>

                        <Drawer
                            width={'40%'}
                            title={'项目日志'}
                            placement="right"
                            closable={false}
                            onClose={this.onCloseDrawer}
                            visible={this.state.Drawervisible}
                            footer={
                                <div
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >

                                </div>
                            }
                        >

                            <Timeline style={{paddingTop:10,backgroundColor:'',height:'auto'}}>

                                {
                                    (this.state.order_events.length>0)?(
                                        this.state.order_events.map((key, index) => (
                                            <Timeline.Item key={index} color={(key.progress==null)?('yellow'):(timelineItemcolor[key.progress])}>
                                                <div style={{width:'100%',backgroundColor:'',height:20}}  >
                                                    {key.event} <span style={{width:80,paddingLeft:20}}>{key.request_date.substr(0, 10)} </span>


                                                </div>
                                            </Timeline.Item>
                                        ))


                                    ):('')
                                }

                            </Timeline>


                        </Drawer>


                    </div>
                )
            }else {
                if (this.state.total_list == 0) {
                       return ( <div style={{width: '100%', padding: '0px'}}>
                           <div style={{
                               width: '100%',
                               height: 'auto',
                               backgroundColor: '#fff',
                               padding: '30px',
                               marginBottom: '10px',
                               display:"flex",
                               justifyContent:"center",

                           }}>
                               <Text type="secondary">没有进行中的项目 </Text>
                           </div>
                       </div>)
                } else {
                    return (
                        <div style={{width: '100%', padding: '0px'}}>

                            {
                                this.state.order_list.map((key, index) => (

                                    <div key={index}  style={{
                                        width: '100%',
                                        backgroundColor: key.bgColor,
                                        padding: '30px',
                                        marginBottom: '10px',

                                    }}>
                                        <Title level={5}>项目名称 : {key.order_name}</Title>
                                        <div style={{
                                            width: '100%',
                                            height: '30px',
                                            backgroundColor: '',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}>
                                            <div style={{width: '50%', fontSize: '13px'}}>
                                                <Text type="secondary">项目编号 {key.order_id}</Text>
                                            </div>
                                            <div style={{width: '30%', fontSize: '13px'}}>

                                            </div>
                                            <div style={{width: '20%', fontSize: '13px'}}>
                                                <Text type="secondary">上线时间 {key.deliver.substr(0, 10)}</Text>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '100%',
                                            height: '30px',
                                            backgroundColor: '',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            paddingBottom: '20px'
                                        }}>
                                            <div style={{width: '50%', fontSize: '13px'}}>
                                                <Text type="secondary">项目状态 {(key.state=='true')?'正常':'终止'}</Text>
                                            </div>
                                            <div style={{width: '30%', fontSize: '13px'}}>
                                                <Text type="secondary">项目进度 {(key.progress==null)?('等待审核'):(this.state.progress_state[key.progress])}</Text>
                                            </div>
                                            <div style={{width: '20%', fontSize: '13px'}}>
                                                <Text type="secondary">创建日期 {key.created_at.substr(0, 10)}</Text>
                                            </div>
                                        </div>


                                        <Steps current={(key.progress==null)?0:Number(key.progress)} progressDot status={(key.state=='true')?'process':'error'}>
                                            <Step title="项目创建" description={key.created_at.substr(0, 10)} />
                                            <Step title="前期准备" description={key.brief_call.substr(0, 10) +'-' +key.calling_time} />
                                            <Step title="进行中" description="" />
                                            <Step title="交付" description={key.deliver.substr(0, 10)} />
                                        </Steps>



                                    </div>
                                ))
                            }

                            <div style={{
                                width: '100%',
                                height: '86px',
                                backgroundColor: '#fff',
                                padding: '30px',
                                marginBottom: '10px'
                            }}>
                                <Pagination
                                    size="small"
                                    defaultPageSize={10}
                                    defaultCurrent={this.state.current_page}
                                    total={this.state.total_list}
                                    onChange={this.onPageChange}
                                />

                            </div>


                        </div>
                    )
                }
            }
        }
    }
}


export default Projectview;