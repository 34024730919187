//by yi 2020 kuaipf project


import React from 'react';
import './App.css';
import logoW from '../asset/logo-w.svg';
import {Link} from "react-router-dom" 

 
class Footer extends React.Component{




  constructor(){
      super()
  	this.state ={
  		initial:1,


       }
  	}
 


 render(){

 	let styles={
 		footNav:{
          fontSize:'14px',
          fontFamily:'"Microsoft YaHei", "黑体","宋体",',
          color:'#fff',
          width:'120px',
          minWidth:'120px',
          height:'50px',
          margin:'5px',
          alignItems:'center',
          display:'flex',
          justifyContent: 'center',
          backgroundColor:'',
          float:'left',
 		},
 		footCopy:{
          fontSize:'12px',
          fontFamily:'"Microsoft YaHei", "黑体","宋体",',
          color:'#999',
          width:'auto',
          minWidth:'130px',
          height:'50px',     
          margin:'5px',          
          backgroundColor:'',
          float:'left',
 		},
 		footFont:{
          fontSize:'12px',
          fontFamily:'"Microsoft YaHei", "黑体","宋体",',
          color:'#fff',
          alignItems:'center',
          alignSelf: 'center',
          margin:'10px',
   
         
 		},
 	}

  const navStyle={
     
      color:'#fff',
      textDecoration: 'none',

    
    }

  let logoblockW='20%'
  let navblockW='80%'
  let footBlockW='820px'
  
  if( window.innerWidth <= 1124){
    logoblockW='100%'
    navblockW='100%'
    footBlockW='70%'
  }


  if(this.props.type=='FOOTER_FULL'){

   return (
      <div className="contentFrameBox" style={{backgroundColor:'#000',height:'auto'}}>


      <div  style={{width:footBlockW,backgroundColor:'',height:'auto',alignSelf: 'center',paddingTop:'50px',display:'',justifyContent:''}}>

         <div className='contentFrame_alignL' style={{width:logoblockW,height:'auto',backgroundColor:'',float:'left',paddingTop:'10px'}}>
                <img src={logoW}   className='Bottom-logo' alt="logo" style={{paddingTop:'0%'}} />

                <div style={styles.footFont}  >
                </div>

         </div>

          <div  style={{width:navblockW,backgroundColor:'',float:'right'}}>
              <div style={styles.footNav}><Link to='/why-kuaipf' style={navStyle}>选择快平方</Link></div>
              <div style={styles.footNav}><Link to='/how' style={navStyle}>合作方式</Link></div>
              <div style={styles.footNav}><Link to='/about' style={navStyle}>了解我们</Link></div>
              <div style={styles.footNav}><Link to='/privacy' style={navStyle}>隐私政策</Link></div>
              <div style={styles.footNav}><Link to='/terms' style={navStyle}>用户协议</Link></div>

          </div>

      </div>


            <div className='contentFrameRow' style={{backgroundColor:'',width:'90%',height:'auto',paddingTop:'50px',paddingBottom:'50px',display:"flex",flexDirection:"column"}}>
                <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',textAlign:"center"}}>@2021 上海快咖网络科技有限公司版权所有 </div>
                <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',paddingTop:20}}>联系号码: +86 13911895958</div>
                <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',paddingTop:20}}><a style={{color:'#999'}} href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2020025889号-1</a></div>
            </div>



      </div>
   	)


     }else{
      return (
          <div className="contentFrameBox" style={{backgroundColor:'#000',height:'auto'}}>
              <div className='contentFrameRow' style={{backgroundColor:'',width:'90%',height:'auto',paddingTop:'50px',paddingBottom:'50px',display:"flex",flexDirection:"column"}}>
                  <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',textAlign:"center"}}>@2021 上海快咖网络科技有限公司版权所有 </div>
                  <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',paddingTop:20}}>联系号码: +86 13911895958</div>
                  <div className={'contentText'} style={{fontSize:'12px',color:'#999',height:'auto',margin:'0px',float:'center',paddingTop:20}}><a style={{color:'#999'}} href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2020025889号-1</a></div>
              </div>
          </div>
      )

  }


}



}


export default Footer