/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import {connect} from "react-redux";
import  '../../components/global';
import  '../../components/global';
import  PanelNav from '../../components/panelNav';
import  {fullfooter,minfooter,minmenu} from  "../../reduceractions";

import Overview from "../controller/overview";
import Projectview from "../controller/projectview";
import Accountview from "../controller/accountview";
import Createorder from "../controller/createorder";
import Setaccount from "../controller/setaccount";
import Profolio from "../controller/profolio";
import Systemmessage from "../controller/systemmessage";
import Projectmanage from "../admin/projectmanage";
import Userdetial from "../controller/userdetial";
import Http from "../../components/functions/ApiBridge";


/**
 * antd layout
 */

import  './console.css';
import {Modal, Button, Space, Layout, Menu, Typography} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LoadingOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    UploadOutlined,
} from '@ant-design/icons';

import Sidemenu from "../controller/sidemenu";
import cookie from "react-cookies";
const { Header, Sider, Content } = Layout;
const { Title,Text,Paragraph } = Typography;
/**
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})



//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(load){
        if(load=='land'){
            dispatch(minfooter())
            dispatch(minmenu())
        }else{
            cookie.remove('YiToken')
            window.location.href='/login'
        }
    }
})


class Consolepanel extends React.Component {


    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            error:false,
            errorContent:'',
            collapsed: false,
            user_data:{
                type:null,

            },
            user_data_default:{
                type:null,
            },
            controll_key:1,
            action:"",
            loading:true,


        }


        this.handleScroll =this.handleScroll.bind(this)
        this.menuselected =this.menuselected.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.componentsCallBack =this.componentsCallBack.bind(this)
        this.navCallback =this.navCallback.bind(this)
        this.oncancel =this.oncancel.bind(this)

        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }


    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({

            error:true,
            errorContent:error,
        })
    }



    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1

        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })



    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState('land');

        //
        window.addEventListener('scroll', this.handleScroll);
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

        this.Get_userinfo();

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
    }


    //props update

    componentWillReceiveProps(){

    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    menuselected(key){
        if(key==3 ){
            console.log(this.state.user_data.member_state)
            if(this.state.user_data.member_state!='activate'){
              this.showErrorbar('您的账号还在审核中，暂时不能使用该功能。')
            }else{
                this.setState({
                    controll_key: key,
                })
            }
        }else {
            this.setState({
                controll_key: key,
            })
        }
    }

    /**
     * get user data
     * @constructor
     */
    Get_userinfo(){

        Http.get_Profile().then((res)=>{




            if(res){
                this.setState({
                    user_data:res.data,
                    user_data_default:res.data,

                },()=>{

                    if(res.data.user_type=='admin'){
                        this.warning()
                    }else{
                        this.get_overview()
                    }

                })
            }else{
                this.warning()
            }

        }).catch((res) => {
            console.log(res.message)
            this.warning()

        })

    }



    get_overview(){
        const postdata={
            page:1,
        }
        Http.user_overview_info(postdata).then((res)=>{
            const user_Data=this.state.user_data
            user_Data.total_orders=res.data.total_orders
            user_Data.new_message=res.data.new_message
            this.setState({
                user_Data,
                loading:false,
            })
        }).catch((res) => {
            console.log(res.message)
        })

    }


    /**
     * callback swith componment
     * @returns {JSX.Element}
     */

    componentsCallBack(key){
        this.Get_userinfo()
  
        switch (key){
            case 2:
                this.setState({
                    controll_key:2
                })
                break;
            case 'account_mobile':
                this.setState({controll_key:10,action:'set_mobile'})
                break;
            case 'account_wechatid':
                this.setState({controll_key:10,action:'set_wechat'})
                break;
            case 'account_password':
                this.setState({controll_key:10,action:'set_password'})
                break;
            case 'account_company':
                this.setState({controll_key:10,action:'set_company'})
                break;
            case 'account_view':
                this.setState({controll_key:4,action:''})
                break;
            case 'profile_view':
                this.setState({controll_key:6,action:''})
                break;
            case 'message_readed':
                 this.get_overview()
                break;
            default:
        }

    }


    /**
     * nav call back
     *
     */

    navCallback(){


        this.setState({
             controll_key:5
        })
    }

    oncancel(){
        this.props.LoadPageState('out');
    }


    warning() {
        Modal.warning({
            title: 'ERROR',
            content: '账号错误',
            okType:'text',
            onOk:this.oncancel,
        });
    }




    render(){
        console.log(this.props.menustate)
        const controllkey=this.state.controll_key;

        let user_Data=this.state.user_data
        const default_data=this.state.user_data_default

        let ControllerComponent=Overview;
        if(controllkey==1){
            ControllerComponent=Overview;
        }else if(controllkey==2){
            ControllerComponent=Projectview;
        }else if(controllkey==3){
            ControllerComponent=Createorder;
        }else if(controllkey==4){
            ControllerComponent=Accountview;
        }else if(controllkey==5){
            ControllerComponent=Systemmessage;
        }else if(controllkey==10){
            ControllerComponent=Setaccount;
        }else if(controllkey==6){
            ControllerComponent=Profolio;
        }else if(controllkey==7){

            ControllerComponent=Userdetial;
        }



        if(this.state.user_data.user_type=='admin'){

            return (
                <div className='contentView'>


                <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>

                </div>);

        }else {

            if (this.state.loading) {
                    return(<div className='contentView'>


                        <div style={{
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <LoadingOutlined style={{fontSize: '20px'}}/>
                        </div>

                    </div>);
            } else {
                return (


                    <div className='contentView'>
                        <PanelNav callBack={this.navCallback} data={this.state.user_data.new_message}/>
                        <div style={{height: '86px', width: '100%', backgroundColor: '#efefef'}}>
                        </div>
                        <Layout>
                            <Sider className={'panel_sider'} theme={'light'} trigger={null} collapsible
                                   collapsed={this.state.collapsed}>

                                <Sidemenu type={this.state.user_data.type} account_type={this.state.user_data.user_type}
                                          action={this.menuselected} synckey={this.state.controll_key}/>

                            </Sider>
                            <Layout className="site-layout">
                                <Header className="site-layout-background" style={{padding: 0}}>
                                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: this.toggle,
                                    })}
                                </Header>

                                <Content
                                    className="site-layout-background"
                                    style={{
                                        margin: '24px 16px',
                                        padding: 24,
                                        minHeight: '76vh',
                                        backgroundColor: '#f0f2f5',
                                    }}
                                >
                                    {
                                        (!this.state.loading) ? (
                                            <ControllerComponent bridge={user_Data}
                                                                 action={this.state.action}
                                                                 callBack={this.componentsCallBack}/>
                                        ) : (
                                            <div style={{
                                                width: '100%',
                                                height: '500px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <LoadingOutlined style={{fontSize: '20px'}}/>
                                            </div>
                                        )
                                    }


                                </Content>


                            </Layout>
                        </Layout>

                        <Modal
                            title="提示"
                            style={{ top: 20 }}
                            visible={this.state.error}
                            onCancel={this.onClose}
                            footer={null}
                        >
                            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                                <Text type="secondary">{this.state.errorContent}</Text>


                            </div>
                        </Modal>

                    </div>
                )
            }
        }
    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Consolepanel) ;

