
/**
Yi 2020

这里负责login logout 以及 refresh token 的操作

配合组件 axios 、 react-redux
 */



import axios from 'axios';
import '../global';
import jwt_decode from 'jwt-decode'
import {Modal} from "antd";

// 引入设置token方法
import setAuthToken from './setAuthToken'
import  {authlogin} from  "../../reduceractions";



import cookie from 'react-cookies'

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
}



function warning(msg) {
    Modal.warning({
        title: '错误',
        content: msg,
        okType:'text'
    });
}


/**
 * 检查令牌
 * @returns {string|boolean}
 * @constructor
 */
function CheckAuth (){



    let access_token=  cookie.load('YiToken')

    if(access_token==undefined){

        return "null token"
    }else {

        const decoded = jwt_decode(access_token)


        //jwt 用的是秒作为exp单位  有时候要转化为毫秒

        const expdate = new Date(decoded.exp * 1000)
        const exptimestamp = decoded.exp * 1000
        const nowtimestamp = (new Date()).valueOf()


        const exptimedis = 120
        // console.log((nowtimestamp-exptimestamp)/1000)
        //  console.log('check auth : '+ JSON.stringify(decoded))


        if ((nowtimestamp - exptimestamp) / 1000 > exptimedis) {

            // console.log('Token need refresh')
            return true

        } else {
            //  console.log('Token in used')
            return false
        }
      }


}


/**
 * 登录接口
 * @param userData
 * @param actionType
 * @returns {function(...[*]=)}
 */
export const autoAchtion = (userData,actionType)=> dispatch  => {

 switch (actionType) {

     case 'cookie':

           const Istokenexp= CheckAuth()
           const access_token= {
                token:cookie.load('YiToken')

            }

           console.log(Istokenexp)
            if(Istokenexp==true){

                axios.post(global.gData.api_domain+'/api/auth/refresh', access_token)
                    .then(function (response) {

                        cookie.save('YiToken',response.data.access_token,{path:"/"})
                        console.log('refresh token success')
                         dispatch({ type:'SIGN_IN' })
                    })
                    .catch(function (error) {
                        let statuscode=error.response.status

                        cookie.remove('YiToken')


                        console.log(codeMessage[statuscode]);
                    });


            }else if(Istokenexp==false) {
                console.log("confrim");
                dispatch({ type:'SIGN_IN' })

            }else if(Istokenexp=="null token"){
                console.log("token false");
                dispatch({ type:'SIGN_OUT' })
            }

           return

     case 'login':

         axios.post(global.gData.api_domain+'/api/auth/login', userData)
             .then(function (response) {
                 // console.log(response.data.access_token);

                 cookie.save('YiToken',response.data.access_token,{path:"/"})

                  dispatch({ type:'SIGN_IN' })


             })
             .catch(function (error) {
                 let statuscode=error.response.status

                 warning("用户名或密码错误！");
                 console.log(codeMessage[statuscode]);
             });

             return

     case 'logout':
         const logaccess_token= {
             token:cookie.load('YiToken')

         }

         axios.post(global.gData.api_domain+'/api/auth/logout', logaccess_token)
             .then(function (response) {

                 cookie.remove('YiToken')
                 console.log('remove token success')

                  dispatch({ type:'SIGN_OUT' })
                  dispatch({ type:'FOOTER_FULL' })
             })
             .catch(function (error) {
                 let statuscode=error.response.status

                 cookie.remove('YiToken')

                 dispatch({ type:'SIGN_OUT' })
                 console.log(codeMessage[statuscode]);
             });

             return
     case 'minfooter':
         dispatch({ type:'FOOTER_MIN' })
         break
     default:

             return

 }



    /*
        axios.post(
            {
                method: 'post',
                url: global.gData.api_domain+'/api/auth/login',
                data: userData
            })
            .then(res => {//对返回的token进行解构,并存储

                console.log(res);
                const {token} = res.data;
                localStorage.setItem('YiToken', token)
                //设置axios的headers token
                 setAuthToken(token)
            }).catch(err => {
            // 在登录息错误的时候用dispatch把信息返回回去
            console.log(err);
           // dispatch({

            //    payload: err.response.data
            //})
        })
    */







}