import React from "react"
import axios from "axios";
import  Getlocation from "./Getlocation";



class LocationCity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            province: '',
            city: '',
            county:'',
            provinces: [],
            cities: [],
            counties: [],
            border:'1px solid #000',
        }
    }

    handleChangeprovince(e) {
        e.preventDefault()
        const data = this.state.data;
        for(var i in data) {
            if(data[i].name == e.target.value) {
                this.setState({
                    cities: data[i].city,
                    counties: data[i].city[0].area,
                    province: e.target.value,
                    city: data[i].city[0].name,
                    county: data[i].city[0].area[0],
                })
                let select=document.getElementById('city_selecter');
                select.options[1].selected=true;
                let select2=document.getElementById('county_selecter');
                select2.options[1].selected=true;

                   setTimeout(() => {
                       if(this.props.callBack) this.props.callBack('location',this.state.province+','+this.state.city+','+this.state.county);
                       //console.log(this.state.province+','+this.state.city+','+this.state.county)
                   }, 10)
            }
        }
    }

    defaultChangeprovince(e) {

        const data = this.state.data;
        for(var i in data)
       {

            if(data[i].name == e) {

                this.setState({
                    cities: data[i].city,
                    counties: data[i].city[0].area,
                    province: e,
                    city: data[i].city[0].name,
                    county: data[i].city[0].area[0],
                },()=>{
                    let select=document.getElementById('city_selecter');
                    select.options[1].selected=true;
                    let select2=document.getElementById('county_selecter');
                    select2.options[1].selected=true;

                    this.defaultChangecity(this.props.city)
                })

            }
        }
    }


    handleChangecity(e) {
        e.preventDefault()
        const cities = this.state.cities
        for(var i in cities) {
            if(cities[i].name == e.target.value) {
                this.setState({
                    counties: cities[i].area,
                    city: e.target.value,
                    county: cities[i].area[0],

                })
                let select=document.getElementById('county_selecter');
                select.options[1].selected=true;

                setTimeout(() => {
                    if(this.props.callBack) this.props.callBack('location',this.state.province+','+this.state.city+','+this.state.county);
                    //console.log(this.state.province+'/'+this.state.city+'/'+this.state.county)
                }, 10)
            }
        }
    }

    defaultChangecity(e) {

        const cities = this.state.cities
        for(var i in cities) {
            if(cities[i].name == e) {
                this.setState({
                    counties: cities[i].area,
                    city: e,
                    county: cities[i].area[0],

                },()=>{
                    let select=document.getElementById('county_selecter');
                    select.options[1].selected=true;

                    this.defaultChangecounty(this.props.area)
                })

            }
        }
    }


    handleChangecounty(e) {
        e.preventDefault()
        this.setState({
            county: e.target.value
        })

        setTimeout(() => {
            if(this.props.callBack) this.props.callBack('location',this.state.province+','+this.state.city+','+this.state.county);
            //console.log(this.state.province+'/'+this.state.city+'/'+this.state.county)
        }, 10)
    }

    defaultChangecounty(e) {

        this.setState({
            county: e
        })


    }



    render() {
        return(
            <div style={{width:this.props.width,height:this.props.height,backgroundColor:'',marginBottom:'30px'}}>
                {
                    this.state.provinces.length!=0
                        ?
                        <div style={{height:'100%',backgroundColor:'',display:'flex'}}>
                            <select className='selectText' value={this.state.province} onChange={this.handleChangeprovince.bind(this)}  style={{height:this.props.height,width:'30%',
                                color:this.props.borderColor,backgroundColor:this.props.bgColor,border: this.state.border,marginBottom:'25px',
                                appearance:'none',outline: 'none',marginRight:'10px',
                            }}>
                                <option  > 省</option>
                                {
                                    this.state.provinces.map((i,index) => (
                                        <option value={i} key={index}>{i}</option>
                                    ))
                                }
                            </select >
                            <select className='selectText' id='city_selecter' value={this.state.city} onChange={this.handleChangecity.bind(this)} style={{height:this.props.height,width:'30%',
                                color:this.props.borderColor,backgroundColor:this.props.bgColor,border: this.state.border,marginBottom:'25px',
                                appearance:'none',outline: 'none',marginRight:'10px'
                            }}>
                                <option > 市</option>
                                {
                                    this.state.cities.map((i,index) => (
                                        <option value={i.name} key={index}>{i.name}</option>
                                    ))
                                }
                            </select>
                            <select className='selectText' id='county_selecter' value={this.state.county} onChange={this.handleChangecounty.bind(this)} style={{height:this.props.height,width:'30%',
                                color:this.props.borderColor,backgroundColor:this.props.bgColor,border: this.state.border,marginBottom:'25px',
                                appearance:'none',outline: 'none',marginRight:'0px',
                            }}>
                                <option> 区</option>
                                {
                                    this.state.counties.map((i,index) => (
                                        <option value={i} key={index}>{i}</option>
                                    ))
                                }
                            </select>
                            <img src={require('../asset/location.png')}     style={{width:'30px'}}  />

                        </div>

                        :<p> </p>

                }



            </div>
        )
    }


    componentDidMount() {
        const provinces = []


        const data=  Getlocation

        // console.log(data[0]);

        /*
         *
         * 重本地文件获取
         */

        Object.assign(data).map(i => {
            provinces.push(i.name)
            data.push(i)
        })

        this.setState({
            data: data,
            provinces: provinces,
            cities: data[0].city,
            counties: data[0].city[0].area
        },()=>{
            this.defaultChangeprovince(this.props.province)
        })


        if(this.props.broderColor){
            const newborder='1px solid '+this.props.broderColor;
            this.setState({
                border:newborder,
            })
        }








        //请求API
        //重接口获取地址数据
        /*
         const url = "***********************";
        const resquest = axios.get(url);
        resquest.then(res => {
            Object.assign(res.data).map(i => {
                provinces.push(i.name)
                data.push(i)
            })
            this.setState({
                data: data,
                provinces: provinces,
                cities: data[0].city,
                counties: data[0].city[0].area
            })
        })

        */



    }



}

export default LocationCity