import { Yibutton } from './Formelements';
import React from "react";

let timeChange;


    class VcodeBtn extends React.Component{

        constructor(){
            super()
            this.state ={
                initall:0,
                btnContent:'获取验证码',
                settime:60,
                btnDisabled:false,


            }

            this.getPhoneCaptcha =this.getPhoneCaptcha.bind(this)
            this.setBtnDisabled =this.setBtnDisabled.bind(this)


        }


        //close component
        componentWillUnmount(){
            clearInterval(timeChange);
        }


        setBtnDisabled(){

           let stime=this.state.settime
            if(stime>0){
                stime=stime-1
                this.setState({
                    btnContent:stime+'s再试',
                    settime:stime,
                })
            }else{
                this.setState({
                    btnContent:'获取验证码',
                    settime:60,
                })
                clearInterval(timeChange);
            }

        }


        getPhoneCaptcha(){
            if (this.props.canClick == undefined) {
                if (!this.state.btnDisabled) {

                     if(this.state.settime==60) timeChange = setInterval(() => this.setBtnDisabled(), 1000);

                    this.props.callBack();
                }
            } else {
                if (this.props.canClick) {
                    if (!this.state.btnDisabled) {
                        // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
                        if(this.state.settime==60)  timeChange = setInterval(() => this.setBtnDisabled(), 1000);

                         this.props.callBack();
                    }
                }
            }
        };



        render() {



            return (
                <Yibutton width={this.props.width} height='30px' value={this.state.btnContent}
                          borderColor={(this.props.borderColor) ? this.props.borderColor : '#D1D1D1'} color={'#666'}
                          callBack={this.getPhoneCaptcha}>
                    {this.state.btnContent}
                </Yibutton>
            )
        }
};

export default VcodeBtn;