/**
 * yi 2020
 *
 *
 */

import React from 'react';
import DataListitem from "../../components/DataListitem";
import {Input, Drawer, Button,Modal,Result, Typography, Space, Divider, Statistic, Row, Col, Pagination, Alert} from "antd";


import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    ProjectOutlined,
    CarryOutOutlined,
    LoadingOutlined,
    NotificationOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/ApiBridge";
import {Ytextarea} from "../../components/Formelements";
const { TextArea } = Input;

const { Title,Text,Paragraph } = Typography;

/*
* choose registration account type
*/



class Faqview extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            faq_Data:null,
            loading:true,
            visible: false,
            Drawereditvisible:false,
            total_list:null,
            current_page:null,
            error:false,
            errorContent:'',
            Modalvisible:false,
            itemstate:'true',
            edit_item:{
                id:'',
                comment_a:'',
                comment_q:'',
            },
            submit_data:{
                comment_a:'',
                comment_q:'',
            },


        }

        this.onPageChange =this.onPageChange.bind(this)
        this.get_qa_list =this.get_qa_list.bind(this)
        this.createFAQ =this.createFAQ.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this);
        this.qainput =this.qainput.bind(this);
        this.edit_qainput =this.edit_qainput.bind(this);
        this.handleCancel =this.handleCancel.bind(this);
    }

    componentWillMount() {


      this.get_qa_list(1)

    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            faq_Data:null,
            loading:true,
        })
        this.get_qa_list(page);
    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    handleCancel(){

        this.setState({
            Modalvisible: false,
        });
    }



    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    showDrawer_edit = (item) => {
       // console.log(item)
        this.setState({
            edit_item:{
                id:item.id,
                comment_a:item.comment_a,
                comment_q:item.comment_q,
            },

        },()=>{
            this.setState({
                Drawereditvisible: true,
            });
        });


    };

    onCloseDrawer_edit = () => {
        this.setState({
            Drawereditvisible: false,
        });
    };




    get_qa_list(page){
        const postdata={
            pageshow:10,
            page:page,
        }
        Http.get_faq_list(postdata).then((res)=>{

            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                    faq_Data: res.data.data,
                    loading: false,
                })
            }else {
                this.setState({
                    faq_Data: res.data,
                    loading: false,
                    total_list: res.data.length,
                    current_page: 1,
                })
            }

           console.log(this.state.total_list)



        }).catch((res) => {
            console.log(res.message)
        })

    }

    createFAQ(){
       this.showDrawer()
    }

    edit_qainput(ta){
         const name=ta.target.name
        const val=ta.target.value

        const submitdata=this.state.edit_item
        if(name=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(name=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }


    }

    qainput(ta,val){
        const submitdata=this.state.submit_data
        if(ta=='question'){
            submitdata.comment_q=val;
            this.setState({submitdata})

        }else if(ta=='answer'){
            submitdata.comment_a=val;
            this.setState({submitdata})
        }
    }
    submitFAQ(){
        if(this.state.submit_data.comment_a=='' || this.state.submit_data.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.submit_data.comment_a,
                comment_q:this.state.submit_data.comment_q,
            }

            Http.create_faq(postdata).then((res)=>{

                  this.onCloseDrawer();
                  this.onPageChange(1);

            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }

    }

    deleteFAQ(){

        this.setState({
            Modalvisible: true,
        });

    }

    submiteditFAQ(state){
        if(this.state.edit_item.comment_a=='' || this.state.edit_item.comment_q==''){
            this.showErrorbar('请填写完整内容')
        }else{
            const postdata={
                comment_a:this.state.edit_item.comment_a,
                comment_q:this.state.edit_item.comment_q,
                id:this.state.edit_item.id,
                state:state
            }
            this.setState({
                Modalvisible: false,
            });

            Http.edit_faq(postdata).then((res)=>{

                this.onCloseDrawer_edit();

                setTimeout(this.onPageChange(1), 1000 )


            }).catch((res) => {
                this.showErrorbar(res.message)

            })
        }
    }



    render() {



        if (this.state.loading == true) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>


                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px',


                    }}>


                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}> </Title>

                        </Space>
                        <Paragraph>
                            快平方v1.0.0更新，<a href="">点击</a>查看更多更新细节。
                        </Paragraph>


                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        paddingTop: '1px',


                    }}>
                        {
                            this.state.faq_Data.map((key, index) => (

                                <DataListitem item={key} key={index} callBack={this.showDrawer_edit} space={[2,12,10]}/>
                            ))
                        }


                    </div>

                    <div style={{
                        width: '100%',
                        height: '86px',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"space-between",
                    }}>
                        <Pagination
                            size="small"
                            defaultPageSize={10}
                            defaultCurrent={this.state.current_page}
                            total={this.state.total_list}
                            onChange={this.onPageChange}
                        />


                        <Button key={'back'} type="text"   onClick={()=>this.createFAQ()}>新建</Button>
                    </div>

                    <Drawer
                        width={640}
                        title="创建新的FAQ"
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer}
                        visible={this.state.visible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button key={'back'} type="text"   onClick={()=>this.submitFAQ()}>提交</Button>
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Text  type="secondary" >输入问题</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <Ytextarea width={'100%'} height='50px' name={'question'} full={'none'}  callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={60}/>

                        <Text  type="secondary"  >输入答案</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>
                        <Ytextarea width={'100%'} height='120px' name={'answer'} full={'none'}   callBack={this.qainput} bgColor='#fff' borderColor={'#ebebeb'} value='' preset='' limit={250}/>



                    </Drawer>



                    <Drawer
                        width={640}
                        title="编辑内容"
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer_edit}
                        visible={this.state.Drawereditvisible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button key={'delect'} type="text"   onClick={()=>this.deleteFAQ()}>删除</Button>

                                <Button key={'back'} type="text"   onClick={()=>this.submiteditFAQ('true')}>更新</Button>
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Text  type="secondary" >问题</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <textarea style={{
                            border:'1px solid #efefef',
                            outline: 'none',
                            width:'100%',
                            paddingLeft:'2px',
                            paddingTop:'2px',
                            marginBottom:'20px',
                            height:'50px',
                            overflow:'hidden',
                            color:'#999',
                            resize:'none',
                            backgroundColor:'',
                            fontSize:'12px'
                        }}
                           name={'question'}
                           onChange={this.edit_qainput}
                           value={this.state.edit_item.comment_q}

                        />


                        <Text  type="secondary"  >答案</Text>
                        <div style={{width:'100%',height:'5px'}}> </div>

                        <textarea style={{
                            border:'1px solid #efefef',
                            outline: 'none',
                            width:'100%',
                            paddingLeft:'2px',
                            paddingTop:'2px',
                            marginBottom:'20px',
                            height:'120px',
                            overflow:'hidden',
                            color:'#999',
                            resize:'none',
                            backgroundColor:'',
                            fontSize:'12px'
                        }}
                                  name={'answer'}
                                  onChange={this.edit_qainput}
                                  value={this.state.edit_item.comment_a}

                        />




                    </Drawer>

                    <Modal
                        title="删除"
                        style={{ top: 20 }}
                        visible={this.state.Modalvisible}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                            <Text type="secondary">确认要删除这条数据吗？</Text>

                            <Button type="text" onClick={()=>this.submiteditFAQ('false')}>确认</Button>

                        </div>
                    </Modal>

                </div>
            )
        }
    }
}


export default Faqview;