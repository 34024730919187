/**
 * yi 2020
 *
 *
 */

import React from 'react';
import DataListitem from "../../components/DataListitem";
import {
    Collapse,
    Avatar,
    Select,
    Switch,
    Tabs,
    Tag,
    Breadcrumb,
    Descriptions,
    Badge,
    List,
    Input,
    Drawer,
    Button,
    Modal,
    Result,
    Typography,
    Space,
    Divider,
    Statistic,
    Row,
    Col,
    Pagination,
    Alert,
    Calendar, DatePicker
} from "antd";


import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    MessageOutlined,
    ProjectOutlined,
    LockOutlined,
    InboxOutlined,
    LoadingOutlined,
    FilePdfOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import Http from "../../components/functions/Adminbridge";
import {Yinput, Ytextarea} from "../../components/Formelements";
import axios from "axios";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;
const { Title,Text,Paragraph } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

/*
* choose registration account type
*/



class Clientmanage extends React.Component{

    constructor(){
        super()
        this.state ={
            initial:1,
            GET_Data:null,
            loading:true,
            visible: false,
            Drawereditvisible:false,
            childrenDrawer:false,
            total_list:null,
            current_page:null,
            error:false,
            errorContent:'',
            Modalvisible:false,
            errorboxContent:'',
            itemstate:'true',
            edit_item:{
                id:'',
                orders:[],


            },
            submit_data:{
                comment_a:'',
                comment_q:'',
            },
            tab_key:1,
            tab_value:['','fl'],
            profile_file_url:'',
            tag_data:{
                tag1:null,
                tag2:null,
                tag1_option:null,
                tag2_option:null,
                tag1_array:null,
                tag2_array:null,
            },
            tag_pro:[],
            tag_exp:[],
            filter_tag1:[],
            filter_tag2:[],
            working_years:'1-5年',
            working_hours:'2-4小时',
            calling_time_ar:['10:00','10:30','11:00','11:30','01:00','01:30','02:00','03:30'],
            user_data:{
                order_name:'',
                name:'',
                email:'',
                mobile:'',
                wechat_id:'',
                company:'',
                title:'',
                department:'',
                service:'',
                industry:'',
                deliver:'',
                brief_call:'2020-11-30',
                calling_time:'10:00',
                remark:'',

            },


        }

        this.inputChannge =this.inputChannge.bind(this);
        this.onPageChange =this.onPageChange.bind(this)
        this.get_data_list =this.get_data_list.bind(this)
        this.createFAQ =this.createFAQ.bind(this)
        this.showErrorbar =this.showErrorbar.bind(this);

        this.handleCancel =this.handleCancel.bind(this);
        this.activeUser =this.activeUser.bind(this);
        this.select_tag1_handler =this.select_tag1_handler.bind(this);
        this.select_tag2_handler =this.select_tag2_handler.bind(this);
        this.working_years_onChange =this.working_years_onChange.bind(this);
        this.working_hours_onChange =this.working_hours_onChange.bind(this);
        this.onChildrenDrawerClose =this.onChildrenDrawerClose.bind(this)
        this.showChildrenDrawer =this.showChildrenDrawer.bind(this)

        this.serviceSelectChange =this.serviceSelectChange.bind(this);
        this.industrySelectChange =this.industrySelectChange.bind(this);
        this.deliverChannge =this.deliverChannge.bind(this);
        this.calldateChannge =this.calldateChannge.bind(this);
        this.calltimeChannge =this.calltimeChannge.bind(this);
    }

    componentWillMount() {


        this.get_data_list(1)
        const tag1=this.state.tag_data
        this.Get_tags_pro(1,tag1)
        this.Get_tags_pro(2,tag1)

    }

    onPageChange(page,pageSize){
        //console.log(page)
        this.setState({
            GET_Data:null,
            loading:true,
        })
        this.get_data_list(page);
    }

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            Modalvisible: false,
            error:true,
            errorContent:error,
        })
    }

    showErrorbox(error){
        this.setState({
            Modalvisible: true,
            errorboxContent:error,
        })
    }


    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    handleCancel(){

        this.setState({
            Modalvisible: false,
        });
    }



    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onCloseDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    showDrawer_edit = (item) => {
         console.log(item)
        this.setState({
            edit_item:item,
        },()=>{


            this.setState({
                Drawereditvisible: true,
            });
        });


    };

    onCloseDrawer_edit = () => {
        this.setState({
            Drawereditvisible: false,

        });
    };

    showChildrenDrawer = () => {
        let GMTdate = new Date(moment().subtract(0, 'day')._d)

        let Str = GMTdate.getFullYear() + '-' +
            (GMTdate.getMonth() + 1) + '-' +
            GMTdate.getDate()


       const reset_user_data={
            order_name:'',
                name:'',
                email:'',
                mobile:'',
                wechat_id:'',
                company:'',
                title:'',
                department:'',
                service:this.state.user_data.service,
                industry:this.state.user_data.industry,
                deliver:this.state.user_data.deliver,
                brief_call:this.state.user_data.deliver,
                calling_time:'00:00',
                remark:'',

        };



        this.setState({
            childrenDrawer: true,
            user_data:reset_user_data,
        });


    };

    onChildrenDrawerClose = () => {
        this.setState({
            childrenDrawer: false,

        });
    };


    /**
     * get data
     * @param page
     */




    get_data_list(page){

        const postdata={
            page:page,

        }

        Http.filter_clients_list(postdata).then((res)=>{


            if(res.data.total) {
                //当数量达到分页数据存在分页数据时调用
                this.setState({
                    total_list: res.data.total,
                    current_page: res.data.current_page,
                    GET_Data: res.data.data,
                    loading: false,
                })
                res.data.data.map((_key, index) => {
                    if(_key.user_id==this.state.edit_item.user_id){
                        this.setState({edit_item:_key})
                    }
                })

            }else {

                this.setState({
                    GET_Data: res.data,
                    loading: false,
                    total_list: res.data.length,
                    current_page: 1,
                })
                res.data.map((_key, index) => {
                    if(_key.user_id==this.state.edit_item.user_id){
                        this.setState({edit_item:_key})
                    }
                })
            }

        }).catch((res) => {
            console.log(res.message)
        })

    }




    createFAQ(){
        this.showDrawer()
    }






    deleteFAQ(){



    }

    submiteditFAQ(state){

    }



    /**
     * get all tags
     * @constructor
     */

    Get_tags_pro(id,tagdata){
        const postdata={
            type_id:id,
        }
        Http.get_all_tag(postdata).then((res)=>{
            const pro_data_array=[];
            const select_data_array=[];
            const in1_data_array=[];
            const in2_data_array=[];
            res.data.map((_key, index) => {

                pro_data_array[_key.id]=_key.tag_name
                select_data_array.push(<Option key={_key.id}>{_key.tag_name}</Option>)
                if(_key.type_id==1){
                    in1_data_array.push(_key.tag_name);
                }else if(_key.type_id==2){
                    in2_data_array.push(_key.tag_name);
                }
            })
            if(id==1) {
                tagdata.tag1 = pro_data_array
                tagdata.tag1_option=select_data_array
                tagdata.tag1_array=in1_data_array
            }else if(id==2){
                tagdata.tag2 = pro_data_array
                tagdata.tag2_option=select_data_array
                tagdata.tag2_array=in2_data_array
            }
            this.setState({
                tagdata,
            },()=>{console.log('load tags')})

        }).catch((res) => {
            this.showErrorbar(res.message)

        })
    }


    /**
     * get user tags
     * @constructor
     */

    Get_user_tags(type_id,user_item){

        if(type_id==1){
            this.setState({
                tag_pro:user_item.link_tags
            })

        }else if(type_id==2){
            this.setState({
                tag_exp:user_item.link_tags
            })
        }

    }




    working_years_onChange(value){
        this.setState({
            working_years:value
        },()=>{
            this.get_data_list(1)
        })
    }

    working_hours_onChange(value){
        this.setState({
            working_hours:value
        },()=>{
            this.get_data_list(1)
        })
    }


    select_tag1_handler(value){

        this.setState({
            filter_tag1:value
        },()=>{
            this.get_data_list(1)
        })
    }

    select_tag2_handler(value){
        this.setState({
            filter_tag2:value
        },()=>{
            this.get_data_list(1)
        })
    }


    /**
     * extra func
     * @returns {JSX.Element}
     */

    activeUser(action,id){
        if(action=='activate'){
            action='default'
        }else{
            action='activate'
        }
        const postdata={
            id:id,
            action:action
        }
        Http.activate_user(postdata).then((res)=>{
            const ta_item=this.state.edit_item
            ta_item.member_state=action
            this.setState({
                ta_item
            })
            this.get_data_list(this.state.current_page)
        }).catch((res) => {
            console.log(res.message)
        })
    }


    /**
     * Order
     */

    submitOrder(){



     let user_data=this.state.user_data

        user_data.name=this.state.edit_item.family_name
        user_data.user_id=this.state.edit_item.user_id
        user_data.email=this.state.edit_item.email
        user_data.mobile=this.state.edit_item.mobile
        user_data.wechat_id=this.state.edit_item.wechat_id
        user_data.company=this.state.edit_item.company
        user_data.title=this.state.edit_item.title
        user_data.brief_time=this.state.user_data.deliver  //fake
        user_data.calling_time='00:00'


        this.setState({
            user_data
        },()=>{
            //console.log(this.state.user_data)



            if(this.state.user_data.order_name==''){
                this.showErrorbox('请填写项目名称')
            }else if(this.state.user_data.department==''){
                this.showErrorbox('请填写联系人所属部门')
            }else if(this.state.user_data.service==''){
                this.showErrorbox('请选择项目所需服务')
            }else if(this.state.user_data.industry==''){
                this.showErrorbox('请选择项目所属行业')
            }else if(this.state.user_data.deliver==''){
                this.showErrorbox('请选择项目上线日期')
            }else{
                Http.Creat_order(this.state.user_data).then((res)=>{



                    this.get_data_list(this.state.current_page)
                  //  const ta_item=this.state.edit_item

                   // this.setState({
                   //     ta_item
                   // })

                    this.showErrorbox('项目创建成功')
                    this.onChildrenDrawerClose()

                }).catch((res) => {
                  //  console.log(res.message)
                    this.showErrorbox(res.message)
                })

            }



        })






    }


    inputChannge(e){

        const val=e.target.value;
        const user_Data=this.state.user_data
       // console.log(e.target.id+'/'+val)
        switch (e.target.id){
            case 'name':
                user_Data.name=val
                break
            case 'order_name':
                user_Data.order_name=val
                break
            case 'email':
                user_Data.email=val
                break
            case 'mobile':
                user_Data.mobile=val
                break
            case 'wechat_id':
                user_Data.wechat_id=val
                break
            case 'company':
                user_Data.company=val
                break
            case 'title':
                user_Data.title=val
                break
            case 'department':
                user_Data.department=val
                break
            case 'remark':
                user_Data.remark=val
                break
            default:

        }
        this.setState({user_Data})
      //  console.log(user_Data)
    }

    deliverChannge(date, dateString){
        //console.log(dateString)
        const user_Data=this.state.user_data
        user_Data.deliver=dateString
        user_Data.brief_call=dateString
        this.setState({user_Data})
    }

    calldateChannge(date, dateString){
        const user_Data=this.state.user_data
        user_Data.brief_call=dateString
        this.setState({user_Data})
    }
    calltimeChannge(value){
        const user_Data=this.state.user_data
        user_Data.calling_time=value
        this.setState({user_Data})
    }

    serviceSelectChange(value){
        const user_Data=this.state.user_data
        user_Data.service=value
        this.setState({user_Data})
    }

    industrySelectChange(value){
        const user_Data=this.state.user_data
        user_Data.industry=value
        this.setState({user_Data})
    }



    render() {


        const progress_state=['项目创建','Kick start','制作中','交付']

        if (this.state.loading == true || this.state.tag_data.tag1_array==null || this.state.tag_data.tag2_array==null) {
            return (<div style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);

        } else {
            return (
                <div style={{width: '100%', padding: '0px'}}>


                    <div style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        padding: '30px 30px 0px 30px',


                    }}>


                        <Space>
                            <Title level={5} style={{paddingTop: '6px'}}> </Title>

                        </Space>

                        <Tabs defaultActiveKey={this.state.tab_key} onChange={this.Tabcallback}>
                            <TabPane tab="CLIENTS" key="1">




                            </TabPane>


                        </Tabs>


                    </div>

                    <div style={{
                        width: '100%',
                        height: 'auto',
                        paddingTop: '0px',


                    }}>

                        <List

                            itemLayout="horizontal"
                            style={{backgroundColor:'#fff'}}
                            dataSource={this.state.GET_Data}
                            renderItem={item => (
                                <List.Item style={{paddingLeft:'30px'}}

                                >
                                    {(item.user_state=='false')?(
                                        <div style={{paddingRight:'30px'}}>

                                            <Avatar style={{ backgroundColor: '#efefef' }}><LockOutlined style={{color:'#999'}}/></Avatar>
                                        </div>

                                    ):(
                                        (item.member_state=='default')?(

                                            <div style={{paddingRight:'30px'}}>
                                                <Badge count={1} dot>

                                                    <Avatar style={{ backgroundColor: '#f56a00' }}>C</Avatar>
                                                </Badge>
                                            </div>
                                        ):( <div style={{paddingRight:'30px'}}>

                                                <Avatar style={{ backgroundColor: '#f56a00' }}>C</Avatar>

                                            </div>
                                        )
                                    )}
                                    <List.Item.Meta
                                        title={item.family_name}
                                        description={''}
                                    />
                                    <List.Item.Meta
                                        title={item.company}
                                    />
                                    <List.Item.Meta
                                        title={item.title}
                                    />
                                    <List.Item.Meta

                                        title={item.email}
                                    />
                                    <List.Item.Meta
                                        title={item.mobile}
                                    />
                                    <List.Item.Meta
                                        title={(item.type=='cl')?'CLIENT':'FREELANCER'}
                                    />



                                    <Button key={'back'} type="text" onClick={()=>this.showDrawer_edit(item)} style={{color:"#999",marginRight:'30px',fontSize:'13px'}}  >查看</Button>


                                </List.Item>
                            )}
                        />



                    </div>

                    <div style={{
                        width: '100%',
                        height: '86px',
                        backgroundColor: '#fff',
                        padding: '30px',
                        marginBottom: '10px',
                        display:"flex",
                        justifyContent:"space-between",
                    }}>
                        <Pagination
                            size="small"
                            defaultPageSize={10}
                            defaultCurrent={this.state.current_page}
                            total={this.state.total_list}
                            onChange={this.onPageChange}
                        />



                    </div>





                    <Drawer
                        width={'60%'}
                        title={'用户：'+this.state.edit_item.family_name}
                        placement="right"
                        closable={false}
                        onClose={this.onCloseDrawer_edit}
                        visible={this.state.Drawereditvisible}

                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <Button key={'child'} type="text" onClick={()=>this.showChildrenDrawer()}  >新建项目</Button>
                            </div>
                        }
                    >
                        {
                            this.state.error ? (
                                <Alert
                                    message="Error!"
                                    description={this.state.errorContent}
                                    type="error"
                                    closable
                                    onClose={this.onClose}
                                />

                            ) : ('')
                        }


                        <Text  style={{color:'#666'}} >账号状态：</Text>
                        {(this.state.edit_item.user_state == 'true')?(
                        < Switch checkedChildren="启用中" unCheckedChildren="审核中" onChange={()=>this.activeUser(this.state.edit_item.member_state,this.state.edit_item.id)} checked={(this.state.edit_item.member_state=='default')?false:true} />
                        ):(
                            '已禁用'
                        )
                        }
                        <div style={{width:'100%',height:'15px'}}> </div>
                        <Descriptions
                            title=""


                        >

                            <Descriptions.Item label="ID">{this.state.edit_item.user_id}</Descriptions.Item>
                            <Descriptions.Item label="邮箱">{this.state.edit_item.email}</Descriptions.Item>
                            <Descriptions.Item label="电话">{this.state.edit_item.mobile}</Descriptions.Item>
                            <Descriptions.Item label="微信ID">{this.state.edit_item.wechat_id}</Descriptions.Item>



                            <Descriptions.Item label="注册日期">{this.state.edit_item.created_at}</Descriptions.Item>

                        </Descriptions>




                        <Divider orientation="center" style={{fontSize:'13px'}}>所持有项目</Divider>

                        <div>
                            <Collapse accordion>
                            {

                                    this.state.edit_item.orders.map((key, index) => (
                                        <Panel header={key.order_name+"   | 项目编号:"+key.order_id} key={index}>
                                            <Row>
                                                <Col span={9}>
                                                    进度: {(key.progress==null)?('项目创建'):(progress_state[key.progress])}
                                                </Col>
                                                <Col span={9}>
                                                    状态: {(key.state=='true')?('正常'):('关闭')}
                                                </Col>
                                                <Col span={6}>
                                                    创建日期: {key.created_at}
                                                </Col>
                                            </Row>

                                        </Panel>
                                        )
                                    )



                            }
                            </Collapse>

                        </div>

                        <Divider/>

                        <Drawer
                            title="新建项目"
                            width={500}
                            closable={false}
                            onClose={this.onChildrenDrawerClose}
                            visible={this.state.childrenDrawer}

                            footer={
                                <div
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >

                                    <Button key={'cancel'} type="text" onClick={()=>this.submitOrder()}  >提交</Button>

                                </div>
                            }

                        >
                            <div style={{width: 'auto', padding: '10px', fontSize: '13px'}}>


                                <Space direction="vertical" size={"middle"} style={{width: '400px'}}>

                                    <Text type="secondary">*项目一旦建立即会实时同步至客户端</Text>

                                    <Input id='order_name' style={{fontSize: '13px'}} placeholder="项目名称"
                                           onChange={this.inputChannge} value={this.state.user_data.order_name}/>

                                    <Input id='department' style={{fontSize: '13px'}} placeholder="部门"
                                           onChange={this.inputChannge} value={this.state.user_data.department}/>


                                    <Select defaultValue={'请选择所需服务'}
                                            style={{width: 250, color: '#999', fontSize: '13px'}}
                                            onChange={this.serviceSelectChange}>
                                        {
                                            this.state.tag_data.tag1_array.map((i, index) => (
                                                <Option value={i} key={index}>{i}</Option>
                                            ))
                                        }


                                    </Select>

                                    <Select defaultValue={'请选择属在行业'}
                                            style={{width: 250, color: '#999', fontSize: '13px'}}
                                            onChange={this.industrySelectChange}>
                                        {
                                            this.state.tag_data.tag2_array.map((i, index) => (
                                                <Option value={i} key={index}>{i}</Option>
                                            ))
                                        }


                                    </Select>


                                    <Text type="secondary"> 项目上线时间 </Text>

                                    <DatePicker style={{borderColor: '#dadada'}} type="primary"
                                                onChange={this.deliverChannge}/>


                                    <Input id='remark' style={{fontSize: '13px'}} placeholder="备注"
                                           onChange={this.inputChannge}/>

                                    <Divider/>

                                </Space>
                            </div>


                        </Drawer>







                    </Drawer>

                    <Modal
                        title="提示"
                        style={{ top: 20 }}
                        visible={this.state.Modalvisible}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>

                            <Text type="secondary">{this.state.errorboxContent}</Text>


                        </div>
                    </Modal>

                </div>
            )
        }
    }
}


export default Clientmanage;