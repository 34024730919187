/**
 * yi 2020
 *redux temp
 *
 */

import React from 'react';
import TopButton from "../components/TopButton";
import {connect} from "react-redux";
import  '../components/global'
import {Link} from "react-router-dom"
import {fullfooter, minfooter, minmenu, navHome} from "../reduceractions";
import Nav_sim from "../components/nav_sim";

import {Calendar, Typography, Steps, Button, message, Modal, Divider, Space, Checkbox, DatePicker} from 'antd';
import {ExclamationCircleOutlined, SolutionOutlined, SettingOutlined, FieldTimeOutlined ,CheckCircleOutlined,PhoneOutlined} from '@ant-design/icons';
import {Yinput} from "../components/Formelements";
import VcodeBtn from "../components/60slimitbutton";
import Http from "../components/functions/ApiBridge";
import moment from "moment";
import Toast from "../components/toast";
import LoadingAnima from "../components/LoadingAnima";

import {

    LoadingOutlined,

} from '@ant-design/icons';


const { Step } = Steps;

const { Text,Title } = Typography;


/*
 *
 * REDUCER  props function
 */

const mapStateToProps = (state) =>({

    footerstate:state.footerstate,
    menustate:state.menustate,
    redux_islog:state.redux_islog,

})


let topbarboxH="200px";
let AtitleSize=25;





//默认情况下 connent dispatch调用
const mapDispatchToProps = (dispatch) => ({

    LoadPageState(){
        dispatch(minfooter())
        dispatch(minmenu())
        dispatch(navHome())
        // console.log(minfooter())
    }
})


class Leaveform extends React.Component {



    constructor(dispatch,props){
        super(dispatch,props)
        this.state ={
            initall:0,
            scrolliconAlpha:1,
            showscrolltop:0,
            current: 0,
            pass_code_btn:'0px',
            mobile:'',
            email:'',
            name:'',
            title:'',
            company:'',
            industry:'',
            service:'',
            deliver:'',
            brief_date:'',
            brief_time:'10:00',
            pass_code:'',
            error:false,
            errorContent:'',
            code:'',
            setpsw:'',
            repsw:'',
            termsCheckbox:false,
            user_pro_data:null,
            user_exp_data:null,
            loading:true,
            calling_time:['10:00','10:30','11:00','11:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00'],

        }


        this.handleScroll =this.handleScroll.bind(this)
        this.clickbtn =this.clickbtn.bind(this)
        this.input_content_contact =this.input_content_contact.bind(this)
        this.send_passcode =this.send_passcode.bind(this)
        this.onClose =this.onClose.bind(this)
        this.nextStep =this.nextStep.bind(this)
        this.onPanelChange =this.onPanelChange.bind(this)
        this.onChangeslete =this.onChangeslete.bind(this)
        this.termsCheck =this.termsCheck.bind(this)
        this.dateonChange_deliver =this.dateonChange_deliver.bind(this)
        this.checkbooking_time =this.checkbooking_time.bind(this)



        this.scrolliconR='20px'
        this.centerViewbox_W='54%'

    }

    componentDidMount() {


    }

    clickbtn(){
        window.scroll(0, -1000);

    }


    handleScroll(){
        this.scrolltopcount=window.pageYOffset
        //console.log(this.scrolltopcount)
        let scrolliconAlpha=1
        if(this.scrolltopcount >= window.innerHeight){
            scrolliconAlpha=0
        }else{
            scrolliconAlpha=1
        }
        this.setState({
            scrolliconAlpha:scrolliconAlpha,
            showscrolltop:1-scrolliconAlpha,
        })

    }

    handleResize(e){
        if(window.innerWidth<1124){

            topbarboxH="200px";
            AtitleSize=20;
        }


    }

    componentWillMount() {
        /*
         *
         * change state
         */
        this.props.LoadPageState();

        this.Get_tags_pro();



        let GMTdate = new Date(moment().subtract(0, 'day'))

        let dStr = GMTdate.getFullYear() + '-' +
            (GMTdate.getMonth() + 1) + '-' +
            GMTdate.getDate()


         console.log('GMTdate:'+GMTdate.getHours())

         //禁止选择当天的情况下，以下判断不生效
        //默认不能选择当天

        if(GMTdate.getDay()==0 || GMTdate.getDay()==6) {
            this.setState({
                brief_date: 'notweekday',
            })
        }else if(GMTdate.getHours()>=0 && GMTdate.getHours()<=12){
            this.setState({
                calling_time:['13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00'],
            },()=>{
                console.log(this.state.calling_time)
            })

        }else if(GMTdate.getHours()>12 && GMTdate.getHours()<=13){
            this.setState({
                calling_time:['16:00','16:30','17:00','17:30','18:00'],
            },()=>{
                console.log(this.state.calling_time)
            })

        }else if(GMTdate.getHours()>13){
            this.setState({
                brief_date: 'nextday',
            })

        }else {
            this.setState({
                brief_date: dStr,
            })
        }



        //

        this.handleResize()
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize.bind(this));
        if(window.innerWidth<1124){
            this.centerViewbox_W='320px'
            this.QAViewbox_W='90%'
            this.setState({
                scrolliconAlpha:0,
                showscrolltop:1
            })

        }

    }


    componentDidUpdate(){

    }

    //close component
    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll)
        window.removeEventListener('resize', this.handleResize.bind(this));
    }


    //props update

    componentWillReceiveProps(){



    }

    dateonChange_deliver(date, dateString){
        this.input_content_contact('deliver',dateString);
    }

    input_content_contact(target,value){

        switch (target){
            case 'mobile':
                this.setState({mobile:value})
                if(value==''){
                    this.setState({pass_code_btn:'0px'})
                }else{
                    this.setState({pass_code_btn:'60px'})
                }
            case 'pass_code':
                this.setState({pass_code:value})
                break
            case 'name':
                this.setState({name:value})
                break
            case 'email':
                this.setState({email:value})
                break
            case 'title':
                this.setState({title:value})
                break
            case 'company':
                this.setState({company:value})
                break
            case 'service':
                this.setState({service:value})
                break;
            case 'industry':
                this.setState({industry:value})
                break;
            case 'deliver':
                this.setState({deliver:value})
                break;
            default:
        }

    }


    /**
     * get tags data
     * @returns {array}
     */

    Get_tags_pro(){

        //请求API

        const pro_data_array=[];

        Http.Get_tag_list(4).then((res)=>{


            Object.keys(res.data).map(key =>
            {
                // console.log(res.data[key].tag_name)
                pro_data_array.push(res.data[key].tag_name)
            })
            this.setState({
                user_pro_data:pro_data_array,
            })

            this.input_content_contact('service',pro_data_array[0]);//初始化数据
            this.Get_tags_exp();

        }).catch((res) => {

            Toast.error(res.message)
        })


    }

    Get_tags_exp(){

        //请求API

        const exp_data_array=[];

        Http.Get_tag_list(2).then((res)=>{
            Object.keys(res.data).map(key =>
            {

                exp_data_array.push(res.data[key].tag_name)
            })
            this.setState({
                user_exp_data:exp_data_array,
                loading:false,
            })
            // console.log(exp_data_array)
           this.input_content_contact('industry',exp_data_array[0]);//初始化数据


        }).catch((res) => {

            Toast.error(res.message)
        })


    }



    onClose = () => {
        //console.log(e, 'I was closed.');
        this.setState({
            error:false,
            errorContent:'',
        })
    };

    /**
     *
     * error
     * @param error
     */

    showErrorbar(error){
        this.setState({
            error:true,
            errorContent:error,
        })
    }

    nextStep(){
        if(this.state.current==0) {
            if (this.state.mobile == '') {
                this.showErrorbar('请输入您的手机号！')

            } else {
                if (this.state.pass_code == '') {
                    this.showErrorbar('请输入验证码！')
                } else {
                    const postdata = {
                        mobile: this.state.mobile,
                        pass_code: this.state.pass_code,
                    }
                    Http.pass_check_leave_form(postdata).then((res) => {

                        this.setState({
                            code: res.data.code,
                            current: 1,
                        })

                     }).catch((res) => {
                         this.showErrorbar(res.message)
                    })
                }
            }
        }else if(this.state.current==1){
            var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;

            if (this.state.name == '' ) {
                this.showErrorbar('请输入您的姓名！')
            }else if(this.state.email == ''){
                this.showErrorbar('请输入您的邮箱地址！')
            }else  if(!myreg.test(this.state.email))
            {
                this.showErrorbar('请输入正确的邮箱地址！')
            }else if(this.state.title == ''){
                this.showErrorbar('请输入您的职位！')
            }else if(this.state.company == ''){
                this.showErrorbar('请输入您的企业名称！')
            }else if(this.state.deliver == ''){
                this.showErrorbar('请选择项目上线日期！')
            }else{


                this.setState({
                    current: 2,
                })


            }

        }else if(this.state.current==2){

            console.log(this.state.brief_date)


            if(this.state.brief_date==''){
                this.showErrorbar('请选择一个日期！')
            }else if(this.state.brief_date=='notweekday'){
                this.showErrorbar('请选择周一至周五工作日进行预约')
            }else if(this.state.brief_date=='nextday'){
                this.showErrorbar('请选择其他日期')
            }else if(!this.state.termsCheckbox){
                this.showErrorbar('请阅读用户协议及隐私条款！')
            }else{

                const postdata = {
                    mobile: this.state.mobile,
                    code: this.state.code,
                    name: this.state.name,
                    email: this.state.email,
                    title: this.state.title,
                    company: this.state.company,
                    brief_date: this.state.brief_date,
                    brief_time: this.state.brief_time,
                    service: this.state.service,
                    industry: this.state.industry,
                    deliver: this.state.deliver,
                }
                console.log('booking time: '+postdata.brief_time)




                Http.submit_leave_form(postdata).then((res) => {

                    this.setState({
                        current: 3,
                    })

                }).catch((res) => {
                    this.showErrorbar(res.message)
                })





            }
        }
    }


    checkbooking_time(request_date){







    }


    filterbooking(filterdata,paredata){
        let base_ar=filterdata
        let new_call_arr=[]
        if(paredata.length>0) {
            for (let i = 0; i < base_ar.length; i++) {

                for (let l = 0; l < paredata.length; l++) {
                    if (paredata[l].remark == base_ar[i]) {
                        console.log(paredata[l].remark)
                       // base_ar[i]=0
                        base_ar.splice(i,1)
                    }
                }
            }
            //筛选已经被booking的时间
            new_call_arr=base_ar
            console.log(new_call_arr)

            //更新默认选择时段

            this.setState({
                brief_time: new_call_arr[0],
            })

            return new_call_arr
        }else{
            console.log('free for booking: '+filterdata)
            //更新默认选择时段
            this.setState({
                brief_time: filterdata[0],
            })
            return filterdata
        }



    }


    onPanelChange(date) {

        const nowdate=new Date()
        // console.log(nowdate.getHours())

        let GMTdate = new Date(date._d)
        // console.log(GMTdate.getDate())

        let dateStr = GMTdate.getFullYear() + '-' +
           (GMTdate.getMonth() + 1) + '-' +
            GMTdate.getDate()

        console.log('GMTdate:'+GMTdate.getDate())
        console.log('todate:'+nowdate.getDate())
        console.log('GMThours:'+GMTdate.getHours())

        let post_data={
           //date:'2020-10-29 00:00:00'
            date:dateStr
         }

        Http.check_leave_form_booking(post_data).then((res)=>{
           console.log('booking record:'+res.data)

           let lsaf=['10:00','10:30','11:00','11:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']

         //  let new_call_arr=this.filterbooking(lsaf,res.data)



        if(nowdate.getMonth()==GMTdate.getMonth() && GMTdate.getDate()-nowdate.getDate()==1){
            console.log('case1')
            if(nowdate.getHours()>=20){
                //8点以后中午以前 只能选第二天下午预约
                let roldata=['13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
                let new_call_arr=this.filterbooking(roldata,res.data)
                this.setState({
                    calling_time:[''],
                },()=>{
                    this.setState({
                        calling_time:new_call_arr,
                    })

                })
            }else{
                let roldata=['10:00','10:30','11:00','11:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
                let new_call_arr=this.filterbooking(roldata,res.data)
                this.setState({
                    calling_time:[''],
                },()=>{

                    this.setState({
                        calling_time:new_call_arr,
                    })

                })
            }

        }else if(nowdate.getMonth()==GMTdate.getMonth() && GMTdate.getDate()==nowdate.getDate()){
            console.log('case2')
            //默认不能选择当天 忽略该判断
            //9点以后中午以前 只能选当天下午预约
            if(nowdate.getHours()>=0 && nowdate.getHours()<=12){
                let roldata=['13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
                let new_call_arr=this.filterbooking(roldata,res.data)
                this.setState({
                    calling_time:new_call_arr,
                },()=>{
                   // console.log(this.state.calling_time)
                })

            }else if(nowdate.getHours()>12 && nowdate.getHours()<=15){
                //12点以后3点以前能选4点以后预约
                let roldata=['16:00','16:30','17:00','17:30','18:00']
                let new_call_arr=this.filterbooking(roldata,res.data)
                this.setState({
                    calling_time:new_call_arr,
                },()=>{
                   // console.log(this.state.calling_time)
                })

            }else if(nowdate.getHours()>15){
                //20点以后只能预约明天下午
                this.setState({
                    brief_date: 'nextday',
                })

            }

        }else{
            console.log('case3')
            let roldata=['10:00','10:30','11:00','11:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00']
            let new_call_arr=this.filterbooking(roldata,res.data)
           // let testtime=['14:20','15:60']
            this.setState({
                calling_time:[''],
            },()=>{

                this.setState({
                    calling_time:new_call_arr,
                })

            })

        }

        if(GMTdate.getDay()==0 || GMTdate.getDay()==6){
            this.showErrorbar('请选择周一至周五工作日进行预约')
            this.setState({
                brief_date: 'notweekday'
            })
        }else {
            let Str = GMTdate.getFullYear() + '-' +
                (GMTdate.getMonth() + 1) + '-' +
                GMTdate.getDate()
            /*
                GMTdate.getHours() + ':' +
                GMTdate.getMinutes() + ':' +
                GMTdate.getSeconds()*/

            this.setState({
                brief_date: Str
            })
        }


        }).catch((res) => {
            this.showErrorbar(res.message)
        })


    }

    onChangeslete(tar,val){
        this.setState({
            brief_time:val
        })
        console.log('selete time:'+val)

    }




    send_passcode(){
        const post_data={
            mobile:this.state.mobile,
        }

        if(this.state.mobile!=''){
            Http.Send_pass(post_data).then((res)=>{
                console.log('pass sent')
            }).catch((res) => {
                this.showErrorbar(res.message)
            })
        }else{
            this.showErrorbar('请输入注册的手机号！')
        }
    }


    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }


    termsCheck(){
        if(this.state.termsCheckbox){
            this.setState({
                termsCheckbox:false,
            })
        }else{
            this.setState({
                termsCheckbox:true,
            })
        }



    }



    render() {

        if (this.state.loading) {
            return( <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <LoadingOutlined style={{fontSize: '20px'}}/>
            </div>);
        } else {

            return (


                <div className='contentView'>
                    <Nav_sim/>
                    <div style={{
                        width: '100%',
                        height: 80,
                        backgroundColor: '#f6d809',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '0px',
                        flexDirection: 'column'
                    }}>


                    </div>

                    {(this.state.current == 3) ? (
                        <div>
                        </div>
                    ) : (


                        <div style={{
                            width: "100%",
                            backgroundColor: '#efefef',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            height: 'auto'
                        }}>
                            <div style={{
                                width: '80%',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '0px',
                                flexDirection: 'column'
                            }}>
                                <span className="ATitle"
                                      style={{paddingTop: '30px', alignSelf: 'center', fontSize: AtitleSize}}>48小时定制您的快平方专业团队 体验专属服务</span>

                                <span className="contentText_center"
                                      style={{paddingTop: '20px'}}>只需填写简单的信息，快平方咨询顾问会在您指定时间与您联系。</span>
                            </div>
                        </div>


                    )

                    }

                    <div className='contentFrameBox'
                         style={{backgroundColor: '#efefef', height: 'auto', minHeight: '70vh', paddingTop: 20}}>

                        <div style={{width: '70%', minWidth: '360px', paddingTop: 0}}>
                            <Steps current={this.state.current} status="process" size={"small"}
                                   labelPlacement={"vertical"}>

                                <Step title="联系人电话" icon={<PhoneOutlined/>}/>
                                <Step title="基本信息" icon={<SolutionOutlined/>}/>
                                <Step title="预约会议" icon={<FieldTimeOutlined/>}/>
                            </Steps>

                        </div>

                        <div style={{
                            width: '70%',
                            minWidth: '360px',
                            backgroundColor: '#fff',
                            height: 'auto',
                            minHeight: 400,
                            marginTop: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            {
                                (this.state.current == 0) ? (
                                    <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>
                                        <Text type="secondary" style={{color: '#666'}}>请输入您的手机号 </Text><span
                                        style={{color: '#FFC300'}}>*</span>
                                        <br/>
                                        <br/>
                                        <Yinput width={300} height='30px' name={'mobile'} bgColor=''
                                                value={this.state.mobile}
                                                preset='+86'
                                                type='phone' limit={11} broderColor={'#D4D4D4'}
                                                callBack={this.input_content_contact}/>

                                        <div className='selfcenter_alignLeft'
                                             style={{
                                                 width: 300,
                                                 height: this.state.pass_code_btn,
                                                 backgroundColor: '',
                                                 overflow: "hidden",
                                                 transition: '0.15s ease-out all'
                                             }}>
                                            <Yinput width={200} height='30px' name={'pass_code'} bgColor=''
                                                    value={this.state.pass_code} preset=''
                                                    type='' limit={11} broderColor={'#D4D4D4'}
                                                    callBack={this.input_content_contact}/>
                                            <div style={{width: 90, height: '30px', float: 'right'}}>
                                                <VcodeBtn canClick={true} width={'100%'} borderColor={"#999"}
                                                          callBack={this.send_passcode}/>
                                            </div>
                                        </div>
                                        <div style={{width: 300, textAlign: "right"}}>
                                            <Button type={"text"} onClick={() => this.nextStep()}>下一步</Button>
                                        </div>

                                    </div>) : ('')

                            }

                            {
                                (this.state.current == 1) ? (
                                    <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>
                                        <Text type="secondary" style={{color: '#000'}}> </Text>
                                        <br/>
                                        <br/>
                                        <div style={{width: 300}}>
                                            <Text type="secondary">姓名 </Text><span style={{color: '#FFC300'}}>*</span>
                                            <Yinput width='100%' height='30px' name='name' broderColor={'#b0b0b0'}
                                                    callBack={this.input_content_contact} marginBottom={'0px'}
                                                    analyze={true} type='' bgColor='#fff' state={""} value={""}
                                                    preset='' limit={60}/>


                                            <br/>

                                            <Text type="secondary">邮箱地址 </Text><span style={{color: '#FFC300'}}>*</span>
                                            <Yinput width='100%' height='30px' name='email' broderColor={'#b0b0b0'}
                                                    callBack={this.input_content_contact} marginBottom={'0px'}
                                                    analyze={true} type='' bgColor='#fff' state={""} value={""}
                                                    preset='' limit={100}/>
                                            <br/>

                                            <Text type="secondary">职位 </Text><span style={{color: '#FFC300'}}>*</span>
                                            <Yinput width='100%' height='30px' name='title' broderColor={'#b0b0b0'}
                                                    callBack={this.input_content_contact} marginBottom={'0px'}
                                                    analyze={true} type='' bgColor='#fff' state={""} value={""}
                                                    preset='' limit={60}/>

                                            <br/>
                                            <Text type="secondary">企业名称 </Text><span style={{color: '#FFC300'}}>*</span>
                                            <Yinput width='100%' height='30px' name='company' broderColor={'#b0b0b0'}
                                                    callBack={this.input_content_contact} marginBottom={'0px'}
                                                    analyze={true} type='' bgColor='#fff' state={""} value={""}
                                                    preset='' limit={120}/>


                                            <Yinput width='100%' height='30px' type='select' bgColor='#fff'
                                                    value={this.state.user_pro_data} name={'service'}
                                                    callBack={this.input_content_contact} broderColor={'#b0b0b0'}
                                                    preset='请选择您需要的服务' limit={20}/>


                                            <Yinput width='100%' height='30px' type='select' bgColor='#fff'
                                                    value={this.state.user_exp_data} name={'industry'}
                                                    callBack={this.input_content_contact} broderColor={'#b0b0b0'}
                                                    preset='请选择您的行业类别' limit={20}/>



                                            <div style={{
                                                width: '100%',
                                                height: '30px',
                                                marginBottom: '20px',
                                                marginTop: '10px'
                                            }}>

                                                <DatePicker bordered={true} className={'antd_style'}
                                                            inputReadOnly={true} onChange={this.dateonChange_deliver}

                                                />
                                                <span className={'inputText'}> 请选择项目上线时间</span>

                                            </div>

                                        </div>


                                        <div style={{width: 300, textAlign: "center", paddingBottom: 30}}>
                                            <Button type={"text"} onClick={() => this.nextStep()}>下一步</Button>
                                        </div>

                                    </div>

                                ) : ('')
                            }

                            {
                                (this.state.current == 2) ? (
                                    <div style={{alignItems: "center", width: 'auto', paddingTop: 0}}>

                                        <div style={{
                                            width: 300,
                                            border: '1px solid #fff',
                                            borderRadius: '2px',
                                            paddingTop: 30
                                        }}>
                                            <Text type="secondary">请选择一个会议时间 </Text>

                                            <Calendar
                                                fullscreen={false}
                                                onSelect={this.onPanelChange}
                                               // defaultValue={moment().add(1, 'day')} //moment add one day
                                                disabledDate={current => {
                                                    return (current < moment().subtract(0, 'day'))
                                                }}

                                            />
                                        </div>
                                        <div style={{paddingLeft: 0, width: '100%'}}>
                                            <Yinput width='100%' height='27px' type='select' bgColor='#fff'
                                                    value={this.state.calling_time} name={'calling_time'}
                                                    callBack={this.onChangeslete} broderColor={'#D4D4D4'}
                                                    preset=' 请选择具体时间' limit={20}/>
                                        </div>

                                        <Checkbox
                                            checked={this.state.termsCheckbox}

                                            onChange={this.termsCheck}
                                        >
                                            <span className={'contentText_center'}
                                                  style={{fontSize: '12px', paddingBottom: 10}}>我已阅读 <Link to='/terms'
                                                                                                           target={'_bank'}
                                                                                                           style={{color: 'orange'}}>《用户协议》</Link>及<Link
                                                to='/privacy' target={'_bank'}
                                                style={{color: 'orange'}}>《隐私政策》</Link></span>

                                        </Checkbox>

                                        <div style={{
                                            width: 300,
                                            textAlign: "center",
                                            paddingBottom: 30,
                                            paddingTop: 20
                                        }}>
                                            <Button type={"text"} onClick={() => this.nextStep()}>提交</Button>
                                        </div>

                                    </div>

                                ) : ('')
                            }
                            {
                                (this.state.current == 3) ? (
                                    <div style={{
                                        alignItems: "center",
                                        width: 'auto',
                                        paddingTop: 0,
                                        textAlign: "center"
                                    }}>
                                        <div style={{paddingBottom: 40}}>
                                            <CheckCircleOutlined
                                                style={{fontSize: 24, paddingRight: 12, color: 'orange'}}

                                            />
                                            <span className='txt03' style={{
                                                color: '#000',
                                                fontSize: 16,
                                                backgroundColor: '',
                                                width: '100%',
                                                alignItems: 'center',
                                                paddingBottom: 10
                                            }}>提交成功 </span><br/>
                                        </div>
                                        <Text type="secondary">感谢您使用快平方服务 </Text><br/>
                                        <Text type="secondary">我们的咨询顾问会在指定时间与您联系。 </Text>

                                    </div>

                                ) : ('')
                            }


                        </div>


                    </div>


                    {(this.state.current == 3) ? (
                        <div style={{height: '10vh', backgroundColor: '#efefef'}}>
                        </div>
                    ) : (''
                    )
                    }

                    <Modal
                        title="提示"
                        visible={this.state.error}
                        footer={false}
                        onCancel={this.onClose}
                    >
                        <ExclamationCircleOutlined style={{color: 'red', paddingRight: 12, fontSize: 20}}/><Text
                        type={"secondary"} style={{color: '#000'}}>{this.state.errorContent}</Text>


                    </Modal>


                </div>
            )

        }
    }

}


/*
 *
 * REDUCER  connent
 */

export default connect(mapStateToProps,mapDispatchToProps)(Leaveform) ;